/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/components/xom-admin-message/xom-admin-message.component.ngfactory";
import * as i3 from "./shared/components/xom-admin-message/xom-admin-message.component";
import * as i4 from "./shared/components/xom-splashscreen/xom-splashscreen.component.ngfactory";
import * as i5 from "./shared/components/xom-splashscreen/xom-splashscreen.component";
import * as i6 from "./app-config.service";
import * as i7 from "@angular/common";
import * as i8 from "@angular/router";
import * as i9 from "./shared/components/shared-modals/shared-modals.component.ngfactory";
import * as i10 from "./shared/components/shared-modals/shared-modals.component";
import * as i11 from "@ngrx/store";
import * as i12 from "./shared/services/content.service";
import * as i13 from "./shared/services/message.service";
import * as i14 from "./app.component";
import * as i15 from "./shared/services/routing.service";
import * as i16 from "./shared/services/user-api.service";
import * as i17 from "@azure/msal-angular";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xom-admin-message", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeBrowserTypeWarning() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_XomAdminMessageComponent_0, i2.RenderType_XomAdminMessageComponent)), i1.ɵdid(1, 49152, null, 0, i3.XomAdminMessageComponent, [], { title: [0, "title"], message: [1, "message"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content.global.BROWSER_TYPE_WARNING.TITLE; var currVal_1 = _co.content.global.BROWSER_TYPE_WARNING.MESSAGE; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xom-admin-message", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeScreenSizeWarning() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_XomAdminMessageComponent_0, i2.RenderType_XomAdminMessageComponent)), i1.ɵdid(1, 49152, null, 0, i3.XomAdminMessageComponent, [], { title: [0, "title"], message: [1, "message"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content.global.SCREEN_SIZE_WARNING_TITLE; var currVal_1 = _co.content.global.SCREEN_SIZE_WARNING_MESSAGE; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xom-splashscreen", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeSplashScreen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_XomSplashscreenComponent_0, i4.RenderType_XomSplashscreenComponent)), i1.ɵdid(1, 114688, null, 0, i5.XomSplashscreenComponent, [i6.AppConfigService], null, { close: "close" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xom-admin-message", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeAdminMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_XomAdminMessageComponent_0, i2.RenderType_XomAdminMessageComponent)), i1.ɵdid(1, 49152, null, 0, i3.XomAdminMessageComponent, [], { title: [0, "title"], message: [1, "message"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.adminMessage.title; var currVal_1 = _co.adminMessage.message; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_5)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.preferences.showSplashScreen && !_co.showAdminMessage); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.showAdminMessage && _co.router.url.includes("/geobrain")); _ck(_v, 4, 0, currVal_1); }, null); }
function View_AppComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_3)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_6)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "xom-shared-modals", [], null, [["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_SharedModalsComponent_0, i9.RenderType_SharedModalsComponent)), i1.ɵdid(10, 180224, null, 0, i10.SharedModalsComponent, [i11.Store, i12.ContentService, i13.MessageService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.browserTypeWarning; _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.browserTypeWarning && _co.screenSizeWarning); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.preferences$)) && !_co.screenSizeWarning) && !_co.browserTypeWarning); _ck(_v, 5, 0, currVal_2); var currVal_3 = !_co.isIframe; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xom-app-root", [], null, [["window", "resize"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i14.AppComponent, [i11.Store, i15.RoutingService, i8.Router, i12.ContentService, i11.Store, i16.UserAPIService, i6.AppConfigService, i17.BroadcastService, i17.MsalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("xom-app-root", i14.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
