import { Action } from '@ngrx/store';

export enum NavigationActionTypes {
    OpenAppFromOtherApp = '[Navigation] App opened from other app',
}


export class OpenAppFromOtherApp implements Action {
    readonly type = NavigationActionTypes.OpenAppFromOtherApp;

    constructor(public payload: { from: string, to: string }) { }
}

export type UserActionsUnion =
    | OpenAppFromOtherApp;
