// tslint:disable:max-line-length
var GlobalContent = /** @class */ (function () {
    function GlobalContent() {
        this.FTUE_PERMITED_ROUTES = [
            '/geobrain/landing',
            '/geobrain/hybrid',
            '/geobrain/map',
            '/geobrain/list'
        ];
        this.AOI_PILL_TEXT = 'AOI Applied';
        this.HIDE_ALL_CTA_TEXT = 'Hide All Duplicates';
        this.SHOW_ALL_CTA_TEXT = 'Show All Duplicates';
        this.SHOW_DETAILS_CTA_TEXT = 'Document Details';
        this.PUBLISHED_IN = 'Published in ';
        this.BY = ' by ';
        this.UNKNOWN = 'Unknown';
        this.EXT = 'EXT';
        this.INT = 'INT';
        this.SUBMIT_BTN_TEXT = 'Submit';
        this.SELECT_AN_OPTION = 'Select an Option';
        this.ALL = 'All';
        this.SS_FOUND = 'Surprising sentences found';
        this.SUGGESTED_SEARCHES_TEXT = 'Suggested Searches';
        this.EXTERNAL_BADGE_TOOLTIP = 'Most likely Externally-sourced documents';
        this.INTERNAL_BADGE_TOOLTIP = 'Most likely Internally-written documents';
        this.GEOLOCATION_FILTER_TITLE = 'Filter by Location';
        this.GEOLOCATION_FILTER_NOTE = 'Select a country to filter by basin and well';
        this.GEOLOCATION_FAILED_FILTER_NOTE = 'Error: Filtered item does not exist';
        this.RESULTS = 'Results';
        this.GISTRI_ATTRIBUTION = 'Data by GISTRI | ';
        this.HEADER_MENU_ITEMS = [
            'Quick Links',
            'Help',
            'Collection',
            'Workspaces',
            'Profile'
        ];
        this.HEADER_MENU_VIEWWORKSPACES = 'View All Workspaces';
        this.HEADER_MENU_VIEWCOLLECTIONS = 'View All Collections';
        this.HEADER_MENU_MAX_ITEMS = 10;
        this.COLLECTION_TXT = 'Collection';
        this.WORKSPACES_TXT = 'Workspaces';
        this.HELP_TXT = 'Help';
        this.QUICK_LINKS_TXT = 'Quick links';
        this.QUICK_LINKS = [
            {
                label: 'Geo Location Info',
                hyperlink: 'https://ishareteam1.na.xom.com/sites/EMECORG08/DNI/_layouts/15/AccessDenied.aspx?Source=https%3A%2F%2Fishareteam1%2Ena%2Exom%2Ecom%2Fsites%2FEMECORG08%2FDNI%2FSitePages%2FGeoscience%20Data%20Sources%2Easpx&Type=item&name=1ee17f2f%2Dd5c3%2D4901%2Daf5a%2D6af753ed4941&listItemId=38',
                newWindow: true
            },
            {
                label: 'Geoscience Data Sources',
                hyperlink: 'https://ishareteam1.na.xom.com/sites/EMECORG08/DNI/_layouts/15/AccessDenied.aspx?Source=https%3A%2F%2Fishareteam1%2Ena%2Exom%2Ecom%2Fsites%2FEMECORG08%2FDNI%2FSitePages%2FGeoscience%20Data%20Sources%2Easpx&Type=item&name=1ee17f2f%2Dd5c3%2D4901%2Daf5a%2D6af753ed4941&listItemId=38',
                newWindow: true
            },
            {
                label: 'BD Geoscience Data',
                hyperlink: 'https://ishareteam2.na.xom.com/sites/emecnewop/Global%20Projects/SitePages/Home.aspx',
                newWindow: true
            },
            {
                label: 'BD Home',
                hyperlink: 'https://ishareteam2.na.xom.com/sites/emecnewop/SitePages/Home.aspx',
                newWindow: true
            },
            {
                label: 'Release Notes',
                hyperlink: 'https://ishareteam8.na.xom.com/sites/Geobrain/SitePages/Release%20Notes.aspx',
                newWindow: true
            }
        ];
        this.PROFILE_BTN_TEXT = 'Profile';
        this.PERSONAL_INFO_TEXT = 'Personal Information';
        this.AREAS_OF_INTEREST_TEXT = 'Areas of Interest';
        this.COUNTRY_TEXT = 'COUNTRY';
        this.BASIN_TEXT = 'BASIN';
        this.BLOCK_TEXT = 'BLOCK';
        this.FIELDS_TEXT = 'FIELDS';
        this.WELL_TEXT = 'WELL';
        this.CHANGE_AOI_BTN_TEXT = 'Change Default AOI';
        this.HOMEPAGE_SETTINGS_TEXT = 'Homepage Settings';
        this.PERSONAL_WORKSPACE_TEXT = 'PERSONAL WORKSPACE';
        this.TEMPLATE_WORKSPACE_TEXT = 'TEMPLATE WORKSPACE';
        this.SEARCH_SETTINGS_TEXT = 'Search Preferences';
        this.SELECT_BTN_TEXT = 'Select';
        this.SAVE = 'Save';
        this.SET_SEARCH_TEXT = 'DEFAULT APPLICATION AFTER SEARCH';
        this.SET_HOMEPAGE_TEXT = 'SET HOMEPAGE';
        this.SET_HOMEPAGE_DROPDOWN = [
            {
                label: 'Geobrain Landing',
                value: 'homepage',
                hyperlink: '#',
            },
            {
                label: 'Personal Workspace',
                value: 'workspace',
                hyperlink: '#',
            },
            {
                label: 'Template Workspace',
                value: 'workspace',
                hyperlink: '#',
            }
        ];
        this.SET_VIEW_TEXT = 'VIEW AS';
        this.VIEW_DROPDOWN = [
            {
                label: 'Map + Results',
                value: 'hybrid',
            },
            {
                label: 'Results',
                value: 'list',
            },
            {
                label: 'Map',
                value: 'map',
            }
        ];
        this.EXCLUDED_SEARCH_APPS = [
            'workspaces-app',
            'new-documents'
        ];
        this.HELP_ITEMS = [
            {
                label: 'Re-run Tutorial',
                value: 'tutorial'
            },
            {
                label: 'Submit Feedback',
                value: 'feedback',
            },
            {
                label: 'Get Help',
                value: 'help',
            },
            {
                label: 'GeoBrain SharePoint Site',
                value: 'link',
                hyperlink: 'https://goto/geobrain',
                newWindow: true,
                icon: 'details-white'
            },
            {
                label: 'Data Privacy Notice',
                value: 'link',
                hyperlink: 'https://ishareteam8.na.xom.com/sites/Geobrain/SiteAssets/GeoBrain/dataprivacy.html',
                newWindow: true
            }
        ];
        this.SET_SEARCH_RESULTS_VIEW_TEXT = 'SET SEARCH RESULTS VIEW';
        this.SET_SEARCH_RESULTS_VIEW_DROPDOWN = [
            {
                label: 'List+Map',
                hyperlink: '#',
            },
            {
                label: 'Map',
                hyperlink: '#',
            },
            {
                label: 'List',
                hyperlink: '#',
            }
        ];
        this.SET_WORKSPACE_HOMEPAGE_TEXT = 'SET WORKSPACE AS HOMEPAGE';
        this.LAST_WORKSPACE_USED_TEXT = 'LAST WORKSPACE USED';
        this.NOTIFICATION_PREFERENCES_TEXT = 'Weekly Notification Preferences';
        this.WORKSPACE_EMAIL_NOTIFICATIONS_TEXT = 'WORKSPACE EMAIL NOTIFICATIONS';
        this.WORKSPACE_EMAIL_NOTIFICATIONS_TOGGLES = [
            {
                label: 'Email confirmation when I share a workspace'
            },
            {
                label: 'Email notification when a workspace has been shared with me'
            },
            {
                label: 'Receive bi-weekly email notifications of new results for all of my workspaces'
            }
        ];
        this.SPECIFIC_WORKSPACE_EMAIL_NOTIFICATIONS_TEXT = 'SPECIFY WORKSPACES TO RECEIVE EMAIL NOTIFICATIONS OF NEW RESULTS';
        this.NONE_AVAILABLE = 'None Available';
        this.SHOW_ALL = 'Show All Workspaces';
        this.SHOW_LESS = 'Show Less';
        this.SHOW_MORE = 'Show More';
        this.TOGGLEON = 'On';
        this.TOGGLEOFF = 'Off';
        this.WALKTHROUGH_DATA = [
            { title: 'Select Data by AOI',
                text: 'Set an area of interest from the AOI manager.',
                extraInfo: 'Choose between the Draw Polygon or Select GeoBody buttons.',
                image: '/assets/images/walkthrough/gif_01_AOI.gif' },
            { title: 'Manage your AOI',
                text: 'Manage the GeoBodies in your selected AOI here.',
                image: '/assets/images/walkthrough/gif_02_select_geobodies.gif' },
            { title: 'Search for Images or Documents',
                text: 'Search for documents and/or images by phrases here.',
                extraInfo: 'You can use geolocations (e.g., “Ecuador”) and image classifications (e.g., “Ecuador Lead Maps”) to refine your search.',
                image: '/assets/images/walkthrough/gif_03_search.gif' },
            { title: 'Use Filters',
                text: 'For a more specific search, use one of the four filters available: Geo Location, Geo Ages, Result Type, and Word Cloud.',
                image: '/assets/images/walkthrough/gif_04_filters.gif' },
            { title: 'Filter by Date',
                text: 'With this filtering tool, select a portion of the graphic to find files in a specific period of time measured by geologic date.',
                image: '/assets/images/walkthrough/gif_05_geodates.gif' },
            { title: 'Search Results',
                text: 'Results are divided by images and documents. GeoBrain will show you how many results you have and the time it took to perform the search.',
                image: '/assets/images/walkthrough/gif_06_search results.gif' },
            { title: 'Document Details',
                text: 'Clicking “Document Details” on a result card will give you a description and important information about the document.',
                image: '/assets/images/walkthrough/gif_07_document details.gif' },
            { title: 'Image Preview Mode',
                text: 'Clicking on the image card will show more information and similar images in “Image Preview Mode”.',
                image: '/assets/images/walkthrough/gif_08_image_preview.gif' },
            { title: 'Document Collection',
                text: 'A document collection allows you to save related documents in the same place. You can create one or add documents to an existing one with this button.',
                image: '/assets/images/walkthrough/gif_09_add_collections.gif' },
            { title: 'Document Collection',
                text: 'You can access all your collections here.',
                image: '/assets/images/walkthrough/gif_10_add_collection.gif' },
            { title: 'Geo-Sentiment',
                text: 'Click "Run Analysis" from a collection page to view a sentiment analysis and collect key insights from document phrases.',
                image: '/assets/images/walkthrough/gif_11_streamgraph_1.gif' },
            { title: 'New Feature – Workspaces!',
                text: 'A workspace includes your current map layer set, AOI, filters, and search terms.',
                extraInfo: 'You can save multiple workspaces and share them with others.',
                image: '/assets/images/walkthrough/gif_12_save_workspace.gif' },
            { title: 'Select a Default Workspace',
                text: 'To get started faster, select a workspace with default settings.',
                extraInfo: 'Customize and save your own workspace.',
                image: '/assets/images/walkthrough/gif_13_select_default_workspace.gif' }
        ];
        this.WALKTHROUGH_INTRO_TITLE = 'Welcome to GeoBrain';
        this.WALKTHROUGH_INTRO_TEXT = 'GeoBrain workbench provides the resources to enhance your opportunity investigation.<br>To get you started, let\'s take a tour of the key features together.';
        this.WALKTHROUGH_INTRO_CTA = 'Start tour';
        this.WALKTHROUGH_BACK_CTA = 'Back';
        this.WALKTHROUGH_NEXT_CTA = 'Next';
        this.WALKTHROUGH_FINISH_CTA = 'Finish';
        this.WALKTHROUGH_SKIP_CTA = 'Skip';
        this.WALKTHROUGH_SKIP_TEXT = 'Find the tutorial and additional help by clicking on this tab.';
        this.WALKTHROUGH_SKIP_BTN = 'Got It';
        this.SEARCH_BAR = {
            TEXT: 'Search keywords or phrases',
            BUTTON_TEXT: 'Search',
            TOOLTIP: {
                0: 'Use quotes for exact phrases.',
                1: 'Logical operators (e.g., NOT, AND, OR) are supported.',
                2: 'Click the \'i\' for more information.'
            },
            TOOLTIP_LINK: 'https://ishareteam8.na.xom.com/sites/Geobrain/HelpDocuments/GeoBrain%20Search%20Cheatsheet_BooleanLogic.pptx'
        };
        this.FACET_TREE_UPPERCASE = ['fileExtension', 'source'];
        this.FILE_PATH_COPIED_MODAL = {
            TITLE: 'File path copied to your clipboard',
            TEXT: ['Please open A New Tab or File Explorer and paste (Ctrl + V) path to open file.', 'The document will open provided you have access.'],
            HELP_LINK: 'https://ishareteam8.na.xom.com/sites/Geobrain/HelpDocuments/Opening%20a%20File%20in%20EDGE.pptx',
            HELP_LINK_TEXT: 'Click here for further help.',
            CLOSE: 'Close'
        };
        this.NO_DOCUMENTS_TITLE = 'Showing 0 Documents Results.';
        this.NO_DOCUMENTS_TEXT = 'At this time your search didn\'t show any Documents results.<br>You can try doing another search with other terms.';
        this.NEW_DOCUMENTS_TITLE = 'New Results';
        this.NO_NEW_DOCUMENTS_TITLE = '0 New Results.';
        this.NO_NEW_DOCUMENTS_TEXT = 'No new results for your workspace at this time';
        this.CLEAR_RESULTS = 'Clear New Results';
        this.CLEAR_RESULTS_MESSAGE = 'When you clear the results you will not be able to see this view again. Are you sure you want to continue?';
        this.CLEAR_RESULTS_CONFIRM = 'Yes';
        this.CLEAR_RESULTS_CANCEL = 'Cancel';
        this.NO_IMAGES_TITLE = 'Showing 0 Image Results.';
        this.NO_IMAGES_TEXT = 'At this time your search didn\'t show any Images results.<br>You can try doing another search with other terms.';
        this.NO_SEARCH_TITLE = 'Image search requires a Geo Location.';
        this.NO_SEARCH_TEXT = 'Please add an AOI to the map or a Geo Location to the search for image results.';
        this.NO_SEARCH_LINK = 'For help with images click here';
        this.GET_HELP_MODAL_MESSAGE = [
            'For GeoBrain support please contact',
            'GTLink via Skype',
            'For Help Documents',
            'Click Here'
        ];
        this.GEOCHAT_EMAIL = 'sip:geochat@exxonmobil.com';
        this.HELP_DOCUMENTS = 'https://ishareteam8.na.xom.com/sites/Geobrain/SitePages/Help%20Documents.aspx';
        this.GIVE_FEEDBACK_MODAL = {
            TITLE: 'Submit Feedback',
            EMOJI_TITLE: 'What do you think about GeoBrain?',
            SUBJECT_TEXT: 'SUBJECT',
            SUBJECT_PLACEHOLDER: 'Write the subject of your feedback',
            CATEGORY_TEXT: 'CATEGORY',
            CATEGORY_PLACEHOLDER: 'Select the category that best describes your feedback',
            CATEGORY_DROPDOWN: [{ label: 'Defect' }, { label: 'Enhancement' }, { label: 'Comment' }, { label: 'Other' }],
            DESCRIPTION_PLACEHOLDER: 'Describe in detail how we can help you',
            ADD_ATTACHMENT: 'Add an attachment',
            ATTACHMENT_EXT: '.pdf,.doc,.docx,.jpg,.png,.xlsx',
            HERE: 'here',
            VIEW_FEEDBACK: 'View Feedback',
            CANCEL_CTA: 'Cancel',
            SUBMIT_CTA: 'Submit',
            VIEW_FEEDBACK_LINK: 'https://ishareteam8.na.xom.com/sites/Geobrain/_layouts/15/start.aspx#/Lists/GeoBrainAppFeedback/AllItems.aspx',
            FEELINGS: [
                {
                    subject: 'Idea',
                    category: 'Enhancement',
                    description: 'Idea',
                    assetActive: '/assets/icons/lightbulb_active.svg',
                    assetInactive: '/assets/icons/lightbulb_inactive.svg'
                },
                {
                    subject: 'Positive Experience',
                    category: 'Positive Feedback',
                    description: 'It is great!',
                    assetActive: '/assets/icons/happy-active.svg',
                    assetInactive: '/assets/icons/happy-inactive.svg'
                },
                {
                    subject: 'Neutral Experience',
                    category: 'Neutral Feedback',
                    description: 'It is ok.',
                    assetActive: '/assets/icons/neutral-active.svg',
                    assetInactive: '/assets/icons/neutral-inactive.svg'
                },
                {
                    subject: 'Negative Experience',
                    category: 'Negative Feedback',
                    description: 'I don’t like it.',
                    assetActive: '/assets/icons/sad-active.svg',
                    assetInactive: '/assets/icons/sad-inactive.svg'
                },
                {
                    subject: 'Bug',
                    category: 'Bug',
                    description: 'Bug',
                    assetActive: '/assets/icons/ladybug_active.svg',
                    assetInactive: '/assets/icons/ladybug_inactive.svg'
                }
            ]
        };
        this.LANDING_PANEL = {
            workspace_title: 'Workspaces',
            collection_title: 'Collections',
            welcome_intro: "\n        <h1>What's new in GeoBrain today?</h1>\n        <br>\n        <span>We have moved the Image and Document Finder to the side bar.</span>\n        <br>\n        <span>Why? It allows us to bring in many more modules for cool stuff to help you do business faster.<span>\n        <br>\n        <span>e.g. <strong>PaleoGISTRI, Basin Analogs</strong></span>\n        ",
            no_workspaces: 'After you create a workspace, it will appear here.',
            collection_card_count_label: 'DOCUMENTS',
            counts_slide: {
                title: 'GeoBrain is growing...',
                image_subtext: 'Total Images Ingested',
                file_subtext: 'Total Files Ingested'
            },
            workspace_slide: {
                title: 'Since your last visit...',
                subtext: [
                    'New results in Workspace "',
                    '".'
                ]
            },
            slides: []
        };
        this.CLEAR_BTN_TEXT = 'Clear';
        this.CLEAR_ALL_FILTERS_BTN_TEXT = 'Clear All Filters';
        this.RESET_ALL_BTN_TEXT = 'Reset All';
        this.ADD_TO_COLLECTION_BTN = 'Add to Collection';
        this.GEO_SENTIMENT_BTN = 'Geo Sentiment';
        this.VIEW_ALL_RESULTS_BTN = 'View All Results';
        this.IMG_DOC_RESULT_MESG = [
            'Image',
            'Result',
            'Results',
            'out of',
            'in',
            'sec',
            'Unique Document'
        ];
        this.NO_IMAGE_RESULT = '0 Image Result';
        this.WORKSPACE = 'Workspace';
        this.VIEW_ALL_IMAGES_BTN = 'View All Images';
        this.IMAGES_INCLUDED_TERMS_TEXT = 'Hover to see all included image search terms';
        this.IMAGES_INCLUDED_GEOLOCATIONS = 'Geolocations included in image search: ';
        this.SELECTED_AOI = 'Selected AOI';
        this.DETAILS_PANEL = {
            KEY_PHRASES: 'Key Phrases',
            SUBTITLE_IMAGES: 'Images found in document',
            KP_STATUS_INIT: 'Collapse Key Phrases',
            KP_STATUS_POS: 'Expand Key Phrases',
            IMAGE_STATUS_INIT: 'View All Images',
            IMAGE_STATUS_POS: 'Collapse Images',
            COPY_TO_CLIPBOARD: 'Copy file path to clipboard',
            NO_IMAGES: 'There are no images for this file',
            SUBTITLE_CLASSFICICATION: 'Classifications',
            BULLET_SCALE: 'Most Likely Scale:',
            BULLET_CLASS: 'Document Class:',
            BULLET_THEME: 'Most Likely Theme:',
            SURPRISING_SENTENCES: 'Surprising Sentences',
            SS_STATUS_INIT: 'Collapse Surprising Sentences',
            SS_STATUS_POS: 'Expand Surprising Sentences',
            PUBLISHED: 'Published: ',
            AUTHOR: 'Author(s): ',
            GEOLOCATION: 'Geolocations',
            CITATION: 'Citation',
            NO_FILE_PATH: 'No File Path',
            UNKNOWN: 'Unknown',
            UNAVAILABLE: 'Unavailable'
        };
        this.PUBLISHED_YEAR_FILTER = {
            INCLUDED_UNKNOW: 'Include unknown publication date',
            DOCUMENTS: 'documents',
            HIGHER_DESC: ' has the most documents, '
        };
        this.SCREEN_SIZE_WARNING_TITLE = 'Improve your experience';
        this.SCREEN_SIZE_WARNING_MESSAGE = 'For the best experience please zoom out using keyboard command "<strong>Ctrl</strong> & <strong>-</strong>" until this message disappears';
        this.IMAGE_FEEDBACK_PANEL = {
            ENTERING_BTN: 'Re-Classify Image',
            TITLE: 'Request to Update Metadata',
            SECTION_1_SUBTITLE: 'Select Incorrect Classifications',
            SECTION_2_SUBTITLE: 'Update Classifications',
            SECTION_3_SUBTITLE: 'Select Incorrect Geo Locations',
            SECTION_4_SUBTITLE: 'Add New Geo Locations',
            LABELS: {
                CLASSIFICATION: 'CLASSIFICATION',
                SUBCLASSIFICATION: 'SUBCLASSIFICATION',
                COUNTRY: 'COUNTRY',
                BASIN: 'BASIN',
                BASINS: [{ label: 'Basins' }],
                GEOTAGS_ADDED: 'Geotags Added'
            },
            BUTTONS: {
                CANCEL: 'Cancel',
                REQUEST_UPDATES: 'Request Updates'
            }
        };
        this.BROWSER_TYPE_WARNING = {
            TITLE: 'Improve your experience',
            MESSAGE: 'GeoBrain is optimized for Edge/Chrome. Some features will not work in Internet Explorer.'
        };
        this.MORE = '+ More';
        this.APPLY = 'Apply';
        this.RESET = 'Reset';
        this.IMAGES_HELP_URL = 'https://ishareteam8.na.xom.com/sites/Geobrain/HelpDocuments/GeoBrain%20Images%20Application.pptx';
        this.SPIDER_CHART = {
            LINE_COUNT: 'Line Count:',
            DOCUMENTS_COUNT: 'Documents Count:',
            COLOR_POOL: [
                { line: '#540536', fill: '#986986' },
                { line: '#880c58', fill: '#c385ab' },
                { line: '#a62072', fill: '#d28fb8' },
                { line: '#d84196', fill: '#eba0ca' },
                { line: '#d0598e', fill: '#f1bace' },
                { line: '#e3759d', fill: '#f1bace' },
                { line: '#f1a0bd', fill: '#f8cfde' },
                { line: '#f5b9ce', fill: '#fadce6' },
                { line: '#ffd9e7', fill: '#ffecf3' },
                { line: '#feeaf2', fill: '#fef2f7' }
            ],
            MAIN_COLOR: '#012f6c'
        };
        this.CANCEL = 'Cancel';
        this.OK = 'Ok';
        this.SNACKBAR_NOTIFICATION = {
            COLLECTIONS: {
                CREATED: 'Collection created',
                NOT_CREATED: 'Collection couldn\'t be created',
                UPDATED: 'Collection updated',
                NOT_UPDATED: 'Collection couldn\'t be updated',
                DELETED: 'Collection deleted',
                NOT_DELETED: 'Collection couldn\'t be deleted',
                DOWNLOAD: 'Files exported successfully, download will start soon',
                PINNED: 'Collection successfully pinned',
                NOT_PINNED: 'Collection could not be pinned',
                UNPINNED: 'Collection successfully unpinned',
                NOT_UNPINNED: 'Collection could not be unpinned',
            }
        };
        this.APP_STORE = {
            TITLE: 'App Store',
            ICON: 'app-store',
            DEFAULT_APPS: ['seisbook-app', 'landing-app', 'summary-app', 'images-app', 'documents-app', 'collections-app', 'sentiment-app', 'workspaces-app'],
            PERMANENT_APPS: ['seisbook-app', 'landing-app', 'documents-app', 'images-app'],
            MORE_APPS: 'More Apps',
            MORE_APPS_ICON: 'more-apps',
            GO_TO_APP_STORE: '+ Go to App Store'
        };
        this.GEO_UNIT_SELECTOR = {
            ALL: 'All',
            SELECT_COUNTRY: 'Select a Country',
            SELECT_BASIN: 'Select a Basin',
            SELECT_WELL: 'Select a Well',
            SELECT_BLOCK: 'Select a Block',
            BASIN: 'BASIN',
            WELL: 'WELL',
            BLOCK: 'BLOCK',
            COUNTRIES: 'COUNTRIES'
        };
        this.BAR_GRAPH = {
            TITLE: 'Bar Graph',
            ICON_TITLE: 'Keywords',
            MESSAGE: {
                TITLE: 'Please Select a Basin and a Keyword',
                SUBTITLE: 'To generate the bar graph select at least one basin and one keyword'
            },
            CHART: {
                ENRICHMENT: 'Enrichment',
                SCORE: 'Score',
                KEYWORDS: 'Keywords'
            },
            TOOLTIP: {
                CONTENT: 'The enrichment score is a way to fairly compare different keyword/basin co-occurance counts despite un-even levels of reporting on different keyword topics or basins. Raw counts are divided by the total count of a given keyword (across all basins) and the total count of the relevant basin (across all keywords). The normalized counts are further adjusted so as to be on a more readable scale, leading to the enrichment score.'
            },
            MODAL: {
                ENRICHMENT_SCORE: 'Enrichment Score:',
                OBSERVATION_COUNT: 'Observation Count:',
                SOURCE_COUNT: 'Source Count:'
            }
        };
        this.BROWSER_WARNING = 'You are about to navigate away from GeoBrain and your progress will be lost, save a workspace to maintain your map state and search terms';
        this.GEOSENTIMENT_TIME_WARNING = 'Geo Sentiment for basins with large volumes of data may take several minutes to render.';
        this.BLOCKS_MESSAGE = 'The information for blocks that we have available is associated with the wells found in your AOI.';
        this.FTUE = {
            STATIC_OPTIONS: [
                {
                    title: 'Perform a Search',
                    description: "You can find documents, " +
                        "images and valuable information for your investigation " +
                        "through the search bar or by setting an area of interest (AOI).",
                    image: 'search.png'
                },
                {
                    title: 'Profile',
                    description: "Set and manage your GeoBrain preferences in the profile page. Here you can set your homepage, default search apps, and notification preferences.",
                    image: 'profile.png'
                },
                {
                    title: 'Workspaces',
                    description: "A workspace saves your state in GeoBrain so that you can easily pick up where you last left off. A workspace will maintain your layers, map orientation, search terms, apps, and AOI. Get started with template workspaces published by super-users or build and save your own personal workspace.",
                    image: 'dropdown.png',
                    icon: 'workspaces-app'
                }
            ],
            DINAMIC_OPTIONS: [{
                    title: 'GeoBrain Apps',
                    description: "GeoBrain offers you multiple tools that will support your investigation",
                    subDescription: "Click on the applications below to see how they work."
                }],
            OPTIONS: ['Back', 'Next', 'Finish'],
            DINAMIC_SECTION_INFO: ['Investigation Tools', 'Map Tools'],
            MAP_TOOLS: [
                {
                    title: 'AOI Manager',
                    icon: 'polygon',
                    description: 'Start a search by setting an area of interest (AOI) from the AOI manager. Draw a polygon on the map or select individual geo units to focus your search on a specific location.',
                    videoUrl: 'https://ishareteam8.na.xom.com/sites/Geobrain/HelpDocuments/Videos/AOI%20Voice.mp4'
                },
                {
                    title: 'Layer Manager',
                    icon: 'layers',
                    description: 'You can activate the visualization of different layers on the map here for georeferenced data. Customize your map by adding or removing layers and altering their transparency and order. Browse all available layers in the layer store.',
                    videoUrl: ''
                },
                {
                    title: 'Legend',
                    icon: 'legend',
                    description: 'Use the legend to identify symbologies on the map from layers you have turned on.',
                    videoUrl: 'https://ishareteam8.na.xom.com/sites/Geobrain/HelpDocuments/Videos/Legend%20Voice.mp4'
                },
                {
                    title: 'Measurement Tool',
                    icon: 'measure',
                    description: 'Use the measuring tool to find the distance between two points or the area of a region on the map.',
                    videoUrl: 'https://ishareteam8.na.xom.com/sites/Geobrain/HelpDocuments/Videos/Measuring%20Tool%20Voice.mp4'
                },
                {
                    title: 'Basemaps',
                    icon: 'map',
                    description: 'Use this tool to change your basemap and help with visibility.',
                    videoUrl: 'https://ishareteam8.na.xom.com/sites/Geobrain/HelpDocuments/Videos/Basemap%20Voice.mp4'
                }
            ]
        };
        this.WELCOME_CONTENT = {
            TITLE: 'Welcome to GeoBrain',
            MESSAGE: 'GeoBrain workbench provides the resources to enhance your opportunity investigation. To get you started, let’s take a tour of the key features together.',
            OPTIONS: {
                MESSAGE: 'Please see our Data Privacy Notice ',
                OPTION_1: 'Here',
                OPTION_2: 'Next'
            }
        };
        this.ESC_KEY_CODE = 27;
        this.WORK_SPACE_LOADING_SCREEN = {
            title: 'Your Workspace is Loading',
            subTitle: 'We are restoring your map, search terms, filters, and apps',
            cancelLabel: 'Cancel'
        };
        this.TRANSCRIPT = {
            topClassificationScale: 'Scale',
            topClassificationTheme: 'Theme',
            topDocumentClassification: 'Class'
        };
        this.PROMOTE_DIALOG = {
            title: 'Promote a copy of this workspace',
            button: 'Promote',
            select: 'Select',
            promoteAs: 'PROMOTE AS'
        };
        this.PROMOTE_MODAL = {
            title: 'Promote this Workspace',
            editMessage: {
                strong: 'You will not be able to edit this workspace copy once it has been promoted.',
                text: 'All users will have read only access. You will retain edit access only for your personal workspaces.'
            },
            descriptionMessage: 'Add a description of your workspace before sharing it with other colleagues',
            textAreaPlaceHolder: 'Add a description of your workspace',
            nameLabel: 'NAME',
            cancelButton: 'Cancel',
            publishButton: 'Publish'
        };
    }
    return GlobalContent;
}());
export { GlobalContent };
var ERROR_MESSAGE_HANDLING = {
    HTTP_FAILURE_RESPONSE: 'Http failure response',
    STATUS: 'Status: '
};
export { ERROR_MESSAGE_HANDLING };
