import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { asyncScheduler, EMPTY as empty, Observable, of, defer, merge, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';

import {
    catchError,
    map,
    switchMap,
    concatMap,
    withLatestFrom,
} from 'rxjs/operators';

import { WorkspacesActions } from '../actions';
import { WorkspaceService } from '@app/modules/workspaces/services/workspace.service';
import { NotificationService } from '@app/modules/workspaces/services/notification.service';
import { Workspace } from '../models/UserAPI.model';
import { WORKSPACE_ACTION_TYPE, HydrateWorkspace } from '@app/actions';
import * as fromGis from '@gis/reducers/index';
import { MapLayerActions, PopupActions } from '@app/modules/gis/actions';
import { LatLng } from 'leaflet';

/**
 * Effects offer a way to isolate and easily test side-effects within your
 * application.
 *
 * If you are unfamiliar with the operators being used in these examples, please
 * check out the sources below:
 *
 * Official Docs: http://reactivex.io/rxjs/manual/overview.html#categories-of-operators
 * RxJS 5 Operators By Example: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35
 */

@Injectable()
export class WorkspacesEffects {

    @Effect()
    updateWorkspaces$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<WorkspacesActions.UpdateWorkspace | WorkspacesActions.CreateWorkspace |
                   WorkspacesActions.DeleteWorkspace | WorkspacesActions.LoadAllWorkspaces>(
                WorkspacesActions.WorkspacesActionTypes.UpdateWorkspace,
                WorkspacesActions.WorkspacesActionTypes.CreateWorkspace,
                WorkspacesActions.WorkspacesActionTypes.DeleteWorkspace,
                WorkspacesActions.WorkspacesActionTypes.LoadAllWorkspaces
            ),
            concatMap(workspace => {
                return combineLatest([
                    this.workspacesService.loadWorkspaces(),
                    this.workspacesService.loadPersonalWorkspaces(),
                    this.workspacesService.loadTemplateWorkspaces()
                ]).pipe(
                    map(([all, personal, template]) => {
                        this.workspacesService.resetNameValidation();
                        this.workspacesService.getTitlesOfWorkspacesToSetNames(personal);
                        this.workspacesService.getTitlesOfWorkspacesToSetNames(template);
                        return new WorkspacesActions.LoadAllWorkspacesSuccess(all, personal, template);
                    }
                    ),
                    catchError(err => of(new WorkspacesActions.LoadAllWorkspacesFailure(err)))
                );
            })
        )


    @Effect({ dispatch: false })
    workspaceLoaded$ = (): Observable<
        Action
    > =>
        this.actions$.pipe(
            ofType<WorkspacesActions.LoadWorkspace>(
                WorkspacesActions.WorkspacesActionTypes.LoadWorkspace,
            ),
            switchMap(action => {
                this.workspacesService.currentWorkspace = action.payload;
                if (action.payload) {
                    this.workspacesService.openSnackbar('loaded', action.payload.title);
                    const popup = action.payload.appState.find(item => item.app === 'popup');
                    /*
                    if (popup) {
                        const data = JSON.parse(popup.data);
                        if (data.features && data.features.length) {
                            const layer = data.features[0].feature;
                            if (layer && layer.geometry.coordinates) {
                                return of(new PopupActions.OpenPopup(
                                    new LatLng(layer.geometry.coordinates[0], layer.geometry.coordinates[1])
                                ));
                            }
                        }
                    }
                    */
                }
                return empty;
            }))

    @Effect()
    updateNotifications$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<WorkspacesActions.LoadNotifications>(
                WorkspacesActions.WorkspacesActionTypes.LoadNotifications

            ),
            concatMap(workspace => {
                return this.notificationsService.getNotifications().pipe(
                    map(notifications => {
                        return new WorkspacesActions.LoadNotificationsSuccess(notifications);
                    }),
                    catchError(err => of(new WorkspacesActions.LoadNotificationsFailure(err)))
                );
            })
        )

    @Effect()
    createNotification$ = (): Observable<
        Action
    > =>
        this.actions$.pipe(
            ofType<WorkspacesActions.CreateNotification>(
                WorkspacesActions.WorkspacesActionTypes.CreateNotification,
            ),
            switchMap(action => {
                return this.notificationsService.createNotification({workspaceId: action.payload}).pipe(
                    map(res => {
                        if (res) {
                            return new WorkspacesActions.LoadNotifications();
                        }
                    })
                );
            })
        )

    @Effect()
    deleteNotification$ = (): Observable<
        Action
    > =>
        this.actions$.pipe(
            ofType<WorkspacesActions.DeleteNotification>(
                WorkspacesActions.WorkspacesActionTypes.DeleteNotification,
            ),
            switchMap(action => {
                return this.notificationsService.deleteNotification(action.payload).pipe(
                    map(res => {
                        if (res) {
                            return new WorkspacesActions.LoadNotifications();
                        }
                    })
                );
            })
        )


    constructor(
        private actions$: Actions,
        private workspacesService: WorkspaceService,
        private notificationsService: NotificationService,
        private store$: Store<fromGis.State>
    ) { }
}
