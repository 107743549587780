import { Injectable, Inject } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import * as esri from 'esri-leaflet';
import * as fromGis from '@gis/reducers/index';
import { Store } from '@ngrx/store';
import { AppConfigService } from '@app/app-config.service';
import { FeatureCollection } from 'geojson';
import { MapLayerActions } from '@app/modules/gis/actions';

@Injectable({
    providedIn: 'root'
})
export class InterrogatorService {

    public features = new BehaviorSubject<any>({});
    public features$ = this.features.asObservable();
    public layers = [];
    public basinConfig = {};
    public playConfig = {};
    public sourceRockConfig = {};
    public wellConfig = {};
    public basinLayerUrl = this.appConfigSvc.getConfig().basinLayerUrl;
    public playLayerUrl = this.appConfigSvc.getConfig().playLayerUrl;
    public sourceRockLayerUrl = this.appConfigSvc.getConfig().sourceRockLayerUrl;
    public wellLayerUrl = this.appConfigSvc.getConfig().wellServiceUrl;
    public wellServiceUrl = this.appConfigSvc.getConfig().wellServiceUrl;
    public wellMetadataServiceUrl = this.appConfigSvc.getConfig().wellMetadataServiceUrl;
    public basinToPlayUrl = this.appConfigSvc.getConfig().basinToPlayUrl;
    public playMetadataServiceUrl = this.appConfigSvc.getConfig().playMetadataServiceUrl;
    public wellCoordinatedataServiceUrl = this.appConfigSvc.getConfig().wellCoordinatedataServiceUrl;
    public sourceRockServiceUrl = this.appConfigSvc.getConfig().sourceRockServiceUrl;
    public penetrationServiceUrl = this.appConfigSvc.getConfig().penetrationServiceUrl;
    public wellSourceServiceUrl = this.appConfigSvc.getConfig().wellSourceServiceUrl;


    constructor(public store: Store<fromGis.State>,
        @Inject(AppConfigService) private appConfigSvc: AppConfigService) { }

    public queryWellsByBasinName(url: string, basinName: string, attributes: string[],
        returnGeometry = false): Observable<FeatureCollection> {
        const query = esri.query({
            url: url,
            useCors: true,
            withCredentials: true
        });
        return new Observable(observer => {
            const where = `BASIN_NAME_1 = '${basinName}'`;
            query
                .where(where)
                .run((error, featureCollection: FeatureCollection) => {
                    if (error) {
                        observer.error(error);
                    } else {
                        observer.next(featureCollection);
                        observer.complete();
                    }
                });
        });
    }

    public queryPenetrationByBasinName(url: string, basinName: string, attributes: string[],
        returnGeometry = false): Observable<FeatureCollection> {
        const query = esri.query({
            url: url,
            useCors: true,
            withCredentials: true
        });
        return new Observable(observer => {
            const where = `BASIN_NAME = '${basinName}'`;
            query
                .where(where)
                .returnGeometry(returnGeometry)
                .fields(attributes)
                .run((error, featureCollection: FeatureCollection) => {
                    if (error) {
                        observer.error(error);
                    } else {
                        observer.next(featureCollection);
                        observer.complete();
                    }
                });
        });
    }

    public querywellSourcesByBasinName(url: string, basinName: string, attributes: string[],
        returnGeometry = false): Observable<FeatureCollection> {
        const query = esri.query({
            url: url,
            useCors: true,
            withCredentials: true
        });
        return new Observable(observer => {
            const where = `BASIN_NAME = '${basinName}'`;
            query
                .where(where)
                .returnGeometry(returnGeometry)
                .fields(attributes)
                .distinct()
                .run((error, featureCollection: FeatureCollection) => {
                    if (error) {
                        observer.error(error);
                    } else {
                        observer.next(featureCollection);
                        observer.complete();
                    }
                });
        });
    }

    public querySourceRockMetadata(url: string, sourceRockMinAge: string, sourceRockMaxAge: string, playIDs: string[]): Observable<any> {
        const attrs = ['*'
        ];
        const query = esri.query({
            url: url,
            useCors: true,
            withCredentials: true
        });
        return new Observable(observer => {
            const where = `AGE_END = ${sourceRockMinAge} AND AGE_START = ${sourceRockMaxAge} AND (${playIDs.map(playID =>
                `PLAY_ID = '${playID}'`).join(' OR ')})`;
            query
                .where(where)
                .returnGeometry(false)
                .fields(attrs)
                .run((error, featureCollection: FeatureCollection) => {
                    if (error) {
                        observer.error(error);
                    } else {
                        observer.next(featureCollection);
                        observer.complete();
                    }
                });
        });
    }

    /* mapping functions */

    public SetPlayConfig(config) {
        this.store.dispatch(new MapLayerActions.SetMapLoading(true));
        // this.SetFeatures({}, null);
        if (config) {
            this.playConfig = config;
            this.queryFeatures('PLAY', config.featureCollection);
        }
    }

    public SetWellConfig(config) {
        this.store.dispatch(new MapLayerActions.SetMapLoading(true));
        // this.SetFeatures({}, null);
        if (config) {
            this.wellConfig = config;
            this.queryFeatures('WELL', config.featureCollection);
        }
        this.store.dispatch(new MapLayerActions.SetMapLoading(false));
    }

    public SetSourceRockConfig(config) {
        this.store.dispatch(new MapLayerActions.SetMapLoading(true));
        this.SetFeatures({}, null, null);
        if (config) {
            this.sourceRockConfig = config;
            this.queryFeatures('SOURCEROCK', config.featureCollection);
        }
    }

    public SetBasinConfig(config) {
        this.store.dispatch(new MapLayerActions.SetMapLoading(true));
        // this.SetFeatures({}, null);
        if (config) {
            this.basinConfig = config;
            this.queryFeatures('BASIN', config.featureCollection);
        }
    }

    queryFeatures(type, features) {
        let condition, url;
        switch (type) {
            case 'PLAY':
                url = this.playLayerUrl;
                condition = `${features.map(feature => `PLAY_ID = '${feature.src}'`).join(' OR ')}`;
                break;
            case 'SOURCEROCK':
                url = this.sourceRockLayerUrl;
                condition = features.map(feature =>
                    `(AGE_END = ${feature.src.AGE_END} AND AGE_START = ${feature.src.AGE_START} AND PLAY_ID = '${feature.play}')`)
                    .join(' OR ');
                break;
            case 'BASIN':
                url = this.appConfigSvc.getConfig().basinLayerUrl;
                condition = features.map(feature => `BASIN_NAME = '${feature.src}'`);
                break;
            case 'WELL':
                url = this.wellLayerUrl;
                condition = features.map(feature => `BASIN_NAME_1 = '${feature.basin}'`);
                break;
            default:
                url = '';
                condition = '';
        }
        const query = esri.query({
            url: url,
            useCors: true,
            withCredentials: true
        });
        query.where(condition);
        query.run((error, featureCollection: any) => {
            if (error) {
                this.store.dispatch(new MapLayerActions.SetMapLoading(false));
            } else {
                let result = {};
                if (featureCollection.features.length > 0) {
                    result = featureCollection.features;
                }
                this.SetFeatures(result, type, this.layers);
                if (!Object.keys(result).length) {
                    this.store.dispatch(new MapLayerActions.SetMapLoading(false));
                }
            }
        });
    }

    private SetFeatures(features, type, layers) {
        this.features.next({ features: features, type: type, layers: layers });
    }
}
