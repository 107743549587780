import { map } from 'rxjs/operators';
import 'isomorphic-fetch';
import { HttpClient } from '@angular/common/http';
import { buildMessageError } from '@app/shared/util/search-util';
import { from } from 'rxjs';
import { console } from '@app/shared/util/console.util';
var PlayElements = /** @class */ (function () {
    function PlayElements() {
    }
    return PlayElements;
}());
export { PlayElements };
var MockConfig = /** @class */ (function () {
    function MockConfig() {
    }
    MockConfig.prototype.getConfig = function () {
        return {
            server: 'local',
            datascience_api_baseurl: 'https://hplml0002.hpl.xom.com:5434/geobrain/v2',
            user_api_baseurl: 'https://userprofilesdev.geobrain.xomsvcs.com/api',
            instrumentation_key: '04b30350-2efc-42c7-bdad-77132e9c5ce8',
            splashscreen: {
                title: 'Explore Basin IQ',
                message: 'Lorem ipsum dolor sit amet',
                button_text: 'Learn More',
                button_link: 'http://www.google.com',
                image_url: 'assets/images/splashscreen.png'
            },
            admin_message: {
                title: 'Slower Performance Than Usual',
                message: 'Lorem ipsum dolor sit amet'
            },
            seisbook_app_baseurl: 'https://test.geobook.xomsvcs.com/',
            seisbook_api_baseurl: 'https://testapi.geobook.xomsvcs.com/api/',
        };
    };
    return MockConfig;
}());
export { MockConfig };
var AppConfigService = /** @class */ (function () {
    function AppConfigService(http) {
        this.http = http;
    }
    AppConfigService.prototype.loadAppConfig = function () {
        var _this = this;
        return from(fetch('/assets/config/app-config.json')
            .then(function (res) {
            return res.json();
        })).pipe(map(function (config) {
            _this.appConfig = config;
            console.log(_this.appConfig.server + " Environment Loaded");
            console.log(_this.appConfig);
        })).toPromise();
    };
    AppConfigService.prototype.getApps = function () {
        return this.http.get(this.appConfig.user_api_baseurl + "/apps").pipe(map(function (res) {
            if (res.status) {
                throw Error(buildMessageError(res));
            }
            else {
                return res;
            }
        }));
        // return this.http.get<any>(`/assets/data/app_store_get_apps.json`).pipe(delay(3000)).pipe(
        //   map(res => {
        //     if (res.status) {
        //       throw Error(buildMessageError(res));
        //     } else {
        //       return res;
        //     }
        //   })
        // );
    };
    AppConfigService.prototype.updateMyApps = function (apps) {
        this.appConfig.myApps = apps;
    };
    AppConfigService.prototype.updateApps = function (apps) {
        this.appConfig.apps = apps;
    };
    AppConfigService.prototype.getConfig = function () {
        return this.appConfig;
    };
    AppConfigService.prototype.getAppByName = function (appName) {
        var app;
        this.appConfig.apps.map(function (appElement) {
            if (appElement.name === appName) {
                app = appElement;
            }
        });
        return app;
    };
    return AppConfigService;
}());
export { AppConfigService };
