<xom-modal-layout class="modal get-help-modal" hasHeader="true">
  <div modal-header>
    <span (click)="closeShowHelp()" class="close icon-close"></span>
  </div>
  <div modal-content>
    <div class="get-help">{{content.global.GET_HELP_MODAL_MESSAGE[0]}}
      <a #divFocused [href]="sanitize(content.global.GEOCHAT_EMAIL)">{{content.global.GET_HELP_MODAL_MESSAGE[1]}}
        <img src="/assets/icons/details.svg" alt=""></a>
    </div><BR>
      <div class="get-help">{{content.global.GET_HELP_MODAL_MESSAGE[2]}}<BR>
      <a #divFocused [href]="sanitize(content.global.HELP_DOCUMENTS)" target="_blank">{{content.global.GET_HELP_MODAL_MESSAGE[3]}}
          <img src="/assets/icons/details.svg" alt=""></a>
      </div>
  </div>
</xom-modal-layout>