import { createSelector, createFeatureSelector, ActionReducerMap } from '@ngrx/store';

import * as fromLayers from './map-layers.reducer';
import * as fromAoi from './map-aoi.reducer';
import * as fromToolbox from './toolbox.reducer';
import * as fromMeasure from './map-measure.reducer';
import * as fromTable from './map-table.reducer';
import * as fromPopup from './map-popup.reducer';
import * as fromContextMenu from './context-menu.reducer';
import * as fromLeadMaps from './lead-maps.reducer';
import * as fromRoot from '@app/reducers/index';

export interface GisState {
    tableLoaded: fromTable.State;
    layers: fromLayers.State;
    aoi: fromAoi.State;
    toolbox: fromToolbox.State;
    measure: fromMeasure.State;
    table: fromTable.State;
    popup: fromPopup.State;
    contextmenu: fromContextMenu.State;
    leadMaps: fromLeadMaps.State;
}

export interface State extends fromRoot.State {
    gis: GisState;
}

export const reducers: ActionReducerMap<GisState, any> = {
    tableLoaded: fromTable.reducer,
    layers: fromLayers.reducer,
    aoi: fromAoi.reducer,
    toolbox: fromToolbox.reducer,
    measure: fromMeasure.reducer,
    table: fromTable.reducer,
    popup: fromPopup.reducer,
    leadMaps: fromLeadMaps.reducer,
    contextmenu: fromContextMenu.reducer
};

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const getGisState = createFeatureSelector<State, GisState>('gis');

/**
 * Map Table
 */
export const isTableLoading = createSelector(getGisState, (state) => state.table.loading);

 // returns the selector for map table expanded state
export const isMapTableExpanded = createSelector(getGisState, (state) => state.table.expanded);

// returns the selector for map table rows
export const getMapTableRows = createSelector(getGisState, (state) => state.table.rows);

// returns the selector for map table col definitions
export const getMapTableCols = createSelector(getGisState, (state) => state.table.cols);

// returns the selector for the layer name of the currently active map table layer
export const getMapTableTitle = createSelector(getGisState, (state) => state.table.title);
export const getFilterMap = createSelector(getGisState, (state) => state.table.filterMap);
export const getOffset = createSelector(getGisState, (state) => state.table.offset);
export const getTotalCount = createSelector(getGisState, (state) => state.table.totalCount);
export const getPageSize = createSelector(getGisState, (state) => state.table.pageSize);
export const getSortingData = createSelector(getGisState, (state) => state.table.sortingData);
export const getSelectedTables = createSelector(getGisState, (state) => state.table.selectedTables);
export const getActiveRowsFeatures = createSelector(getGisState, (state) => state.table.activeRowsFeatures);

/**
 * Map Toolbox
 */
export const getToolboxExpanded = createSelector(getGisState, (state) => state.toolbox.expanded);



/**
 * Map AOI
 */
export const getAoiArea = createSelector(getGisState, (state) => state.aoi.aoiArea);
export const getAoiFeatures = createSelector(getGisState, (state) => state.aoi.aoiFeatures);
export const getAvailableAois = createSelector(getGisState, (state) => state.aoi.availableAois);
export const isAoiLoading = createSelector(getGisState, (state) => state.aoi.aoiLoading);
export const getAOIActive = createSelector(getGisState, (state) => state.aoi.aoiActive);
export const getAOIVisible = createSelector(getGisState, (state) => state.aoi.aoiLayerVisible);
export const getAOIAddFeature = createSelector(getGisState, (state) => state.aoi.aoiAddFeatureMode);
export const getAOIGeoUnit = createSelector(getGisState, (state) => state.aoi.aoiGeoUnit);
export const getAOILayerConfig = createSelector(getGisState, (state) => state.aoi.validAOILayers);
export const getAoiQuery = createSelector(getGisState, (state) => state.aoi.aoiQuery);
export const getWellCountryAssociation = createSelector(getGisState, (state) => state.aoi.wellsCountryAssociation);
export const getSelectedAOIBasin = createSelector(getGisState, (state) => state.aoi.selectedAOIBasin);
export const isAoiApplied = createSelector(getGisState, (state) => state.aoi.aoiApplied);
export const isAOIManagerOpen = createSelector(getGisState, (state) => state.aoi.aoiManagerOpen);
export const isAOIMeasurementToolOpen = createSelector(getGisState, (state) => state.aoi.aoiMeasurementToolOpen);
export const isAOIManagerFromProfile = createSelector(getGisState, (state) => state.aoi.aoiManagerFromProfile);

// Returns selector for the drawn aoi layer
export const getAoiLayer = createSelector(getGisState, (state) => state.aoi.aoiLayer);

/**
 * Map Layers
 */
export const getSelectedFeatures = createSelector(getGisState, (state) => state.layers.selectedFeatures);
export const getAvailableLayers = createSelector(getGisState, (state) => state.layers.availableLayers);
export const getAvailableMapServers = createSelector(getGisState, (state) => state.layers.availableMapServers);
export const getActiveMapServers = createSelector(getGisState, (state) => state.layers.activeMapServers);
export const getVisibleMapServers = createSelector(
    getGisState,
    (state) => state.layers.activeMapServers.filter(l => l.visible && l.layers.length > 0)
);
export const isLoading = createSelector(getGisState, (state) => state.layers.loading);
export const getActiveGeoTags = createSelector(
    getGisState,
    state => state.layers.activeGeoTags ? state.layers.activeGeoTags : null
);
export const getAvailableFields = createSelector(getGisState, (state) => state.layers.currentFieldData);
export const getActivateCentroid = createSelector(getGisState, (state) => state.layers.activeCentroid);
export const isLayerManagerOpen = createSelector(getGisState, (state) => state.layers.layerManagerOpen);
export const getFacetFeatureCollection = createSelector(getGisState, (state) => state.layers.facetFeaturesCollection);
export const getZoom = createSelector(getGisState, (state) => state.layers.currentZoom);
export const getBound = createSelector(getGisState, (state) => state.layers.bounds);
export const getLastURLMapServerFail = createSelector(getGisState, (state) => state.layers.lastURLMapServerFail);
export const getAllLayerFeatures = createSelector(getGisState, (state) => state.table.allLayerFeatures);

/**
 * Map Legends
 */
export const getAvailableLegends = createSelector(getGisState, (state) => state.layers.availableLegends);

// Returns selector for the min zoom of the current basemap
export const getMinZoom = createSelector(getGisState, (state) => state.layers.activeBasemap.options.minZoom);

// Returns selector for the max zoom of the current basemap
export const getMaxZoom = createSelector(getGisState, (state) => state.layers.activeBasemap.options.maxZoom);

// returns the current map zoom level
export const getCurrentZoom = createSelector(getGisState, (state) => state.layers.currentZoom);

// returns the coordinates to center map
export const getCoordinates = createSelector(getGisState, (state) => state.layers.coordinates);
/**
 * Map Basemaps
 */
// Returns selector for all basemap configs as defined in @gis/models/EsriBasemapLayers.ts
// Used to create base selector
export const getAvailableBasemaps = createSelector(getGisState, (state) => state.layers.availableBasemaps);

// Returns selector for the current basemap config as defined in @gis/models/EsriBasemapLayers.ts
export const getActiveBasemap = createSelector(getGisState, (state) => state.layers.activeBasemap);

/**
 * Map Measurement
 */
export const getMeasureMetric = createSelector(getGisState, (state) => state.measure.metric);
export const getMapMeasures = createSelector(getGisState, (state) => state.measure.measures);
export const getMeasuringState = createSelector(getGisState, (state) => state.measure.measuring);
export const getMeasureLayer = createSelector(getGisState, (state) => state.measure.measureLayer);

/**
 * Map Popup
 */
export const getPopupFeatures = createSelector(getGisState, (state) => state.popup.features);
export const getPopupIndex = createSelector(getGisState, (state) => state.popup.popupIndex);
export const getPopupLoading = createSelector(getGisState, (state) => state.popup.loading);

/**
 * Context Menu
 */
export const getContextMenuEvent = createSelector(getGisState, (state) => state.contextmenu.event);
