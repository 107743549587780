import { EventEmitter, OnInit } from '@angular/core';
import { AppConfigService } from '@app/app-config.service';
import { ContentService } from '@shared/services/content.service';
var XomFTUEDynamicComponent = /** @class */ (function () {
    function XomFTUEDynamicComponent(appConfig, globalContent) {
        this.appConfig = appConfig;
        this.globalContent = globalContent;
        this.closeDynamicOptions = new EventEmitter(null);
        this.backToStaticOptions = new EventEmitter(null);
        this.selectedOption = {
            icon: '',
            title: '',
            description: '',
            subDescription: '',
            videoUrl: ''
        };
        this.isToolSelected = false;
        this.dinamicOptions = this.globalContent.global.FTUE.DINAMIC_OPTIONS;
        this.options = this.globalContent.global.FTUE.OPTIONS;
        this.dinamicSectionInfo = this.globalContent.global.FTUE.DINAMIC_SECTION_INFO;
        this.mapTools = this.globalContent.global.FTUE.MAP_TOOLS;
        this.apps = this.appConfig.getConfig().apps;
    }
    XomFTUEDynamicComponent.prototype.ngOnInit = function () {
        if (this.app) {
            this.isToolSelected = true;
            this.openApp(this.app);
        }
        else {
            this.updateSelectedOption(this.dinamicOptions[0]);
        }
    };
    XomFTUEDynamicComponent.prototype.back = function () {
        if (this.app) {
            this.backToStaticOptions.emit();
        }
        if (this.isToolSelected) {
            this.isToolSelected = false;
            this.updateSelectedOption(this.dinamicOptions[0]);
        }
        else {
            this.backToStaticOptions.emit();
        }
    };
    XomFTUEDynamicComponent.prototype.updateSelectedOption = function (option) {
        this.selectedOption.icon = option.icon;
        this.selectedOption.title = option.title;
        this.selectedOption.description = option.description;
        this.selectedOption.subDescription = option.subDescription;
        this.selectedOption.videoUrl = option.videoUrl;
    };
    XomFTUEDynamicComponent.prototype.openTool = function (tool) {
        this.isToolSelected = true;
        this.updateSelectedOption(tool);
    };
    XomFTUEDynamicComponent.prototype.openApp = function (app) {
        this.isToolSelected = true;
        this.selectedOption.title = app.title;
        this.selectedOption.icon = app.icon;
        this.selectedOption.description = app.ftue.body;
        this.selectedOption.videoUrl = app.ftue.videoUrl;
        this.selectedOption.subDescription = app.subDescription;
    };
    return XomFTUEDynamicComponent;
}());
export { XomFTUEDynamicComponent };
