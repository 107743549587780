import { PipeTransform } from '@angular/core';
var FileNamePipe = /** @class */ (function () {
    function FileNamePipe() {
    }
    FileNamePipe.prototype.transform = function (value) {
        var filename = value.split('\\').pop();
        return filename;
    };
    return FileNamePipe;
}());
export { FileNamePipe };
