export var WorkspacesActionTypes;
(function (WorkspacesActionTypes) {
    WorkspacesActionTypes["LoadAllWorkspaces"] = "[Workspaces] Load All Workspaces";
    WorkspacesActionTypes["LoadAllWorkspacesSuccess"] = "[Workspaces] Load All Workspaces Success";
    WorkspacesActionTypes["LoadAllWorkspacesFailure"] = "[Workspaces] Load All Workspaces Failure";
    WorkspacesActionTypes["LoadWorkspace"] = "[Workspaces] Load Workspace";
    WorkspacesActionTypes["UpdateWorkspace"] = "[Workspaces] Update Workspace";
    WorkspacesActionTypes["UpdateLegenedOpen"] = "[Workspaces] Update Workspace Legend Open";
    WorkspacesActionTypes["CreateWorkspace"] = "[Workspaces] Create Workspace";
    WorkspacesActionTypes["DeleteWorkspace"] = "[Workspaces] Delete Workspace";
    WorkspacesActionTypes["CreateNotification"] = "[Workspaces] Create Notification";
    WorkspacesActionTypes["DeleteNotification"] = "[Workspaces] Delete Notification";
    WorkspacesActionTypes["LoadNotifications"] = "[Workspaces] Load Notifications";
    WorkspacesActionTypes["LoadNotificationsSuccess"] = "[Workspaces] Load Notifications Success";
    WorkspacesActionTypes["LoadNotificationsFailure"] = "[Workspaces] Load Notifications Failure";
})(WorkspacesActionTypes || (WorkspacesActionTypes = {}));
var LoadAllWorkspaces = /** @class */ (function () {
    function LoadAllWorkspaces() {
        this.type = WorkspacesActionTypes.LoadAllWorkspaces;
    }
    return LoadAllWorkspaces;
}());
export { LoadAllWorkspaces };
var LoadAllWorkspacesSuccess = /** @class */ (function () {
    function LoadAllWorkspacesSuccess(all, personal, template) {
        if (personal === void 0) { personal = null; }
        if (template === void 0) { template = null; }
        this.all = all;
        this.personal = personal;
        this.template = template;
        this.type = WorkspacesActionTypes.LoadAllWorkspacesSuccess;
    }
    return LoadAllWorkspacesSuccess;
}());
export { LoadAllWorkspacesSuccess };
var LoadAllWorkspacesFailure = /** @class */ (function () {
    function LoadAllWorkspacesFailure(payload) {
        this.payload = payload;
        this.type = WorkspacesActionTypes.LoadAllWorkspacesFailure;
    }
    return LoadAllWorkspacesFailure;
}());
export { LoadAllWorkspacesFailure };
var LoadWorkspace = /** @class */ (function () {
    function LoadWorkspace(payload) {
        this.payload = payload;
        this.type = WorkspacesActionTypes.LoadWorkspace;
    }
    return LoadWorkspace;
}());
export { LoadWorkspace };
var UpdateWorkspace = /** @class */ (function () {
    function UpdateWorkspace(payload) {
        this.payload = payload;
        this.type = WorkspacesActionTypes.UpdateWorkspace;
    }
    return UpdateWorkspace;
}());
export { UpdateWorkspace };
var UpdateLegendOpen = /** @class */ (function () {
    function UpdateLegendOpen(payload) {
        this.payload = payload;
        this.type = WorkspacesActionTypes.UpdateLegenedOpen;
    }
    return UpdateLegendOpen;
}());
export { UpdateLegendOpen };
var CreateWorkspace = /** @class */ (function () {
    function CreateWorkspace(payload) {
        this.payload = payload;
        this.type = WorkspacesActionTypes.CreateWorkspace;
    }
    return CreateWorkspace;
}());
export { CreateWorkspace };
var DeleteWorkspace = /** @class */ (function () {
    function DeleteWorkspace(payload) {
        this.payload = payload;
        this.type = WorkspacesActionTypes.DeleteWorkspace;
    }
    return DeleteWorkspace;
}());
export { DeleteWorkspace };
var CreateNotification = /** @class */ (function () {
    function CreateNotification(payload) {
        this.payload = payload;
        this.type = WorkspacesActionTypes.CreateNotification;
    }
    return CreateNotification;
}());
export { CreateNotification };
var DeleteNotification = /** @class */ (function () {
    function DeleteNotification(payload) {
        this.payload = payload;
        this.type = WorkspacesActionTypes.DeleteNotification;
    }
    return DeleteNotification;
}());
export { DeleteNotification };
var LoadNotifications = /** @class */ (function () {
    function LoadNotifications() {
        this.type = WorkspacesActionTypes.LoadNotifications;
    }
    return LoadNotifications;
}());
export { LoadNotifications };
var LoadNotificationsSuccess = /** @class */ (function () {
    function LoadNotificationsSuccess(payload) {
        this.payload = payload;
        this.type = WorkspacesActionTypes.LoadNotificationsSuccess;
    }
    return LoadNotificationsSuccess;
}());
export { LoadNotificationsSuccess };
var LoadNotificationsFailure = /** @class */ (function () {
    function LoadNotificationsFailure(payload) {
        this.payload = payload;
        this.type = WorkspacesActionTypes.LoadNotificationsFailure;
    }
    return LoadNotificationsFailure;
}());
export { LoadNotificationsFailure };
