export var SnackbarActionTypes;
(function (SnackbarActionTypes) {
    SnackbarActionTypes["Open"] = "[Snackbar] Open";
    SnackbarActionTypes["Close"] = "[Snackbar] Close";
    SnackbarActionTypes["OpenSuccess"] = "[Snackbar] Open Success";
})(SnackbarActionTypes || (SnackbarActionTypes = {}));
var Open = /** @class */ (function () {
    function Open(payload) {
        this.payload = payload;
        this.type = SnackbarActionTypes.Open;
        if (!payload.config) {
            this.payload.config = {
                horizontalPosition: 'right',
                verticalPosition: 'bottom'
            };
        }
        if (payload.type) {
            this.payload.config.panelClass = payload.type;
        }
    }
    return Open;
}());
export { Open };
var Close = /** @class */ (function () {
    function Close() {
        this.type = SnackbarActionTypes.Close;
    }
    return Close;
}());
export { Close };
var OpenSuccess = /** @class */ (function () {
    function OpenSuccess() {
        this.type = SnackbarActionTypes.OpenSuccess;
    }
    return OpenSuccess;
}());
export { OpenSuccess };
