import { createSelector, createFeatureSelector, ActionReducerMap } from '@ngrx/store';

import * as fromSnackbar from './snackbar.reducer';
import * as fromCollection from './collections.reducer';
import * as fromGeosentiment from './geosentiment.reducer';
import * as fromWorkspaces from './workspaces.reducer';
import * as fromUser from './user.reducer';
import * as fromFeedback from './feedback.reducer';
import * as fromHydration from './hydration.reducer';
import * as fromRoot from '@app/reducers';
import * as fromHypothesis from './hypothesis.reducer';

export interface SharedState {
    snackbar: fromSnackbar.State;
    collections: fromCollection.State;
    geosentiment: fromGeosentiment.State;
    workspaces: fromWorkspaces.State;
    user: fromUser.State;
    feedback: fromFeedback.State;
    hypothesis: fromHypothesis.State;
    hydration: fromHydration.State;
}

export interface State extends fromRoot.State {
    shared: SharedState;
}

export const reducers: ActionReducerMap<SharedState, any> = {
    snackbar: fromSnackbar.reducer,
    collections: fromCollection.reducer,
    geosentiment: fromGeosentiment.reducer,
    workspaces: fromWorkspaces.reducer,
    user: fromUser.reducer,
    feedback: fromFeedback.reducer,
    hypothesis: fromHypothesis.reducer,
    hydration: fromHydration.reducer
};

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */

export const getSharedState = createFeatureSelector<State, SharedState>('shared');

// Collections
export const isLoading = createSelector(getSharedState, (state) => state.collections.loading);
export const isLoadingAllCollections = createSelector(getSharedState, (state) => state.collections.loadingAllCollections);
export const getAllCollections = createSelector(getSharedState, (state) => state.collections.allUserCollections);
export const getCollection = createSelector(getSharedState, (state) => state.collections.userCollection);
export const getCollectionResponse = createSelector(getSharedState, (state) => state.collections.collectionResponse);
export const getCollectionFileSummary = createSelector(getSharedState, (state) => state.collections.fileSummary);
export const getCollectionFileTypes = createSelector(getSharedState, (state) => state.collections.filtersTypes);
export const getSelectedDocuments = createSelector(getSharedState, (state) => state.collections.selectedDocuments);
export const getSelectedDocumentNames = createSelector(getSharedState, (state) => state.collections.selectedDocumentNames);
export const getExportResult = createSelector(getSharedState, (state) => state.collections.CSVDocs);
export const getCollectionLoadFlag = createSelector(getSharedState, (state) => state.collections.allowCardsRefresh);
export const getCollectionNotExisting = createSelector(getSharedState, (state) => state.collections.collectionNotExisting);
export const getNotesPanelState = createSelector(getSharedState, (state) => state.collections.toggleNotesPanel);
export const getNotesFromFlipcard = createSelector(getSharedState, (state) => state.collections.isNotesFromFlipcard);
export const getShownNotes = createSelector(getSharedState, (state) => state.collections.shownNotes);
export const getNotesLoading = createSelector(getSharedState, (state) => state.collections.notesLoading);

// Geosentiment
export const getOriginalGraphData = createSelector(getSharedState, (state) => state.geosentiment.originalGraphData);
export const getProcessedGraphData = createSelector(getSharedState, (state) => state.geosentiment.processedGraphData);
export const getMentions = createSelector(getSharedState, (state) => state.geosentiment.originalMentionsData);
export const getDocuments = createSelector(getSharedState, (state) => state.geosentiment.documentsData);
export const getIndexesToFilter = createSelector(getSharedState, (state) => state.geosentiment.indexesToFilterAssociations);
export const getResponseTime = createSelector(getSharedState, (state) => state.geosentiment.responseTime);

// Workspaces
export const getAllWorkspaces = createSelector(getSharedState, (state) => state.workspaces.allWorkspaces);
export const getPersonalWorkspaces = createSelector(getSharedState, (state) => state.workspaces.personalWorkspaces);
export const getTemplateWorkspaces = createSelector(getSharedState, (state) => state.workspaces.templateWorkspaces);
export const getWorkspace = createSelector(getSharedState, (state) => state.workspaces.workspace);
export const getBounds = createSelector(getSharedState, (state) => state.workspaces.workspace ? state.workspaces.workspace.bounds : null);
export const getLegendOpen = createSelector(getSharedState,
    (state) => state.workspaces.workspace ? state.workspaces.workspace.legendOpen : false);

// Notifications
export const getNotifications = createSelector(getSharedState, (state) => state.workspaces.notifications);

// User
export const getAllUsers = createSelector(getSharedState, (state) => state.user.allUsers);
export const getUser = createSelector(getSharedState, (state) => state.user.userInfo);
export const getPreferences = createSelector(getSharedState, (state) => state.user.preferences);
export const getScreenSizeWarning = createSelector(getSharedState, (state) => state.user.screenSizeFlag);
export const getActiveModal = createSelector(getSharedState, (state) => state.user.activeModal);
export const getAvailableMessages = createSelector(getSharedState, (state) => state.user.availableMessages);

// Promoter
export const getPromoterCategories = createSelector(getSharedState, (state) => state.user.promoterCategories);

// Hydration
export const getHydrationStarted = createSelector(getSharedState, (state) => state.hydration.hydrationStarted);
export const getHydrationSearchResetCompleted = createSelector(getSharedState,
    (state) => state.hydration.step_hydrationSearchResetCompleted);
export const getDocumentFacetsRemovalCompleted = createSelector(getSharedState,
(state) => state.hydration.step_documentFacetsRemovalCompleted);
export const getSearchCompleted = createSelector(getSharedState, (state) => state.hydration.step_searchCompleted);
export const getSearchDocumentSuccessCompleted = createSelector(getSharedState,
    (state) => state.hydration.step_searchDocumentSuccessCompleted);
export const getMapServersHydrationCompleted = createSelector(getSharedState,
    (state) => state.hydration.step_mapServersCompleted);

// Hypothesis
export const getHypothesis = createSelector(getSharedState, (state) => state.hypothesis.hypothesisModel);
export const getHypothesisCard = createSelector(getSharedState, (state) => state.hypothesis.hypothesisCardModel);
export const getHypothesisAllowedCountries = createSelector(getSharedState, (state) => state.hypothesis.allowedCountries);
export const getUserSubscribedModel = createSelector(getSharedState, (state) => state.hypothesis.userSubscribedModel);
export const getHypothesisMainLoading = createSelector(getSharedState, (state) => state.hypothesis.hypothesisMainLoading);
export const getHypothesisManagerLoading = createSelector(getSharedState, (state) => state.hypothesis.hypothesisManagerLoading);
