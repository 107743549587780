export var AwarenessActions;
(function (AwarenessActions) {
    AwarenessActions["SetBasin"] = "[Awareness] Set Basin";
    AwarenessActions["GetTab"] = "[Awareness] Get Tab";
    AwarenessActions["SetTab"] = "[Awareness] Set Tab";
})(AwarenessActions || (AwarenessActions = {}));
var SetBasin = /** @class */ (function () {
    function SetBasin(payload) {
        this.payload = payload;
        this.type = AwarenessActions.SetBasin;
    }
    return SetBasin;
}());
export { SetBasin };
var GetTab = /** @class */ (function () {
    function GetTab(payload) {
        this.payload = payload;
        this.type = AwarenessActions.GetTab;
    }
    return GetTab;
}());
export { GetTab };
var SetTab = /** @class */ (function () {
    function SetTab(payload) {
        this.payload = payload;
        this.type = AwarenessActions.SetTab;
    }
    return SetTab;
}());
export { SetTab };
