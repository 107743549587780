export var SearchActionTypes;
(function (SearchActionTypes) {
    SearchActionTypes["SetQueryText"] = "[Search] Set Query Text";
    SearchActionTypes["Search"] = "[Search] Trigger Search";
    SearchActionTypes["SearchImages"] = "[Search] Search Images";
    SearchActionTypes["SearchLeadMaps"] = "[Search] Search Lead Maps";
    SearchActionTypes["SearchImagesSuccess"] = "[Search] Image Search Success";
    SearchActionTypes["SearchImagesFailure"] = "[Search] Image Search Failure";
    SearchActionTypes["BuildDocumentSearch"] = "[Search] Build Document Search";
    SearchActionTypes["SearchDocuments"] = "[Search] Search Documents";
    SearchActionTypes["SearchDocumentsSuccess"] = "[Search] Document Search Success";
    SearchActionTypes["SearchDocumentsFailure"] = "[Search] Document Search Failure";
    SearchActionTypes["SearchNewDocuments"] = "[Search] Search New Documents";
    SearchActionTypes["SearchNewDocumentsSuccess"] = "[Search] New Documents Search Success";
    SearchActionTypes["SearchNewDocumentsFailure"] = "[Search] New Documents Search Failure";
    SearchActionTypes["SearchSuggestionsSuccess"] = "[Search] Search Suggestions Success";
    SearchActionTypes["SearchSuggestionsFailure"] = "[Search] Search Suggestions Failure";
    SearchActionTypes["SetClassifications"] = "[Search] Set Images Classifications";
    SearchActionTypes["RemoveSearchTerm"] = "[Search] Remove Search Term";
    SearchActionTypes["AddSearchTerms"] = "[Search] Add Search Terms";
    SearchActionTypes["AddAOIQuery"] = "[Search] Add AOI Query";
    SearchActionTypes["AddDocumentResponseTime"] = "[Search] Add Document Response Time";
    SearchActionTypes["AddImageResponseTime"] = "[Search] Add Image Response Time";
    SearchActionTypes["Export"] = "[Search] Export Search Documents";
    SearchActionTypes["ExportSuccess"] = "[Search] Export Success";
    SearchActionTypes["ExportFailure"] = "[Search] Export Failure";
    SearchActionTypes["SetInitialSearch"] = "[Search] Set Initial Search";
    SearchActionTypes["AddPill"] = "[Search] Add Pill";
    SearchActionTypes["RemovePill"] = "[Search] Remove Pill";
    SearchActionTypes["RemovePills"] = "[Search] Remove Pills";
    SearchActionTypes["SetExportLoading"] = "[Search] Export Documents Loading";
    SearchActionTypes["ResetAll"] = "[Search] Reset all";
    SearchActionTypes["SetResetAllState"] = "[Search] Set resetAllState";
    SearchActionTypes["SetDisplayResetAll"] = "[Search] Set displayResetAll";
    SearchActionTypes["GetDocImages"] = "[Search] Get Doc Images";
    SearchActionTypes["GetDocImagesSuccess"] = "[Search] Get Doc Images Success";
    SearchActionTypes["GetDocImagesFailure"] = "[Search] Get Doc Images Failure";
    SearchActionTypes["SetWordCloudAppliedTerms"] = "[Search] Set Word Cloud Applied Terms";
    SearchActionTypes["RemoveCloudAppliedTerms"] = "[Search] Remove Cloud Applied Terms";
    SearchActionTypes["SetDocumentDetailPanelContent"] = "[Search] Set Document Detail Panel Content";
    SearchActionTypes["HydrationSearchReset"] = "[Search] Hydration Search Reset";
    SearchActionTypes["SetLoadingFlags"] = "[Search] Set Loading Flag";
    SearchActionTypes["GetSurprisingSentencesFromSearch"] = "[Search] Get Surprising Sentences From a Search";
    SearchActionTypes["SetSurprisingSentencesFromSearch"] = "[Search] Set Surprising Sentences From a Search";
    SearchActionTypes["SetSurprisingSentencesFailure"] = "[Search] Set Surprising Sentences From a Search Failure";
})(SearchActionTypes || (SearchActionTypes = {}));
/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */
var SetQueryText = /** @class */ (function () {
    function SetQueryText(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SetQueryText;
    }
    return SetQueryText;
}());
export { SetQueryText };
var Search = /** @class */ (function () {
    function Search(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.Search;
    }
    return Search;
}());
export { Search };
var SearchImages = /** @class */ (function () {
    function SearchImages(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SearchImages;
    }
    return SearchImages;
}());
export { SearchImages };
var SearchLeadMaps = /** @class */ (function () {
    function SearchLeadMaps(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SearchLeadMaps;
    }
    return SearchLeadMaps;
}());
export { SearchLeadMaps };
var SearchImagesSuccess = /** @class */ (function () {
    function SearchImagesSuccess(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SearchImagesSuccess;
    }
    return SearchImagesSuccess;
}());
export { SearchImagesSuccess };
var SearchImagesFailure = /** @class */ (function () {
    function SearchImagesFailure(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SearchImagesFailure;
    }
    return SearchImagesFailure;
}());
export { SearchImagesFailure };
var BuildDocumentSearch = /** @class */ (function () {
    function BuildDocumentSearch(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.BuildDocumentSearch;
    }
    return BuildDocumentSearch;
}());
export { BuildDocumentSearch };
var SearchDocuments = /** @class */ (function () {
    function SearchDocuments(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SearchDocuments;
    }
    return SearchDocuments;
}());
export { SearchDocuments };
var SearchDocumentsSuccess = /** @class */ (function () {
    function SearchDocumentsSuccess(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SearchDocumentsSuccess;
    }
    return SearchDocumentsSuccess;
}());
export { SearchDocumentsSuccess };
var SearchDocumentsFailure = /** @class */ (function () {
    function SearchDocumentsFailure(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SearchDocumentsFailure;
    }
    return SearchDocumentsFailure;
}());
export { SearchDocumentsFailure };
var SearchNewDocuments = /** @class */ (function () {
    function SearchNewDocuments(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SearchNewDocuments;
    }
    return SearchNewDocuments;
}());
export { SearchNewDocuments };
var SearchNewDocumentsSuccess = /** @class */ (function () {
    function SearchNewDocumentsSuccess(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SearchNewDocumentsSuccess;
    }
    return SearchNewDocumentsSuccess;
}());
export { SearchNewDocumentsSuccess };
var SearchNewDocumentsFailure = /** @class */ (function () {
    function SearchNewDocumentsFailure(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SearchNewDocumentsFailure;
    }
    return SearchNewDocumentsFailure;
}());
export { SearchNewDocumentsFailure };
var SearchSuggestionsSuccess = /** @class */ (function () {
    function SearchSuggestionsSuccess(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SearchSuggestionsSuccess;
    }
    return SearchSuggestionsSuccess;
}());
export { SearchSuggestionsSuccess };
var SearchSuggestionsFailure = /** @class */ (function () {
    function SearchSuggestionsFailure(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SearchSuggestionsFailure;
    }
    return SearchSuggestionsFailure;
}());
export { SearchSuggestionsFailure };
var RemoveSearchTerm = /** @class */ (function () {
    function RemoveSearchTerm(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.RemoveSearchTerm;
    }
    return RemoveSearchTerm;
}());
export { RemoveSearchTerm };
var AddSearchTerms = /** @class */ (function () {
    function AddSearchTerms(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.AddSearchTerms;
    }
    return AddSearchTerms;
}());
export { AddSearchTerms };
var AddAOIQuery = /** @class */ (function () {
    function AddAOIQuery(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.AddAOIQuery;
    }
    return AddAOIQuery;
}());
export { AddAOIQuery };
var SetClassifications = /** @class */ (function () {
    function SetClassifications(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SetClassifications;
    }
    return SetClassifications;
}());
export { SetClassifications };
var AddDocumentResponseTime = /** @class */ (function () {
    function AddDocumentResponseTime(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.AddDocumentResponseTime;
    }
    return AddDocumentResponseTime;
}());
export { AddDocumentResponseTime };
var AddImageResponseTime = /** @class */ (function () {
    function AddImageResponseTime(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.AddImageResponseTime;
    }
    return AddImageResponseTime;
}());
export { AddImageResponseTime };
var Export = /** @class */ (function () {
    function Export(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.Export;
    }
    return Export;
}());
export { Export };
var ExportSuccess = /** @class */ (function () {
    function ExportSuccess(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.ExportSuccess;
    }
    return ExportSuccess;
}());
export { ExportSuccess };
var ExportFailure = /** @class */ (function () {
    function ExportFailure(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.ExportFailure;
    }
    return ExportFailure;
}());
export { ExportFailure };
var SetInitialSearch = /** @class */ (function () {
    function SetInitialSearch(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SetInitialSearch;
    }
    return SetInitialSearch;
}());
export { SetInitialSearch };
var AddPill = /** @class */ (function () {
    function AddPill(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.AddPill;
    }
    return AddPill;
}());
export { AddPill };
var RemovePill = /** @class */ (function () {
    function RemovePill(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.RemovePill;
    }
    return RemovePill;
}());
export { RemovePill };
var RemovePills = /** @class */ (function () {
    function RemovePills(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.RemovePills;
    }
    return RemovePills;
}());
export { RemovePills };
var SetExportLoading = /** @class */ (function () {
    function SetExportLoading(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SetExportLoading;
    }
    return SetExportLoading;
}());
export { SetExportLoading };
var ResetAll = /** @class */ (function () {
    function ResetAll() {
        this.type = SearchActionTypes.ResetAll;
    }
    return ResetAll;
}());
export { ResetAll };
var SetResetAllState = /** @class */ (function () {
    function SetResetAllState(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SetResetAllState;
    }
    return SetResetAllState;
}());
export { SetResetAllState };
var SetDisplayResetAll = /** @class */ (function () {
    function SetDisplayResetAll(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SetDisplayResetAll;
    }
    return SetDisplayResetAll;
}());
export { SetDisplayResetAll };
var GetDocImages = /** @class */ (function () {
    function GetDocImages(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.GetDocImages;
    }
    return GetDocImages;
}());
export { GetDocImages };
var GetDocImagesSuccess = /** @class */ (function () {
    function GetDocImagesSuccess(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.GetDocImagesSuccess;
    }
    return GetDocImagesSuccess;
}());
export { GetDocImagesSuccess };
var GetDocImagesFailure = /** @class */ (function () {
    function GetDocImagesFailure(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.GetDocImagesFailure;
    }
    return GetDocImagesFailure;
}());
export { GetDocImagesFailure };
var SetWordCloudAppliedTerms = /** @class */ (function () {
    function SetWordCloudAppliedTerms(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SetWordCloudAppliedTerms;
    }
    return SetWordCloudAppliedTerms;
}());
export { SetWordCloudAppliedTerms };
var RemoveCloudAppliedTerms = /** @class */ (function () {
    function RemoveCloudAppliedTerms() {
        this.type = SearchActionTypes.RemoveCloudAppliedTerms;
    }
    return RemoveCloudAppliedTerms;
}());
export { RemoveCloudAppliedTerms };
var SetDocumentDetailPanelContent = /** @class */ (function () {
    function SetDocumentDetailPanelContent(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SetDocumentDetailPanelContent;
    }
    return SetDocumentDetailPanelContent;
}());
export { SetDocumentDetailPanelContent };
var HydrationSearchReset = /** @class */ (function () {
    function HydrationSearchReset() {
        this.type = SearchActionTypes.HydrationSearchReset;
    }
    return HydrationSearchReset;
}());
export { HydrationSearchReset };
var SetLoadingFlags = /** @class */ (function () {
    function SetLoadingFlags(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SetLoadingFlags;
    }
    return SetLoadingFlags;
}());
export { SetLoadingFlags };
var GetSurprisingSentencesFromSearch = /** @class */ (function () {
    function GetSurprisingSentencesFromSearch(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.GetSurprisingSentencesFromSearch;
    }
    return GetSurprisingSentencesFromSearch;
}());
export { GetSurprisingSentencesFromSearch };
var SetSurprisingSentencesFromSearch = /** @class */ (function () {
    function SetSurprisingSentencesFromSearch(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SetSurprisingSentencesFromSearch;
    }
    return SetSurprisingSentencesFromSearch;
}());
export { SetSurprisingSentencesFromSearch };
var SetSurprisingSentencesFailure = /** @class */ (function () {
    function SetSurprisingSentencesFailure(payload) {
        this.payload = payload;
        this.type = SearchActionTypes.SetSurprisingSentencesFailure;
    }
    return SetSurprisingSentencesFailure;
}());
export { SetSurprisingSentencesFailure };
