export var ToolboxActionTypes;
(function (ToolboxActionTypes) {
    ToolboxActionTypes["Toolbox_Toggle"] = "[Toolbox] Toggle Success";
})(ToolboxActionTypes || (ToolboxActionTypes = {}));
var ToolboxToggle = /** @class */ (function () {
    function ToolboxToggle(payload) {
        this.payload = payload;
        this.type = ToolboxActionTypes.Toolbox_Toggle;
    }
    return ToolboxToggle;
}());
export { ToolboxToggle };
