var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EsriBasemapLayers } from '@gis/models/EsriBasemapLayers';
import { MapLayerActions } from '@gis/actions/index';
import { DEFAULT_ACTIVE_BASE_MAP } from '../gis-content';
export var initialState = {
    availableLayers: [],
    availableMapServers: [],
    activeMapServers: [],
    availableLegends: [],
    availableBasemaps: EsriBasemapLayers,
    activeBasemap: EsriBasemapLayers.find(function (config) { return config.name === DEFAULT_ACTIVE_BASE_MAP; }),
    mapServerUrls: [],
    activePopups: [],
    selectedFeatures: [],
    activeGeoTags: [],
    currentZoom: null,
    currentFieldData: null,
    activeCentroid: null,
    loading: false,
    coordinates: null,
    bounds: [[0, 0], [0, 0]],
    layerManagerOpen: false,
    facetFeaturesCollection: [],
    lastURLMapServerFail: ''
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case MapLayerActions.MapLayerActionTypes.ActivateDynamicMapLayer: {
            var index = state.activeMapServers.map(function (server) { return server.url; }).indexOf(action.payload.url);
            var mapServers = [];
            if (index > -1) {
                // do update
                mapServers = state.activeMapServers.map(function (server) {
                    if (server.url === action.payload.url) {
                        if (!action.payload.layerId.length) {
                            // just toggle visibility and opacity
                            return __assign({}, server, { plotIndex: action.payload.plotIndex, opacity: action.payload.opacity, visible: true });
                        }
                        else {
                            var layers = void 0;
                            if (server.layers && server.layers.length) {
                                if (action.payload.layerId && action.payload.layerId.length) {
                                    layers = Array.from(new Set(server.layers.concat(action.payload.layerId)));
                                }
                                else {
                                    layers = server.layers;
                                }
                            }
                            else {
                                layers = action.payload.layerId;
                            }
                            return __assign({}, server, { layers: layers, opacity: action.payload.opacity });
                        }
                    }
                    else {
                        return server;
                    }
                });
            }
            else {
                // add
                mapServers = state.activeMapServers.concat([
                    {
                        url: action.payload.url,
                        layers: action.payload.layerId,
                        opacity: action.payload.opacity,
                        plotIndex: action.payload.plotIndex,
                        visible: action.payload.layerId.length ? false : true,
                        portalId: action.payload.portalId,
                        minZoom: action.payload.minZoom
                    }
                ]);
            }
            return __assign({}, state, { activeMapServers: mapServers, loading: action.payload.layerId === null ? true : false });
        }
        case MapLayerActions.MapLayerActionTypes.SetMapLoading: {
            return __assign({}, state, { loading: action.payload });
        }
        case MapLayerActions.MapLayerActionTypes.DeactivateDynamicMapLayer: {
            var mapServers = [];
            mapServers = state.activeMapServers.filter(function (server) {
                if (server.url === action.payload.url) {
                    if (action.payload.layerId !== null) {
                        // remove layer id provided
                        server.layers = server.layers.filter(function (layerId) { return layerId !== action.payload.layerId; });
                    }
                    else {
                        // no layer id provided, toggle visibility
                        server.visible = false;
                    }
                }
                return server;
            });
            return __assign({}, state, { activeMapServers: mapServers.slice() });
        }
        case MapLayerActions.MapLayerActionTypes.RemoveActivatedMapServer: {
            var index = state.activeMapServers.map(function (server) { return server.url; }).indexOf(action.payload);
            var activeServers = state.activeMapServers.slice();
            activeServers.splice(index, 1);
            return __assign({}, state, { activeMapServers: activeServers });
        }
        case MapLayerActions.MapLayerActionTypes.SetBasemap: {
            return __assign({}, state, { activeBasemap: action.payload });
        }
        case MapLayerActions.MapLayerActionTypes.LoadAllMapServers: {
            var availableServers = action.payload;
            var activeServers_1 = state.activeMapServers.slice();
            availableServers.forEach(function (server) {
                activeServers_1.forEach(function (active) {
                    if (server.url === active.url) {
                        active.plotIndex = server.plotIndex;
                    }
                });
            });
            return __assign({}, state, { availableMapServers: availableServers, activeMapServers: activeServers_1 });
        }
        case MapLayerActions.MapLayerActionTypes.LoadMapServerSuccess: {
            var availableServers = state.availableMapServers.slice();
            var activeServers_2 = state.activeMapServers.slice();
            if (!action.payload.plotIndex) {
                action.payload.plotIndex = 1;
                availableServers.forEach(function (server, index) {
                    server.plotIndex = index + 2;
                    activeServers_2.forEach(function (active) {
                        if (server.url === active.url) {
                            active.plotIndex = server.plotIndex;
                        }
                    });
                });
            }
            else {
                // For old workspaces to align available and active map servers plotIndex
                availableServers.forEach(function (server) {
                    activeServers_2.forEach(function (active) {
                        if (server.url === active.url) {
                            active.plotIndex = server.plotIndex;
                        }
                    });
                });
            }
            var sortedLayers = [action.payload].concat(state.availableMapServers);
            sortedLayers.sort(function (a, b) { return a.plotIndex - b.plotIndex; });
            return __assign({}, state, { availableMapServers: sortedLayers, activeMapServers: activeServers_2 });
        }
        case MapLayerActions.MapLayerActionTypes.LoadLayers: {
            return __assign({}, state, { mapServerUrls: state.mapServerUrls.concat([action.payload]) });
        }
        case MapLayerActions.MapLayerActionTypes.LoadLayersSuccess: {
            return __assign({}, state, { availableLayers: state.availableLayers.concat(action.payload) });
        }
        case MapLayerActions.MapLayerActionTypes.RemoveMapServer: {
            var availableMapServers = state.availableMapServers.filter(function (layer) { return layer !== action.payload; }).slice();
            var activeMapServers_1 = state.activeMapServers.slice();
            var idx = state.activeMapServers.map(function (server) { return server.url; }).indexOf(action.payload.url);
            if (idx !== -1) {
                activeMapServers_1.splice(idx, 1);
            }
            availableMapServers.forEach(function (mapServer, index) {
                mapServer.plotIndex = index + 1;
                activeMapServers_1.forEach(function (activeMapServer) {
                    if (activeMapServer.url === mapServer.url) {
                        activeMapServer.plotIndex = mapServer.plotIndex;
                    }
                });
            });
            var legends = state.availableLegends.slice();
            for (var i = legends.length - 1; i >= 0; i--) {
                if (legends[i].url === action.payload.url) {
                    legends.splice(i, 1);
                }
            }
            return __assign({}, state, { availableMapServers: availableMapServers, activeMapServers: activeMapServers_1, availableLegends: legends });
        }
        case MapLayerActions.MapLayerActionTypes.RemoveAllMapServers: {
            return __assign({}, state, { availableMapServers: [] });
        }
        case MapLayerActions.MapLayerActionTypes.ReplaceMapServer: {
            var mapServers = state.availableMapServers.slice();
            mapServers[action.payload.index] = action.payload.mapServer;
            return __assign({}, state, { availableMapServers: mapServers.slice() });
        }
        case MapLayerActions.MapLayerActionTypes.ActivateCentroid: {
            return __assign({}, state, { activeCentroid: action.payload });
        }
        case MapLayerActions.MapLayerActionTypes.LoadLegendSuccess: {
            return __assign({}, state, { availableLegends: state.availableLegends.concat([action.payload]) });
        }
        case MapLayerActions.MapLayerActionTypes.RemoveMapLegends: {
            return __assign({}, state, { availableLegends: [] });
        }
        case MapLayerActions.MapLayerActionTypes.HighlightFeature: {
            return __assign({}, state, { selectedFeatures: state.selectedFeatures.concat([action.payload]) });
        }
        case MapLayerActions.MapLayerActionTypes.ActivateFeaturePopupSuccess: {
            return __assign({}, state, { activePopups: state.activePopups.concat([action.payload]) });
        }
        case MapLayerActions.MapLayerActionTypes.DeactivateAllLayers: {
            return __assign({}, state, { activeMapServers: [] });
        }
        case MapLayerActions.MapLayerActionTypes.SetGeoTags: {
            return __assign({}, state, { activeGeoTags: action.payload });
        }
        case MapLayerActions.MapLayerActionTypes.SetZoom: {
            return __assign({}, state, { currentZoom: action.payload });
        }
        case MapLayerActions.MapLayerActionTypes.LoadFieldData: {
            return __assign({}, state, { currentFieldData: action.payload });
        }
        case MapLayerActions.MapLayerActionTypes.SetCoordinates: {
            return __assign({}, state, { coordinates: action.payload });
        }
        case MapLayerActions.MapLayerActionTypes.SetBounds: {
            return __assign({}, state, { bounds: action.payload });
        }
        case MapLayerActions.MapLayerActionTypes.ResetActiveCentroid: {
            return __assign({}, state, { activeCentroid: null });
        }
        case MapLayerActions.MapLayerActionTypes.SetLayerManagerOpen: {
            return __assign({}, state, { layerManagerOpen: action.payload });
        }
        case MapLayerActions.MapLayerActionTypes.AddFacetFeaturesCollection: {
            return __assign({}, state, { facetFeaturesCollection: state.facetFeaturesCollection.concat([action.payload]) });
        }
        case MapLayerActions.MapLayerActionTypes.ClearFacetFeaturesCollection: {
            return __assign({}, state, { facetFeaturesCollection: [] });
        }
        case MapLayerActions.MapLayerActionTypes.SetLastURLMapServerFail: {
            return __assign({}, state, { lastURLMapServerFail: action.payload });
        }
        default: {
            return state;
        }
    }
}
