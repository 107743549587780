import { Action } from '@ngrx/store';
import {CollectionComment, UserCollection} from '@app/shared/models/UserAPI.model';

export enum CollectionsActionTypes {
    LoadCollection = '[Collections] Load Collection',
    LoadCollectionSuccess = '[Collections] Load Collection Success',
    GetCollectionDocuments = '[Collections] Get Collection Documents',
    GetCollectionDocumentsSuccess = '[Collections] Get Collection Documents Success',
    GetCollectionDocumentsFailure = '[Collections] Get Collection Documents Failure',
    LoadCollectionSummary = '[Collections] Load Collection Summary',
    SetFilteringTypes = '[Collections] Set Filtering Types',
    ResetFilteringTypes = '[Collections] Reset Filtering Types',
    GetFilteredCollection = '[Collections] Get Filtered Collection',
    GetFilteredCollectionSuccess = '[Collections] Get Filtered Collection Success',
    GetSortedCollection = '[Collections] Get Sorted Collection',
    GetSortedCollectionSuccess = '[Collections] Get Sorted Collection Success',
    CollectionSearch = '[Collections] Collection Search',
    CollectionSearchSuccess = '[Collections] Collection Search Success',
    LoadAllCollections = '[Collections] Load All Collections',
    LoadAllCollectionsSuccess = '[Collections] Load All Collections Success',
    LoadAllCollectionsFailure = '[Collections] Load All Collections Failure',
    CreateCollection = '[Collections] Create Collection',
    CreateCollectionSuccess = '[Collections] Create Collections Success',
    CreateCollectionFailure = '[Collections] Create Collections Failure',
    DeleteCollection = '[Collections] Delete Collection',
    DeleteCollectionSuccess = '[Collections] Delete Collections Success',
    DeleteCollectionFailure = '[Collections] Delete Collections Failure',
    RemoveFromCollection = '[Collections] Remove From Collection',
    RemoveFromCollectionSuccess = '[Collections] Remove From Collection Success',
    UpdateCollection = '[Collections] Update Collection',
    UpdateCollectionSuccess = '[Collections] Update Collection Success',
    UpdateCollectionFailure = '[Collections] Update Collection Failure',
    SetSelectedDocument = '[Collections] Set Selected Document',
    SetAllSelectedDocument = '[Collections] Set All Selected Document',
    SetNotPresentFilesToRemove = '[Collections] Set Not Present Files To Remove',
    ResetSelectedDocument = '[Collections] Reset Selected Document',
    ExportAllCollectionDocs = '[Collections] Export Documents to CSV',
    ExportAllCollectionDocsSuccess = '[Collections] Export Documents to CSV Success',
    SetCardsFlag = '[Collections] Set Cards Flag',
    CollectionNotExisting = '[Collections] Collection Not Existing',
    ToggleNotesPanel = '[Collections] Toggle Notes Panel',
    SetNotesFromFlipcard = '[Collections] Set Notes From Flipcard',
    SetNotesLoading = '[Collections] Set Notes Loading',
    SetShownNotes = '[Collections] Set Shown Notes',
    AddCollectionComment = '[Collections] Add Collection Comment',
    AddCollectionCommentSuccess = '[Collections] Add Collection Comment Success',
    DeleteCollectionComment = '[Collections] Delete Collection Comment',
    DeleteCollectionCommentSuccess = '[Collections] Delete Collection Comment Success',
    PinCollection = '[Collections] Pin Collection',
    UnpinCollection = '[Collections] Unpin Collection'
}

export class LoadCollection implements Action {
    readonly type = CollectionsActionTypes.LoadCollection;

    constructor(public payload: number) {}
}

export class LoadCollectionSuccess implements Action {
    readonly type = CollectionsActionTypes.LoadCollectionSuccess;

    constructor(public payload: UserCollection) {}
}

export class GetCollectionDocuments implements Action {
    readonly type = CollectionsActionTypes.GetCollectionDocuments;

    constructor(public payload: string[]) {}
}

export class GetCollectionDocumentsSuccess implements Action {
    readonly type = CollectionsActionTypes.GetCollectionDocumentsSuccess;

    constructor(public payload: any) { }
}

export class GetCollectionDocumentsFailure implements Action {
    readonly type = CollectionsActionTypes.GetCollectionDocumentsFailure;

    constructor(public payload: string) { }
}

export class LoadCollectionSummary implements Action {
    readonly type = CollectionsActionTypes.LoadCollectionSummary;

    constructor(public payload: []) {}
}

export class SetFilteringTypes implements Action {
    readonly type = CollectionsActionTypes.SetFilteringTypes;

    constructor(public payload: string[]) {}
}

export class ResetFilteringTypes implements Action {
    readonly type = CollectionsActionTypes.ResetFilteringTypes;
}


export class GetFilteredCollection implements Action {
    readonly type = CollectionsActionTypes.GetFilteredCollection;

    constructor(public payload: { collectionId: number, filterExtensions: string[]}) {}
}

export class GetFilteredCollectionSuccess implements Action {
    readonly type = CollectionsActionTypes.GetFilteredCollectionSuccess;

    constructor(public payload: UserCollection) {}
}

export class GetSortedCollection implements Action {
    readonly type = CollectionsActionTypes.GetSortedCollection;

    constructor(public payload: { collectionId: number, fileSort: number}) {}
}

export class GetSortedCollectionSuccess implements Action {
    readonly type = CollectionsActionTypes.GetSortedCollectionSuccess;

    constructor(public payload: UserCollection) {}
}

export class CollectionSearch implements Action {
    readonly type = CollectionsActionTypes.CollectionSearch;

    constructor(public payload: { collectionId: number, filterFileContains: string}) {}
}

export class CollectionSearchSuccess implements Action {
    readonly type = CollectionsActionTypes.CollectionSearchSuccess;

    constructor(public payload: UserCollection) {}
}

export class LoadAllCollections implements Action {
    readonly type = CollectionsActionTypes.LoadAllCollections;

    constructor() { }
}

export class LoadAllCollectionsSuccess implements Action {
    readonly type = CollectionsActionTypes.LoadAllCollectionsSuccess;

    constructor(public payload: any) { }
}

export class LoadAllCollectionsFailure implements Action {
    readonly type = CollectionsActionTypes.LoadAllCollectionsFailure;

    constructor(public payload: any) { }
}

export class CreateCollection implements Action {
    readonly type = CollectionsActionTypes.CreateCollection;

    constructor(public payload: UserCollection) { }
}

export class CreateCollectionSuccess implements Action {
    readonly type = CollectionsActionTypes.CreateCollectionSuccess;

    constructor(public payload: string) { }
}

export class CreateCollectionFailure implements Action {
    readonly type = CollectionsActionTypes.CreateCollectionFailure;

    constructor(public payload: any) { }
}

export class DeleteCollection implements Action {
    readonly type = CollectionsActionTypes.DeleteCollection;

    constructor(public payload: UserCollection) { }
}

export class DeleteCollectionSuccess implements Action {
    readonly type = CollectionsActionTypes.DeleteCollectionSuccess;

    constructor(public payload: number) { }
}

export class DeleteCollectionFailure implements Action {
    readonly type = CollectionsActionTypes.DeleteCollectionFailure;

    constructor(public payload: any) { }
}

export class RemoveFromCollection implements Action {
    readonly type = CollectionsActionTypes.RemoveFromCollection;

    constructor() { }
}

export class RemoveFromCollectionSuccess implements Action {
    readonly type = CollectionsActionTypes.RemoveFromCollectionSuccess;

    constructor(public payload: any) { }
}

export class UpdateCollection implements Action {
    readonly type = CollectionsActionTypes.UpdateCollection;

    constructor(public payload: UserCollection) { }
}

export class UpdateCollectionSuccess implements Action {
    readonly type = CollectionsActionTypes.UpdateCollectionSuccess;

    constructor(public payload: any) { }
}

export class UpdateCollectionFailure implements Action {
    readonly type = CollectionsActionTypes.UpdateCollectionFailure;

    constructor(public payload: any) { }
}

export class SetCardsFlag implements Action {
    readonly type = CollectionsActionTypes.SetCardsFlag;
    constructor(public payload: boolean) { }
}

export class SetSelectedDocument implements Action {
    readonly type = CollectionsActionTypes.SetSelectedDocument;

    constructor(public payload: {isSelected: boolean, documentName: string}) { }
}

export class SetAllSelectedDocument implements Action {
    readonly type = CollectionsActionTypes.SetAllSelectedDocument;

    constructor(public payload: number) { }
}

export class SetNotPresentFilesToRemove implements Action {
    readonly type = CollectionsActionTypes.SetNotPresentFilesToRemove;

    constructor(public payload: string[]) { }
}

export class ResetSelectedDocument implements Action {
    readonly type = CollectionsActionTypes.ResetSelectedDocument;
}

export class ExportAllCollectionDocs implements Action {
    readonly type = CollectionsActionTypes.ExportAllCollectionDocs;

    constructor(public payload: string[]) {}
}

export class ExportAllCollectionDocsSuccess implements Action {
    readonly type = CollectionsActionTypes.ExportAllCollectionDocsSuccess;

    constructor(public payload: string) {}
}

export class CollectionNotExisting implements Action {
    readonly type = CollectionsActionTypes.CollectionNotExisting;

    constructor(public payload: boolean) {}
}

export class ToggleNotesPanel implements Action {
    readonly type = CollectionsActionTypes.ToggleNotesPanel;

    constructor(public payload: boolean) {}
}

export class SetNotesFromFlipcard implements Action {
    readonly type = CollectionsActionTypes.SetNotesFromFlipcard;

    constructor(public payload: {state: boolean, id: number | null}) {}
}

export class SetNotesLoading implements Action {
    readonly type = CollectionsActionTypes.SetNotesLoading;

    constructor(public payload: boolean) {}
}

export class SetShownNotes implements Action {
    readonly type = CollectionsActionTypes.SetShownNotes;

    constructor(public payload: CollectionComment[]) {}
}

export class AddCollectionComment implements Action {
    readonly type = CollectionsActionTypes.AddCollectionComment;

    constructor(public payload: {collectionID: number, message: string}) {}
}

export class AddCollectionCommentSuccess implements Action {
    readonly type = CollectionsActionTypes.AddCollectionCommentSuccess;

    constructor(public payload: {collectionID: number, message: string}) {}
}

export class DeleteCollectionComment implements Action {
    readonly type = CollectionsActionTypes.DeleteCollectionComment;

    constructor(public payload: {collectionID: number, messageID: string}) {}

}

export class DeleteCollectionCommentSuccess implements Action {
    readonly type = CollectionsActionTypes.DeleteCollectionCommentSuccess;

    constructor(public payload: {collectionID: number, messageID: string}) {}
}

export class PinCollection implements Action {
    readonly type = CollectionsActionTypes.PinCollection;

    constructor(public payload: number) {}
}

export class UnpinCollection implements Action {
    readonly type = CollectionsActionTypes.UnpinCollection;

    constructor(public payload: number) {}
}

export type CollectionsActionsUnion =
    | LoadCollection
    | LoadCollectionSuccess
    | GetCollectionDocuments
    | GetCollectionDocumentsSuccess
    | GetCollectionDocumentsFailure
    | LoadCollectionSummary
    | SetFilteringTypes
    | ResetFilteringTypes
    | GetFilteredCollection
    | GetFilteredCollectionSuccess
    | GetSortedCollection
    | GetSortedCollectionSuccess
    | CollectionSearch
    | CollectionSearchSuccess
    | LoadAllCollections
    | LoadAllCollectionsSuccess
    | LoadAllCollectionsFailure
    | CreateCollection
    | CreateCollectionSuccess
    | CreateCollectionFailure
    | DeleteCollection
    | DeleteCollectionSuccess
    | DeleteCollectionFailure
    | RemoveFromCollection
    | RemoveFromCollectionSuccess
    | UpdateCollection
    | UpdateCollectionSuccess
    | UpdateCollectionFailure
    | SetSelectedDocument
    | SetAllSelectedDocument
    | SetNotPresentFilesToRemove
    | ResetSelectedDocument
    | ExportAllCollectionDocs
    | ExportAllCollectionDocsSuccess
    | SetCardsFlag
    | CollectionNotExisting
    | ToggleNotesPanel
    | SetNotesFromFlipcard
    | SetShownNotes
    | SetNotesLoading
    | AddCollectionComment
    | AddCollectionCommentSuccess
    | DeleteCollectionComment
    | DeleteCollectionCommentSuccess
    | PinCollection
    | UnpinCollection;
