export var MeasureActionTypes;
(function (MeasureActionTypes) {
    MeasureActionTypes["SetMetric"] = "[Measure] Set Metric";
    MeasureActionTypes["SetMeasuring"] = "[Measure] Set Measure status";
    MeasureActionTypes["SetMapMeasures"] = "[Measure] Set Map Measures";
    MeasureActionTypes["SetMeasureLayer"] = "[Measure] Set Measure Layer";
    MeasureActionTypes["ResetMeasureLayer"] = "[Measure] Reset Measure Layer";
})(MeasureActionTypes || (MeasureActionTypes = {}));
var SetMetric = /** @class */ (function () {
    function SetMetric(payload) {
        this.payload = payload;
        this.type = MeasureActionTypes.SetMetric;
    }
    return SetMetric;
}());
export { SetMetric };
var SetMeasuring = /** @class */ (function () {
    function SetMeasuring(payload) {
        this.payload = payload;
        this.type = MeasureActionTypes.SetMeasuring;
    }
    return SetMeasuring;
}());
export { SetMeasuring };
var SetMapMeasures = /** @class */ (function () {
    function SetMapMeasures(payload) {
        this.payload = payload;
        this.type = MeasureActionTypes.SetMapMeasures;
    }
    return SetMapMeasures;
}());
export { SetMapMeasures };
var SetMeasureLayer = /** @class */ (function () {
    function SetMeasureLayer(payload) {
        this.payload = payload;
        this.type = MeasureActionTypes.SetMeasureLayer;
    }
    return SetMeasureLayer;
}());
export { SetMeasureLayer };
var ResetMeasureLayer = /** @class */ (function () {
    function ResetMeasureLayer() {
        this.type = MeasureActionTypes.ResetMeasureLayer;
    }
    return ResetMeasureLayer;
}());
export { ResetMeasureLayer };
