import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { Preference, Workspace } from '@shared/models/UserAPI.model';
import * as fromShared from '@shared/reducers';
import { Router } from '@angular/router';
import { WorkspacesActions, SnackbarActions } from '@shared/actions/index';
import { WSContent } from '../workspaces-content';
import { ContentService } from '@shared/services/content.service';
import { AppConfigService } from '@app/app-config.service';
import { SearchActions } from '@app/modules/search/actions';
import { MapAOIActions, MapLayerActions } from '@app/modules/gis/actions';
import { GeobrainLoaderService } from '@app/shared/services/geobrain-loader.service';
import { console } from '@app/shared/util/console.util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../app-config.service";
import * as i3 from "@ngrx/store";
import * as i4 from "@angular/router";
import * as i5 from "../../../shared/services/geobrain-loader.service";
import * as i6 from "../workspaces-content";
var PERSONAL_TYPE = 1;
var TEMPLATE_TPYE = 2;
var WorkspaceService = /** @class */ (function () {
    function WorkspaceService(http, _appConfig, sharedStore, searchStore, gisStore, router, geoLoaderService, content) {
        this.http = http;
        this._appConfig = _appConfig;
        this.sharedStore = sharedStore;
        this.searchStore = searchStore;
        this.gisStore = gisStore;
        this.router = router;
        this.geoLoaderService = geoLoaderService;
        this.content = content;
        this.personalNames = [];
        this.templateNames = [];
        this.currentWorkspace = null;
        this.preferences$ = this.sharedStore.pipe(select(fromShared.getPreferences), skipWhile(function (preferences) { return preferences === null; }));
        this.snackbars = this.content.local.MANAGER.snackbars;
        this._config = this._appConfig.getConfig();
    }
    WorkspaceService.prototype.createWorkspace = function (workspace) {
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            withCredentials: true
        };
        return this.http.post(this._config.user_api_baseurl + "/workspace", workspace, httpOptions);
    };
    WorkspaceService.prototype.loadWorkspaces = function () {
        return this.http.get(this._config.user_api_baseurl + "/workspaces");
    };
    WorkspaceService.prototype.loadTemplateWorkspaces = function () {
        return this.http.get(this._config.user_api_baseurl + "/globalworkspaces");
    };
    WorkspaceService.prototype.loadPersonalWorkspaces = function () {
        return this.http.get(this._config.user_api_baseurl + "/userWorkspaces");
    };
    WorkspaceService.prototype.loadWorkspace = function (id) {
        return this.http.get(this._config.user_api_baseurl + "/workspace/" + id);
    };
    WorkspaceService.prototype.mockloadWorkspace = function (id) {
        // return this.http.get<Workspace>(`/assets/data/sample-workspace.json`);
        return this.http.get("/assets/data/workspace834.json");
    };
    WorkspaceService.prototype.updateWorkspace = function (workspace) {
        return this.http.put(this._config.user_api_baseurl + "/workspace/" + workspace.workspaceId, workspace);
    };
    WorkspaceService.prototype.updateWorkspaceFields = function (workspaceId, object) {
        return this.http.put(this._config.user_api_baseurl + "/workspace/" + workspaceId, object);
    };
    WorkspaceService.prototype.deleteWorkspace = function (id) {
        return this.http.delete(this._config.user_api_baseurl + "/workspace/" + id);
    };
    WorkspaceService.prototype.addEnabledApp = function (workspaceId, enabledApp) {
        return this.http.post(this._config.user_api_baseurl + "/workspace/enabledApps/" + workspaceId + "/" + enabledApp, {});
    };
    WorkspaceService.prototype.deleteEnabledApp = function (workspaceId, enabledApp) {
        return this.http.delete(this._config.user_api_baseurl + "/workspace/enabledApps/" + workspaceId + "/" + enabledApp);
    };
    WorkspaceService.prototype.replaceEnabledApps = function (workspaceId, enabledApps) {
        return this.http.put(this._config.user_api_baseurl + "/workspace/enabledApps/replace/" + workspaceId, enabledApps);
    };
    WorkspaceService.prototype.updateActiveMapServer = function (workspaceId, activeMaps) {
        return this.http.put(this._config.user_api_baseurl + "/workspace/activeMapServer/replace/" + workspaceId, activeMaps);
    };
    WorkspaceService.prototype.updateCustomMapServer = function (workspaceId, activeMaps) {
        return this.http.put(this._config.user_api_baseurl + "/workspace/customMapServer/replace/" + workspaceId, activeMaps);
    };
    WorkspaceService.prototype.validatePersonalName = function (name) {
        return (this.personalNames.indexOf(name) === -1);
    };
    WorkspaceService.prototype.validateTemplateName = function (name) {
        return (this.templateNames.indexOf(name) === -1);
    };
    WorkspaceService.prototype.clearResults = function (id) {
        var _this = this;
        this.http.put(this._config.user_api_baseurl + "/workspace/ResetDocumentCount/" + id, {}).pipe(take(1)).subscribe(function (res) {
            _this.loadWorkspace(id).pipe(take(1)).subscribe(function (ws) {
                _this.sharedStore.dispatch(new WorkspacesActions.LoadWorkspace(ws));
            });
        });
    };
    WorkspaceService.prototype.openSnackbar = function (messageType, workspaceName, type) {
        if (workspaceName === void 0) { workspaceName = null; }
        if (type === void 0) { type = 'success'; }
        console.log(messageType, this.snackbars[messageType]);
        this.sharedStore.dispatch(new SnackbarActions.Open({
            message: this.snackbars[messageType] + ((workspaceName) ? ': ' + workspaceName : ''),
            type: ('xom-snackbar-' + type),
            action: 'x'
        }));
    };
    WorkspaceService.prototype.overwriteWorkspace = function (workspace, search, basins) {
        if (basins) {
            workspace.aoi.aoiQuery = {
                basin: basins.split(' AND ')
            };
            workspace.aoi.availableAois = {
                basin: basins.split(' AND ')
            };
            window._override_bounds = true;
        }
        if (search) {
            workspace.queryText = search;
        }
        workspace.query = {
            basin: basins ? basins.split(' AND ') : null,
            keywords: search ? [search] : null
        };
        return workspace;
    };
    WorkspaceService.prototype.loadWorkspaceFromUrl = function (id, search, basins) {
        var _this = this;
        this.loadWorkspace(id).pipe(take(1)).subscribe(function (res) {
            if (basins || search) {
                res = _this.overwriteWorkspace(res, search, basins);
            }
            _this.sharedStore.dispatch(new WorkspacesActions.LoadWorkspace(res));
        }, function (error) {
            _this.openSnackbar('errorLoading', '', 'error');
        });
    };
    WorkspaceService.prototype.loadWorkspaceFromPreferences = function (id, search, basins) {
        var _this = this;
        this.loadWorkspace(id).pipe(take(1)).subscribe(function (res) {
            if (basins || search) {
                res = _this.overwriteWorkspace(res, search, basins);
            }
            _this.sharedStore.dispatch(new WorkspacesActions.LoadWorkspace(res));
            _this.openSnackbar('defaultLoaded', res.title);
        }, function (error) {
            _this.openSnackbar('errorLoading', '', 'error');
        });
    };
    WorkspaceService.prototype.loadLastWorkspace = function () {
        var _this = this;
        this.loadWorkspaces().pipe(take(1)).subscribe(function (workspaces) {
            var mostRecent = null;
            workspaces.forEach(function (ws) {
                if (mostRecent === null || mostRecent.lastModified < ws.lastModified) {
                    mostRecent = ws;
                }
            });
            if (mostRecent !== null) {
                _this.sharedStore.dispatch(new WorkspacesActions.LoadWorkspace(mostRecent));
                _this.openSnackbar('recentLoaded', mostRecent.title);
            }
        });
    };
    WorkspaceService.prototype.loadInitialWorkspace = function (id) {
        var _this = this;
        if (id) {
            console.log('Loading ws from url:', id);
            this.loadWorkspaceFromUrl(parseInt(id, 10));
        }
        else {
            if (this.currentWorkspace === null) {
                // If no specific workspace was requested, lets load the one in preferences
                this.preferences$.pipe(take(1)).subscribe(function (prefs) {
                    console.log('Default view:', prefs.defaultView);
                    if (prefs.defaultView === 'homepage') {
                        switch (prefs.resultsView.toLowerCase()) {
                            case 'map':
                                _this.geoLoaderService.activateItemByUrl('geobrain/map');
                                break;
                            case 'list':
                                _this.geoLoaderService.activateAppById('summary-app', 'list');
                                break;
                            case 'list+map':
                            case 'both':
                            case 'hybrid':
                                _this.geoLoaderService.activateAppById('summary-app', 'hybrid');
                                break;
                            default:
                                _this.geoLoaderService.activateAppById('landing-app', 'hybrid');
                                break;
                        }
                    }
                    else if (prefs.defaultView === 'previous_workspace') {
                        _this.loadLastWorkspace();
                    }
                    else if (prefs.defaultView === 'workspace'
                        && prefs.defaultWorkspaceID !== null
                        && prefs.defaultWorkspaceID !== -1
                        && _this.currentWorkspace === null) {
                        _this.loadWorkspaceFromPreferences(prefs.defaultWorkspaceID);
                    }
                });
            }
        }
    };
    WorkspaceService.prototype.cleanCurrentWorkspace = function () {
        this.currentWorkspace = null;
        this.searchStore.dispatch(new SearchActions.ResetAll());
        this.gisStore.dispatch(new MapAOIActions.ClearAOI());
        this.gisStore.dispatch(new MapLayerActions.ResetActiveCentroid());
        this.gisStore.dispatch(new MapLayerActions.RemoveAllMapServers());
        this.gisStore.dispatch(new MapLayerActions.DeactivateAllLayers(true));
        this.gisStore.dispatch(new MapLayerActions.RemoveMapLegends());
        this.searchStore.dispatch(new SearchActions.SetInitialSearch(false));
        this.searchStore.dispatch(new SearchActions.RemovePills([]));
        this.sharedStore.dispatch(new WorkspacesActions.LoadWorkspace(this.currentWorkspace));
        this.geoLoaderService.activateAppById('landing-app');
        this.openSnackbar('cleaned');
    };
    WorkspaceService.prototype.notifyLastURLMapServerFail = function (url) {
        this.gisStore.dispatch(new MapLayerActions.SetLastURLMapServerFail(url));
    };
    // Reset the names array to correct working of the validateName
    WorkspaceService.prototype.resetNameValidation = function () {
        this.personalNames = [];
        this.templateNames = [];
    };
    WorkspaceService.prototype.getTitlesOfWorkspacesToSetNames = function (workspaces) {
        this.personalNames = this.personalNames.concat(this.personalNames, workspaces.filter(function (ws) { return ws.type === PERSONAL_TYPE; }).map(function (ws) { return ws.title; }));
        this.templateNames = this.templateNames.concat(this.templateNames, workspaces.filter(function (ws) { return ws.type === TEMPLATE_TPYE; }).map(function (ws) { return ws.title; }));
    };
    WorkspaceService.ngInjectableDef = i0.defineInjectable({ factory: function WorkspaceService_Factory() { return new WorkspaceService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService), i0.inject(i3.Store), i0.inject(i3.Store), i0.inject(i3.Store), i0.inject(i4.Router), i0.inject(i5.GeobrainLoaderService), i0.inject(i6.WS_CONTENT)); }, token: WorkspaceService, providedIn: "root" });
    return WorkspaceService;
}());
export { WorkspaceService };
