import { AppConfigService } from '@app/app-config.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app-config.service";
var CreamingCurvesService = /** @class */ (function () {
    function CreamingCurvesService(http, _appConfig) {
        this.http = http;
        this._appConfig = _appConfig;
        this.features = new BehaviorSubject([]);
        var config = this._appConfig.getConfig();
        this.API_PATH = config.datascience_api_baseurl + '/sqldata/creamingcurves';
    }
    CreamingCurvesService.prototype.getBasins = function () {
        return this.http.get(this.API_PATH + "/getBasins");
        // return this.http.get<any[]>(`/assets/data/creaming_curves_getBasins.json`);
    };
    CreamingCurvesService.prototype.getPlays = function () {
        return this.http.get(this.API_PATH + "/getPlays");
        // return this.http.get<any[]>(`/assets/data/creaming_curves_getPlays.json`);
    };
    CreamingCurvesService.prototype.productionByBasins = function (listOfBasins) {
        return this.http.post(this.API_PATH + "/productionByBasins", { basins: listOfBasins });
        // return this.http.get<any[]>(`/assets/data/creaming_curves_productionByBasins.json`);
    };
    CreamingCurvesService.prototype.productionByPlays = function (listOfBasins) {
        return this.http.post(this.API_PATH + "/productionByPlays", { basins: listOfBasins });
        // return this.http.get<any[]>(`/assets/data/creaming_curves_productionByBasinsPlays.json`);
    };
    CreamingCurvesService.prototype.productionByBasinsPlays = function (listOfBasins, listOfPlays) {
        return this.http.post(this.API_PATH + "/productionByBasinsPlays", { basins: listOfBasins, plays: listOfPlays });
        // return this.http.get<any[]>(`/assets/data/creaming_curves_productionByPlays.json`);
        // return this.http.get<any[]>(`/assets/data/creaming_curves_productionByBasinsPlaysFilterJustBasinCuumLum.json`);
    };
    CreamingCurvesService.prototype.getBasinsWithCreamingCurve = function (country) {
        return this.http.get(this.API_PATH + "/getBasins?country=" + country);
    };
    CreamingCurvesService.ngInjectableDef = i0.defineInjectable({ factory: function CreamingCurvesService_Factory() { return new CreamingCurvesService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService)); }, token: CreamingCurvesService, providedIn: "root" });
    return CreamingCurvesService;
}());
export { CreamingCurvesService };
