export var NavigationActionTypes;
(function (NavigationActionTypes) {
    NavigationActionTypes["OpenAppFromOtherApp"] = "[Navigation] App opened from other app";
})(NavigationActionTypes || (NavigationActionTypes = {}));
var OpenAppFromOtherApp = /** @class */ (function () {
    function OpenAppFromOtherApp(payload) {
        this.payload = payload;
        this.type = NavigationActionTypes.OpenAppFromOtherApp;
    }
    return OpenAppFromOtherApp;
}());
export { OpenAppFromOtherApp };
