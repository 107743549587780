import { OnInit } from '@angular/core';
var ModalLayoutComponent = /** @class */ (function () {
    function ModalLayoutComponent() {
        this.hasHeader = false;
        this.large = false;
    }
    ModalLayoutComponent.prototype.ngOnInit = function () {
    };
    return ModalLayoutComponent;
}());
export { ModalLayoutComponent };
