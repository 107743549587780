
import { LeadMapsActions } from '../actions/index';

export interface State {
    basin: string | null;
    country: string | null;
}

const initialState: State = { basin: null, country: null };

export function reducer(
    state = initialState,
    action: LeadMapsActions.LeadMapsActionsUnion
): State {
    switch (action.type) {
        case LeadMapsActions.LeadMapActionTypes.Basin:
            return {
                ...state,
                basin: action.payload
            };
        case LeadMapsActions.LeadMapActionTypes.Country:
            return {
                ...state,
                basin: action.payload
            };
        default: {
            return state;
        }
    }
}
