import { Observable, BehaviorSubject } from 'rxjs';
import * as esri from 'esri-leaflet';
import { Store } from '@ngrx/store';
import { AppConfigService } from '@app/app-config.service';
import { MapLayerActions } from '@app/modules/gis/actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../../app-config.service";
var InterrogatorService = /** @class */ (function () {
    function InterrogatorService(store, appConfigSvc) {
        this.store = store;
        this.appConfigSvc = appConfigSvc;
        this.features = new BehaviorSubject({});
        this.features$ = this.features.asObservable();
        this.layers = [];
        this.basinConfig = {};
        this.playConfig = {};
        this.sourceRockConfig = {};
        this.wellConfig = {};
        this.basinLayerUrl = this.appConfigSvc.getConfig().basinLayerUrl;
        this.playLayerUrl = this.appConfigSvc.getConfig().playLayerUrl;
        this.sourceRockLayerUrl = this.appConfigSvc.getConfig().sourceRockLayerUrl;
        this.wellLayerUrl = this.appConfigSvc.getConfig().wellServiceUrl;
        this.wellServiceUrl = this.appConfigSvc.getConfig().wellServiceUrl;
        this.wellMetadataServiceUrl = this.appConfigSvc.getConfig().wellMetadataServiceUrl;
        this.basinToPlayUrl = this.appConfigSvc.getConfig().basinToPlayUrl;
        this.playMetadataServiceUrl = this.appConfigSvc.getConfig().playMetadataServiceUrl;
        this.wellCoordinatedataServiceUrl = this.appConfigSvc.getConfig().wellCoordinatedataServiceUrl;
        this.sourceRockServiceUrl = this.appConfigSvc.getConfig().sourceRockServiceUrl;
        this.penetrationServiceUrl = this.appConfigSvc.getConfig().penetrationServiceUrl;
        this.wellSourceServiceUrl = this.appConfigSvc.getConfig().wellSourceServiceUrl;
    }
    InterrogatorService.prototype.queryWellsByBasinName = function (url, basinName, attributes, returnGeometry) {
        if (returnGeometry === void 0) { returnGeometry = false; }
        var query = esri.query({
            url: url,
            useCors: true,
            withCredentials: true
        });
        return new Observable(function (observer) {
            var where = "BASIN_NAME_1 = '" + basinName + "'";
            query
                .where(where)
                .run(function (error, featureCollection) {
                if (error) {
                    observer.error(error);
                }
                else {
                    observer.next(featureCollection);
                    observer.complete();
                }
            });
        });
    };
    InterrogatorService.prototype.queryPenetrationByBasinName = function (url, basinName, attributes, returnGeometry) {
        if (returnGeometry === void 0) { returnGeometry = false; }
        var query = esri.query({
            url: url,
            useCors: true,
            withCredentials: true
        });
        return new Observable(function (observer) {
            var where = "BASIN_NAME = '" + basinName + "'";
            query
                .where(where)
                .returnGeometry(returnGeometry)
                .fields(attributes)
                .run(function (error, featureCollection) {
                if (error) {
                    observer.error(error);
                }
                else {
                    observer.next(featureCollection);
                    observer.complete();
                }
            });
        });
    };
    InterrogatorService.prototype.querywellSourcesByBasinName = function (url, basinName, attributes, returnGeometry) {
        if (returnGeometry === void 0) { returnGeometry = false; }
        var query = esri.query({
            url: url,
            useCors: true,
            withCredentials: true
        });
        return new Observable(function (observer) {
            var where = "BASIN_NAME = '" + basinName + "'";
            query
                .where(where)
                .returnGeometry(returnGeometry)
                .fields(attributes)
                .distinct()
                .run(function (error, featureCollection) {
                if (error) {
                    observer.error(error);
                }
                else {
                    observer.next(featureCollection);
                    observer.complete();
                }
            });
        });
    };
    InterrogatorService.prototype.querySourceRockMetadata = function (url, sourceRockMinAge, sourceRockMaxAge, playIDs) {
        var attrs = ['*'
        ];
        var query = esri.query({
            url: url,
            useCors: true,
            withCredentials: true
        });
        return new Observable(function (observer) {
            var where = "AGE_END = " + sourceRockMinAge + " AND AGE_START = " + sourceRockMaxAge + " AND (" + playIDs.map(function (playID) {
                return "PLAY_ID = '" + playID + "'";
            }).join(' OR ') + ")";
            query
                .where(where)
                .returnGeometry(false)
                .fields(attrs)
                .run(function (error, featureCollection) {
                if (error) {
                    observer.error(error);
                }
                else {
                    observer.next(featureCollection);
                    observer.complete();
                }
            });
        });
    };
    /* mapping functions */
    InterrogatorService.prototype.SetPlayConfig = function (config) {
        this.store.dispatch(new MapLayerActions.SetMapLoading(true));
        // this.SetFeatures({}, null);
        if (config) {
            this.playConfig = config;
            this.queryFeatures('PLAY', config.featureCollection);
        }
    };
    InterrogatorService.prototype.SetWellConfig = function (config) {
        this.store.dispatch(new MapLayerActions.SetMapLoading(true));
        // this.SetFeatures({}, null);
        if (config) {
            this.wellConfig = config;
            this.queryFeatures('WELL', config.featureCollection);
        }
        this.store.dispatch(new MapLayerActions.SetMapLoading(false));
    };
    InterrogatorService.prototype.SetSourceRockConfig = function (config) {
        this.store.dispatch(new MapLayerActions.SetMapLoading(true));
        this.SetFeatures({}, null, null);
        if (config) {
            this.sourceRockConfig = config;
            this.queryFeatures('SOURCEROCK', config.featureCollection);
        }
    };
    InterrogatorService.prototype.SetBasinConfig = function (config) {
        this.store.dispatch(new MapLayerActions.SetMapLoading(true));
        // this.SetFeatures({}, null);
        if (config) {
            this.basinConfig = config;
            this.queryFeatures('BASIN', config.featureCollection);
        }
    };
    InterrogatorService.prototype.queryFeatures = function (type, features) {
        var _this = this;
        var condition, url;
        switch (type) {
            case 'PLAY':
                url = this.playLayerUrl;
                condition = "" + features.map(function (feature) { return "PLAY_ID = '" + feature.src + "'"; }).join(' OR ');
                break;
            case 'SOURCEROCK':
                url = this.sourceRockLayerUrl;
                condition = features.map(function (feature) {
                    return "(AGE_END = " + feature.src.AGE_END + " AND AGE_START = " + feature.src.AGE_START + " AND PLAY_ID = '" + feature.play + "')";
                })
                    .join(' OR ');
                break;
            case 'BASIN':
                url = this.appConfigSvc.getConfig().basinLayerUrl;
                condition = features.map(function (feature) { return "BASIN_NAME = '" + feature.src + "'"; });
                break;
            case 'WELL':
                url = this.wellLayerUrl;
                condition = features.map(function (feature) { return "BASIN_NAME_1 = '" + feature.basin + "'"; });
                break;
            default:
                url = '';
                condition = '';
        }
        var query = esri.query({
            url: url,
            useCors: true,
            withCredentials: true
        });
        query.where(condition);
        query.run(function (error, featureCollection) {
            if (error) {
                _this.store.dispatch(new MapLayerActions.SetMapLoading(false));
            }
            else {
                var result = {};
                if (featureCollection.features.length > 0) {
                    result = featureCollection.features;
                }
                _this.SetFeatures(result, type, _this.layers);
                if (!Object.keys(result).length) {
                    _this.store.dispatch(new MapLayerActions.SetMapLoading(false));
                }
            }
        });
    };
    InterrogatorService.prototype.SetFeatures = function (features, type, layers) {
        this.features.next({ features: features, type: type, layers: layers });
    };
    InterrogatorService.ngInjectableDef = i0.defineInjectable({ factory: function InterrogatorService_Factory() { return new InterrogatorService(i0.inject(i1.Store), i0.inject(i2.AppConfigService)); }, token: InterrogatorService, providedIn: "root" });
    return InterrogatorService;
}());
export { InterrogatorService };
