/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./xom-ftue-dynamic.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/videogular2/compiled/src/core/vg-player/vg-player.ngfactory";
import * as i4 from "videogular2/compiled/src/core/services/vg-api";
import * as i5 from "videogular2/compiled/src/core/services/vg-fullscreen-api";
import * as i6 from "videogular2/compiled/src/core/services/vg-controls-hidden";
import * as i7 from "videogular2/compiled/src/core/vg-player/vg-player";
import * as i8 from "videogular2/compiled/src/core/vg-media/vg-media";
import * as i9 from "../../layouts/modal-layout/modal-layout.component.ngfactory";
import * as i10 from "../../layouts/modal-layout/modal-layout.component";
import * as i11 from "../xom-button/xom-button.component.ngfactory";
import * as i12 from "../xom-button/xom-button.component";
import * as i13 from "./xom-ftue-dynamic.component";
import * as i14 from "../../../app-config.service";
import * as i15 from "../../services/content.service";
var styles_XomFTUEDynamicComponent = [i0.styles];
var RenderType_XomFTUEDynamicComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_XomFTUEDynamicComponent, data: {} });
export { RenderType_XomFTUEDynamicComponent as RenderType_XomFTUEDynamicComponent };
function View_XomFTUEDynamicComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "title-icon"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "/assets/icons/", _co.selectedOption.icon, ".svg"); _ck(_v, 0, 0, currVal_0); }); }
function View_XomFTUEDynamicComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "p", [["class", "message sub-message"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedOption.subDescription; _ck(_v, 0, 0, currVal_0); }); }
function View_XomFTUEDynamicComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "tool"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openApp(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "tool-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "/assets/icons/", _v.context.$implicit.icon, ".svg"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_1); }); }
function View_XomFTUEDynamicComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "tool map-tool"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openTool(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "tool-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "/assets/icons/", _v.context.$implicit.icon, ".svg"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_1); }); }
function View_XomFTUEDynamicComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "dinamic-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "dinamic-section-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "investigation-tools"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_XomFTUEDynamicComponent_4)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "dinamic-section-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "map-tools"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_XomFTUEDynamicComponent_5)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.apps; _ck(_v, 5, 0, currVal_1); var currVal_3 = _co.mapTools; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dinamicSectionInfo[0]; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.dinamicSectionInfo[1]; _ck(_v, 7, 0, currVal_2); }); }
function View_XomFTUEDynamicComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "video-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "vg-player", [], [[2, "fullscreen", null], [2, "native-fullscreen", null], [2, "controls-hidden", null], [4, "z-index", null]], null, null, i3.View_VgPlayer_0, i3.RenderType_VgPlayer)), i1.ɵprd(512, null, i4.VgAPI, i4.VgAPI, []), i1.ɵprd(512, null, i5.VgFullscreenAPI, i5.VgFullscreenAPI, []), i1.ɵprd(512, null, i6.VgControlsHidden, i6.VgControlsHidden, []), i1.ɵdid(5, 1228800, null, 1, i7.VgPlayer, [i1.ElementRef, i4.VgAPI, i5.VgFullscreenAPI, i6.VgControlsHidden], null, null), i1.ɵqud(603979776, 1, { medias: 1 }), (_l()(), i1.ɵeld(7, 0, [["media", 1]], 0, 2, "video", [["controls", ""], ["height", "250"], ["id", "singleVideo"], ["preload", "auto"], ["width", "650"]], null, null, null, null, null)), i1.ɵdid(8, 212992, [[1, 4]], 0, i8.VgMedia, [i4.VgAPI, i1.ChangeDetectorRef], { vgMedia: [0, "vgMedia"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null))], function (_ck, _v) { var currVal_4 = i1.ɵnov(_v, 7); _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 5).isFullscreen; var currVal_1 = i1.ɵnov(_v, 5).isNativeFullscreen; var currVal_2 = i1.ɵnov(_v, 5).areControlsHidden; var currVal_3 = i1.ɵnov(_v, 5).zIndex; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = i1.ɵinlineInterpolate(1, "", _co.selectedOption.videoUrl, ""); _ck(_v, 9, 0, currVal_5); }); }
export function View_XomFTUEDynamicComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "xom-modal-layout", [], null, null, null, i9.View_ModalLayoutComponent_0, i9.RenderType_ModalLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i10.ModalLayoutComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 2, 23, "div", [["modal-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 22, "div", [["class", "row middle-xs ftue-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "col-xs-12 close-container no-gutter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "close icon-close increase-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDynamicOptions.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 19, "div", [["class", "col-xs-12 ftue-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "title-section"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_XomFTUEDynamicComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h1", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "p", [["class", "message"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_XomFTUEDynamicComponent_2)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_XomFTUEDynamicComponent_3)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_XomFTUEDynamicComponent_6)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 6, "div", [["class", "options"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "button", [["class", "back-button"], ["xom-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_XomButtonComponent_0, i11.RenderType_XomButtonComponent)), i1.ɵdid(21, 49152, null, 0, i12.XomButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(22, 0, ["", ""])), (_l()(), i1.ɵeld(23, 0, null, null, 2, "button", [["xom-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDynamicOptions.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_XomButtonComponent_0, i11.RenderType_XomButtonComponent)), i1.ɵdid(24, 49152, null, 0, i12.XomButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(25, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.selectedOption.icon; _ck(_v, 9, 0, currVal_0); var currVal_3 = !_co.isToolSelected; _ck(_v, 14, 0, currVal_3); var currVal_4 = !_co.isToolSelected; _ck(_v, 16, 0, currVal_4); var currVal_5 = (_co.isToolSelected && (_co.selectedOption.videoUrl !== "")); _ck(_v, 18, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.selectedOption.title; _ck(_v, 11, 0, currVal_1); var currVal_2 = _co.selectedOption.description; _ck(_v, 12, 0, currVal_2); var currVal_6 = _co.options[0]; _ck(_v, 22, 0, currVal_6); var currVal_7 = _co.options[2]; _ck(_v, 25, 0, currVal_7); }); }
export function View_XomFTUEDynamicComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xom-ftue-dynamic", [], null, null, null, View_XomFTUEDynamicComponent_0, RenderType_XomFTUEDynamicComponent)), i1.ɵdid(1, 114688, null, 0, i13.XomFTUEDynamicComponent, [i14.AppConfigService, i15.ContentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var XomFTUEDynamicComponentNgFactory = i1.ɵccf("xom-ftue-dynamic", i13.XomFTUEDynamicComponent, View_XomFTUEDynamicComponent_Host_0, { app: "app" }, { closeDynamicOptions: "closeDynamicOptions", backToStaticOptions: "backToStaticOptions" }, []);
export { XomFTUEDynamicComponentNgFactory as XomFTUEDynamicComponentNgFactory };
