import { Action } from '@ngrx/store';
import { Layer } from 'leaflet';
import { Feature } from 'geojson';
import { LatLng } from 'leaflet';
import { LayerConfigs } from '@app/shared/models/SearchAPI.model';
import { AOIQuery } from '@app/shared/models/aoi-query';

export enum MapAOIActionTypes {
    SetAOIActive = '[Map AOI] Set drawing mode',
    SetAOIApplied = '[Map AOI] Set AOI Applied',
    SetAddingStatus = '[Map AOI] Set add feature mode',
    SetAOILayer = '[Map AOI] Set AOI Layer',
    ClearAOI = '[Map AOI] Clear AOI',
    SetAOIArea = '[Map AOI] Set AOI Area',
    AddAOIFeature = '[Map AOI] Add AOI Feature',
    LoadAOI = '[Map AOI] Load AOI',
    SetAOIQuery = '[Map AOI] Set AOI Query',
    RemoveAOIFeature = '[Map AOI] Remove AOI Feature',
    LayerIntersectFailure = '[MAP AOI] Layer Intersect Failure',
    FindNearFeatures = '[Map AOI] Find Near Features to Add',
    SetAOIGeoUnit = '[Map AOI] Set AOI Geo Unit',
    SetAOILayerConfigs = '[Map AOI] Set Layer Configs',
    SetAOIManagerOpen = '[Map AOI] Set AOI Manager Open',
    SetAOIMeasurementToolOpen = '[Map AOI] Set AOI Measurement Tool Open',
    SetAOIManagerFromProfile = '[Map AOI] Set AOI Manager From Profile',
    SetAOIVisibility = '[Map AOI] Set AOI Layer Visibility',
    UpdateAOIQuery = '[Map AOI] Update AOI Query',
    AddAvailableAOI = '[Map AOI] Add Availabe AOIs',
    SetWellCountryAssociation = '[Map AOI] Set Well Country Association',
    SetSelectedAOIBasin = '[Map AOI] Set Selected AOI Basin'
}

export class SetAOIActive implements Action {
    readonly type = MapAOIActionTypes.SetAOIActive;

    constructor(public payload: Boolean) {}
}

export class SetAOIApplied implements Action {
    readonly type = MapAOIActionTypes.SetAOIApplied;

    constructor(public payload: Boolean) {}
}

export class SetAddingStatus implements Action {
    readonly type = MapAOIActionTypes.SetAddingStatus;

    constructor(public payload: Boolean) {}
}

export class SetAOILayer implements Action {
    readonly type = MapAOIActionTypes.SetAOILayer;

    constructor(public payload: Layer) {}
}

export class SetAOIArea implements Action {
    readonly type = MapAOIActionTypes.SetAOIArea;

    constructor(public payload: number) {}
}

export class AddAOIFeature implements Action {
    readonly type = MapAOIActionTypes.AddAOIFeature;

    constructor(public payload: Feature) {}
}

export class LoadAOI implements Action {
    readonly type = MapAOIActionTypes.LoadAOI;

    constructor(public payload: AOIQuery) {}
}

export class SetAOIQuery implements Action {
    readonly type = MapAOIActionTypes.SetAOIQuery;

    constructor(public payload: AOIQuery) {}
}

export class RemoveAOIFeature implements Action {
    readonly type = MapAOIActionTypes.RemoveAOIFeature;

    constructor(public payload: Feature) {}
}

export class ClearAOI implements Action {
    readonly type = MapAOIActionTypes.ClearAOI;

    constructor(public payload: any = null) { }
}

export class LayerIntersectFailure implements Action {
    readonly type = MapAOIActionTypes.LayerIntersectFailure;

    constructor(public payload: string) {}
}

export class FindNearFeatures implements Action {
    readonly type = MapAOIActionTypes.FindNearFeatures;

    constructor(public payload: LatLng) {}
}

export class SetAOIGeoUnit implements Action {
    readonly type = MapAOIActionTypes.SetAOIGeoUnit;

    constructor(public payload: string) {}
}

export class SetAOILayerConfigs implements Action {
    readonly type = MapAOIActionTypes.SetAOILayerConfigs;

    constructor(public payload: LayerConfigs) {}
}

export class SetAOIManagerOpen implements Action {
    readonly type = MapAOIActionTypes.SetAOIManagerOpen;

    constructor(public payload: Boolean) {}
}

export class SetAOIMeasurementToolOpen implements Action {
    readonly type = MapAOIActionTypes.SetAOIMeasurementToolOpen;

    constructor(public payload: Boolean) {}
}

export class SetAOIManagerFromProfile implements Action {
    readonly type = MapAOIActionTypes.SetAOIManagerFromProfile;

    constructor(public payload: Boolean) {}
}

export class SetAOIVisibility implements Action {
    readonly type = MapAOIActionTypes.SetAOIVisibility;

    constructor(public payload: boolean) {}
}

export class UpdateAOIQuery implements Action {
    readonly type = MapAOIActionTypes.UpdateAOIQuery;
    constructor(public payload: any) {}
}

export class AddAvailableAOI  implements Action {
    readonly type = MapAOIActionTypes.AddAvailableAOI;
    constructor(public payload: any) {}
}

export class SetWellCountryAssociation implements Action {
    readonly type = MapAOIActionTypes.SetWellCountryAssociation;
    constructor(public payload: any) {}
}

export class SetSelectedAOIBasin implements Action {
    readonly type = MapAOIActionTypes.SetSelectedAOIBasin;
    constructor(public payload: string) {}
}

export type MapAOIActionsUnion =
    | SetAOIActive
    | SetAOIApplied
    | SetAddingStatus
    | SetAOILayer
    | SetAOIArea
    | AddAOIFeature
    | LoadAOI
    | SetAOIQuery
    | RemoveAOIFeature
    | ClearAOI
    | LayerIntersectFailure
    | FindNearFeatures
    | SetAOIGeoUnit
    | SetAOILayerConfigs
    | SetAOIManagerOpen
    | SetAOIMeasurementToolOpen
    | SetAOIManagerFromProfile
    | SetAOIVisibility
    | UpdateAOIQuery
    | AddAvailableAOI
    | SetWellCountryAssociation
    | SetSelectedAOIBasin;
