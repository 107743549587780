var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MapMeasureActions } from '../actions/index';
import { Layer } from 'leaflet';
import { SearchActions } from '@app/modules/search/actions';
var initialState = {
    measureLayer: null,
    metric: true,
    measuring: false,
    measures: []
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case MapMeasureActions.MeasureActionTypes.SetMetric:
            return __assign({}, state, { metric: action.payload });
        case MapMeasureActions.MeasureActionTypes.SetMeasuring:
            return __assign({}, state, { measureLayer: (action.payload) ? null : state.measureLayer, measuring: action.payload });
        case MapMeasureActions.MeasureActionTypes.SetMapMeasures:
            return __assign({}, state, { measures: action.payload });
        case MapMeasureActions.MeasureActionTypes.SetMeasureLayer:
            return __assign({}, state, { measureLayer: action.payload });
        case SearchActions.SearchActionTypes.ResetAll:
        case MapMeasureActions.MeasureActionTypes.ResetMeasureLayer:
            return __assign({}, state, { measureLayer: new Layer, measures: [] });
        default: {
            return state;
        }
    }
}
