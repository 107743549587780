import { EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { ContentService } from '@shared/services/content.service';
import { Router } from '@angular/router';
var XomGlobalFeedbackModalComponent = /** @class */ (function () {
    function XomGlobalFeedbackModalComponent(content, router) {
        this.content = content;
        this.router = router;
        this.submitFeedback = new EventEmitter(null);
        this.submitFileList = new EventEmitter(null);
        this.closeModal = new EventEmitter(null);
        this.feelings = this.content.local.GIVE_FEEDBACK_MODAL.FEELINGS;
        this.isMood = false;
        this.initializeFeedback();
    }
    XomGlobalFeedbackModalComponent.prototype.ngAfterViewInit = function () {
        this.divFocused.nativeElement.focus();
    };
    XomGlobalFeedbackModalComponent.prototype.initializeFeedback = function () {
        this.feedback = { subject: '', category: this.feelings[0].category, description: '', URL: window.location.origin + this.router.url };
        this.fileList = [];
        this.activeFeeling = this.feelings[0];
    };
    XomGlobalFeedbackModalComponent.prototype.fileChange = function (event) {
        if (event.target.files.length) {
            this.fileList.push(event.target.files);
        }
    };
    XomGlobalFeedbackModalComponent.prototype.fileRemoved = function (event) {
        this.fileList.splice(event, 1);
    };
    XomGlobalFeedbackModalComponent.prototype.close = function () {
        this.initializeFeedback();
        this.closeModal.emit();
    };
    XomGlobalFeedbackModalComponent.prototype.submit = function () {
        this.submitFileList.emit(this.fileList);
        this.submitFeedback.emit(this.feedback);
    };
    XomGlobalFeedbackModalComponent.prototype.setFeedbackType = function (event) {
        this.activeFeeling = event;
        this.feedback.category = this.activeFeeling.category;
        if (event.subject !== this.feelings[0].subject && event.subject !== this.feelings[4].subject) {
            this.feedback.subject = event.subject;
            this.isMood = true;
        }
        else {
            this.feedback.subject = '';
            this.isMood = false;
        }
    };
    XomGlobalFeedbackModalComponent.prototype.mouseover = function (event, feeling) {
        event.target.src = feeling.assetActive;
    };
    XomGlobalFeedbackModalComponent.prototype.mouseout = function (event, feeling) {
        event.target.src = this.activeFeeling.subject === feeling.subject ? feeling.assetActive : feeling.assetInactive;
    };
    return XomGlobalFeedbackModalComponent;
}());
export { XomGlobalFeedbackModalComponent };
