var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AskBobActions } from '../actions/index';
export var initialState = {
    basin: '',
    query: '',
    queryParameters: {
        query: '',
        qa_retriever: 100,
        qa_numResults: 20,
        summ_retriever: 5,
        levels: 1,
        context: 30
    },
    errorMessage: '',
    isLoading: { answer: false, summary: false },
    hasError: false,
    answers: [],
    summary: [],
    activeTab: 'QA',
    feedbackAnswer: null,
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AskBobActions.AskBobActions.SetBasin: {
            return __assign({}, state, { basin: action.payload });
        }
        case AskBobActions.AskBobActions.SetActiveTab: {
            return __assign({}, state, { activeTab: action.payload });
        }
        case AskBobActions.AskBobActions.SetQueryParameters: {
            return __assign({}, state, { queryParameters: action.payload });
        }
        case AskBobActions.AskBobActions.LoadAnswers: {
            return __assign({}, state, { isLoading: __assign({}, state.isLoading, { answer: true }), hasError: false, answers: [] });
        }
        case AskBobActions.AskBobActions.LoadAnswersSuccess: {
            return __assign({}, state, { isLoading: __assign({}, state.isLoading, { answer: false }), answers: action.response.answers.filter(function (x) { return x.answer !== null; }), query: action.response.query });
        }
        case AskBobActions.AskBobActions.LoadAnswersFailure: {
            return __assign({}, state, { isLoading: __assign({}, state.isLoading, { answer: false }), hasError: true, errorMessage: action.payload });
        }
        case AskBobActions.AskBobActions.LoadSummary: {
            return __assign({}, state, { isLoading: __assign({}, state.isLoading, { summary: true }), hasError: false, summary: [] });
        }
        case AskBobActions.AskBobActions.LoadSummarySuccess: {
            return __assign({}, state, { isLoading: __assign({}, state.isLoading, { summary: false }), summary: action.response });
        }
        case AskBobActions.AskBobActions.LoadSummaryFailure: {
            return __assign({}, state, { isLoading: __assign({}, state.isLoading, { summary: false }), hasError: true, errorMessage: action.payload });
        }
        case AskBobActions.AskBobActions.SetFeedbackAnswer: {
            return __assign({}, state, { feedbackAnswer: action.payload });
        }
        default:
            return state;
    }
}
