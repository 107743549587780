/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../layouts/modal-layout/modal-layout.component.ngfactory";
import * as i2 from "../../layouts/modal-layout/modal-layout.component";
import * as i3 from "./show-help-modal.component";
import * as i4 from "../../services/content.service";
import * as i5 from "@angular/platform-browser";
var styles_ShowHelpModalComponent = [];
var RenderType_ShowHelpModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShowHelpModalComponent, data: {} });
export { RenderType_ShowHelpModalComponent as RenderType_ShowHelpModalComponent };
export function View_ShowHelpModalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { divFocused: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 16, "xom-modal-layout", [["class", "modal get-help-modal"], ["hasHeader", "true"]], null, null, null, i1.View_ModalLayoutComponent_0, i1.RenderType_ModalLayoutComponent)), i0.ɵdid(2, 114688, null, 0, i2.ModalLayoutComponent, [], { hasHeader: [0, "hasHeader"] }, null), (_l()(), i0.ɵeld(3, 0, null, 0, 1, "div", [["modal-header", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "span", [["class", "close icon-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeShowHelp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, 2, 12, "div", [["modal-content", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "get-help"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", " "])), (_l()(), i0.ɵeld(8, 0, [[1, 0], ["divFocused", 1]], null, 2, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", " "])), (_l()(), i0.ɵeld(10, 0, null, null, 0, "img", [["alt", ""], ["src", "/assets/icons/details.svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "BR", [], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 5, "div", [["class", "get-help"]], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["", ""])), (_l()(), i0.ɵeld(14, 0, null, null, 0, "BR", [], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, [[1, 0], ["divFocused", 1]], null, 2, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(16, null, ["", " "])), (_l()(), i0.ɵeld(17, 0, null, null, 0, "img", [["alt", ""], ["src", "/assets/icons/details.svg"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "true"; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.content.global.GET_HELP_MODAL_MESSAGE[0]; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.sanitize(_co.content.global.GEOCHAT_EMAIL); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.content.global.GET_HELP_MODAL_MESSAGE[1]; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.content.global.GET_HELP_MODAL_MESSAGE[2]; _ck(_v, 13, 0, currVal_4); var currVal_5 = _co.sanitize(_co.content.global.HELP_DOCUMENTS); _ck(_v, 15, 0, currVal_5); var currVal_6 = _co.content.global.GET_HELP_MODAL_MESSAGE[3]; _ck(_v, 16, 0, currVal_6); }); }
export function View_ShowHelpModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "xom-show-help-modal", [], null, null, null, View_ShowHelpModalComponent_0, RenderType_ShowHelpModalComponent)), i0.ɵdid(1, 4243456, null, 0, i3.ShowHelpModalComponent, [i4.ContentService, i5.DomSanitizer], null, null)], null, null); }
var ShowHelpModalComponentNgFactory = i0.ɵccf("xom-show-help-modal", i3.ShowHelpModalComponent, View_ShowHelpModalComponent_Host_0, {}, { closeModal: "closeModal" }, []);
export { ShowHelpModalComponentNgFactory as ShowHelpModalComponentNgFactory };
