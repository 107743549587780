import { Action } from '@ngrx/store';

export enum ImageQueryActionTypes {
    ImageResultsSorting = '[Search Image] Search Results Sorting',
    ImageResultsPageSize = '[Search Image] Search Results Page Size',
    ImageResultsStartIndex = '[Search Image] Search Results Start Index',
    SetImageClassificationTypes = '[Search Image] Set Classification Types',
    SetImageCountries = '[Search Image] Set Image Countries',
    SetFromLeadMap = '[Search Image] Set From Lead map',
    ImageResultsPageSizeLeadMap = '[Search Image] Search Results Page Size From Lead Map',
    ImageResultsStartIndexLeadMap = '[Search Image] Search Results Start Index From Lead Map'
}

export class ImageResultsSorting implements Action {
    readonly type = ImageQueryActionTypes.ImageResultsSorting;

    constructor(public payload: string) {}
}

export class ImageResultsPageSize implements Action {
    readonly type = ImageQueryActionTypes.ImageResultsPageSize;

    constructor(public payload: number) {}
}

export class ImageResultsStartIndex implements Action {
    readonly type = ImageQueryActionTypes.ImageResultsStartIndex;

    constructor(public payload: number) {}
}

export class ImageResultsPageSizeLeadMap implements Action {
    readonly type = ImageQueryActionTypes.ImageResultsPageSizeLeadMap;

    constructor(public payload: number) {}
}

export class ImageResultsStartIndexLeadMap implements Action {
    readonly type = ImageQueryActionTypes.ImageResultsStartIndexLeadMap;

    constructor(public payload: number) {}
}
export class SetImageClassificationTypes implements Action {
    readonly type = ImageQueryActionTypes.SetImageClassificationTypes;

    constructor(public payload: string[]) {}
}

export class SetImageCountries implements Action {
    readonly type = ImageQueryActionTypes.SetImageCountries;

    constructor(public payload: string[]) {}
}

export class SetFromLeadMap implements Action {
    readonly type = ImageQueryActionTypes.SetFromLeadMap;

    constructor(public payload: boolean) {}
}

export type ImageQueryActionsUnion = ImageResultsSorting
    | ImageResultsPageSize
    | ImageResultsStartIndex
    | ImageResultsStartIndexLeadMap
    | ImageResultsPageSizeLeadMap
    | SetImageClassificationTypes
    | SetImageCountries
    | SetFromLeadMap;
