<xom-modal-layout class="modal feedback-modal" hasHeader="true">
  <div modal-header>
    <span (click)="close()" class="close icon-close increase-icon"></span>
  </div>
  <div modal-content>
    <div class="give-feedback">
      <div class="title">{{content.global.GIVE_FEEDBACK_MODAL.EMOJI_TITLE}}</div>

      <div class="emojis-container">
        <div *ngFor="let feeling of feelings" class="emoji-container">
          <img src="{{ activeFeeling.subject === feeling.subject ? feeling.assetActive : feeling.assetInactive }}"
            (mouseover)="mouseover($event, feeling)" (mouseout)="mouseout($event, feeling)" (click)="setFeedbackType(feeling)"
            alt="Emoji">
        </div>
      </div>

      <div class="input-container row">
        <div class="col-xs-2 label">{{content.global.GIVE_FEEDBACK_MODAL.SUBJECT_TEXT}}</div>
        <div class="col-xs-10">
          <input
                  class="xom-input" type="text"
                  [(ngModel)]="feedback.subject"
                  placeholder="{{content.global.GIVE_FEEDBACK_MODAL.SUBJECT_PLACEHOLDER}}"
          >
        </div>
      </div>

      <div class="description-container">
        <textarea #divFocused class="description-input description-height"
                  type="text"
                  [(ngModel)]="feedback.description"
                  placeholder="{{content.global.GIVE_FEEDBACK_MODAL.DESCRIPTION_PLACEHOLDER}}"
                  [disabled]="!feedback.subject"></textarea>
      </div>

      <div class="add-file-version-container">
        <div class="add-file">
          {{content.global.GIVE_FEEDBACK_MODAL.ADD_ATTACHMENT}}
          <label for="files">{{content.global.GIVE_FEEDBACK_MODAL.HERE}}</label>
          <input id="files"
                 type="file"
                 (change)="fileChange($event)"
                 placeholder="{{content.global.GIVE_FEEDBACK_MODAL.HERE}}"
                 accept="{{content.global.GIVE_FEEDBACK_MODAL.ATTACHMENT_EXT}}">
        </div>
        <div class="version">v {{version}}</div>
      </div>

      <div class="files-container">
        <div class="file-name" *ngFor="let file of fileList; let i = index">{{file[0].name}} <i class="icon-close" (click)="fileRemoved(i)"></i></div>
      </div>

      <div class="controls-container">
        <div class="view-feedback-container"><a class="view-feedback" href="{{content.global.GIVE_FEEDBACK_MODAL.VIEW_FEEDBACK_LINK}}" target="_blank">{{content.global.GIVE_FEEDBACK_MODAL.VIEW_FEEDBACK}}</a></div>
        <div class="buttons-container">
          <button xom-button
                  (click)="submit()"
                  [disabled]="feedback.description === '' && !isMood">{{content.global.GIVE_FEEDBACK_MODAL.SUBMIT_CTA}}
          </button>
          <button class="secondary-button" xom-secondary-button
                  (click)="close()">{{content.global.GIVE_FEEDBACK_MODAL.CANCEL_CTA}}
          </button>
        </div>
      </div>
    </div>
  </div>
</xom-modal-layout>
