import { Action } from '@ngrx/store';
import { Workspace } from '@app/shared/models/UserAPI.model';

export const STORAGE_ACTION_TYPE = '@ngrx/store/storage';
export const WORKSPACE_ACTION_TYPE = 'WORKSPACE_HYDRATION';

export class StorageAction implements Action {
  readonly type = STORAGE_ACTION_TYPE;
  constructor(readonly payload: string) {}
}

export class HydrateWorkspace implements Action {
  readonly type = WORKSPACE_ACTION_TYPE;
  constructor(readonly payload: Workspace) {}
}
