import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { AppConfigService } from '@app/app-config.service';
import { ContentService } from '@shared/services/content.service';
import { GlobalContent } from '@shared/shared-content';
import { App } from '@app/shared/models/UserAPI.model';

export interface TextTrackCueList {
  length: number;
  getCueById(id: string): TextTrackCue;
  item(index: number): TextTrackCue;
  [index: number]: TextTrackCue;
}
@Component({
  selector: 'xom-ftue-dynamic',
  templateUrl: './xom-ftue-dynamic.component.html',
  styleUrls: ['./xom-ftue-dynamic.component.scss']
})

export class XomFTUEDynamicComponent implements OnInit {
  @Input() app: App;
  @Output() closeDynamicOptions: EventEmitter<any> = new EventEmitter(null);
  @Output() backToStaticOptions: EventEmitter<any> = new EventEmitter(null);
  selectedOption = {
    icon: '',
    title: '',
    description: '',
    subDescription: '',
    videoUrl: ''
  };
  apps: any;
  isToolSelected = false;
  dinamicOptions = this.globalContent.global.FTUE.DINAMIC_OPTIONS;
  options = this.globalContent.global.FTUE.OPTIONS;
  dinamicSectionInfo = this.globalContent.global.FTUE.DINAMIC_SECTION_INFO;
  mapTools = this.globalContent.global.FTUE.MAP_TOOLS;

  constructor(private appConfig: AppConfigService, public globalContent: ContentService<GlobalContent>) {
    this.apps = this.appConfig.getConfig().apps;
  }

  ngOnInit() {
    if (this.app) {
      this.isToolSelected = true;
      this.openApp(this.app);
    } else {
      this.updateSelectedOption(this.dinamicOptions[0]);
    }
  }

  back() {
    if (this.app) {
      this.backToStaticOptions.emit();
    }
    if (this.isToolSelected) {
      this.isToolSelected = false;
      this.updateSelectedOption(this.dinamicOptions[0]);
    } else {
      this.backToStaticOptions.emit();
    }
  }

  updateSelectedOption(option) {
    this.selectedOption.icon = option.icon;
    this.selectedOption.title = option.title;
    this.selectedOption.description = option.description;
    this.selectedOption.subDescription = option.subDescription;
    this.selectedOption.videoUrl = option.videoUrl;
  }

  openTool(tool) {
    this.isToolSelected = true;
    this.updateSelectedOption(tool);
  }

  openApp(app) {
    this.isToolSelected = true;
    this.selectedOption.title = app.title;
    this.selectedOption.icon = app.icon;
    this.selectedOption.description = app.ftue.body;
    this.selectedOption.videoUrl = app.ftue.videoUrl;
    this.selectedOption.subDescription = app.subDescription;
  }
}
