// Facate to real console implementation
export class ConsoleFacade {

    log(...args: any[]) {
        console.log(...args);
    }

    error(...args: any[]) {
        console.error(...args);
    }

    warn(...args: any[]) {
        console.warn(...args);
    }

    groupCollapsed(...args: any[]) {
        console.groupCollapsed(...args);
    }

    groupEnd() {
        console.groupEnd();
    }
}
