import { InjectionToken } from '@angular/core';
import { ContentService } from '@shared/services/content.service';

export class GISContent {
  GISTRI_DASHBOARD_ID = '2dd44f99ba0245b09821fa4c9f9fa344';
  MAP_SERVER_URLS = [
    'https://sampleserver6.arcgisonline.com/arcgis/rest/services/USA/MapServer',
    'https://sampleserver6.arcgisonline.com/arcgis/rest/services/Water_Network/MapServer',
    'https://sampleserver6.arcgisonline.com/arcgis/rest/services/Census/MapServer',
    'https://sampleserver6.arcgisonline.com/arcgis/rest/services/Military/MapServer',
    'https://sampleserver6.arcgisonline.com/arcgis/rest/services/Wildfire/MapServer'
  ];
  LAYER_LIST_TITLE = 'Add Layers';
  LAYER_POPUP_MANAGER_TITLE = 'Manage Layers';
  LAYER_LIST_CLOSE = 'Minimize';
  LAYER_LIST = {
    title: 'Layers',
    maxMapServers: 10000
  };
  LAYER_STORE = {
    default: {
      numQueryperPage: 100
    },
    queryConfig: {
      MyOrganization: { types: true, terms: true, token: true, ids: false }, // Xom Portal Content
      XTO: { types: true, terms: true, token: false, ids: false }, // XTO Portal Content
      ArcGISOnline: { terms: true, types: true, token: false, ids: false }, // ArcGis Online Content
      MyPersonalWorkspaces: { types: false, terms: true, token: false, owner: false, ids: true },
      MyCurrentWorkspace: { types: false, terms: true, token: false, owner: false, ids: true },
      TemplateCategories: { types: false, terms: true, token: false, owner: false, ids: true }
    },
    // https://10.128.12.61/GISTRI/configs/AddData/config_widgets_AddData_Widget_37.json?wab_dv=2.9
    labels: {
      title: 'Layers',
      addSearch: 'Add by Search',
      addURL: 'Add by URL',
      searchLabel1: 'SOURCE',
      searchLabel2: 'FILTER BY TYPE',
      searchLabel3: 'SORT BY',
      viewDetails: 'View Details',
      add: 'Add',
      remove: 'Remove',
      noResults: 'No Results',
      loading: 'Loading...',
      urlError: 'URL Error',
      error: 'Error...',
      addLayer: 'Add Layer',
      tryNewUrl: 'Try a new URL',
      centroid_layer: 'Centroid Layer',
      aoi_layer: 'AOI Layer',
      search_placeholder: 'Search keywords or phrases',
      url_placeholder: 'Enter URL',
      SAVE_MAINTAIN: {
        anchor: 'Save a Workspace',
        text: 'to maintain these changes.',
        toolTip: 'Only Save Layers',
        exportToolTip: 'Export layers to csv file'
      }
    },
    options: {
      dropdownItems: [
        { label: 'Deselect All Layers', actionType: 'DeactivateAllLayers' },
        { label: 'Expand All Layers', actionType: 'ExpandAll' },
        { label: 'Collapse All Layers', actionType: 'CollapseAll' },
      ],
      typeOptions: [
        { label: 'Map Service', value: '0', checked: true },
        { label: 'Feature Service', value: '1', checked: true },
        /* This methods are not supported in the Map.
        Commented in case we need to enable them
        { label: 'Image Service', value: '2', checked: true },
        { label: 'Vector Tile Service', value: '3', checked: true },
        { label: 'KML', value: '4', checked: true },
        { label: 'WMS', value: '5', checked: true }*/
      ],
      sourceOptions: [
        { id: 'MyPersonalWorkspaces', label: 'My Personal Workspaces', value: 0 },
        { id: 'MyCurrentWorkspace', label: 'My Current Session', value: 1 },
        { id: 'MyOrganization', label: 'All (XOM Portal)', value: 2 },
        // { id: 'XTO', label: 'All (XTO Portal)', value: 3 },
        { id: 'ArcGISOnline', label: 'All (ArcGIS Online)', value: 3 }, // Change to 4 when XTO is back
        // This list is also modified programatically to include template categories!
      ],
      sortOptions: [
        // { label: 'Relevance (A-Z)', value: {label: 'Relevance', order: 'asc'} },
        // { label: 'Relevance (Z-A)', value: {label: 'Relevance', order: 'desc'} },
        { label: 'Title (A-Z)', value: { label: 'title', order: 'asc' } },
        { label: 'Title (Z-A)', value: { label: 'title', order: 'desc' } },
        // { label: 'Owner (A-Z)', value: {label: 'Relevance', order: 'asc'} },
        // { label: 'Owner (Z-A)', value: {label: 'Relevance', order: 'desc'} },
        // { label: 'Rating (A-Z)', value: {label: 'Relevance', order: 'asc'} },
        // { label: 'Rating (Z-A)', value: {label: 'Relevance', order: 'desc'} },
        { label: 'Views (A-Z)', value: { label: 'numviews', order: 'asc' } },
        { label: 'Views (Z-A)', value: { label: 'numviews', order: 'desc' } }
      ],
      urlOptions: [
        { label: 'An ArcGIS Server Web Service', value: '' },
        { label: 'A WMS OGC Web Service', value: '' },
        /* This methods are not supported in the Map.
        Commented in case we need to enable them
        { label: 'A KML File', value: '' },
        { label: 'A GeoRSS File', value: '' },
        { label: 'A CSV File', value: '' }*/
      ],
      quantityOptions: [
        { label: '20', value: 20 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
      ]
    }
  }; // end LAYER_STORE

  AOI_MANAGER = {
    TITLE: 'AOI Manager',
    POLYGON_BUTTON: 'Draw Polygon',
    GEOBODY_BUTTON: 'Select Geo Unit',
    APPLY_BUTTON: 'Apply and Search',
    RESET_BUTTON: 'Clear all AOI',
    UNIT_LABEL: 'GEO UNIT',
    CURRENT_SELECTION: 'Current AOIs',
    ALL_LABEL: 'All',
    GEO_WARNING_TITLE: '! Warning',
    GEO_WARNING_MSG: 'Search works best when 1000 or fewer Geo Units are selected in your AOI. Please consider reducing your AOI selection.'
  };

  AOI_MANAGER_TITLE = 'AOI Manager';
  LAYER_MANAGER_TITLE = 'Layer Manager';
  LEGEND_TITLE = 'Legend';
  MEASUREMENT_TOOL_TITLE = 'Measurement Tool';
  BASEMAPS_TITLE = 'Basemaps';
  HOME_BUTTON_TOOLTIP = 'Default Extent';
  LAYER_DRAG_BUTTON_TOOLTIP = 'Drag to Reorder';
  LAYER_MORE_BUTTON_TOOLTIP = 'Layer Options';
  POPUP_EXCLUSIONS = [
    'Null',
    'null',
    'NULL',
    'Nill',
  ];
  POPUP_EXCLUSION_TEXT = '';
  ESRI_DOWN_ERR_MSG = 'ESRI is non-responsive; error loading map layers';
  ESRI_BASEMAP_DOWN_ERR_MSG = 'ESRI Basemap service is not available at the moment';
  GEOBODY_COLORS = {
    country: '#4D94FF',
    basin: '#58CF5C',
    well: '#f2ac34',
    block: '#f05822'
  };

  GEOBODY_ORDER = [
    'country',
    'basin',
    'block',
    'well'
  ];

  GEOBODY_SYMBOLS = {
    country: {
      'color': [77, 148, 255, 255],
      'type': 'esriSFS',
      'style': 'esriSFSSolid',
      'outline': {
        'color': [255, 255, 255, 255],
        'width': 1.5,
        'type': 'esriSLS',
        'style': 'esriSLSSolid'
      }
    },
    basin: {
      'color': [128, 128, 128, 255],
      'type': 'esriSFS',
      'style': 'esriSFSNull',
      'outline': {
        'color': [128, 128, 128, 255],
        'width': 3,
        'type': 'esriSLS',
        'style': 'esriSLSSolid'
      }
    },
    play: {
      'type': 'esriSFS',
      'style': 'esriSFSNull',
      'outline': {
        'color': [255, 0, 255, 255],
        'width': 3,
        'type': 'esriSLS',
        'style': 'esriSLSSolid'
      }
    },
    block: {
      'type': 'esriSFS',
      'style': 'esriSFSNull',
      'outline': {
        'color': [198, 99, 64, 255],
        'width': 2,
        'type': 'esriSLS',
        'style': 'esriSLSSolid'
      }
    },
    selectedWell: {
      'color': [0, 255, 255, 255],
      'size': 4,
      'type': 'esriSMS',
      'style': 'esriSMSCircle',
      'outline': {
        'color': [0, 255, 255, 255],
        'width': 1.5,
        'type': 'esriSLS',
        'style': 'esriSLSSolid'
      }
    },
    well: {
      'color': [0, 0, 0, 255],
      'size': 9,
      'type': 'esriSMS',
      'style': 'esriSMSCircle',
      'outline': {
        'color': [0, 0, 0, 255],
        'width': 1.5,
        'type': 'esriSLS',
        'style': 'esriSLSSolid'
      }
    },
    studyPoint: {
      'color': [0, 0, 0, 255],
      'size': 4,
      'type': 'esriSMS',
      'style': 'esriSMSCircle',
      'outline': {
        'color': [0, 191, 255, 255],
        'width': 4,
        'type': 'esriSLS',
        'style': 'esriSLSSolid'
      }
    },
    studyPoly: {
      'type': 'esriSFS',
      'style': 'esriSFSNull',
      'outline': {
        'color': [0, 191, 255, 255],
        'width': 3,
        'type': 'esriSLS',
        'style': 'esriSLSSolid'
      }
    },
    decisionGatePoint: {
      'color': [0, 250, 75, 255],
      'size': 9,
      'type': 'esriSMS',
      'style': 'esriSMSStar',
      'outline': {
        'color': [0, 255, 255, 255],
        'width': 0.5,
        'type': 'esriSLS',
        'style': 'esriSLSSolid'
      }
    },
    decisionGatePoly: {
      'type': 'esriSFS',
      'style': 'esriSFSNull',
      'outline': {
        'color': [0, 250, 75, 255],
        'width': 2,
        'type': 'esriSLS',
        'style': 'esriSLSSolid'
      }
    },
    penetration: {
      'color': [0, 0, 0, 255],
      'size': 9,
      'type': 'esriSMS',
      'style': 'esriSMSCircle',
      'outline': {
        'color': [0, 255, 255, 255],
        'width': 1.5,
        'type': 'esriSLS',
        'style': 'esriSLSSolid'
      }
    },
    geophysical0: {
      'color': [255, 0, 0, 255],
      'size': 9,
      'type': 'esriSMS',
      'style': 'esriSMSSquare',
      'outline': {
        'color': [0, 255, 255, 255],
        'width': 1.0,
        'type': 'esriSLS',
        'style': 'esriSLSSolid'
      }
    },
    geophysical1: {
      'color': [255, 0, 0, 255],
      'type': 'esriSLS',
      'style': 'esriSLSSolid',
      'width': 1.0,
    },
    geophysical2: {
      'type': 'esriSFS',
      'style': 'esriSFSNull',
      'outline': {
        'color': [255, 0, 0, 255],
        'width': 2,
        'type': 'esriSLS',
        'style': 'esriSLSSolid'
      }
    }
  };
  LAYER_EXPORT_SUCCESS_MSG = 'Layers exported successfully';
  LAYER_EXPORT_NO_LAYER_MSG = 'No layer to export';
  NO_WORKSPACE_FILE_NAME = 'FreshStartLayers';
  LAYER_DOWN_ERR_MSG = 'Layer could not be added';
  LAYER_CONFIG_ERROR = 'Layer config error';
  POPUP_INFO_ERROR = 'Popup information could not be shown';
  LAYER_INFO_ERROR = 'Error loading layer information from portal';
  LEGEND_DOWN_ERR_MSG = 'Legend could not be added';
  LEGEND_DEFAULT_ERR_MSG = 'Legend Error.';
  LEGEND_PERMISSION_ERR = 'Insufficient permissions for selected layer.';
  METADAT_ERR_MSG = 'Table information errors';
  SEARCH_LAYERS_ERROR = 'Search layers results error';
  GEOSENTIMENT_HEADER = {
    TITLE: 'Geo Sentiment Analysis'
  };
  FILTER_BY_MAP_EXTENT = 'Filter by Map Extent';
  FILTER = 'Filter';
  CLEAR = 'Clear Filter';
  SELECT_ALL_FEATURES = 'Select All Pages (Limit 1000 Rows)';
  EXPORT_TO_CSV = 'Export to CSV';
  VIEWS_WITH_TABLE = ['map', 'landing', 'hybrid'];
  MAX_ZOOM_LEVEL_FIT_BOUNDS = 12;

  EXPORT_TABLE_OPTIONS = {
    exportAll: 'Export All',
    exportPage: 'Export Page',
    exportMapExtent: 'Export Map Extent',
    exportSelectedRows: 'Export Selected Rows',
  };

  ZOOM_TO = 'Zoom To';
  NO_POPUP_RESULTS = 'No results found.';

  FILTER_MODAL = {
    filter: 'Filter',
    description: 'Choose the expression or expressions to filter the',
    displayAllStatement: 'Display features in the layer that match all of the following expressions.',
    displayAnyStatement: 'Display features in the layer that match any of the following expressions.',
    errorMessage: '* Filtering on the same column more than once will only support "OR" functionality',
    addExpressionButton: 'Add Expression',
    expression: 'Expression',
    and: 'and',
    addAnExpression: 'Add an Expresion',
    directionsMessage: 'To filter the selected table you should Add an Expression with the button "Add Expression"',
    selectDate: 'Select a Date'
  };

  ADD_TO_AOI = 'Add to AOI';

  MINUTES = 'Minutes';
  HOURS = 'Hours';
  DAYS = 'Days';
  WEEKS = 'Weeks';
  MONTHS = 'Months';
  YEARS = 'Years';

  NO_LAYERS_MESSAGE = `
    <span><strong>You don't have layers added yet.</strong></span>
    <p>Select layers from the Layer Store, by clicking on the "<strong>+</strong>" icon above.
  `;

  AOI_SNACKBAR_MSG = {
    AOI_UPDATED: 'AOI Updated',
    ERROR_LOADING_AOI: 'Error Loading AOI'
  };

  MEASURE = {
    TITLE: 'Measure',
    CLEARBUTTON: 'Clear',
    DRAWLABEL: 'DRAW',
    LINELABEL: 'Line',
    DISTANCELABEL: 'Distance',
    TOTALLABEL: 'Total:'
  };

  CENTROID_LIMIT = 20;
}

export const GIS_CONTENT = new InjectionToken<GISContent>('GIS_CONTENT');

const csFactory = function () {
  // https://github.com/mgechev/ngrev/issues/36
  return new ContentService<GISContent>(new GISContent);
};

export const GisContentProvider = {
  provide: GIS_CONTENT,
  useFactory: csFactory
};

export const DEFAULT_ACTIVE_BASE_MAP = 'NationalGeographic';
