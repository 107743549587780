import { EventEmitter, OnInit } from '@angular/core';
import { AppConfigService } from '@app/app-config.service';
import { ContentService } from '@shared/services/content.service';
var XomFTUEStaticComponent = /** @class */ (function () {
    function XomFTUEStaticComponent(appConfig, globalContent) {
        this.appConfig = appConfig;
        this.globalContent = globalContent;
        this.closeStaticOptions = new EventEmitter(null);
        this.backToWelcome = new EventEmitter(null);
        this.openDynamicOptions = new EventEmitter(null);
        this.selectedOption = {
            icon: '',
            title: '',
            description: '',
            image: ''
        };
        this.staticOptions = this.globalContent.global.FTUE.STATIC_OPTIONS;
        this.options = this.globalContent.global.FTUE.OPTIONS;
        this.apps = this.appConfig.getConfig().apps;
    }
    XomFTUEStaticComponent.prototype.ngOnInit = function () {
        this.updateSelectedOption(this.staticOptions[this.count]);
    };
    XomFTUEStaticComponent.prototype.next = function () {
        if (this.count < this.staticOptions.length - 1) {
            this.count++;
            this.updateSelectedOption(this.staticOptions[this.count]);
        }
        else {
            this.openDynamicOptions.emit();
        }
    };
    XomFTUEStaticComponent.prototype.back = function () {
        if (this.count - 1 < 0) {
            this.backToWelcome.emit();
        }
        if (this.count > 0) {
            this.count--;
            this.updateSelectedOption(this.staticOptions[this.count]);
        }
    };
    XomFTUEStaticComponent.prototype.updateSelectedOption = function (option) {
        this.selectedOption.icon = option.icon;
        this.selectedOption.title = option.title;
        this.selectedOption.description = option.description;
        this.selectedOption.image = option.image;
    };
    return XomFTUEStaticComponent;
}());
export { XomFTUEStaticComponent };
