import { Action } from '@ngrx/store';
import { Workspace } from '@app/shared/models/UserAPI.model';

export enum WorkspacesActionTypes {
    LoadAllWorkspaces = '[Workspaces] Load All Workspaces',
    LoadAllWorkspacesSuccess = '[Workspaces] Load All Workspaces Success',
    LoadAllWorkspacesFailure = '[Workspaces] Load All Workspaces Failure',
    LoadWorkspace = '[Workspaces] Load Workspace',
    UpdateWorkspace = '[Workspaces] Update Workspace',
    UpdateLegenedOpen = '[Workspaces] Update Workspace Legend Open',
    CreateWorkspace = '[Workspaces] Create Workspace',
    DeleteWorkspace = '[Workspaces] Delete Workspace',
    CreateNotification = '[Workspaces] Create Notification',
    DeleteNotification = '[Workspaces] Delete Notification',
    LoadNotifications = '[Workspaces] Load Notifications',
    LoadNotificationsSuccess = '[Workspaces] Load Notifications Success',
    LoadNotificationsFailure = '[Workspaces] Load Notifications Failure'
}


export class LoadAllWorkspaces implements Action {
    readonly type = WorkspacesActionTypes.LoadAllWorkspaces;

}

export class LoadAllWorkspacesSuccess implements Action {
    readonly type = WorkspacesActionTypes.LoadAllWorkspacesSuccess;

    constructor(public all: any, public personal: any = null, public template: any = null) { }
}

export class LoadAllWorkspacesFailure implements Action {
    readonly type = WorkspacesActionTypes.LoadAllWorkspacesFailure;

    constructor(public payload: any) { }
}

export class LoadWorkspace implements Action {
    readonly type = WorkspacesActionTypes.LoadWorkspace;

    constructor(public payload: Workspace) { }
}

export class UpdateWorkspace implements Action {
    readonly type = WorkspacesActionTypes.UpdateWorkspace;

    constructor(public payload: Workspace) { }
}

export class UpdateLegendOpen implements Action {
    readonly type = WorkspacesActionTypes.UpdateLegenedOpen;

    constructor(public payload: boolean) { }
}

export class CreateWorkspace implements Action {
    readonly type = WorkspacesActionTypes.CreateWorkspace;

    constructor(public payload: Workspace) { }
}

export class DeleteWorkspace implements Action {
    readonly type = WorkspacesActionTypes.DeleteWorkspace;

    constructor(public payload: Workspace) { }
}

export class CreateNotification implements Action {
    readonly type = WorkspacesActionTypes.CreateNotification;
    constructor(public payload: number) { }
}

export class DeleteNotification implements Action {
    readonly type = WorkspacesActionTypes.DeleteNotification;
    constructor(public payload: number) { }
}

export class LoadNotifications implements Action {
    readonly type = WorkspacesActionTypes.LoadNotifications;

}

export class LoadNotificationsSuccess implements Action {
    readonly type = WorkspacesActionTypes.LoadNotificationsSuccess;

    constructor(public payload: any[]) { }
}

export class LoadNotificationsFailure implements Action {
    readonly type = WorkspacesActionTypes.LoadNotificationsFailure;

    constructor(public payload: any) { }
}

export type WorkspacesActionsUnion =
    | LoadAllWorkspaces
    | LoadAllWorkspacesSuccess
    | LoadAllWorkspacesFailure
    | LoadWorkspace
    | UpdateWorkspace
    | UpdateLegendOpen
    | CreateWorkspace
    | DeleteWorkspace
    | CreateNotification
    | DeleteNotification
    | LoadNotifications
    | LoadNotificationsSuccess
    | LoadNotificationsFailure;
