export var GeosentimentActionTypes;
(function (GeosentimentActionTypes) {
    GeosentimentActionTypes["SaveInitialData"] = "[Geosentiment] Save Initial Data";
    GeosentimentActionTypes["SaveDocumentsData"] = "[Geosentiment] Save Documents Data";
    GeosentimentActionTypes["SaveGraphProcessedData"] = "[Geosentiment] Save Graph Processed Data";
    GeosentimentActionTypes["SetIndexesSelected"] = "[Geosentiment] Set Indexes Selected";
    GeosentimentActionTypes["SetResponseTime"] = "[Geosentiment] Set Response Time";
    GeosentimentActionTypes["SetStreamGraphOnTheFlyQuery"] = "[Geosentiment] Set Stream On The Fly Query";
    GeosentimentActionTypes["RunAnalysis"] = "[Geosentiment] Run Analysis";
})(GeosentimentActionTypes || (GeosentimentActionTypes = {}));
var SaveInitialData = /** @class */ (function () {
    function SaveInitialData(payload) {
        this.payload = payload;
        this.type = GeosentimentActionTypes.SaveInitialData;
    }
    return SaveInitialData;
}());
export { SaveInitialData };
var SaveDocumentsData = /** @class */ (function () {
    function SaveDocumentsData(payload) {
        this.payload = payload;
        this.type = GeosentimentActionTypes.SaveDocumentsData;
    }
    return SaveDocumentsData;
}());
export { SaveDocumentsData };
var SaveGraphProcessedData = /** @class */ (function () {
    function SaveGraphProcessedData(payload) {
        this.payload = payload;
        this.type = GeosentimentActionTypes.SaveGraphProcessedData;
    }
    return SaveGraphProcessedData;
}());
export { SaveGraphProcessedData };
var SetIndexesSelected = /** @class */ (function () {
    function SetIndexesSelected(payload) {
        this.payload = payload;
        this.type = GeosentimentActionTypes.SetIndexesSelected;
    }
    return SetIndexesSelected;
}());
export { SetIndexesSelected };
var SetResponseTime = /** @class */ (function () {
    function SetResponseTime(payload) {
        this.payload = payload;
        this.type = GeosentimentActionTypes.SetResponseTime;
    }
    return SetResponseTime;
}());
export { SetResponseTime };
var SetStreamGraphOnTheFlyQuery = /** @class */ (function () {
    function SetStreamGraphOnTheFlyQuery() {
        this.type = GeosentimentActionTypes.SetStreamGraphOnTheFlyQuery;
    }
    return SetStreamGraphOnTheFlyQuery;
}());
export { SetStreamGraphOnTheFlyQuery };
var RunAnalysis = /** @class */ (function () {
    function RunAnalysis(payload) {
        this.payload = payload;
        this.type = GeosentimentActionTypes.RunAnalysis;
    }
    return RunAnalysis;
}());
export { RunAnalysis };
