import { ActionReducer, Action } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MapTableActions } from '@gis/actions/index';
import { EsriLayer, Field } from '@shared/interfaces/esri.interface';
import { FeatureCollection } from 'geojson';
import { Actions } from '@ngrx/effects';

export interface State {
  loading: boolean;
  expanded: boolean;
  esriLayer: EsriLayer;
  cols: Field[];
  rows: any[];
  title: string;
  filterMap: boolean;
  selectedRowId: string;
  offset: number;
  totalCount: number;
  pageSize: number;
  sortingData: {
    direction: string;
    property: string;
  };
  selectedTables: any[];
  activeRowsOnMap: number[];
  activeRowsFeatures: any[];
  allLayerFeatures: any[];
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export const initialState: State = {
  loading: false,
  expanded: false,
  esriLayer: null,
  cols: [],
  rows: [],
  title: null,
  filterMap: false,
  selectedRowId: null,
  offset: 0,
  totalCount: 0,
  pageSize: 100,
  sortingData: {
    direction: null,
    property: null
  },
  selectedTables: [],
  activeRowsOnMap: [],
  activeRowsFeatures: [],
  allLayerFeatures: []
};

export function reducer(
  state = initialState,
  action: MapTableActions.MapTableActionsUnion
): State {
  switch (action.type) {
    case MapTableActions.MapTableActionTypes.LoadMapTableLayerByUrl:
      let tableAlreadyOpen = false;
      const tables = [...state.selectedTables];

      tables.forEach((table) => {
        table.active = false;
        if (table.url === action.payload.url && table.layerId ===  action.payload.layerId) {
          table.active = true;
          tableAlreadyOpen = true;
        }
      });
      if (!tableAlreadyOpen) {
        tables.push({
          url: action.payload.url,
          layerId: action.payload.layerId,
          title: null,
          active: true,
          expressions: [],
          condition: ''});
      }

      return {
        ...state,
        offset: 0,
        cols: [],
        rows: [],
        sortingData: {
          direction: null,
          property: null
        },
        selectedTables: [...tables]
      };
    case MapTableActions.MapTableActionTypes.LoadMapTableLayer:
      const layerData = action.payload ? action.payload : state.esriLayer;
      let tableLayers: any[];
      tableLayers = [...state.selectedTables];

      tableLayers.forEach((table) => {
        if (!table.title && table.url === layerData.url && table.layerId === layerData.id) {
          table.title = layerData.name;
        }
      });

      return {
        ...state,
        esriLayer: layerData,
        title: layerData.name,
        loading: true,
        totalCount: null,
        selectedTables: [...tableLayers],
        activeRowsFeatures: []
      };
    case MapTableActions.MapTableActionTypes.LoadLayerFeaturesSuccess:
      return {
        ...state,
        rows: action.payload.features.map(feat => feat.properties),
        cols: action.fields,
        totalCount: action.count,
        loading: false
      };
    case MapTableActions.MapTableActionTypes.LoadAllLayerFeaturesSuccess:
      return {
        ...state,
        allLayerFeatures: action.payload.features,
        loading: false
      };
    case MapTableActions.MapTableActionTypes.LoadLayerFeaturesFailure:
      return {
        ...state,
        rows: [],
        cols: [{name: 'Error loading table', type: 'Error loading table', alias: 'Error loading table'}],
        loading: false
      };
    case MapTableActions.MapTableActionTypes.ToggleMapTable:
      return {
        ...state,
        expanded: action.payload
      };
    case MapTableActions.MapTableActionTypes.CloseMapTable:
      return {
        ...state,
        title: action.payload,
        filterMap: false,
        totalCount: 0,
        selectedTables: []
      };
    case MapTableActions.MapTableActionTypes.SetSelectedTables:
        return {
          ...state,
          selectedTables: action.payload
        };
    case MapTableActions.MapTableActionTypes.FilterByMapExtent:
        return {
          ...state,
          filterMap: action.payload,
          totalCount: null
        };
    case MapTableActions.MapTableActionTypes.SetRowFeatureId:
      return {
        ...state,
        selectedRowId: action.payload
      };
    case MapTableActions.MapTableActionTypes.SetTableOffset:
      return {
        ...state,
        offset: action.payload
      };
    case MapTableActions.MapTableActionTypes.SetSortingData:
      return {
        ...state,
        sortingData: action.payload
      };
    case MapTableActions.MapTableActionTypes.SetActiveRowsOnMap:
        return {
          ...state,
          activeRowsOnMap: action.payload
        };
    case MapTableActions.MapTableActionTypes.SetActiveRowsFeatures:
      return {
        ...state,
        activeRowsFeatures: action.payload
      };
    default: {
      return state;
    }
  }
}
