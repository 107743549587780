import { ConsoleFacade } from './console.facade';
import { environment } from '@environments/environment';
// Singleton to overwrite the normal console process
var ConsoleUtil = /** @class */ (function () {
    function ConsoleUtil() {
        this.consoleFacade = new ConsoleFacade();
    }
    ConsoleUtil.getInstance = function () {
        if (!ConsoleUtil.instance) {
            ConsoleUtil.instance = new ConsoleUtil();
        }
        return ConsoleUtil.instance;
    };
    ConsoleUtil.prototype.log = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a;
        if (!environment.production) {
            (_a = this.consoleFacade).log.apply(_a, args);
        }
    };
    ConsoleUtil.prototype.error = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a;
        if (!environment.production) {
            (_a = this.consoleFacade).error.apply(_a, args);
        }
    };
    ConsoleUtil.prototype.warn = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a;
        if (!environment.production) {
            (_a = this.consoleFacade).warn.apply(_a, args);
        }
    };
    ConsoleUtil.prototype.groupCollapsed = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _a;
        if (!environment.production) {
            (_a = this.consoleFacade).groupCollapsed.apply(_a, args);
        }
    };
    ConsoleUtil.prototype.groupEnd = function () {
        if (!environment.production) {
            this.consoleFacade.groupEnd();
        }
    };
    return ConsoleUtil;
}());
export var console = ConsoleUtil.getInstance();
