import { Directive, Input, OnInit, OnChanges, OnDestroy, DoCheck } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'input[xom-input]',
  exportAs: 'xom-input',
  host: {
    'class': 'xom-input',
    // Native input properties that are overwritten by Angular inputs need to be synced with
    // the native input element. Otherwise property bindings for those don't work.
    // '[attr.id]': 'id',
    // '[attr.placeholder]': 'placeholder',
    // '[disabled]': 'disabled',
    // '[required]': 'required',
    // '[attr.readonly]': 'readonly'
  }
})
export class XomInputDirective {
  constructor() {}
}
