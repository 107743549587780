import { Action } from '@ngrx/store';

export enum InterrogatorActions {
  ToggleWells   = '[Interrogator] Toggle Wells',
  EnablePlayFilter  = '[Interrogator] Enable Play Filter',
  SelectFeature = '[Interrogator] Select Feature'
}

export class ToggleWells implements Action {
  readonly type = InterrogatorActions.ToggleWells;

  constructor() { }
}

export class SelectFeature implements Action {
  readonly type = InterrogatorActions.SelectFeature;

  constructor(public payload: any) { }
}


export class EnablePlayFilter implements Action {
  readonly type = InterrogatorActions.EnablePlayFilter;

  constructor() { }
}

export type InterrogatorActionsUnion =
  | ToggleWells
  | EnablePlayFilter
  | SelectFeature;
