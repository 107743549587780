import { Action } from '@ngrx/store';

export enum HydrationActionTypes {
    ResetHydration = '[Hydration] Reset Hydration',
    StartHydration = '[Hydration] Start Hydration',
    HydrationSearchResetCompleted = '[Hydration] Hydration Search ResetCompleted',
    DocumentFacetsRemovalCompleted = '[Hydration] Document Facets Removal Completed',
    SearchCompleted = '[Hydration] Search Completed',
    SearchDocumentSuccessCompleted = '[Hydration] Search Document Success Completed',
    FacetsSearchCompleted = '[Hydration] Facets Search Completed',
    HydrationStateVerification = '[Hydration] Hydration State Verification',
    MapServerHydrationCompleted = '[Hydration] Map Server Hydration Completed',
    SetHydrationToInitialState = '[Hydration] Set Hydration To Initial State'
}

export class ResetHydration implements Action {
    readonly type = HydrationActionTypes.ResetHydration;
    constructor(public payload: { mapServersTotal: number }) {}
}

export class StartHydration implements Action {
    readonly type = HydrationActionTypes.StartHydration;
    constructor(public payload: { mapServersTotal: number }) {}
}

export class HydrationSearchResetCompleted implements Action {
    readonly type = HydrationActionTypes.HydrationSearchResetCompleted;
}

export class DocumentFacetsRemovalCompleted implements Action {
    readonly type = HydrationActionTypes.DocumentFacetsRemovalCompleted;
}

export class SearchCompleted implements Action {
    readonly type = HydrationActionTypes.SearchCompleted;
}

export class SearchDocumentSuccessCompleted implements Action {
    readonly type = HydrationActionTypes.SearchDocumentSuccessCompleted;
}

export class FacetsSearchCompleted implements Action {
    readonly type = HydrationActionTypes.FacetsSearchCompleted;
}

export class MapServerHydrationCompleted implements Action {
    readonly type = HydrationActionTypes.MapServerHydrationCompleted;
}

export class HydrationStateVerification implements Action {
    readonly type = HydrationActionTypes.HydrationStateVerification;
}

export class SetHydrationToInitialState implements Action {
    readonly type = HydrationActionTypes.SetHydrationToInitialState;
}

export type HydrationActionsUnion =
    | ResetHydration
    | StartHydration
    | HydrationSearchResetCompleted
    | SearchCompleted
    | SearchDocumentSuccessCompleted
    | FacetsSearchCompleted
    | HydrationStateVerification
    | MapServerHydrationCompleted
    | DocumentFacetsRemovalCompleted
    | SetHydrationToInitialState;
