var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { HydrationActions } from '../actions';
import { map } from 'rxjs/operators';
var HydrationEffects = /** @class */ (function () {
    function HydrationEffects(actions$) {
        var _this = this;
        this.actions$ = actions$;
        this.hydrationStateVerification$ = function () {
            return _this.actions$.pipe(ofType(HydrationActions.HydrationActionTypes.SearchCompleted, HydrationActions.HydrationActionTypes.MapServerHydrationCompleted, HydrationActions.HydrationActionTypes.HydrationSearchResetCompleted, HydrationActions.HydrationActionTypes.DocumentFacetsRemovalCompleted, HydrationActions.HydrationActionTypes.SearchDocumentSuccessCompleted, HydrationActions.HydrationActionTypes.FacetsSearchCompleted), map(function () { return new HydrationActions.HydrationStateVerification(); }));
        };
        this.resetHydration$ = function () {
            return _this.actions$.pipe(ofType(HydrationActions.HydrationActionTypes.ResetHydration), map(function (action) { return new HydrationActions.StartHydration(action.payload); }));
        };
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], HydrationEffects.prototype, "hydrationStateVerification$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], HydrationEffects.prototype, "resetHydration$", void 0);
    return HydrationEffects;
}());
export { HydrationEffects };
