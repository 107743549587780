import { SnackbarActions } from '@app/shared/actions';
import { ERROR_MESSAGE_HANDLING } from '@shared/shared-content';

export function buildMessageError(error) {
    return ERROR_MESSAGE_HANDLING.STATUS + error.status + ', ' + error.message;
}

export function dispatchErrorMessage(store, error) {
    if (error.message.includes('AADSTS50105')) {
        return;
    }
    if (error.name && error.name === 'HttpErrorResponse') {
        const errorMessage = ERROR_MESSAGE_HANDLING.STATUS + error.status + ', ' + ERROR_MESSAGE_HANDLING.HTTP_FAILURE_RESPONSE;
        store.dispatch(getSnackbarErrorAction(errorMessage));
    } else {
        store.dispatch(getSnackbarErrorAction(error.message));
    }
}

export function getSnackbarErrorAction(error) {
    return new SnackbarActions.Open({
        message: error,
        type: 'xom-snackbar-error',
        action: 'x'
    });
}
