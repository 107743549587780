import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { defer } from 'rxjs';
import { finalize } from 'rxjs/operators';

import * as fromResults from '../reducers';
import { SearchActions } from '../actions';
import { GeosentimentActions } from '../../../shared/actions';
import { Store } from '@ngrx/store';

@Injectable()
export class ApiSearchTimeInterceptor implements HttpInterceptor {

    constructor(
        private store: Store<fromResults.State>
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return defer(() => {
            const key = request.urlWithParams;
            const start = window.performance.now();
            return next.handle(request).pipe(finalize(() => {
                const end = window.performance.now();
                if (key.includes('/geobrain/v2/search/docs')) {
                    this.store.dispatch(new SearchActions.AddDocumentResponseTime(Math.round((end - start) / 1000)));
                } else if (key.includes('/geobrain/v2/search/images')) {
                    this.store.dispatch(new SearchActions.AddImageResponseTime(Math.round((end - start) / 1000)));
                } else if (key.includes('/gistri/geosentiment')) {
                    this.store.dispatch(new GeosentimentActions.SetResponseTime(Math.round((end - start) / 1000)));
                }
            }));
        });
    }
}
