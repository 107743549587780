import { Injectable } from '@angular/core';
import { console } from '@app/shared/util/console.util';

@Injectable()
export class APIErrorHandler {

  public handleError(err: any) {
    console.error(err);
  }
}
