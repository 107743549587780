// this architecture is copied from the angular material library
// https://github.com/angular/material2/blob/master/src/lib/button/button.ts

import { Component, OnInit, ElementRef, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';

export class XomButtonBase {
  constructor(public _elementRef: ElementRef) {}
}

const BUTTON_HOST_ATTRIBUTES = [
  'xom-button',
  'xom-secondary-button',
  'xom-tertiary-button',
  'xom-round-button',
  'xom-round-outline-button',
  'xom-small-button',
  'xom-small-error-button',
  'xom-circle-button'
];

@Component({
  // tslint:disable-next-line:component-selector
  selector: `button[xom-button], button[xom-secondary-button], button[xom-tertiary-button], button[xom-round-button],
             button[xom-round-outline-button], button[xom-small-button], button[xom-small-error-button], button[xom-circle-button]`,
  templateUrl: './xom-button.component.html',
  styleUrls: ['./xom-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XomButtonComponent extends XomButtonBase {

  @Input() color = 'primary';

  constructor(
    elementRef: ElementRef
  ) {
    super(elementRef);

    for (const attr of BUTTON_HOST_ATTRIBUTES) {
      if (this._hasHostAttributes(attr)) {
        (elementRef.nativeElement as HTMLElement).classList.add(attr);
      }
    }
  }

  _getHostElement() {
    return this._elementRef.nativeElement;
  }

  /** Gets whether the button has one of the given attributes. */
  _hasHostAttributes(...attributes: string[]) {
    return attributes.some(attribute => this._getHostElement().hasAttribute(attribute));
  }

}
