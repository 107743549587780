var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HypothesisAction } from '@shared/actions';
export var initialState = {
    hypothesisModel: [],
    allowedCountries: [],
    hypothesisCardModel: [],
    userSubscribedModel: [],
    hypothesisMainLoading: true,
    hypothesisManagerLoading: true
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case HypothesisAction.HypothesisActionType.SetAllowedCountries: {
            return __assign({}, state, { allowedCountries: action.payload });
        }
        case HypothesisAction.HypothesisActionType.SetHypothesis: {
            return __assign({}, state, { hypothesisModel: action.payload });
        }
        case HypothesisAction.HypothesisActionType.SetHypothesisCard: {
            return __assign({}, state, { hypothesisCardModel: action.payload });
        }
        case HypothesisAction.HypothesisActionType.SetUserSubscribedModel: {
            return __assign({}, state, { userSubscribedModel: action.payload });
        }
        case HypothesisAction.HypothesisActionType.UpdateHypothesis: {
            for (var x = 0; x < state.hypothesisModel.length; x++) {
                var model = state.hypothesisModel[x];
                if (model.title === action.payload.title &&
                    model.category === action.payload.category) {
                    state.hypothesisModel[x] = action.payload;
                }
            }
            return state;
        }
        case HypothesisAction.HypothesisActionType.UpdateHypothesisCard: {
            for (var x = 0; x < state.hypothesisCardModel.length; x++) {
                var model = state.hypothesisCardModel[x];
                if (model.triggerId === action.payload.triggerId) {
                    state.hypothesisCardModel[x] = action.payload;
                }
            }
            return state;
        }
        case HypothesisAction.HypothesisActionType.SetHypothesisMainLoading: {
            return __assign({}, state, { hypothesisMainLoading: action.payload });
        }
        case HypothesisAction.HypothesisActionType.SetHypothesisManagerLoading: {
            return __assign({}, state, { hypothesisManagerLoading: action.payload });
        }
        default: {
            return state;
        }
    }
}
