import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { asyncScheduler, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { SeisbookAPIService } from '../services/seisbook-api.service';
import { SeisbookLayerService } from '../services/seisbook-layer.service';
import { SeisbookSearchActions } from '../actions';
import * as fromShared from '@shared/reducers';
import * as fromSearch from '@search/reducers';
import * as fromGis from '@gis/reducers';
import { dispatchErrorMessage } from '@app/shared/util/search-util';
import { SearchActions } from '@app/modules/search/actions';
import { SearchActionTypes } from '@app/modules/search/actions/search.actions';
import { AppConfigService, AppConfig } from '@app/app-config.service';

@Injectable()
export class SeisbookSearchEffects {
  private _config: AppConfig;

  constructor(
    private actions$: Actions,
    private store$: Store<fromSearch.State>,
    private gisStore$: Store<fromGis.State>,
    private seisbookAPI: SeisbookAPIService,
    private seisbookLayerSvc: SeisbookLayerService,
    private sharedStore: Store<fromShared.State>,
    private configSvc: AppConfigService,
  ) {
    this._config = configSvc.getConfig();
  }

  @Effect()
  triggerSearch = ({ debounce = 300, scheduler = asyncScheduler } = {}): Observable<Action> =>
    this.actions$.pipe(
      ofType<SearchActions.Search>(
        SearchActionTypes.Search
      ),
      debounceTime(debounce, scheduler),
      withLatestFrom(this.store$, this.gisStore$),
      switchMap(([action, storeState, gisState]) => {
        const queryText = action.payload || storeState.search.shared.queryText;
        let seisbookAppInstalled = false;
        this._config.myApps.forEach(app => {
          if (app.name.includes('seisbook-app')) {
            seisbookAppInstalled = true;
          }
        });

        let basins = gisState.gis.aoi.aoiQuery['basin'] || [];
        const countries = gisState.gis.aoi.aoiQuery['country'] || [];

        if (seisbookAppInstalled) {
          if (countries.length) {
            return this.seisbookLayerSvc.getBasinNames(countries).pipe(
              map((countryBasins: string[]) => {
                if (countryBasins.length) {
                  basins = basins.concat(countryBasins);
                }
                return new SeisbookSearchActions.SearchSeisbook({ queryText, basins, count: 100, pageIndex: 1 });
              }),
              catchError(err => of(new SeisbookSearchActions.SearchSeisbookFailure(err))));
          }
          return [ new SeisbookSearchActions.SearchSeisbook({ queryText, basins, count: 100, pageIndex: 1 }) ];
        } else {
          return [];
        }
      })
    )

  @Effect()
  searchSeisbook$ = ({ debounce = 300, scheduler = asyncScheduler } = {}): Observable<Action> =>
    this.actions$.pipe(
      ofType<SeisbookSearchActions.SearchSeisbook>(
        SeisbookSearchActions.SEARCH_SEISBOOK
      ),
      debounceTime(debounce, scheduler),
      withLatestFrom(this.store$),
      switchMap(([action, storeState]) => {
        const queryText = action.payload.queryText || storeState.search.shared.queryText;
        const pageIndex = action.payload.pageIndex || 1;
        const count = action.payload.count;
        const basins = action.payload.basins;

        if (queryText === '' && basins.length === 0) {
          return of(new SeisbookSearchActions.SearchSeisbookEmpty());
        }

        return this.seisbookAPI.searchSeisbook(queryText, basins, pageIndex, count).pipe(
          map((res: any) => {
            if (res.status === 401 || res.status === 403) {
              return new SeisbookSearchActions.SearchSeisbookAuthorizationFailure(res);
            }
            return new SeisbookSearchActions.SearchSeisbookSuccess(res);
          }),
          catchError(err => {
            if (err.status === 401 || err.status === 403 || String(err.message).includes('AADSTS50105')) {
              return of(new SeisbookSearchActions.SearchSeisbookNoAccess(err));
            } else if (String(err.message).includes('Http failure during parsing')) {
              return of(new SeisbookSearchActions.SearchSeisbookAuthorizationFailure(err));
            } else {
              dispatchErrorMessage(this.sharedStore, err);
              return of(new SeisbookSearchActions.SearchSeisbookFailure(err));
            }
          })
        );
      })
    )
}
