import * as esri from 'esri-leaflet';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import * as i0 from "@angular/core";
/**
 * Handles the data requests from other components within basin eval
 * Allows for a generic query to be exposed and usable
 */
var BasinEvaluationHistoryService = /** @class */ (function () {
    function BasinEvaluationHistoryService() {
        /**
         * This Observable is used to pass the ESID from the
         * basin-evaluation-history.component to the leaflet
         * component so that it can render geometry data on the map
         */
        this.mapFieldsBasinEval = new BehaviorSubject({ chartData: null, basinName: '' });
    }
    /**
     * This method is a receiver for the fields esID and mapServer from
     * basin-eval-history.component and is called after an object in the
     * awareness charts is clicked
     * @param uniqueID The unique ID field for a chartItem inside the basin-eval
     * charts that is used to filter against within the mapserver
     * @param mapServerUrl Url of mapserver that the chartItem data was taken from
     */
    BasinEvaluationHistoryService.prototype.showOnMap = function (chartData, basinName) {
        this.mapFieldsBasinEval.next({
            chartData: chartData,
            basinName: basinName
        });
    };
    /**
     * Queries the layer data needed for Basin Evaluation History.
     * @param config The configuration for Basin Evaluation History.
     * @param basinName The name of the basin.
     * @returns An Observable containing all layer data needed for Basin Evaluation History.
     */
    BasinEvaluationHistoryService.prototype.queryBasinEvaluationHistoryLayers$ = function (config, basinName) {
        var _this = this;
        return forkJoin({
            blocks: forkJoin(config.blocks.layers.map(function (s) { return _this.queryLayer(s, basinName); })),
            decisionGates: forkJoin(config.decisionGates.layers.map(function (s) { return _this.queryLayer(s, basinName); })),
            geophysicalSurveys: forkJoin(config.geophysicalSurveys.layers.map(function (s) { return _this.queryLayer(s, basinName); })),
            penetrations: forkJoin(config.penetrations.layers.map(function (s) { return _this.queryLayer(s, basinName); })),
            plays: forkJoin(config.plays.layers.map(function (s) { return _this.queryLayer(s, basinName); })),
            studies: forkJoin(config.studies.layers.map(function (s) { return _this.queryLayer(s, basinName); })),
            wells: forkJoin(config.wells.layers.map(function (s) { return _this.queryLayer(s, basinName); }))
        });
    };
    /**
     * Queries the data and metadata of a map service layer.
     * @param layerConfig The configuration of the layer.
     * @param basinName The name of the basin.
     * @returns An Observable of the layer's data and metadata.
     */
    BasinEvaluationHistoryService.prototype.queryLayer = function (layerConfig, basinName) {
        return forkJoin({
            data: this.queryLayerData$(layerConfig, basinName),
            metadata: this.queryLayerMetadata$(layerConfig)
        });
    };
    /**
     * Queries the feature data of a map service layer.
     * @param config The configuration of the layer.
     * @param basinName The name of the basin.
     * @returns An Observable of the layer's data.
     */
    BasinEvaluationHistoryService.prototype.queryLayerData$ = function (config, basinName) {
        return new Observable(function (subscriber) {
            var result = { features: [], error: null };
            esri
                .query({ url: config.url, useCors: true, withCredentials: true })
                .where(config.basinField + " = '" + basinName + "'")
                .returnGeometry(false)
                .run(function (error, featureCollection) {
                try {
                    if (error) {
                        result.error = "Failed to query layer data for " + config.url;
                        console.error(result.error, error);
                    }
                    else {
                        result.features = featureCollection.features.map(function (f) { return f.properties; });
                    }
                }
                catch (exception) {
                    result.error = "Exception occurred querying layer data for " + config.url;
                    console.error(result.error, exception);
                }
                finally {
                    subscriber.next(result);
                    subscriber.complete();
                }
            });
        });
    };
    /**
     * Queries the metadata of a map service layer.
     * @param config The configuration of the layer.
     * @returns An Observable of the layer's metadata.
     */
    BasinEvaluationHistoryService.prototype.queryLayerMetadata$ = function (config) {
        return new Observable(function (subscriber) {
            var result = { name: '', config: config, fields: [], geometryType: '', error: null };
            esri
                .featureLayer({ 'url': config.url, 'useCors': false })
                .metadata(function (error, metadata) {
                try {
                    if (error) {
                        result.error = "Failed to query layer metadata for " + config.url;
                        console.error(result.error, error);
                    }
                    else {
                        result.name = metadata.name;
                        result.fields = metadata.fields.map(function (f) { return ({ name: f.name, esriType: f.type }); });
                        result.geometryType = metadata.geometryType;
                    }
                }
                catch (exception) {
                    result.error = "Exception occurred retrieving metadata for " + config.url;
                    console.error(result.error, exception);
                }
                finally {
                    subscriber.next(result);
                    subscriber.complete();
                }
            });
        });
    };
    BasinEvaluationHistoryService.ngInjectableDef = i0.defineInjectable({ factory: function BasinEvaluationHistoryService_Factory() { return new BasinEvaluationHistoryService(); }, token: BasinEvaluationHistoryService, providedIn: "root" });
    return BasinEvaluationHistoryService;
}());
export { BasinEvaluationHistoryService };
