<xom-admin-message *ngIf="browserTypeWarning" [title]="content.global.BROWSER_TYPE_WARNING.TITLE"
    [message]="content.global.BROWSER_TYPE_WARNING.MESSAGE" (close)="closeBrowserTypeWarning()">
</xom-admin-message>

<xom-admin-message *ngIf="!browserTypeWarning && screenSizeWarning" [title]="content.global.SCREEN_SIZE_WARNING_TITLE"
    [message]="content.global.SCREEN_SIZE_WARNING_MESSAGE" (close)="closeScreenSizeWarning()">
</xom-admin-message>

<ng-container *ngIf="(preferences$ | async) && !screenSizeWarning && !browserTypeWarning">
    <xom-splashscreen *ngIf="preferences.showSplashScreen && !showAdminMessage" (close)="closeSplashScreen()">
    </xom-splashscreen>
    <xom-admin-message *ngIf="showAdminMessage && router.url.includes('/geobrain')" [title]="adminMessage.title" [message]="adminMessage.message"
        (close)="closeAdminMessage()"></xom-admin-message>
</ng-container>


  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <router-outlet *ngIf="!isIframe"></router-outlet>

<xom-shared-modals></xom-shared-modals>
