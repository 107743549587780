import { Injectable } from '@angular/core';
import { ApplicationInsights, IEventTelemetry } from '@microsoft/applicationinsights-web';
import { ICustomProperties } from '@microsoft/applicationinsights-core-js';
import { AppConfigService, AppConfig } from '@app/app-config.service';
import { console } from '@app/shared/util/console.util';

export const appInsights = new ApplicationInsights({ config: {
    instrumentationKey: '',
    samplingPercentage: 0
}});

declare global {
    interface Window { appInsights: any; }
}


@Injectable()
export class InsightsService {
    _appConfig: AppConfig;
    appInsights: ApplicationInsights;

    trackEvent: (event: IEventTelemetry, customProperties?: ICustomProperties) => void;

    constructor(private appConfigSvc: AppConfigService) {
        this._appConfig = this.appConfigSvc.getConfig();
        appInsights.config.instrumentationKey = this._appConfig.instrumentation_key;
        appInsights.loadAppInsights();
        console.log('App Insights Loaded', appInsights);
        this.appInsights = appInsights;
        this.trackEvent = appInsights.trackEvent;
        window.appInsights = this.appInsights; // TODO: For testing/debugging purposes only. Remove before Prod release
     }
}
