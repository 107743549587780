export var STORAGE_ACTION_TYPE = '@ngrx/store/storage';
export var WORKSPACE_ACTION_TYPE = 'WORKSPACE_HYDRATION';
var StorageAction = /** @class */ (function () {
    function StorageAction(payload) {
        this.payload = payload;
        this.type = STORAGE_ACTION_TYPE;
    }
    return StorageAction;
}());
export { StorageAction };
var HydrateWorkspace = /** @class */ (function () {
    function HydrateWorkspace(payload) {
        this.payload = payload;
        this.type = WORKSPACE_ACTION_TYPE;
    }
    return HydrateWorkspace;
}());
export { HydrateWorkspace };
