import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UserCollection } from '@shared/models/UserAPI.model';
import { AppConfig, AppConfigService } from '@app/app-config.service';
import {select, Store} from '@ngrx/store';
import * as fromShared from '@shared/reducers';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CollectionsApiService implements OnDestroy {
  private _config: AppConfig;
  subscriptions: Subscription[] = [];
  private collectionFilterTypes$ = this.sharedStore.pipe(select(fromShared.getCollectionFileTypes));

  constructor(
    private http: HttpClient,
    private _appConfig: AppConfigService,
    private sharedStore: Store<fromShared.State>
  ) {
    this._config = this._appConfig.getConfig();
  }

  ngOnDestroy(): void {
        this.subscriptions.forEach( subscription => {
            try {
                subscription.unsubscribe();
            } catch (e) {
                console.error('[Error] OnDestroy unsubscribes', e);
            }
        });
  }

  getCollection(id: number, filterFileExtensions: string[] = [], sorting: number = 1, filterFileContains: string = ''): Observable<any> {
    let filterString = '';
    if (filterFileExtensions.length <= 0 || !filterFileExtensions) {
      this.subscriptions.push(this.collectionFilterTypes$.pipe(take(1)).subscribe(res => {
        filterFileExtensions = res;
      }));
    }
    filterFileExtensions.forEach((filterExtension, i) => {
      if (i > 0) { filterString = filterString + `&`; }
      filterString = filterString + `filterFileExtensions=${filterExtension}`;
      if (i === filterFileExtensions.length - 1 && sorting) { filterString = filterString + `&`; }
    });
    filterString = filterString + `fileSort=${sorting}`;
    if (filterFileContains !== '') {filterString = filterString + `&filterFileContains=${filterFileContains}`; }
    return this.http.get<any>(`${this._config.user_api_baseurl}/collection/${id}?${filterString}`);
    // return this.http.get<any>('/assets/data/sample-collection-response.json');
    // return this.http.get<any>('/assets/data/129716/C419fileSort.json');
  }

  getCollections(): Observable<any> {
    return this.http.get<any>(`${this._config.user_api_baseurl}/collections`);
    // return this.http.get<any>('/assets/data/sample-collections-response.json');
  }

  createCollection(collection: UserCollection): Observable<any>  {
    return this.http.post<string>(`${this._config.user_api_baseurl}/collection`, collection);
  }

  updateCollection(collection: UserCollection): Observable<any> {
    return this.http.put<UserCollection>(`${this._config.user_api_baseurl}/collection/${collection.collectionId}`, collection);
  }

  deleteCollection(id: number): Observable<any> {
    return this.http.delete<string>(`${this._config.user_api_baseurl}/collection/${id}`);
  }

  docsById(fileNames: string[]): Observable<any> {
    return this.http.post<Document[]>(`${this._config.datascience_api_baseurl}/gistri/docsByIds`, { fileNames: fileNames });
    // return this.http.get<Document[]>('/assets/data/sample-docsbyid-response.json');
  }

  exportDocsById(fileNames: string[]): Observable<any> {
    return this.http.post(`${this._config.datascience_api_baseurl}/gistri/exportByIds`, { fileNames: fileNames }, {responseType: 'text'});
  }

  // Comments
  addCollectionComment(collectionID, message): Observable<any> {
    return this.http.post<any>(`${this._config.user_api_baseurl}/collection/Comments/${collectionID}`, message);
  }
  deleteCollectionComment(collectionID, messageID): Observable<any> {
    return this.http.delete<any>(`${this._config.user_api_baseurl}/collection/Comments/${collectionID}/${messageID}`);
  }

  // Pins
  addPinnedCollection(collectionID): Observable<any> {
    return this.http.put<any>(`${this._config.user_api_baseurl}/preferences/collection/${collectionID}/pin`, {});
  }
  removePinnedCollection(collectionID): Observable<any> {
    return this.http.put<any>(`${this._config.user_api_baseurl}/preferences/collection/${collectionID}/unpin`, {});
  }

  // Filters
  getFileSummary(collectionId: number): Observable<any> {
    return this.http.get<any>(`${this._config.user_api_baseurl}/collections/CollectionfileSummary/${collectionId}`);
    // return this.http.get<any>('/assets/data/sample-file-summary-response.json');
  }
}
