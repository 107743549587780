import { FeedbackActions } from '../actions';

export interface State {
    fileList: any[];
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export const initialState: State = { fileList: [] };

export function reducer(
    state = initialState,
    action: FeedbackActions.FeedbackActionsUnion
): State {
    switch (action.type) {
        case FeedbackActions.FeedbackActionTypes.PostFeedbackAttachments:
            return {
                ...state,
                fileList: action.payload
            };
        default: {
            return state;
        }
    }
}
