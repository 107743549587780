import { ToolboxActions } from '../actions/index';

export interface State {
    expanded: Boolean;
}

const initialState: State = { expanded: false };

export function reducer(state = initialState, action: ToolboxActions.ToolboxActionsUnion): State {
    switch (action.type) {
        case ToolboxActions.ToolboxActionTypes.Toolbox_Toggle:
            return {
                ...state,
                expanded: action.payload
            };
        default:
            return state;
    }
}
