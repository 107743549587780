export var InterrogatorActions;
(function (InterrogatorActions) {
    InterrogatorActions["ToggleWells"] = "[Interrogator] Toggle Wells";
    InterrogatorActions["EnablePlayFilter"] = "[Interrogator] Enable Play Filter";
    InterrogatorActions["SelectFeature"] = "[Interrogator] Select Feature";
})(InterrogatorActions || (InterrogatorActions = {}));
var ToggleWells = /** @class */ (function () {
    function ToggleWells() {
        this.type = InterrogatorActions.ToggleWells;
    }
    return ToggleWells;
}());
export { ToggleWells };
var SelectFeature = /** @class */ (function () {
    function SelectFeature(payload) {
        this.payload = payload;
        this.type = InterrogatorActions.SelectFeature;
    }
    return SelectFeature;
}());
export { SelectFeature };
var EnablePlayFilter = /** @class */ (function () {
    function EnablePlayFilter() {
        this.type = InterrogatorActions.EnablePlayFilter;
    }
    return EnablePlayFilter;
}());
export { EnablePlayFilter };
