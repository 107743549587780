import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromShared from '@shared/reducers';
import { Notification, Workspace } from '@shared/models/UserAPI.model';
import { AppConfig, AppConfigService } from '@app/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _config: AppConfig;

  constructor(
    private http: HttpClient,
    private _appConfig: AppConfigService,
    private sharedStore: Store<fromShared.State>
  ) {
    this._config = this._appConfig.getConfig();
  }

  getNotification(id: number): Observable<any> {
    return this.http.get<any>(`${this._config.user_api_baseurl}/notification/${id}`);
  }

  getNotifications(): Observable<any> {
    return this.http.get<any>(`${this._config.user_api_baseurl}/notifications`);
    // return this.http.get<any>('/assets/data/sample-notifications-response.json');
  }

  createNotification(notification: Notification): Observable<any>  {
    return this.http.post<string>(`${this._config.user_api_baseurl}/notification`, notification);
  }

  updateNotification(notification: Notification): Observable<any> {
    return this.http.put<Notification>(`${this._config.user_api_baseurl}/notification/${notification.notificationId}`, notification);
  }

  deleteNotification(id: number): Observable<any> {
    return this.http.delete<any>(`${this._config.user_api_baseurl}/notification/deleteByWorkspaceId/${id}`);
  }
}
