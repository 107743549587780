// Due to known ngrx issue of effects triggering before app initializer resolves:
// https://github.com/ngrx/platform/issues/931
import { APP_BOOTSTRAP_LISTENER, InjectionToken, Inject } from '@angular/core';
import { EffectSources } from '@ngrx/effects';
export var BOOTSTRAP_EFFECTS = new InjectionToken('Bootstrap Effects');
export function bootstrapEffects(effects, sources) {
    return function () {
        effects.forEach(function (effect) { return sources.addEffects(effect); });
    };
}
export function createInstances() {
    var instances = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        instances[_i] = arguments[_i];
    }
    return instances;
}
export function provideBootstrapEffects(effects) {
    return [
        effects,
        { provide: BOOTSTRAP_EFFECTS, deps: effects, useFactory: createInstances },
        {
            provide: APP_BOOTSTRAP_LISTENER,
            multi: true,
            useFactory: bootstrapEffects,
            deps: [[new Inject(BOOTSTRAP_EFFECTS)], EffectSources]
        }
    ];
}
