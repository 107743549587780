import { Action } from '@ngrx/store';
import { EsriLayer, Field } from '@shared/interfaces/esri.interface';
import { FeatureCollection } from 'geojson';
import { LayerQueryProps } from '../models/QueryLayer';

export enum MapTableActionTypes {
    LoadMapTableLayerByUrl = '[Map Table] Load Table Layer By Url',
    LoadMapTableLayer = '[Map Table] Load Table Layer',
    LoadLayerFeaturesSuccess = '[Map Table] Load Layer Features Success',
    LoadLayerFeaturesFailure = '[Map Table] Load Layer Features Failure',
    LoadAllLayerFeatures = '[Map Table] Load All Layer Features',
    LoadAllLayerFeaturesSuccess = '[Map Table] Load All Layer Features Success',
    LoadAllLayerFeaturesFailure = '[Map Table] Load All Layer Features Failure',
    ToggleMapTable = '[Map Table] Toggle Map Table',
    CloseMapTable = '[Map Table] Close Map Table',
    SetSelectedTables = '[Map Table] Set Selected Tables',
    FilterByMapExtent = '[Map Table] Filter By Map Extent',
    SetRowFeatureId = '[Map Table] Set Row Feature Id',
    SetTableOffset = '[Map Table] Set Table Offset',
    SetSortingData = '[Map Table] Set Sorting Data',
    SetActiveRowsOnMap = '[Map Table] Set Active Rows On Map',
    SetActiveRowsFeatures = '[Map Table] Set Active Rows Features',
}

export class LoadMapTableLayerByUrl implements Action {
    readonly type = MapTableActionTypes.LoadMapTableLayerByUrl;

    constructor(public payload: { url: string, layerId: number }) {}
}

export class LoadMapTableLayer implements Action {
    readonly type = MapTableActionTypes.LoadMapTableLayer;

    constructor(public payload: EsriLayer | null) {}
}

export class LoadLayerFeaturesSuccess implements Action {
    readonly type = MapTableActionTypes.LoadLayerFeaturesSuccess;

    constructor(public payload: FeatureCollection, public count: number, public fields: Field[]) {}
}

export class LoadLayerFeaturesFailure implements Action {
    readonly type = MapTableActionTypes.LoadLayerFeaturesFailure;

    constructor(public payload: string) {}
}

export class LoadAllLayerFeatures implements Action {
    readonly type = MapTableActionTypes.LoadAllLayerFeatures;

    constructor(public payload: LayerQueryProps) {}
}

export class LoadAllLayerFeaturesSuccess implements Action {
    readonly type = MapTableActionTypes.LoadAllLayerFeaturesSuccess;

    constructor(public payload: FeatureCollection) {}
}

export class LoadAllLayerFeaturesFailure implements Action {
    readonly type = MapTableActionTypes.LoadAllLayerFeaturesFailure;

    constructor(public payload: string) { }
}

export class ToggleMapTable implements Action {
    readonly type = MapTableActionTypes.ToggleMapTable;

    constructor(public payload: boolean) {}
}

export class CloseMapTable implements Action {
    readonly type = MapTableActionTypes.CloseMapTable;

    constructor(public payload: string) {}
}

export class SetSelectedTables implements Action {
    readonly type = MapTableActionTypes.SetSelectedTables;
    constructor(public payload: any[]) {}
}

export class FilterByMapExtent implements Action {
    readonly type = MapTableActionTypes.FilterByMapExtent;

    constructor(public payload: boolean) {}
}

export class SetRowFeatureId implements Action {
    readonly type = MapTableActionTypes.SetRowFeatureId;

    constructor(public payload: string | null) {}
}

export class SetTableOffset implements Action {
    readonly type = MapTableActionTypes.SetTableOffset;

    constructor(public payload: number) {}
}

export class SetSortingData implements Action {
    readonly type = MapTableActionTypes.SetSortingData;

    constructor(public payload: {direction: string, property: string}) {}
}

export class SetActiveRowsOnMap implements Action {
    readonly type = MapTableActionTypes.SetActiveRowsOnMap;
    constructor(public payload: number[]) {}
}
export class SetActiveRowsFeatures implements Action {
    readonly type = MapTableActionTypes.SetActiveRowsFeatures;
    constructor(public payload: any[]) {}
}


export type MapTableActionsUnion =
    | LoadMapTableLayerByUrl
    | LoadMapTableLayer
    | LoadLayerFeaturesSuccess
    | LoadLayerFeaturesFailure
    | LoadAllLayerFeatures
    | LoadAllLayerFeaturesSuccess
    | LoadAllLayerFeaturesFailure
    | ToggleMapTable
    | CloseMapTable
    | SetSelectedTables
    | FilterByMapExtent
    | SetRowFeatureId
    | SetTableOffset
    | SetSortingData
    | SetActiveRowsOnMap
    | SetActiveRowsFeatures;

