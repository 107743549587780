import { HttpClient } from '@angular/common/http';
import { OnDestroy } from '@angular/core';
import { AppConfigService } from '@app/app-config.service';
import { select, Store } from '@ngrx/store';
import * as fromShared from '@shared/reducers';
import { take } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app-config.service";
import * as i3 from "@ngrx/store";
var CollectionsApiService = /** @class */ (function () {
    function CollectionsApiService(http, _appConfig, sharedStore) {
        this.http = http;
        this._appConfig = _appConfig;
        this.sharedStore = sharedStore;
        this.subscriptions = [];
        this.collectionFilterTypes$ = this.sharedStore.pipe(select(fromShared.getCollectionFileTypes));
        this._config = this._appConfig.getConfig();
    }
    CollectionsApiService.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) {
            try {
                subscription.unsubscribe();
            }
            catch (e) {
                console.error('[Error] OnDestroy unsubscribes', e);
            }
        });
    };
    CollectionsApiService.prototype.getCollection = function (id, filterFileExtensions, sorting, filterFileContains) {
        if (filterFileExtensions === void 0) { filterFileExtensions = []; }
        if (sorting === void 0) { sorting = 1; }
        if (filterFileContains === void 0) { filterFileContains = ''; }
        var filterString = '';
        if (filterFileExtensions.length <= 0 || !filterFileExtensions) {
            this.subscriptions.push(this.collectionFilterTypes$.pipe(take(1)).subscribe(function (res) {
                filterFileExtensions = res;
            }));
        }
        filterFileExtensions.forEach(function (filterExtension, i) {
            if (i > 0) {
                filterString = filterString + "&";
            }
            filterString = filterString + ("filterFileExtensions=" + filterExtension);
            if (i === filterFileExtensions.length - 1 && sorting) {
                filterString = filterString + "&";
            }
        });
        filterString = filterString + ("fileSort=" + sorting);
        if (filterFileContains !== '') {
            filterString = filterString + ("&filterFileContains=" + filterFileContains);
        }
        return this.http.get(this._config.user_api_baseurl + "/collection/" + id + "?" + filterString);
        // return this.http.get<any>('/assets/data/sample-collection-response.json');
        // return this.http.get<any>('/assets/data/129716/C419fileSort.json');
    };
    CollectionsApiService.prototype.getCollections = function () {
        return this.http.get(this._config.user_api_baseurl + "/collections");
        // return this.http.get<any>('/assets/data/sample-collections-response.json');
    };
    CollectionsApiService.prototype.createCollection = function (collection) {
        return this.http.post(this._config.user_api_baseurl + "/collection", collection);
    };
    CollectionsApiService.prototype.updateCollection = function (collection) {
        return this.http.put(this._config.user_api_baseurl + "/collection/" + collection.collectionId, collection);
    };
    CollectionsApiService.prototype.deleteCollection = function (id) {
        return this.http.delete(this._config.user_api_baseurl + "/collection/" + id);
    };
    CollectionsApiService.prototype.docsById = function (fileNames) {
        return this.http.post(this._config.datascience_api_baseurl + "/gistri/docsByIds", { fileNames: fileNames });
        // return this.http.get<Document[]>('/assets/data/sample-docsbyid-response.json');
    };
    CollectionsApiService.prototype.exportDocsById = function (fileNames) {
        return this.http.post(this._config.datascience_api_baseurl + "/gistri/exportByIds", { fileNames: fileNames }, { responseType: 'text' });
    };
    // Comments
    CollectionsApiService.prototype.addCollectionComment = function (collectionID, message) {
        return this.http.post(this._config.user_api_baseurl + "/collection/Comments/" + collectionID, message);
    };
    CollectionsApiService.prototype.deleteCollectionComment = function (collectionID, messageID) {
        return this.http.delete(this._config.user_api_baseurl + "/collection/Comments/" + collectionID + "/" + messageID);
    };
    // Pins
    CollectionsApiService.prototype.addPinnedCollection = function (collectionID) {
        return this.http.put(this._config.user_api_baseurl + "/preferences/collection/" + collectionID + "/pin", {});
    };
    CollectionsApiService.prototype.removePinnedCollection = function (collectionID) {
        return this.http.put(this._config.user_api_baseurl + "/preferences/collection/" + collectionID + "/unpin", {});
    };
    // Filters
    CollectionsApiService.prototype.getFileSummary = function (collectionId) {
        return this.http.get(this._config.user_api_baseurl + "/collections/CollectionfileSummary/" + collectionId);
        // return this.http.get<any>('/assets/data/sample-file-summary-response.json');
    };
    CollectionsApiService.ngInjectableDef = i0.defineInjectable({ factory: function CollectionsApiService_Factory() { return new CollectionsApiService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService), i0.inject(i3.Store)); }, token: CollectionsApiService, providedIn: "root" });
    return CollectionsApiService;
}());
export { CollectionsApiService };
