import { Action } from '@ngrx/store';
import { HypothesisModel } from '@app/modules/hypothesis/models/hypothesis.model';
import { HypothesisCardModel } from '@app/modules/hypothesis/models/hypothesis-card.model';
import { UserSubscribedModel } from '@app/modules/hypothesis/models/user-subscribed.model';

export enum HypothesisActionType {
    SetAllowedCountries = '[Hypothesis] Set the Allowed Contries',
    SetHypothesis = '[Hypothesis] Set the Hypothesis Model',
    SetHypothesisCard = '[Hypothesis] Set the Hypothesis Card Model',
    SetHypothesisSubscribe = '[Hypothesis] Set the Hypothesis Subscribe Model',
    AddHypothesis = '[Hypothesis] Add a Hypothesis Model',
    AddHypothesisCard = '[Hypothesis] Add a Hypothesis Card Model',
    RemoveHypothesis = '[Hypothesis] Remove a Hypothesis Model',
    RemoveHypothesisCard = '[Hypothesis] Remove a Hypothesis Card Model',
    UpdateHypothesisEnable = '[Hypothesis] Update a Hypothesis Model Enable',
    SetUserSubscribedModel = '[Hypothesis] Update a User subscribed Model Enable',
    UpdateHypothesis = '[Hypothesis] Update Hypothesis model',
    UpdateHypothesisCard = '[Hypothesis] Update Hypothesis Card model',
    SetHypothesisMainLoading = '[Hypothesis] Update Hypothesis Main Loading Spinner',
    SetHypothesisManagerLoading = '[Hypothesis] Update Hypothesis Manager Loading Spiner'
}

export class SetHypothesisMainLoading implements Action {
    readonly type = HypothesisActionType.SetHypothesisMainLoading;

    constructor(public payload: boolean) {}
}

export class SetHypothesisManagerLoading implements Action {
    readonly type = HypothesisActionType.SetHypothesisManagerLoading;

    constructor(public payload: boolean) {}
}

export class SetAllowedCountries implements Action {
    readonly type = HypothesisActionType.SetAllowedCountries;

    constructor(public payload: any) {}
}

export class SetHypothesis implements Action {
    readonly type = HypothesisActionType.SetHypothesis;

    constructor(public payload: HypothesisModel[]) {}
}

export class SetHypothesisCard implements Action {
    readonly type = HypothesisActionType.SetHypothesisCard;

    constructor(public payload: HypothesisCardModel[]) {}
}

export class UpdateHypothesisCard implements Action {
    readonly type = HypothesisActionType.UpdateHypothesisCard;

    constructor(public payload: any) {}
}

export class UpdateHypothesis implements Action {
    readonly type = HypothesisActionType.UpdateHypothesis;

    constructor(public payload: HypothesisModel) {}
}

export class SetUserSubscribedModel implements Action {
    readonly type = HypothesisActionType.SetUserSubscribedModel;

    constructor(public payload: UserSubscribedModel[]) {}
}

export type HypothesisActionUnion =
    | SetAllowedCountries
    | SetHypothesis
    | SetHypothesisCard
    | UpdateHypothesis
    | UpdateHypothesisCard
    | SetUserSubscribedModel
    | SetHypothesisMainLoading
    | SetHypothesisManagerLoading;
