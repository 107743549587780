import { Injectable } from '@angular/core';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIErrorHandler } from './api-error-handler.service';
import { AppConfigService, AppConfig } from '@app/app-config.service';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  private _config: AppConfig;

  constructor(
    private _appConfig: AppConfigService,
    public errorHandler: APIErrorHandler
  ) {
    this._config = this._appConfig.getConfig();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestUrl = request.url;
    if (requestUrl.includes(this._config.datascience_api_baseurl)) {
      if (!this._config.datascience_api_token) {
        request = request.clone({
          headers: new HttpHeaders({
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
          }),
          withCredentials: true
        });
      } else {
        request = request.clone({
          headers: new HttpHeaders({
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
          }),
          setHeaders: {
            'Authorization' : this._config.datascience_api_token
          }
        });
      }
    }

    return next.handle(request);
  }
}
