import { AppConfigService } from '@app/app-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ImageQueryActions } from '@app/modules/search/actions';
import * as i0 from "@angular/core";
import * as i1 from "../../app-config.service";
import * as i2 from "@angular/router";
import * as i3 from "@ngrx/store";
var GeobrainLoaderService = /** @class */ (function () {
    function GeobrainLoaderService(appConfig, route, router, imageQueryStore) {
        this.appConfig = appConfig;
        this.route = route;
        this.router = router;
        this.imageQueryStore = imageQueryStore;
        this.activeApp = 'Landing';
        this.config = appConfig.getConfig();
    }
    GeobrainLoaderService.prototype.activateApp = function (app, urlParams) {
        this.activeApp = app.title;
        var targetView = this.currentView;
        if (targetView === 'map') {
            // app was activated, put the user in hybrid mode
            targetView = 'hybrid';
        }
        if (app.route === 'results/images' && urlParams !== '?fromLeadMaps=true') {
            this.imageQueryStore.dispatch(new ImageQueryActions.SetFromLeadMap(false));
        }
        if (app.route === 'workspaces') {
            this.router.navigateByUrl("" + app.route);
        }
        else {
            if (urlParams) {
                this.router.navigateByUrl("/geobrain/" + targetView + "/" + app.route + urlParams);
            }
            else {
                this.router.navigateByUrl("/geobrain/" + targetView + "/" + app.route);
            }
        }
    };
    GeobrainLoaderService.prototype.activateItemByUrl = function (url) {
        var _this = this;
        this.config.apps.forEach(function (app) {
            if (url.includes(app.route)) {
                _this.activeApp = app.title;
            }
        });
    };
    GeobrainLoaderService.prototype.activateAppById = function (id, view, urlParams) {
        var _this = this;
        this.config.apps.forEach(function (app) {
            if (app.name === id) {
                if (view && ['map', 'list', 'hybrid'].includes(view)) {
                    _this.currentView = view;
                }
                if (urlParams) {
                    _this.activateApp(app, urlParams);
                }
                else {
                    _this.activateApp(app);
                }
            }
        });
    };
    GeobrainLoaderService.prototype.activateAppFromPreferences = function (resultView) {
        // if result view contains a valid view type and a valid app config go to it, otherwise go to summary
        if (new RegExp(['map', 'list', 'hybrid'].join('|')).test(resultView) &&
            new RegExp(this.config.apps.map(function (app) { return app.route; }).join('|')).test(resultView)) {
            this.router.navigateByUrl("/geobrain/" + resultView);
            if (resultView.startsWith('map/')) {
                this.currentView = 'map';
            }
            else if (resultView.startsWith('list/')) {
                this.currentView = 'list';
            }
            else if (resultView.startsWith('hybrid/')) {
                this.currentView = 'hybrid';
            }
        }
        else {
            this.router.navigateByUrl('/geobrain/hybrid/summary');
            this.currentView = 'hybrid';
        }
    };
    GeobrainLoaderService.ngInjectableDef = i0.defineInjectable({ factory: function GeobrainLoaderService_Factory() { return new GeobrainLoaderService(i0.inject(i1.AppConfigService), i0.inject(i2.ActivatedRoute), i0.inject(i2.Router), i0.inject(i3.Store)); }, token: GeobrainLoaderService, providedIn: "root" });
    return GeobrainLoaderService;
}());
export { GeobrainLoaderService };
