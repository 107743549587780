import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Preference, User, Workspace } from '@shared/models/UserAPI.model';
import { UserActions } from '@shared/actions';
import { Store } from '@ngrx/store';
import * as fromShared from '@shared/reducers';
import { take } from 'rxjs/operators';
import { AppConfig, AppConfigService } from '@app/app-config.service';
import { PromoteWSAPIService } from '@shared/services/promoteWS.service';

@Injectable({
  providedIn: 'root',
})
export class UserAPIService {
  private _config: AppConfig;

  constructor(
    private http: HttpClient,
    private _appConfig: AppConfigService,
    private sharedStore: Store<fromShared.State>,
    private promoteService: PromoteWSAPIService
  ) {
    this._config = this._appConfig.getConfig();
    this.loadUser();
  }

  loadUser() {
    this.getUser().pipe(
      take(1)
    ).subscribe(res => {
      // If the User is Promoter then load the categories to promote
      if (res.isPromoter) {
          this.promoteService.getWorkSpaceCategories().pipe(take(1)).subscribe((categories) => {
              this.sharedStore.dispatch(new UserActions.SetPromoterCategories(categories));
          });
      }
      this.sharedStore.dispatch(new UserActions.LoadUser(res));
      this.getPreferences().pipe(
        take(1)
      ).subscribe(prefs => {
        this.sharedStore.dispatch(new UserActions.LoadPreferences(prefs.preferences));
      });
    });
  }

  getUser(): Observable<any> {
    return this.http.get<any>(`${this._config.user_api_baseurl}/user`);
    // return this.http.get<User>('/assets/data/user-sample-response.json');
  }
  updateUser(user: User) {
    return this.http.put<User>(`${this._config.user_api_baseurl}/user`, user);
  }
  getPreferences(): Observable<any> {
    return this.http.get<any>(`${this._config.user_api_baseurl}/preferences`);
    // return this.http.get<any>('/assets/data/preferences-sample-response.json');
  }
  updatePreferences(preferences: Preference) {
    return this.http.put<Preference>(`${this._config.user_api_baseurl}/preferences`, { preferences: preferences });
  }
  // available messages
  getAvailableMessages(): Observable<any> {
    return this.http.get<any>(`${this._config.user_api_baseurl}/messages`);
    // return this.http.get<any>('/assets/data/landing-messages-sample-response.json');
  }

  setFavoriteWorkspace(workspaceId): Observable<any> {
    return this.http.put<any>(`${this._config.user_api_baseurl}/preferences/workspace/${workspaceId}/favorite`, {});
    // return this.http.get<any>('/assets/data/preferences-sample-response.json');
  }

  setUnfavoriteWorkspace(workspaceId): Observable<any> {
    return this.http.put<any>(`${this._config.user_api_baseurl}/preferences/workspace/${workspaceId}/unFavorite`, {});
    // return this.http.get<any>('/assets/data/preferences-sample-response.json');
  }
}
