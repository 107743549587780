import { SnackbarActions } from '../actions';

export interface State {
  show: boolean;
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export const initialState: State = { show: false };

export function reducer(
  state = initialState,
  action: SnackbarActions.SnackbarActionsUnion
): State {
  switch (action.type) {
    case SnackbarActions.SnackbarActionTypes.Open:
      return {
        ...state,
        show: true
      };
      case SnackbarActions.SnackbarActionTypes.Close:
      return {
        ...state,
        show: false
      };
    default: {
      return state;
    }
  }
}
