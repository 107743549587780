import { GeosentimentActions } from '@shared/actions';
import { Document } from '../models/SearchAPI.model';
import { Mention } from '@app/modules/geosentiment/models/mention';

export interface State {
    originalGraphData: any;
    processedGraphData: any;
    originalMentionsData: Mention[];
    documentsData: Document[];
    indexesToFilterAssociations: number[];
    responseTime: number;
}

export const initialState: State = {
    originalGraphData: undefined,
    processedGraphData: undefined,
    originalMentionsData: undefined,
    documentsData: [],
    indexesToFilterAssociations: [-1],
    responseTime: 0
};

export function reducer(
  state = initialState,
  action: GeosentimentActions.GeosentimentActionsUnion
): State {
  switch (action.type) {
    case GeosentimentActions.GeosentimentActionTypes.SaveInitialData:
      return {
        ...state,
        originalGraphData: action.payload.graph,
        originalMentionsData: action.payload.mentions,
        processedGraphData: action.payload.graph
      };
    case GeosentimentActions.GeosentimentActionTypes.SaveDocumentsData:
      return {
        ...state,
        documentsData: action.payload
      };
    case GeosentimentActions.GeosentimentActionTypes.SaveGraphProcessedData:
      return {
        ...state,
        processedGraphData: action.payload
      };
    case GeosentimentActions.GeosentimentActionTypes.SetIndexesSelected:
      return {
        ...state,
        indexesToFilterAssociations: action.payload
      };
    case GeosentimentActions.GeosentimentActionTypes.SetResponseTime:
      return {
        ...state,
        responseTime: action.payload
      };
    default: {
      return state;
    }
  }
}
