import { Action } from '@ngrx/store';
import { SeisbookAPIResponse, SeisbookAPIRequest } from '@shared/models/SeisbookAPI.model';


export const SEARCH_SEISBOOK = '[Seisbook] Search Seisbook';
export const SEARCH_SEISBOOK_SUCCESS = '[Seisbook] Search Seisbook Success';
export const SEARCH_SEISBOOK_FAIL = '[Seisbook] Search Seisbook Failure';
export const SEARCH_SEISBOOK_NO_ACCESS = '[Seisbook] Search Seisbook No Access';
export const SEARCH_SEISBOOK_AUTHORIZATION_FAILURE = '[Seisbook] Search Seisbook Authorization Failure';
export const SEARCH_SEISBOOK_EMPTY = '[Seisbook] Search Empty';

export class SearchSeisbook implements Action {
  readonly type = SEARCH_SEISBOOK;

  constructor(public payload: SeisbookAPIRequest) {}
}

export class SearchSeisbookSuccess implements Action {
  readonly type = SEARCH_SEISBOOK_SUCCESS;

  constructor(public payload: SeisbookAPIResponse) {}
}

export class SearchSeisbookFailure implements Action {
  readonly type = SEARCH_SEISBOOK_FAIL;

  constructor(public payload: any) {}
}

export class SearchSeisbookNoAccess implements Action {
  readonly type = SEARCH_SEISBOOK_NO_ACCESS;

  constructor(public payload: any) {}
}

export class SearchSeisbookAuthorizationFailure implements Action {
  readonly type = SEARCH_SEISBOOK_AUTHORIZATION_FAILURE;

  constructor(public payload: any) {}
}

export class SearchSeisbookEmpty implements Action {
  readonly type = SEARCH_SEISBOOK_EMPTY;
}


export type SeisbookSearchActionsUnion =
  SearchSeisbook |
  SearchSeisbookSuccess |
  SearchSeisbookFailure |
  SearchSeisbookNoAccess |
  SearchSeisbookAuthorizationFailure |
  SearchSeisbookEmpty |
  SearchSeisbook;
