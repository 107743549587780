import { OnDestroy } from '@angular/core';
import { environment } from '@environments/environment';
import { Store, select } from '@ngrx/store';
import * as fromShared from '@shared/reducers';
import { FeedbackActions, UserActions } from '@app/shared/actions';
import { MessageService, CHANNELS, OPERATIONS } from '@app/shared/services/message.service';
import { ContentService } from '@shared/services/content.service';
var SharedModalsComponent = /** @class */ (function () {
    function SharedModalsComponent(sharedStore, globalContent, messages) {
        var _this = this;
        this.sharedStore = sharedStore;
        this.globalContent = globalContent;
        this.messages = messages;
        this.version = environment.VERSION;
        this.showStaticOptions = false;
        this.showDynamicOptions = false;
        this.count = 0;
        this.maxCount = this.globalContent.global.FTUE.STATIC_OPTIONS.length - 1;
        this.ESC_KEY_CODE = this.globalContent.global.ESC_KEY_CODE;
        this.wsLoadingScreen = false;
        this.promoteModal = false;
        // Queries
        this.activeWSLoading$ = this.sharedStore.pipe(select(fromShared.getHydrationStarted));
        this.activeModal$ = this.sharedStore.pipe(select(fromShared.getActiveModal));
        this.activeModal$.pipe().subscribe(function (modal) {
            if (modal === 'tutorial-apps') {
                _this.openDynamicOptions();
                _this.sharedStore.dispatch(new UserActions.SetActiveModal(''));
            }
        });
        this.activeWSLoadingSubscription$ = this.activeWSLoading$.subscribe(function (ws) {
            _this.wsLoadingScreen = ws;
        });
        this.promoteChannelMessgageSubscription$ = messages.getMessage(CHANNELS.Promote).subscribe(function (msg) {
            if (msg && msg.operation && msg.operation === OPERATIONS.Show) {
                _this.promoteModal = true;
                if (msg.data && msg.data.workspaceName && msg.data.workspaceCategory && msg.data.workspaceName && msg.data.workspaceId) {
                    _this.workspaceName = msg.data.workspaceName;
                    _this.workspaceCategory = msg.data.workspaceCategory;
                    _this.workspaceId = msg.data.workspaceId;
                    _this.workspaceDescription = msg.data.workspaceDescription;
                }
            }
        });
    }
    SharedModalsComponent.prototype.ngOnDestroy = function () {
        this.activeWSLoadingSubscription$.unsubscribe();
        this.promoteChannelMessgageSubscription$.unsubscribe();
    };
    SharedModalsComponent.prototype.submitFileList = function (fileList) {
        this.sharedStore.dispatch(new FeedbackActions.PostFeedbackAttachments(fileList));
    };
    SharedModalsComponent.prototype.submitFeedback = function (feedback) {
        this.sharedStore.dispatch(new FeedbackActions.PostFeedback(JSON.stringify({ '__metadata': { 'type': 'SP.Data.GeoBrainAppFeedbackListItem' },
            Title: feedback.subject,
            Description: feedback.description,
            Category: feedback.category,
            cosx: this.version,
            URL: feedback.URL
        })));
        this.closeModal();
    };
    SharedModalsComponent.prototype.closeModal = function () {
        this.sharedStore.dispatch(new UserActions.SetActiveModal(''));
    };
    SharedModalsComponent.prototype.openStaticOptions = function () {
        this.closeModal();
        this.showStaticOptions = true;
    };
    SharedModalsComponent.prototype.backToWelcome = function () {
        this.count = 0;
        this.showStaticOptions = false;
        this.sharedStore.dispatch(new UserActions.SetActiveModal('tutorial'));
    };
    SharedModalsComponent.prototype.closeStaticOptions = function () {
        this.showStaticOptions = false;
    };
    SharedModalsComponent.prototype.openDynamicOptions = function () {
        this.showStaticOptions = false;
        this.showDynamicOptions = true;
    };
    SharedModalsComponent.prototype.closeDynamicOptions = function () {
        this.count = 0;
        this.showDynamicOptions = false;
    };
    SharedModalsComponent.prototype.backToStaticOptions = function () {
        this.showStaticOptions = true;
        this.showDynamicOptions = false;
        this.count = this.maxCount;
    };
    SharedModalsComponent.prototype.closePromoteModal = function () {
        this.promoteModal = false;
    };
    SharedModalsComponent.prototype.onKeyDown = function (event) {
        if (event.keyCode === this.ESC_KEY_CODE) {
            if (this.showStaticOptions) {
                this.closeStaticOptions();
            }
            else {
                if (this.showDynamicOptions) {
                    this.closeDynamicOptions();
                }
                else {
                    this.closeModal();
                }
            }
        }
    };
    return SharedModalsComponent;
}());
export { SharedModalsComponent };
