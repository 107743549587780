<xom-modal-layout>
  <div modal-content>
    <div class="row middle-xs ftue-container">
      <div class="col-xs-12 close-container no-gutter">
        <span (click)="closeStaticOptions.emit()" class="close icon-close increase-icon"></span>
      </div>
      <div class="col-xs-12 ftue-content">
        <div class="title-section">
          <img class="title-icon" *ngIf="selectedOption.icon" src="/assets/icons/{{selectedOption.icon}}.svg" />
          <h1 class="title">{{selectedOption.title}}</h1>
        </div>
        <p class="message" [innerHtml]="selectedOption.description"> </p>
        <div class="image-section">
          <img src="/assets/images/ftue/{{selectedOption.image}}" />
        </div>
        <div class="options">
          <button xom-button class="back-button" (click)="back()">{{options[0]}}</button>
          <button xom-button (click)="next()">{{options[1]}}</button>
        </div>
      </div>
    </div>
  </div>
</xom-modal-layout>