export var LeadMapActionTypes;
(function (LeadMapActionTypes) {
    LeadMapActionTypes["Basin"] = "[Lead Map] Basin";
    LeadMapActionTypes["Country"] = "[Lead Map] Country";
})(LeadMapActionTypes || (LeadMapActionTypes = {}));
var Basin = /** @class */ (function () {
    function Basin(payload) {
        this.payload = payload;
        this.type = LeadMapActionTypes.Basin;
    }
    return Basin;
}());
export { Basin };
var Country = /** @class */ (function () {
    function Country(payload) {
        this.payload = payload;
        this.type = LeadMapActionTypes.Country;
    }
    return Country;
}());
export { Country };
