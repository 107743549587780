/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./xom-ftue-static.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../layouts/modal-layout/modal-layout.component.ngfactory";
import * as i3 from "../../layouts/modal-layout/modal-layout.component";
import * as i4 from "@angular/common";
import * as i5 from "../xom-button/xom-button.component.ngfactory";
import * as i6 from "../xom-button/xom-button.component";
import * as i7 from "./xom-ftue-static.component";
import * as i8 from "../../../app-config.service";
import * as i9 from "../../services/content.service";
var styles_XomFTUEStaticComponent = [i0.styles];
var RenderType_XomFTUEStaticComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_XomFTUEStaticComponent, data: {} });
export { RenderType_XomFTUEStaticComponent as RenderType_XomFTUEStaticComponent };
function View_XomFTUEStaticComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "title-icon"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "/assets/icons/", _co.selectedOption.icon, ".svg"); _ck(_v, 0, 0, currVal_0); }); }
export function View_XomFTUEStaticComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "xom-modal-layout", [], null, null, null, i2.View_ModalLayoutComponent_0, i2.RenderType_ModalLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModalLayoutComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 2, 19, "div", [["modal-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 18, "div", [["class", "row middle-xs ftue-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "col-xs-12 close-container no-gutter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "close icon-close increase-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeStaticOptions.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 15, "div", [["class", "col-xs-12 ftue-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "title-section"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_XomFTUEStaticComponent_1)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h1", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "p", [["class", "message"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "image-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 6, "div", [["class", "options"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "back-button"], ["xom-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_XomButtonComponent_0, i5.RenderType_XomButtonComponent)), i1.ɵdid(17, 49152, null, 0, i6.XomButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(18, 0, ["", ""])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["xom-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_XomButtonComponent_0, i5.RenderType_XomButtonComponent)), i1.ɵdid(20, 49152, null, 0, i6.XomButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(21, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.selectedOption.icon; _ck(_v, 9, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.selectedOption.title; _ck(_v, 11, 0, currVal_1); var currVal_2 = _co.selectedOption.description; _ck(_v, 12, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "/assets/images/ftue/", _co.selectedOption.image, ""); _ck(_v, 14, 0, currVal_3); var currVal_4 = _co.options[0]; _ck(_v, 18, 0, currVal_4); var currVal_5 = _co.options[1]; _ck(_v, 21, 0, currVal_5); }); }
export function View_XomFTUEStaticComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xom-ftue-static", [], null, null, null, View_XomFTUEStaticComponent_0, RenderType_XomFTUEStaticComponent)), i1.ɵdid(1, 114688, null, 0, i7.XomFTUEStaticComponent, [i8.AppConfigService, i9.ContentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var XomFTUEStaticComponentNgFactory = i1.ɵccf("xom-ftue-static", i7.XomFTUEStaticComponent, View_XomFTUEStaticComponent_Host_0, { count: "count" }, { closeStaticOptions: "closeStaticOptions", backToWelcome: "backToWelcome", openDynamicOptions: "openDynamicOptions" }, []);
export { XomFTUEStaticComponentNgFactory as XomFTUEStaticComponentNgFactory };
