import { Action } from '@ngrx/store';

export enum ToolboxActionTypes {
    Toolbox_Toggle = '[Toolbox] Toggle Success'
}

export class ToolboxToggle implements Action {
    readonly type = ToolboxActionTypes.Toolbox_Toggle;
    constructor(public payload: Boolean) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type ToolboxActionsUnion = ToolboxToggle;
