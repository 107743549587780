import { console } from '@app/shared/util/console.util';
var APIErrorHandler = /** @class */ (function () {
    function APIErrorHandler() {
    }
    APIErrorHandler.prototype.handleError = function (err) {
        console.error(err);
    };
    return APIErrorHandler;
}());
export { APIErrorHandler };
