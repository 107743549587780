import { Component, HostListener, OnDestroy } from '@angular/core';
import {environment} from '@environments/environment';
import { Store, select } from '@ngrx/store';
import * as fromShared from '@shared/reducers';
import { FeedbackActions, UserActions } from '@app/shared/actions';
import { Subscription } from 'rxjs';
import { MessageService, CHANNELS, OPERATIONS } from '@app/shared/services/message.service';
import { ContentService } from '@shared/services/content.service';
import { GlobalContent } from '@shared/shared-content';
import { Observable } from 'rxjs';
import { Effect } from '@ngrx/effects';

@Component({
  selector: 'xom-shared-modals',
  templateUrl: './shared-modals.component.html',
  styleUrls: ['./shared-modals.component.scss']
})
export class SharedModalsComponent implements OnDestroy {

  version: string = environment.VERSION;
  activeModal$: Observable<string>;
  showStaticOptions = false;
  showDynamicOptions = false;
  count = 0;
  maxCount = this.globalContent.global.FTUE.STATIC_OPTIONS.length - 1;
  ESC_KEY_CODE = this.globalContent.global.ESC_KEY_CODE;
  wsLoadingScreen = false;
  promoteModal = false;
  workspaceName: string;
  workspaceCategory: string;
  workspaceId: string;
  workspaceDescription: string;
  // Queries
  activeWSLoading$ = this.sharedStore.pipe(select(fromShared.getHydrationStarted));
  // Subscriptions
  activeWSLoadingSubscription$: Subscription;
  promoteChannelMessgageSubscription$: Subscription;

  constructor(
    private sharedStore: Store<fromShared.State>,
    public globalContent: ContentService<GlobalContent>,
    public messages: MessageService
  ) {
    this.activeModal$ = this.sharedStore.pipe(select(fromShared.getActiveModal));
    this.activeModal$.pipe().subscribe(modal => {
      if (modal === 'tutorial-apps') {
        this.openDynamicOptions();
        this.sharedStore.dispatch(new UserActions.SetActiveModal(''));
      }
    });

    this.activeWSLoadingSubscription$ = this.activeWSLoading$.subscribe((ws) => {
      this.wsLoadingScreen = ws;
    });

    this.promoteChannelMessgageSubscription$ = messages.getMessage(CHANNELS.Promote).subscribe((msg) => {
        if (msg && msg.operation && msg.operation === OPERATIONS.Show) {
            this.promoteModal = true;
            if (msg.data && msg.data.workspaceName && msg.data.workspaceCategory && msg.data.workspaceName && msg.data.workspaceId) {
                this.workspaceName = msg.data.workspaceName;
                this.workspaceCategory = msg.data.workspaceCategory;
                this.workspaceId = msg.data.workspaceId;
                this.workspaceDescription = msg.data.workspaceDescription;
            }
        }
    });
  }

  ngOnDestroy() {
      this.activeWSLoadingSubscription$.unsubscribe();
      this.promoteChannelMessgageSubscription$.unsubscribe();
  }

  submitFileList(fileList) {
    this.sharedStore.dispatch(new FeedbackActions.PostFeedbackAttachments(fileList));
  }

  submitFeedback(feedback) {
    this.sharedStore.dispatch(new FeedbackActions.PostFeedback(
      JSON.stringify({ '__metadata': { 'type': 'SP.Data.GeoBrainAppFeedbackListItem' },
        Title: feedback.subject,
        Description: feedback.description,
        Category: feedback.category,
        cosx: this.version,
        URL: feedback.URL
      })
    ));
    this.closeModal();
  }

  closeModal() {
    this.sharedStore.dispatch(new UserActions.SetActiveModal(''));
  }

  openStaticOptions() {
    this.closeModal();
    this.showStaticOptions = true;
  }

  backToWelcome() {
    this.count = 0;
    this.showStaticOptions = false;
    this.sharedStore.dispatch(new UserActions.SetActiveModal('tutorial'));
  }

  closeStaticOptions() {
    this.showStaticOptions = false;
  }

  openDynamicOptions() {
    this.showStaticOptions = false;
    this.showDynamicOptions = true;
  }

  closeDynamicOptions() {
    this.count = 0;
    this.showDynamicOptions = false;
  }

  backToStaticOptions() {
    this.showStaticOptions = true;
    this.showDynamicOptions = false;
    this.count = this.maxCount;
  }

  closePromoteModal() {
      this.promoteModal = false;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === this.ESC_KEY_CODE) {
      if (this.showStaticOptions) {
        this.closeStaticOptions();
      } else {
        if (this.showDynamicOptions) {
          this.closeDynamicOptions();
        } else {
          this.closeModal();
        }
      }
    }
  }
}
