import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER  } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from './reducers';
import { GestureConfig } from '@angular/material';
import { reducers as awarenessReducers } from '@awareness/reducers/index';
import { reducers as askbobReducers } from '@ask-bob/reducers/index';
import { reducers as interrogatorReducers } from '@app/modules/play-interrogator/reducers/index';

// http, api, error handling
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APIInterceptor } from './shared/api/api.intercepter';
import { ApiSearchTimeInterceptor } from './modules/search/api/api-search-time.interceptor';
import { APIErrorHandler } from './shared/api/api-error-handler.service';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import { XomSharedModule } from './shared/shared.module';

// routing
import { AppRoutingModule } from './app-routing.module';

// services
import { AppConfigService, AppConfig } from './app-config.service';
import { ContentService } from '@shared/services/content.service';
import { GlobalContent } from '@shared/shared-content';
import { SnackbarEffects } from '@shared/effects/snackbar.effects';
import { UserEffects } from '@shared/effects/user.effects';
import { WorkspacesEffects } from '@shared/effects/workspaces.effects';
import { InsightsService } from '@app/service/insights-service/insights.service';
import { CollectionsEffects } from '@shared/effects/collections.effect';
import { provideBootstrapEffects } from './bootstrap-effects';

import { Configuration } from 'msal';
import { MsalModule, MsalInterceptor, MsalAngularConfiguration, MsalService, MSAL_CONFIG, MSAL_CONFIG_ANGULAR } from '@azure/msal-angular';
import { AppGlobals } from '@environments/app.global';
import { FeedbackEffects } from './shared/effects/feedback.effects';
import { GeosentimentEffects } from './shared/effects/geosentiment.effects';
import { SeisbookSearchEffects } from './modules/seisbook/';
import { HydrationEffects } from './shared/effects/hydration.effects';

// See: https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/msal-angular-v1/lib/msal-angular#internet-explorer-support
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function init_app(appConfigSvc: AppConfigService) {
  return () => appConfigSvc.loadAppConfig();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreModule.forFeature('interrogator', interrogatorReducers),
    StoreModule.forFeature('awareness', awarenessReducers),
    StoreModule.forFeature('askbob', askbobReducers),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([]),
    XomSharedModule.forRoot(),
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    MsalModule
  ],
  providers: [
    AppConfigService,
    MsalService,
    {
      provide: MSAL_CONFIG,
      useFactory: (appConfigSvc: AppConfigService): Configuration => {
        const appConfig = appConfigSvc.getConfig();

        return {
          auth: {
            clientId: appConfig.msalConfig.clientId,
            authority: appConfig.msalConfig.authority,
            validateAuthority: true,
            redirectUri: window.location.origin,
            postLogoutRedirectUri: window.location.origin,
            navigateToLoginRequestUrl: true,
          },
          cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: isIE, // set to true for IE 11
          },
          system: {
            // See: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1592#issuecomment-725777269
            loadFrameTimeout: 30000
          }
        };
      },
      deps: [AppConfigService]
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: (appConfigSvc: AppConfigService): MsalAngularConfiguration => {
        const appConfig = appConfigSvc.getConfig();

        return {
          popUp: false,
          consentScopes: [ appConfig.msalConfig.geobrain_scope, appConfig.msalConfig.geobook_scope ],
          protectedResourceMap: [
            [appConfig.user_api_baseurl, [appConfig.msalConfig.geobrain_scope]],
            [appConfig.seisbook_api_baseurl, [appConfig.msalConfig.geobook_scope]],
            [appConfig.datascience_api_baseurl, null], [appConfig.geoBertAPIUrl, null]
          ],
          extraQueryParameters: {
            domain_hint: 'exxonmobil.com'
          }
        };
      },
      deps: [AppConfigService]
    },
    { provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppConfigService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiSearchTimeInterceptor,
      multi: true
    },
    APIErrorHandler,
    InsightsService,
    {
      provide : ContentService,
      useFactory: () => new ContentService<GlobalContent>(new GlobalContent)
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: GestureConfig
    },
    AppGlobals,
    provideBootstrapEffects([
      SnackbarEffects,
      UserEffects,
      WorkspacesEffects,
      CollectionsEffects,
      FeedbackEffects,
      GeosentimentEffects,
      SeisbookSearchEffects,
      HydrationEffects])
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule { }
