var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { delay, map, tap } from 'rxjs/operators';
import { SnackbarActions } from '../actions';
import { MatSnackBar } from '@angular/material';
/**
 * Effects offer a way to isolate and easily test side-effects within your
 * application.
 *
 * If you are unfamiliar with the operators being used in these examples, please
 * check out the sources below:
 *
 * Official Docs: http://reactivex.io/rxjs/manual/overview.html#categories-of-operators
 * RxJS 5 Operators By Example: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35
 */
var SnackbarEffects = /** @class */ (function () {
    function SnackbarEffects(actions$, matSnackBar) {
        var _this = this;
        this.actions$ = actions$;
        this.matSnackBar = matSnackBar;
        this.closeSnackbar$ = function () {
            return _this.actions$.pipe(ofType(SnackbarActions.SnackbarActionTypes.Close), tap(function () { return _this.matSnackBar.dismiss(); }));
        };
        this.showSnackbar$ = function () {
            return _this.actions$.pipe(ofType(SnackbarActions.SnackbarActionTypes.Open), map(function (action) { return action.payload; }), tap(function (payload) { return _this.matSnackBar.open(payload.message, payload.action, payload.config); }), delay(30000), tap(function (payload) {
                if (payload.type !== 'xom-snackbar-fullscreen-error') {
                    _this.matSnackBar.dismiss();
                }
            }), map(function () { return new SnackbarActions.OpenSuccess(); }));
        };
    }
    __decorate([
        Effect({
            dispatch: false
        }),
        __metadata("design:type", Object)
    ], SnackbarEffects.prototype, "closeSnackbar$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SnackbarEffects.prototype, "showSnackbar$", void 0);
    return SnackbarEffects;
}());
export { SnackbarEffects };
