import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'xom-admin-message',
  templateUrl: './xom-admin-message.component.html',
  styleUrls: ['./xom-admin-message.component.scss']
})
export class XomAdminMessageComponent {

  @Input() title: string;
  @Input() message: string;
  @Output() close: EventEmitter<any> = new EventEmitter(null);

}
