import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { XomButtonComponent } from './xom-button.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [XomButtonComponent],
  exports: [XomButtonComponent]
})
export class XomButtonModule { }
