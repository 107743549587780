import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromShared from '@shared/reducers';
import { AppConfig, AppConfigService } from '@app/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class PromoteWSAPIService {
  private _config: AppConfig;

  constructor(
    private http: HttpClient,
    private _appConfig: AppConfigService
  ) {
    this._config = this._appConfig.getConfig();
  }

  promote(workspaceId: string, category: string) {
    return this.http.put<any>(`${this._config.user_api_baseurl}/workspace/Promote/${workspaceId}`, {
        category: category
    });
  }

  demote(workspaceId: string, category: string) {
    return this.http.put<any>(`${this._config.user_api_baseurl}/workspace/Demote/${workspaceId}`, {});
  }

  getWorkSpaceCategories(): Observable<any> {
    return this.http.get<any>(`${this._config.user_api_baseurl}/workspaceCategories`);
    // return this.http.get<any>('/assets/data/workspace_categories.json');
  }

}
