export var ImageQueryActionTypes;
(function (ImageQueryActionTypes) {
    ImageQueryActionTypes["ImageResultsSorting"] = "[Search Image] Search Results Sorting";
    ImageQueryActionTypes["ImageResultsPageSize"] = "[Search Image] Search Results Page Size";
    ImageQueryActionTypes["ImageResultsStartIndex"] = "[Search Image] Search Results Start Index";
    ImageQueryActionTypes["SetImageClassificationTypes"] = "[Search Image] Set Classification Types";
    ImageQueryActionTypes["SetImageCountries"] = "[Search Image] Set Image Countries";
    ImageQueryActionTypes["SetFromLeadMap"] = "[Search Image] Set From Lead map";
    ImageQueryActionTypes["ImageResultsPageSizeLeadMap"] = "[Search Image] Search Results Page Size From Lead Map";
    ImageQueryActionTypes["ImageResultsStartIndexLeadMap"] = "[Search Image] Search Results Start Index From Lead Map";
})(ImageQueryActionTypes || (ImageQueryActionTypes = {}));
var ImageResultsSorting = /** @class */ (function () {
    function ImageResultsSorting(payload) {
        this.payload = payload;
        this.type = ImageQueryActionTypes.ImageResultsSorting;
    }
    return ImageResultsSorting;
}());
export { ImageResultsSorting };
var ImageResultsPageSize = /** @class */ (function () {
    function ImageResultsPageSize(payload) {
        this.payload = payload;
        this.type = ImageQueryActionTypes.ImageResultsPageSize;
    }
    return ImageResultsPageSize;
}());
export { ImageResultsPageSize };
var ImageResultsStartIndex = /** @class */ (function () {
    function ImageResultsStartIndex(payload) {
        this.payload = payload;
        this.type = ImageQueryActionTypes.ImageResultsStartIndex;
    }
    return ImageResultsStartIndex;
}());
export { ImageResultsStartIndex };
var ImageResultsPageSizeLeadMap = /** @class */ (function () {
    function ImageResultsPageSizeLeadMap(payload) {
        this.payload = payload;
        this.type = ImageQueryActionTypes.ImageResultsPageSizeLeadMap;
    }
    return ImageResultsPageSizeLeadMap;
}());
export { ImageResultsPageSizeLeadMap };
var ImageResultsStartIndexLeadMap = /** @class */ (function () {
    function ImageResultsStartIndexLeadMap(payload) {
        this.payload = payload;
        this.type = ImageQueryActionTypes.ImageResultsStartIndexLeadMap;
    }
    return ImageResultsStartIndexLeadMap;
}());
export { ImageResultsStartIndexLeadMap };
var SetImageClassificationTypes = /** @class */ (function () {
    function SetImageClassificationTypes(payload) {
        this.payload = payload;
        this.type = ImageQueryActionTypes.SetImageClassificationTypes;
    }
    return SetImageClassificationTypes;
}());
export { SetImageClassificationTypes };
var SetImageCountries = /** @class */ (function () {
    function SetImageCountries(payload) {
        this.payload = payload;
        this.type = ImageQueryActionTypes.SetImageCountries;
    }
    return SetImageCountries;
}());
export { SetImageCountries };
var SetFromLeadMap = /** @class */ (function () {
    function SetFromLeadMap(payload) {
        this.payload = payload;
        this.type = ImageQueryActionTypes.SetFromLeadMap;
    }
    return SetFromLeadMap;
}());
export { SetFromLeadMap };
