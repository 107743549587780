var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HydrationActions } from '../actions';
export var initialState = {
    hydrationStarted: false,
    hydrationCompleted: false,
    step_hydrationSearchResetCompleted: false,
    step_documentFacetsRemovalCompleted: false,
    step_searchCompleted: false,
    step_searchDocumentSuccessCompleted: false,
    step_facetsSearchCompleted: false,
    step_mapServersCompleted: false,
    mapServersTotal: 0,
    mapServersCounter: 0
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case HydrationActions.HydrationActionTypes.ResetHydration:
            return __assign({}, state, { hydrationStarted: false, hydrationCompleted: false, step_hydrationSearchResetCompleted: false, step_documentFacetsRemovalCompleted: false, step_searchCompleted: false, step_mapServersCompleted: false, step_searchDocumentSuccessCompleted: false, step_facetsSearchCompleted: false, mapServersCounter: 0 });
        case HydrationActions.HydrationActionTypes.StartHydration:
            var mapServesHydrationCompleted = false;
            if (!action.payload.mapServersTotal) {
                mapServesHydrationCompleted = true;
            }
            return __assign({}, state, { hydrationStarted: true, step_mapServersCompleted: mapServesHydrationCompleted, mapServersTotal: action.payload.mapServersTotal });
        case HydrationActions.HydrationActionTypes.HydrationSearchResetCompleted:
            return __assign({}, state, { step_hydrationSearchResetCompleted: true });
        case HydrationActions.HydrationActionTypes.DocumentFacetsRemovalCompleted:
            return __assign({}, state, { step_documentFacetsRemovalCompleted: true });
        case HydrationActions.HydrationActionTypes.SearchCompleted:
            return __assign({}, state, { step_searchCompleted: true });
        case HydrationActions.HydrationActionTypes.SearchDocumentSuccessCompleted:
            return __assign({}, state, { step_searchDocumentSuccessCompleted: true });
        case HydrationActions.HydrationActionTypes.FacetsSearchCompleted:
            return __assign({}, state, { step_facetsSearchCompleted: true });
        case HydrationActions.HydrationActionTypes.MapServerHydrationCompleted:
            var mapServersCounter = state.mapServersCounter;
            var mapServersCompleted = false;
            mapServersCounter += 1;
            if (state.step_mapServersCompleted) {
                mapServersCompleted = true;
            }
            else {
                if (mapServersCounter === state.mapServersTotal) {
                    mapServersCompleted = true;
                }
            }
            return __assign({}, state, { mapServersCounter: mapServersCounter, step_mapServersCompleted: mapServersCompleted });
        case HydrationActions.HydrationActionTypes.HydrationStateVerification:
            var propertyCounter = 0;
            var completedStepCounter = 0;
            for (var propt in state) {
                if (propt.startsWith('step_')) {
                    propertyCounter += 1;
                    if (state[propt]) {
                        completedStepCounter += 1;
                    }
                }
            }
            if (completedStepCounter === propertyCounter && state.hydrationStarted) {
                return __assign({}, state, { hydrationStarted: false, hydrationCompleted: true });
            }
            else {
                return __assign({}, state);
            }
        case HydrationActions.HydrationActionTypes.SetHydrationToInitialState:
            return __assign({}, state, { hydrationStarted: false, hydrationCompleted: false, step_hydrationSearchResetCompleted: false, step_documentFacetsRemovalCompleted: false, step_searchCompleted: false, step_searchDocumentSuccessCompleted: false, step_facetsSearchCompleted: false, step_mapServersCompleted: false });
        default: {
            return state;
        }
    }
}
