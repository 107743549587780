export var MapTableActionTypes;
(function (MapTableActionTypes) {
    MapTableActionTypes["LoadMapTableLayerByUrl"] = "[Map Table] Load Table Layer By Url";
    MapTableActionTypes["LoadMapTableLayer"] = "[Map Table] Load Table Layer";
    MapTableActionTypes["LoadLayerFeaturesSuccess"] = "[Map Table] Load Layer Features Success";
    MapTableActionTypes["LoadLayerFeaturesFailure"] = "[Map Table] Load Layer Features Failure";
    MapTableActionTypes["LoadAllLayerFeatures"] = "[Map Table] Load All Layer Features";
    MapTableActionTypes["LoadAllLayerFeaturesSuccess"] = "[Map Table] Load All Layer Features Success";
    MapTableActionTypes["LoadAllLayerFeaturesFailure"] = "[Map Table] Load All Layer Features Failure";
    MapTableActionTypes["ToggleMapTable"] = "[Map Table] Toggle Map Table";
    MapTableActionTypes["CloseMapTable"] = "[Map Table] Close Map Table";
    MapTableActionTypes["SetSelectedTables"] = "[Map Table] Set Selected Tables";
    MapTableActionTypes["FilterByMapExtent"] = "[Map Table] Filter By Map Extent";
    MapTableActionTypes["SetRowFeatureId"] = "[Map Table] Set Row Feature Id";
    MapTableActionTypes["SetTableOffset"] = "[Map Table] Set Table Offset";
    MapTableActionTypes["SetSortingData"] = "[Map Table] Set Sorting Data";
    MapTableActionTypes["SetActiveRowsOnMap"] = "[Map Table] Set Active Rows On Map";
    MapTableActionTypes["SetActiveRowsFeatures"] = "[Map Table] Set Active Rows Features";
})(MapTableActionTypes || (MapTableActionTypes = {}));
var LoadMapTableLayerByUrl = /** @class */ (function () {
    function LoadMapTableLayerByUrl(payload) {
        this.payload = payload;
        this.type = MapTableActionTypes.LoadMapTableLayerByUrl;
    }
    return LoadMapTableLayerByUrl;
}());
export { LoadMapTableLayerByUrl };
var LoadMapTableLayer = /** @class */ (function () {
    function LoadMapTableLayer(payload) {
        this.payload = payload;
        this.type = MapTableActionTypes.LoadMapTableLayer;
    }
    return LoadMapTableLayer;
}());
export { LoadMapTableLayer };
var LoadLayerFeaturesSuccess = /** @class */ (function () {
    function LoadLayerFeaturesSuccess(payload, count, fields) {
        this.payload = payload;
        this.count = count;
        this.fields = fields;
        this.type = MapTableActionTypes.LoadLayerFeaturesSuccess;
    }
    return LoadLayerFeaturesSuccess;
}());
export { LoadLayerFeaturesSuccess };
var LoadLayerFeaturesFailure = /** @class */ (function () {
    function LoadLayerFeaturesFailure(payload) {
        this.payload = payload;
        this.type = MapTableActionTypes.LoadLayerFeaturesFailure;
    }
    return LoadLayerFeaturesFailure;
}());
export { LoadLayerFeaturesFailure };
var LoadAllLayerFeatures = /** @class */ (function () {
    function LoadAllLayerFeatures(payload) {
        this.payload = payload;
        this.type = MapTableActionTypes.LoadAllLayerFeatures;
    }
    return LoadAllLayerFeatures;
}());
export { LoadAllLayerFeatures };
var LoadAllLayerFeaturesSuccess = /** @class */ (function () {
    function LoadAllLayerFeaturesSuccess(payload) {
        this.payload = payload;
        this.type = MapTableActionTypes.LoadAllLayerFeaturesSuccess;
    }
    return LoadAllLayerFeaturesSuccess;
}());
export { LoadAllLayerFeaturesSuccess };
var LoadAllLayerFeaturesFailure = /** @class */ (function () {
    function LoadAllLayerFeaturesFailure(payload) {
        this.payload = payload;
        this.type = MapTableActionTypes.LoadAllLayerFeaturesFailure;
    }
    return LoadAllLayerFeaturesFailure;
}());
export { LoadAllLayerFeaturesFailure };
var ToggleMapTable = /** @class */ (function () {
    function ToggleMapTable(payload) {
        this.payload = payload;
        this.type = MapTableActionTypes.ToggleMapTable;
    }
    return ToggleMapTable;
}());
export { ToggleMapTable };
var CloseMapTable = /** @class */ (function () {
    function CloseMapTable(payload) {
        this.payload = payload;
        this.type = MapTableActionTypes.CloseMapTable;
    }
    return CloseMapTable;
}());
export { CloseMapTable };
var SetSelectedTables = /** @class */ (function () {
    function SetSelectedTables(payload) {
        this.payload = payload;
        this.type = MapTableActionTypes.SetSelectedTables;
    }
    return SetSelectedTables;
}());
export { SetSelectedTables };
var FilterByMapExtent = /** @class */ (function () {
    function FilterByMapExtent(payload) {
        this.payload = payload;
        this.type = MapTableActionTypes.FilterByMapExtent;
    }
    return FilterByMapExtent;
}());
export { FilterByMapExtent };
var SetRowFeatureId = /** @class */ (function () {
    function SetRowFeatureId(payload) {
        this.payload = payload;
        this.type = MapTableActionTypes.SetRowFeatureId;
    }
    return SetRowFeatureId;
}());
export { SetRowFeatureId };
var SetTableOffset = /** @class */ (function () {
    function SetTableOffset(payload) {
        this.payload = payload;
        this.type = MapTableActionTypes.SetTableOffset;
    }
    return SetTableOffset;
}());
export { SetTableOffset };
var SetSortingData = /** @class */ (function () {
    function SetSortingData(payload) {
        this.payload = payload;
        this.type = MapTableActionTypes.SetSortingData;
    }
    return SetSortingData;
}());
export { SetSortingData };
var SetActiveRowsOnMap = /** @class */ (function () {
    function SetActiveRowsOnMap(payload) {
        this.payload = payload;
        this.type = MapTableActionTypes.SetActiveRowsOnMap;
    }
    return SetActiveRowsOnMap;
}());
export { SetActiveRowsOnMap };
var SetActiveRowsFeatures = /** @class */ (function () {
    function SetActiveRowsFeatures(payload) {
        this.payload = payload;
        this.type = MapTableActionTypes.SetActiveRowsFeatures;
    }
    return SetActiveRowsFeatures;
}());
export { SetActiveRowsFeatures };
