import { Action } from '@ngrx/store';

export enum DocumentQueryActionTypes {
    DocumentResultsSort = '[Search Document] Search Results Sorting',
    DocumentResultsPageSize = '[Search Document] Search Results Page Size',
    DocumentResultsStartIndex = '[Search Document] Search Results Start Index',
    DocumentAddFacets = '[Search Document] Add Facets',
    DocumentAddIndividualFacet = '[Search Document] Add Individual Facets',
    DocumentRemoveIndividualFacet = '[Search Document] Remove Individual Facets',
    DocumentRemoveFacets = '[Search Document] Remove Facets'
}

export class DocumentResultsSort implements Action {
    readonly type = DocumentQueryActionTypes.DocumentResultsSort;

    constructor(public payload: any) {}
}

export class DocumentResultsPageSize implements Action {
    readonly type = DocumentQueryActionTypes.DocumentResultsPageSize;

    constructor(public payload: number) {}
}

export class DocumentResultsStartIndex implements Action {
    readonly type = DocumentQueryActionTypes.DocumentResultsStartIndex;

    constructor(public payload: number) {}
}

export class DocumentAddFacets implements Action {
    readonly type = DocumentQueryActionTypes.DocumentAddFacets;

    constructor(public payload: { [propertyName: string]: string[] } ) {}
}

export class DocumentAddIndividualFacet implements Action {
    readonly type = DocumentQueryActionTypes.DocumentAddIndividualFacet;

    constructor( public payload: {facetType: string, value: string} ) {}
}

export class DocumentRemoveIndividualFacet implements Action {
    readonly type = DocumentQueryActionTypes.DocumentRemoveIndividualFacet;

    constructor( public payload: {facetType: string, value: string} ) {}
}

export class DocumentRemoveFacets implements Action {
    readonly type = DocumentQueryActionTypes.DocumentRemoveFacets;

    constructor( public payload: {} ) {}
}

export type DocumentQueryActionsUnion = DocumentResultsSort
    | DocumentResultsPageSize
    | DocumentResultsStartIndex
    | DocumentAddFacets
    | DocumentAddIndividualFacet
    | DocumentRemoveIndividualFacet
    | DocumentRemoveFacets;
