var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MapAOIActions } from '../actions/index';
/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
// tslint:disable-next-line:max-line-length
export var initialState = {
    aoiLayer: null,
    aoiLayerVisible: true,
    aoiArea: 0,
    aoiFeatures: [],
    aoiActive: false,
    aoiApplied: false,
    aoiAddFeatureMode: false,
    aoiGeoUnit: 'all',
    aoiQuery: {},
    aoiLoading: false,
    availableAois: {},
    validAOILayers: {},
    aoiManagerOpen: false,
    aoiMeasurementToolOpen: false,
    aoiManagerFromProfile: false,
    availableAOI: '',
    wellsCountryAssociation: {},
    selectedAOIBasin: ''
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case MapAOIActions.MapAOIActionTypes.SetAOIActive:
            return __assign({}, state, { aoiActive: action.payload });
        case MapAOIActions.MapAOIActionTypes.SetAOIApplied:
            return __assign({}, state, { aoiApplied: action.payload });
        case MapAOIActions.MapAOIActionTypes.SetAddingStatus:
            return __assign({}, state, { aoiAddFeatureMode: action.payload });
        case MapAOIActions.MapAOIActionTypes.FindNearFeatures:
            return __assign({}, state, { aoiLoading: true });
        case MapAOIActions.MapAOIActionTypes.SetAOILayer:
            return __assign({}, state, { aoiLayer: action.payload, aoiLoading: true });
        case MapAOIActions.MapAOIActionTypes.SetAOIArea:
            return __assign({}, state, { aoiArea: action.payload });
        case MapAOIActions.MapAOIActionTypes.AddAOIFeature:
            return __assign({}, state, { aoiFeatures: state.aoiFeatures.filter(function (el) { return el.id !== action.payload.id; }).concat([action.payload]) });
        case MapAOIActions.MapAOIActionTypes.LoadAOI:
            return __assign({}, state, { availableAois: action.payload, aoiQuery: state.aoiAddFeatureMode ? action.payload
                    :
                        Object.keys(action.payload)
                            .filter(function (key) { return ['well'].indexOf(key) < 0; })
                            .reduce(function (newObj, key) {
                            var _a;
                            return Object.assign(newObj, (_a = {}, _a[key] = action.payload[key], _a));
                        }, {}), aoiLoading: false, aoiAddFeatureMode: false });
        case MapAOIActions.MapAOIActionTypes.SetAOIQuery:
            return __assign({}, state, { aoiQuery: action.payload });
        case MapAOIActions.MapAOIActionTypes.SetAOIGeoUnit:
            return __assign({}, state, { aoiGeoUnit: action.payload });
        case MapAOIActions.MapAOIActionTypes.SetAOILayerConfigs:
            return __assign({}, state, { validAOILayers: action.payload });
        case MapAOIActions.MapAOIActionTypes.RemoveAOIFeature:
            return __assign({}, state, { aoiFeatures: state.aoiFeatures.filter(function (el) { return el !== action.payload; }).slice(), aoiActive: false, aoiAddFeatureMode: false });
        case MapAOIActions.MapAOIActionTypes.ClearAOI:
            return __assign({}, initialState, { validAOILayers: state.validAOILayers, aoiGeoUnit: state.aoiGeoUnit });
        case MapAOIActions.MapAOIActionTypes.SetAOIManagerOpen:
            return __assign({}, state, { aoiManagerOpen: action.payload });
        case MapAOIActions.MapAOIActionTypes.SetAOIMeasurementToolOpen:
            return __assign({}, state, { aoiMeasurementToolOpen: action.payload });
        case MapAOIActions.MapAOIActionTypes.SetAOIManagerFromProfile:
            return __assign({}, state, { aoiManagerFromProfile: action.payload });
        case MapAOIActions.MapAOIActionTypes.SetAOIVisibility:
            return __assign({}, state, { aoiLayerVisible: action.payload });
        case MapAOIActions.MapAOIActionTypes.UpdateAOIQuery:
            var aoiQueryClone = JSON.parse(JSON.stringify(state.aoiQuery));
            var availableAoisClone = JSON.parse(JSON.stringify(state.availableAois));
            var configKey = action.payload.configKey;
            var element = action.payload.element;
            if (aoiQueryClone[configKey]) {
                aoiQueryClone[configKey].push(element);
            }
            else {
                aoiQueryClone[configKey] = [element];
            }
            if (availableAoisClone[configKey]) {
                availableAoisClone[configKey].push(element);
            }
            else {
                availableAoisClone[configKey] = [element];
            }
            availableAoisClone[configKey] = availableAoisClone[configKey].filter(function (el, i, a) { return i === a.indexOf(el); });
            aoiQueryClone[configKey] = aoiQueryClone[configKey].filter(function (el, i, a) { return i === a.indexOf(el); });
            return __assign({}, state, { aoiQuery: aoiQueryClone, availableAois: availableAoisClone, aoiManagerFromProfile: action.payload.aoiManagerFromProfile });
        case MapAOIActions.MapAOIActionTypes.AddAvailableAOI:
            return __assign({}, state, { availableAOI: action.payload.availableAOI });
        case MapAOIActions.MapAOIActionTypes.SetWellCountryAssociation:
            return __assign({}, state, { wellsCountryAssociation: action.payload });
        case MapAOIActions.MapAOIActionTypes.SetSelectedAOIBasin:
            return __assign({}, state, { selectedAOIBasin: action.payload });
        default: {
            return state;
        }
    }
}
