import { AskBobActions } from '../actions/index';

export interface State {
  basin: string;
  query: string;
  queryParameters: {
    query: string,
    qa_retriever: number,
    qa_numResults: number,
    summ_retriever: number,
    levels: number,
    context: number
  };
  errorMessage: string;
  isLoading: { answer: boolean, summary: boolean };
  hasError: boolean;
  answers: [];
  summary: [];
  activeTab: string;
  feedbackAnswer: any;
}

export const initialState: State = {
  basin: '',
  query: '',
  queryParameters: {
    query: '',
    qa_retriever: 100,
    qa_numResults: 20,
    summ_retriever: 5,
    levels: 1,
    context: 30
  },
  errorMessage: '',
  isLoading: { answer: false, summary: false },
  hasError: false,
  answers: [],
  summary: [],
  activeTab: 'QA',
  feedbackAnswer: null,
};

export function reducer(
  state = initialState,
  action:
    | AskBobActions.AskBobActionsUnion
): State {
  switch (action.type) {
    case AskBobActions.AskBobActions.SetBasin: {
      return {
        ...state,
        basin: action.payload
      };
    }
    case AskBobActions.AskBobActions.SetActiveTab: {
      return {
        ...state,
        activeTab: action.payload
      };
    }
    case AskBobActions.AskBobActions.SetQueryParameters: {
      return {
        ...state,
        queryParameters: action.payload
      };
    }
    case AskBobActions.AskBobActions.LoadAnswers: {
      return {
        ...state,
        isLoading: { ...state.isLoading, answer: true },
        hasError: false,
        answers: []
      };
    }
    case AskBobActions.AskBobActions.LoadAnswersSuccess: {
      return {
        ...state,
        isLoading: { ...state.isLoading, answer: false },
        answers: action.response.answers.filter(x => x.answer !== null),
        query: action.response.query
      };
    }
    case AskBobActions.AskBobActions.LoadAnswersFailure: {
      return {
        ...state,
        isLoading: { ...state.isLoading, answer: false },
        hasError: true,
        errorMessage: action.payload
      };
    }
    case AskBobActions.AskBobActions.LoadSummary: {
      return {
        ...state,
        isLoading: { ...state.isLoading, summary: true },
        hasError: false,
        summary: []
      };
    }
    case AskBobActions.AskBobActions.LoadSummarySuccess: {
      return {
        ...state,
        isLoading: { ...state.isLoading, summary: false },
        summary: action.response,
      };
    }
    case AskBobActions.AskBobActions.LoadSummaryFailure: {
      return {
        ...state,
        isLoading: { ...state.isLoading, summary: false },
        hasError: true,
        errorMessage: action.payload
      };
    }
    case AskBobActions.AskBobActions.SetFeedbackAnswer: {
      return {
        ...state,
        feedbackAnswer: action.payload
      };
    }
    default:
      return state;
  }
}
