import { Action } from '@ngrx/store';
import { User, Preference } from '@app/shared/models/UserAPI.model';

export enum UserActionTypes {
    LoadAllUsers = '[Users] Load All Users',
    LoadUser = '[Users] Load User',
    UpdateUser = '[Users] Update User',
    LoadPreferences = '[Users] Load Preferences',
    UpdatePreferences = '[Users] Update Preferences',
    ScreenSizeWarning = '[Users] Screen Size Warning',
    SetActiveModal = '[Users] Set Active Modal',
    GetAvailableMessages = '[Users] Get Available Messages',
    GetAvailableMessagesSuccess = '[Users] Get Available Messages Success',
    SetPromoterCategories = '[Users] Set Promoder Categories'
}


export class LoadAllUsers implements Action {
    readonly type = UserActionTypes.LoadAllUsers;

    constructor(public payload: User[]) { }
}

export class LoadUser implements Action {
    readonly type = UserActionTypes.LoadUser;

    constructor(public payload: User) { }
}

export class UpdateUser implements Action {
    readonly type = UserActionTypes.UpdateUser;

    constructor(public payload: User) { }
}

export class LoadPreferences implements Action {
    readonly type = UserActionTypes.LoadPreferences;

    constructor(public payload: Preference) { }
}

export class UpdatePreferences implements Action {
    readonly type = UserActionTypes.UpdatePreferences;

    constructor(public payload: Preference) { }
}

export class ScreenSizeWarning implements Action {
    readonly type = UserActionTypes.ScreenSizeWarning;
    constructor() { }
}

export class SetActiveModal implements Action {
    readonly type = UserActionTypes.SetActiveModal;
    constructor(public payload: string) { }
}

export class GetAvailableMessages implements Action {
    readonly type = UserActionTypes.GetAvailableMessages;
}

export class GetAvailableMessagesSuccess implements Action {
    readonly type = UserActionTypes.GetAvailableMessagesSuccess;
    constructor(public payload: any[]) { }
}

export class SetPromoterCategories implements Action {
    readonly type = UserActionTypes.SetPromoterCategories;

    constructor(public payload: any[]) { }
}

export type UserActionsUnion =
    | LoadAllUsers
    | LoadUser
    | UpdateUser
    | LoadPreferences
    | UpdatePreferences
    | ScreenSizeWarning
    | SetActiveModal
    | GetAvailableMessages
    | GetAvailableMessagesSuccess
    | SetPromoterCategories;
