var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { WorkspacesActions } from '../actions';
/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export var initialState = {
    workspace: null,
    allWorkspaces: [],
    personalWorkspaces: [],
    templateWorkspaces: [],
    notifications: [],
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case WorkspacesActions.WorkspacesActionTypes.LoadAllWorkspacesSuccess:
            return __assign({}, state, { allWorkspaces: action.all, personalWorkspaces: action.personal, templateWorkspaces: action.template });
        case WorkspacesActions.WorkspacesActionTypes.LoadWorkspace:
            return __assign({}, state, { workspace: action.payload });
        case WorkspacesActions.WorkspacesActionTypes.UpdateWorkspace:
            return __assign({}, state, { workspace: action.payload });
        case WorkspacesActions.WorkspacesActionTypes.UpdateLegenedOpen:
            return __assign({}, state, { workspace: __assign({}, state.workspace, { legendOpen: action.payload }) });
        case WorkspacesActions.WorkspacesActionTypes.CreateWorkspace:
            return __assign({}, state, { workspace: action.payload });
        case WorkspacesActions.WorkspacesActionTypes.DeleteWorkspace:
            return __assign({}, state, { workspace: action.payload });
        case WorkspacesActions.WorkspacesActionTypes.LoadNotificationsSuccess:
            return __assign({}, state, { notifications: action.payload });
        default: {
            return state;
        }
    }
}
