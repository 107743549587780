import { Component, OnInit, Inject, ViewChild, OnChanges } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { AoiLayer, Preference, User, Workspace } from '@shared/models/UserAPI.model';
import * as fromShared from '@shared/reducers';
import * as fromSearch from '@search/reducers';
import * as fromGis from '@gis/reducers/index';
import { Router } from '@angular/router';
import { WorkspacesActions, SnackbarActions } from '@shared/actions/index';
import { SnackbarClassType } from '@shared/actions/snackbar.actions';
import { WS_CONTENT, WSContent } from '../workspaces-content';
import { ContentService } from '@shared/services/content.service';
import { AppConfig, AppConfigService } from '@app/app-config.service';
import { SearchActions } from '@app/modules/search/actions';
import { MapAOIActions, MapLayerActions } from '@app/modules/gis/actions';
import { GeobrainLoaderService } from '@app/shared/services/geobrain-loader.service';
import { console } from '@app/shared/util/console.util';

const PERSONAL_TYPE = 1;
const TEMPLATE_TPYE = 2;

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  private _config: AppConfig;
  private personalNames: string[] = [];
  private templateNames: string[] = [];
  public currentWorkspace: Workspace = null;

  preferences$: Observable<Preference> = this.sharedStore.pipe(
    select(fromShared.getPreferences),
    skipWhile(preferences => preferences === null)
  );
  paramsSubscription$: Subscription;
  public snackbars = this.content.local.MANAGER.snackbars;

  constructor(
    private http: HttpClient,
    private _appConfig: AppConfigService,
    private sharedStore: Store<fromShared.State>,
    private searchStore: Store<fromSearch.State>,
    private gisStore: Store<fromGis.State>,
    private router: Router,
    public geoLoaderService: GeobrainLoaderService,
    @Inject(WS_CONTENT) public content: ContentService<WSContent>,
  ) {
    this._config = this._appConfig.getConfig();
  }


  createWorkspace(workspace: Workspace) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true
    };
    return this.http.post<any>(`${this._config.user_api_baseurl}/workspace`, workspace, httpOptions);
  }

  loadWorkspaces(): Observable<any> {
    return this.http.get<Workspace[]>(`${this._config.user_api_baseurl}/workspaces`);
  }

  loadTemplateWorkspaces(): Observable<any> {
    return this.http.get<Workspace[]>(`${this._config.user_api_baseurl}/globalworkspaces`);
  }

  loadPersonalWorkspaces(): Observable<any> {
    return this.http.get<Workspace[]>(`${this._config.user_api_baseurl}/userWorkspaces`);
  }


  loadWorkspace(id: number) {
    return this.http.get<Workspace>(`${this._config.user_api_baseurl}/workspace/${id}`);
  }

  mockloadWorkspace(id: number) {
    // return this.http.get<Workspace>(`/assets/data/sample-workspace.json`);
    return this.http.get<Workspace>(`/assets/data/workspace834.json`);
  }

  updateWorkspace(workspace: Workspace) {
    return this.http.put<Workspace>(`${this._config.user_api_baseurl}/workspace/${workspace.workspaceId}`, workspace);
  }

  updateWorkspaceFields(workspaceId: number, object: any) {
    return this.http.put<Workspace>(`${this._config.user_api_baseurl}/workspace/${workspaceId}`, object);
  }

  deleteWorkspace(id: number) {
    return this.http.delete<string>(`${this._config.user_api_baseurl}/workspace/${id}`);
  }

  addEnabledApp(workspaceId: number, enabledApp: string) {
    return this.http.post<any>(`${this._config.user_api_baseurl}/workspace/enabledApps/${workspaceId}/${enabledApp}`, {});
  }

  deleteEnabledApp(workspaceId: number, enabledApp: string) {
    return this.http.delete<any>(`${this._config.user_api_baseurl}/workspace/enabledApps/${workspaceId}/${enabledApp}`);
  }

  replaceEnabledApps(workspaceId: number, enabledApps: string[]) {
    return this.http.put<any>(`${this._config.user_api_baseurl}/workspace/enabledApps/replace/${workspaceId}`, enabledApps);
  }

  updateActiveMapServer(workspaceId: number, activeMaps: any[]) {
    return this.http.put<any>(`${this._config.user_api_baseurl}/workspace/activeMapServer/replace/${workspaceId}`, activeMaps);
  }

  updateCustomMapServer(workspaceId: number, activeMaps: any[]) {
    return this.http.put<any>(`${this._config.user_api_baseurl}/workspace/customMapServer/replace/${workspaceId}`, activeMaps);
  }

  validatePersonalName(name: string) {
    return (this.personalNames.indexOf(name) === -1);
  }

  validateTemplateName(name: string) {
    return (this.templateNames.indexOf(name) === -1);
  }

  clearResults(id) {
    this.http.put<any>(`${this._config.user_api_baseurl}/workspace/ResetDocumentCount/${id}`, {}).pipe(take(1)).subscribe(res => {
      this.loadWorkspace(id).pipe(take(1)).subscribe(ws => {
        this.sharedStore.dispatch(new WorkspacesActions.LoadWorkspace(ws as Workspace));
      });
    });
  }


  openSnackbar(messageType: string, workspaceName: string = null, type: string = 'success') {
    console.log(messageType, this.snackbars[messageType]);
    this.sharedStore.dispatch(new SnackbarActions.Open({
      message: this.snackbars[messageType] + ((workspaceName) ? ': ' + workspaceName : ''),
      type: ('xom-snackbar-' + type) as SnackbarClassType,
      action: 'x'
    }));
  }

  private overwriteWorkspace(workspace: Workspace, search?: string, basins?: string): Workspace {
    if (basins) {
      workspace.aoi.aoiQuery = {
        basin: basins.split(' AND ')
      };
      workspace.aoi.availableAois = {
        basin: basins.split(' AND ')
      };
      (window as any)._override_bounds = true;
    }

    if (search) {
      workspace.queryText = search;
    }

    workspace.query = {
      basin: basins ? basins.split(' AND ') : null,
      keywords: search ? [search] : null
    };

    return workspace;
  }

  loadWorkspaceFromUrl(id: number, search?: string, basins?: string) {
    this.loadWorkspace(id).pipe(take(1)).subscribe(res => {
      if (basins || search) {
        res = this.overwriteWorkspace(res, search, basins);
      }
      this.sharedStore.dispatch(new WorkspacesActions.LoadWorkspace(res as Workspace));
    }, (error) => {
      this.openSnackbar('errorLoading', '', 'error');
    });
  }

  loadWorkspaceFromPreferences(id: number, search?: string, basins?: string) {
    this.loadWorkspace(id).pipe(take(1)).subscribe(res => {
      if (basins || search) {
        res = this.overwriteWorkspace(res, search, basins);
      }
      this.sharedStore.dispatch(new WorkspacesActions.LoadWorkspace(res as Workspace));
      this.openSnackbar('defaultLoaded', res.title);
    }, (error) => {
      this.openSnackbar('errorLoading', '', 'error');
    });
  }

  loadLastWorkspace() {
    this.loadWorkspaces().pipe(take(1)).subscribe(workspaces => {
      let mostRecent: Workspace = null;
      workspaces.forEach((ws) => {
        if (mostRecent === null || mostRecent.lastModified < ws.lastModified) {
          mostRecent = ws;
        }
      });
      if (mostRecent !== null) {
        this.sharedStore.dispatch(new WorkspacesActions.LoadWorkspace(mostRecent));
        this.openSnackbar('recentLoaded', mostRecent.title);
      }
    });
  }

  loadInitialWorkspace(id: any) {
    if (id) {
      console.log('Loading ws from url:', id);
      this.loadWorkspaceFromUrl(parseInt(id, 10));
    } else {
      if (this.currentWorkspace === null) {
        // If no specific workspace was requested, lets load the one in preferences
        this.preferences$.pipe(take(1)).subscribe(prefs => {
          console.log('Default view:', prefs.defaultView);
          if (prefs.defaultView === 'homepage') {
            switch (prefs.resultsView.toLowerCase()) {
              case 'map':
                this.geoLoaderService.activateItemByUrl('geobrain/map');
                break;
              case 'list':
                  this.geoLoaderService.activateAppById('summary-app', 'list');
                  break;
              case 'list+map':
              case 'both':
              case 'hybrid':
                  this.geoLoaderService.activateAppById('summary-app', 'hybrid');
                  break;
              default:
                  this.geoLoaderService.activateAppById('landing-app', 'hybrid');
                  break;
            }
          } else if (prefs.defaultView === 'previous_workspace') {
            this.loadLastWorkspace();
          } else if (prefs.defaultView === 'workspace'
            && prefs.defaultWorkspaceID !== null
            && prefs.defaultWorkspaceID !== -1
            && this.currentWorkspace === null) {
            this.loadWorkspaceFromPreferences(prefs.defaultWorkspaceID);
          }
        });
      }
    }
  }

  cleanCurrentWorkspace() {
    this.currentWorkspace = null;
    this.searchStore.dispatch(new SearchActions.ResetAll());
    this.gisStore.dispatch(new MapAOIActions.ClearAOI());
    this.gisStore.dispatch(new MapLayerActions.ResetActiveCentroid());
    this.gisStore.dispatch(new MapLayerActions.RemoveAllMapServers());
    this.gisStore.dispatch(new MapLayerActions.DeactivateAllLayers(true));
    this.gisStore.dispatch(new MapLayerActions.RemoveMapLegends());
    this.searchStore.dispatch(new SearchActions.SetInitialSearch(false));
    this.searchStore.dispatch(new SearchActions.RemovePills([]));
    this.sharedStore.dispatch(new WorkspacesActions.LoadWorkspace(this.currentWorkspace));
    this.geoLoaderService.activateAppById('landing-app');
    this.openSnackbar('cleaned');
  }

  notifyLastURLMapServerFail(url: string) {
      this.gisStore.dispatch(new MapLayerActions.SetLastURLMapServerFail(url));
  }

  // Reset the names array to correct working of the validateName
  resetNameValidation() {
      this.personalNames = [];
      this.templateNames = [];
  }

  getTitlesOfWorkspacesToSetNames(workspaces: Workspace[]) {
      this.personalNames = this.personalNames.concat(this.personalNames,
        workspaces.filter(ws => ws.type === PERSONAL_TYPE).map(ws => ws.title));
      this.templateNames = this.templateNames.concat(this.templateNames,
        workspaces.filter(ws => ws.type === TEMPLATE_TPYE).map(ws => ws.title));
  }

}
