import { AppConfigService } from '@app/app-config.service';
import { Observable, EMPTY as empty } from 'rxjs';
import * as esri from 'esri-leaflet';
import * as i0 from "@angular/core";
import * as i1 from "../../../app-config.service";
var SeisbookLayerService = /** @class */ (function () {
    function SeisbookLayerService(_appConfig) {
        this._appConfig = _appConfig;
        this._config = this._appConfig.getConfig();
    }
    SeisbookLayerService.prototype.getBasinNames = function (countries) {
        var featureQuery = esri.query({
            url: this._config.geobookLayerUrl,
            useCors: true,
            withCredentials: true
        });
        if (!countries.length) {
            return empty;
        }
        var condition = countries.reduce(function (prev, country) { return prev.length ? prev + " OR COUNTRY_NAMES = '" + country + "'" : "COUNTRY_NAMES = '" + country + "'"; }, '');
        featureQuery.where(condition);
        featureQuery.fields('BASIN_NAME');
        return new Observable(function (observer) {
            featureQuery.run(function (error, featureCollection) {
                if (error) {
                    observer.next({});
                }
                else {
                    observer.next(featureCollection.features.map((function (feature) { return feature.properties.BASIN_NAME; })));
                }
            });
        });
    };
    SeisbookLayerService.ngInjectableDef = i0.defineInjectable({ factory: function SeisbookLayerService_Factory() { return new SeisbookLayerService(i0.inject(i1.AppConfigService)); }, token: SeisbookLayerService, providedIn: "root" });
    return SeisbookLayerService;
}());
export { SeisbookLayerService };
