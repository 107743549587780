var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { asyncScheduler, Observable } from 'rxjs';
import { debounceTime, map, switchMap, withLatestFrom, } from 'rxjs/operators';
import { UserAPIService } from '@shared/services/user-api.service';
import { UserActions } from '@shared/actions';
import { SnackbarActions } from '@shared/actions/index';
import { User } from '@shared/models/UserAPI.model';
var UserEffects = /** @class */ (function () {
    function UserEffects(actions$, store$, userApi) {
        var _this = this;
        this.actions$ = actions$;
        this.store$ = store$;
        this.userApi = userApi;
        this.updateUser$ = function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.debounce, debounce = _c === void 0 ? 300 : _c, _d = _b.scheduler, scheduler = _d === void 0 ? asyncScheduler : _d;
            return _this.actions$.pipe(ofType(UserActions.UserActionTypes.UpdateUser), debounceTime(debounce, scheduler), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1];
                return _this.userApi.updateUser(storeState.shared.user.userInfo).pipe(map(function (res) {
                    return new SnackbarActions.Open({
                        message: "User updated succesfully",
                        type: 'xom-snackbar-success',
                        action: 'x'
                    });
                }));
            }));
        };
        this.updatePreferences$ = function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.debounce, debounce = _c === void 0 ? 300 : _c, _d = _b.scheduler, scheduler = _d === void 0 ? asyncScheduler : _d;
            return _this.actions$.pipe(ofType(UserActions.UserActionTypes.UpdatePreferences), debounceTime(debounce, scheduler), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1];
                return _this.userApi.updatePreferences(storeState.shared.user.preferences).pipe(map(function (res) {
                    // res is a message with the operation result, i.e.: "1 User preferences modified successfully: "
                    return new SnackbarActions.Open({
                        message: "Preferences saved succesfully",
                        type: 'xom-snackbar-success',
                        action: 'x'
                    });
                }));
            }));
        };
        this.GetAvailableMessages$ = function () {
            return _this.actions$.pipe(ofType(UserActions.UserActionTypes.GetAvailableMessages), switchMap(function (action) {
                return _this.userApi.getAvailableMessages().pipe(map(function (res) {
                    return new UserActions.GetAvailableMessagesSuccess(res);
                }));
            }));
        };
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "updateUser$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "updatePreferences$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "GetAvailableMessages$", void 0);
    return UserEffects;
}());
export { UserEffects };
