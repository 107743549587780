<xom-modal-layout>
  <div modal-content>
    <div class="row middle-xs ws-container">
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div></div>
        <h1> {{content.global.WORK_SPACE_LOADING_SCREEN.title}} </h1>
        <h2> {{content.global.WORK_SPACE_LOADING_SCREEN.subTitle}} </h2>
        <button [class.invisible]="!showCancelButton" (click)="onCancel()" xom-button> {{content.global.WORK_SPACE_LOADING_SCREEN.cancelLabel}} </button>
    </div>
  </div>
</xom-modal-layout>
