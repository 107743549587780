import {ActionReducer, Action} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {MapAOIActions} from '../actions/index';
import {Layer} from 'leaflet';
import {Feature} from 'geojson';
import {LayerConfigs} from '@app/shared/models/SearchAPI.model';
import {AOIQuery} from '@app/shared/models/aoi-query';


export interface State {
    aoiLayer: Layer | null;
    aoiLayerVisible: boolean;
    aoiArea: number;
    aoiFeatures: Feature[];
    aoiActive: Boolean;
    aoiApplied: Boolean;
    aoiAddFeatureMode: Boolean;
    aoiGeoUnit: string;
    aoiQuery: AOIQuery;
    aoiLoading: boolean;
    availableAois: AOIQuery;
    validAOILayers: LayerConfigs;
    aoiManagerOpen: Boolean;
    aoiMeasurementToolOpen: Boolean;
    aoiManagerFromProfile: Boolean;
    availableAOI: string;
    wellsCountryAssociation: any;
    selectedAOIBasin: string;
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
// tslint:disable-next-line:max-line-length
export const initialState: State = {
    aoiLayer: null,
    aoiLayerVisible: true,
    aoiArea: 0,
    aoiFeatures: [],
    aoiActive: false,
    aoiApplied: false,
    aoiAddFeatureMode: false,
    aoiGeoUnit: 'all',
    aoiQuery: {},
    aoiLoading: false,
    availableAois: {},
    validAOILayers: {},
    aoiManagerOpen: false,
    aoiMeasurementToolOpen: false,
    aoiManagerFromProfile: false,
    availableAOI: '',
    wellsCountryAssociation: {},
    selectedAOIBasin: ''
};

export function reducer(
    state = initialState,
    action: MapAOIActions.MapAOIActionsUnion
): State {
    switch (action.type) {
        case MapAOIActions.MapAOIActionTypes.SetAOIActive:
            return {
                ...state,
                aoiActive: action.payload
            };
        case MapAOIActions.MapAOIActionTypes.SetAOIApplied:
            return {
                ...state,
                aoiApplied: action.payload
            };
        case MapAOIActions.MapAOIActionTypes.SetAddingStatus:
            return {
                ...state,
                aoiAddFeatureMode: action.payload
            };
        case MapAOIActions.MapAOIActionTypes.FindNearFeatures:
            return {
                ...state,
                aoiLoading: true
            };
        case MapAOIActions.MapAOIActionTypes.SetAOILayer:
            return {
                ...state,
                aoiLayer: action.payload,
                aoiLoading: true
            };
        case MapAOIActions.MapAOIActionTypes.SetAOIArea:
            return {
                ...state,
                aoiArea: action.payload
            };
        case MapAOIActions.MapAOIActionTypes.AddAOIFeature:
            return {
                ...state,
                aoiFeatures: [...state.aoiFeatures.filter(el => el.id !== action.payload.id), action.payload]
            };
        case MapAOIActions.MapAOIActionTypes.LoadAOI:
            return {
                ...state,
                availableAois: action.payload,
                aoiQuery: state.aoiAddFeatureMode ? action.payload
                    :
                    Object.keys(action.payload)
                    .filter((key) => ['well'].indexOf(key) < 0)
                    .reduce((newObj, key) => Object.assign(newObj, { [key]: action.payload[key] }), {}),
                aoiLoading: false,
                aoiAddFeatureMode: false
            };
        case MapAOIActions.MapAOIActionTypes.SetAOIQuery:
            return {
                ...state,
                aoiQuery: action.payload
            };
        case MapAOIActions.MapAOIActionTypes.SetAOIGeoUnit:
            return {
                ...state,
                aoiGeoUnit: action.payload
            };
        case MapAOIActions.MapAOIActionTypes.SetAOILayerConfigs:
            return {
                ...state,
                validAOILayers: action.payload
            };
        case MapAOIActions.MapAOIActionTypes.RemoveAOIFeature:
            return {
                ...state,
                aoiFeatures: [...state.aoiFeatures.filter(el => el !== action.payload)],
                aoiActive: false,
                aoiAddFeatureMode: false
            };
        case MapAOIActions.MapAOIActionTypes.ClearAOI:
            return {
                ...initialState,
                validAOILayers: state.validAOILayers,
                aoiGeoUnit: state.aoiGeoUnit
            };
        case MapAOIActions.MapAOIActionTypes.SetAOIManagerOpen:
            return {
                ...state,
                aoiManagerOpen: action.payload
            };
        case MapAOIActions.MapAOIActionTypes.SetAOIMeasurementToolOpen:
            return {
                ...state,
                aoiMeasurementToolOpen: action.payload
            };
        case MapAOIActions.MapAOIActionTypes.SetAOIManagerFromProfile:
            return {
                ...state,
                aoiManagerFromProfile: action.payload
            };
        case MapAOIActions.MapAOIActionTypes.SetAOIVisibility:
            return {
                ...state,
                aoiLayerVisible: action.payload
            };
        case MapAOIActions.MapAOIActionTypes.UpdateAOIQuery:
            const aoiQueryClone = JSON.parse(JSON.stringify(state.aoiQuery));
            const availableAoisClone = JSON.parse(JSON.stringify(state.availableAois));
            const configKey = action.payload.configKey;
            const element = action.payload.element;
            if (aoiQueryClone[configKey]) {
                aoiQueryClone[configKey].push(element);
            } else {
                aoiQueryClone[configKey] = [element];
            }
            if (availableAoisClone[configKey]) {
                availableAoisClone[configKey].push(element);
            } else {
                availableAoisClone[configKey] = [element];
            }
            availableAoisClone[configKey] = availableAoisClone[configKey].filter((el, i, a) => i === a.indexOf(el));
            aoiQueryClone[configKey] = aoiQueryClone[configKey].filter((el, i, a) => i === a.indexOf(el));
            return {
                ...state,
                aoiQuery: aoiQueryClone,
                availableAois: availableAoisClone,
                aoiManagerFromProfile: action.payload.aoiManagerFromProfile
            };
        case MapAOIActions.MapAOIActionTypes.AddAvailableAOI:
            return {
                ...state,
                availableAOI: action.payload.availableAOI
            };
        case MapAOIActions.MapAOIActionTypes.SetWellCountryAssociation:
            return {
                ...state,
                wellsCountryAssociation: action.payload
            };
        case MapAOIActions.MapAOIActionTypes.SetSelectedAOIBasin:
            return {
                ...state,
                selectedAOIBasin: action.payload
            };
        default: {
            return state;
        }
    }
}
