var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PopupActions } from '../actions/index';
/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export var initialState = {
    features: [],
    location: null,
    loading: false,
    popupIndex: null
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case PopupActions.PopupActionTypes.OpenPopup:
            return __assign({}, state, { features: [], loading: true });
        case PopupActions.PopupActionTypes.AddPopupFeatures:
            return __assign({}, state, { features: state.features.concat(action.payload), loading: false });
        case PopupActions.PopupActionTypes.ClearPopupFeatures:
            return __assign({}, state, { features: [] });
        case PopupActions.PopupActionTypes.SetPopupIndex:
            return __assign({}, state, { popupIndex: action.payload });
        default: {
            return state;
        }
    }
}
