export var HydrationActionTypes;
(function (HydrationActionTypes) {
    HydrationActionTypes["ResetHydration"] = "[Hydration] Reset Hydration";
    HydrationActionTypes["StartHydration"] = "[Hydration] Start Hydration";
    HydrationActionTypes["HydrationSearchResetCompleted"] = "[Hydration] Hydration Search ResetCompleted";
    HydrationActionTypes["DocumentFacetsRemovalCompleted"] = "[Hydration] Document Facets Removal Completed";
    HydrationActionTypes["SearchCompleted"] = "[Hydration] Search Completed";
    HydrationActionTypes["SearchDocumentSuccessCompleted"] = "[Hydration] Search Document Success Completed";
    HydrationActionTypes["FacetsSearchCompleted"] = "[Hydration] Facets Search Completed";
    HydrationActionTypes["HydrationStateVerification"] = "[Hydration] Hydration State Verification";
    HydrationActionTypes["MapServerHydrationCompleted"] = "[Hydration] Map Server Hydration Completed";
    HydrationActionTypes["SetHydrationToInitialState"] = "[Hydration] Set Hydration To Initial State";
})(HydrationActionTypes || (HydrationActionTypes = {}));
var ResetHydration = /** @class */ (function () {
    function ResetHydration(payload) {
        this.payload = payload;
        this.type = HydrationActionTypes.ResetHydration;
    }
    return ResetHydration;
}());
export { ResetHydration };
var StartHydration = /** @class */ (function () {
    function StartHydration(payload) {
        this.payload = payload;
        this.type = HydrationActionTypes.StartHydration;
    }
    return StartHydration;
}());
export { StartHydration };
var HydrationSearchResetCompleted = /** @class */ (function () {
    function HydrationSearchResetCompleted() {
        this.type = HydrationActionTypes.HydrationSearchResetCompleted;
    }
    return HydrationSearchResetCompleted;
}());
export { HydrationSearchResetCompleted };
var DocumentFacetsRemovalCompleted = /** @class */ (function () {
    function DocumentFacetsRemovalCompleted() {
        this.type = HydrationActionTypes.DocumentFacetsRemovalCompleted;
    }
    return DocumentFacetsRemovalCompleted;
}());
export { DocumentFacetsRemovalCompleted };
var SearchCompleted = /** @class */ (function () {
    function SearchCompleted() {
        this.type = HydrationActionTypes.SearchCompleted;
    }
    return SearchCompleted;
}());
export { SearchCompleted };
var SearchDocumentSuccessCompleted = /** @class */ (function () {
    function SearchDocumentSuccessCompleted() {
        this.type = HydrationActionTypes.SearchDocumentSuccessCompleted;
    }
    return SearchDocumentSuccessCompleted;
}());
export { SearchDocumentSuccessCompleted };
var FacetsSearchCompleted = /** @class */ (function () {
    function FacetsSearchCompleted() {
        this.type = HydrationActionTypes.FacetsSearchCompleted;
    }
    return FacetsSearchCompleted;
}());
export { FacetsSearchCompleted };
var MapServerHydrationCompleted = /** @class */ (function () {
    function MapServerHydrationCompleted() {
        this.type = HydrationActionTypes.MapServerHydrationCompleted;
    }
    return MapServerHydrationCompleted;
}());
export { MapServerHydrationCompleted };
var HydrationStateVerification = /** @class */ (function () {
    function HydrationStateVerification() {
        this.type = HydrationActionTypes.HydrationStateVerification;
    }
    return HydrationStateVerification;
}());
export { HydrationStateVerification };
var SetHydrationToInitialState = /** @class */ (function () {
    function SetHydrationToInitialState() {
        this.type = HydrationActionTypes.SetHydrationToInitialState;
    }
    return SetHydrationToInitialState;
}());
export { SetHydrationToInitialState };
