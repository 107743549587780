import { Injectable } from '@angular/core';
import { AppConfigService, AppConfig } from '@app/app-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { App } from '../models/UserAPI.model';
import { Store } from '@ngrx/store';
import * as fromImageQuery from '@search/reducers';
import { ImageQueryActions } from '@app/modules/search/actions';

@Injectable({
  providedIn: 'root'
})
export class GeobrainLoaderService {
    config: AppConfig;
    activeApp = 'Landing';
    currentView: string;

    constructor(
        private appConfig: AppConfigService,
        private route: ActivatedRoute,
        private router: Router,
        private imageQueryStore: Store<fromImageQuery.State>,
    ) {
        this.config = appConfig.getConfig();
    }

    activateApp(app: App, urlParams?: string) {
        this.activeApp = app.title;
        let targetView = this.currentView;
        if (targetView === 'map') {
            // app was activated, put the user in hybrid mode
            targetView = 'hybrid';
        }

        if (app.route === 'results/images' && urlParams !== '?fromLeadMaps=true') {
            this.imageQueryStore.dispatch(new ImageQueryActions.SetFromLeadMap(false));
        }

        if (app.route === 'workspaces') {
            this.router.navigateByUrl(`${app.route}`);
        } else {
            if (urlParams) {
                this.router.navigateByUrl(`/geobrain/${targetView}/${app.route}${urlParams}`);
            } else {
                this.router.navigateByUrl(`/geobrain/${targetView}/${app.route}`);
            }
        }
    }

    activateItemByUrl(url) {
        this.config.apps.forEach(app => {
            if (url.includes(app.route)) {
                this.activeApp = app.title;
            }
        });
    }

    activateAppById(id: string, view?: string, urlParams?: string) {
        this.config.apps.forEach(app => {
            if (app.name === id) {
                if (view && ['map', 'list', 'hybrid'].includes(view)) {
                    this.currentView = view;
                }

                if (urlParams) {
                    this.activateApp(app, urlParams);
                } else {
                    this.activateApp(app);
                }
            }
        });
    }

    activateAppFromPreferences(resultView: string) {
        // if result view contains a valid view type and a valid app config go to it, otherwise go to summary
        if (
            new RegExp(['map', 'list', 'hybrid'].join('|')).test(resultView) &&
            new RegExp(this.config.apps.map(app => app.route).join('|')).test(resultView)
        ) {
            this.router.navigateByUrl(`/geobrain/${resultView}`);
            if (resultView.startsWith('map/')) {
                this.currentView = 'map';
            } else if (resultView.startsWith('list/')) {
                this.currentView = 'list';
            } else if (resultView.startsWith('hybrid/')) {
                this.currentView = 'hybrid';
            }
        } else {
            this.router.navigateByUrl('/geobrain/hybrid/summary');
            this.currentView = 'hybrid';
        }
    }
}
