import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { publish, refCount } from 'rxjs/operators';
import * as esri from 'esri-leaflet';
import { FeatureCollection } from 'geojson';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FieldDataService {

    constructor(
        private http: HttpClient
    ) {
    }

    public queryFieldData(url: string, geometry: any, attributes: string[], returnGeometry = false): Observable<FeatureCollection> {
        const query = esri.query({
            url: url,
            useCors: true,
            withCredentials: true
        });
        return new Observable(observer => {
             query
                 .intersects(geometry)
                 .returnGeometry(returnGeometry)
                 .fields(attributes)
                 .run((error, featureCollection: FeatureCollection) => {
                     if (error) {
                         observer.error(error);
                     } else {
                         observer.next(featureCollection);
                     }
                 });
        }).pipe(publish(), refCount());
    }
}
