import { Action } from '@ngrx/store';
import { SearchResponse, Document, Query, SurprisingSentence } from '@shared/models/SearchAPI.model';
import { AOIQuery } from '@shared/models/aoi-query';
import { ClassificationType } from '@shared/models/ImageAPI.model';

export enum SearchActionTypes {
    SetQueryText = '[Search] Set Query Text',
    Search = '[Search] Trigger Search',
    SearchImages = '[Search] Search Images',
    SearchLeadMaps = '[Search] Search Lead Maps',
    SearchImagesSuccess = '[Search] Image Search Success',
    SearchImagesFailure = '[Search] Image Search Failure',
    BuildDocumentSearch = '[Search] Build Document Search',
    SearchDocuments = '[Search] Search Documents',
    SearchDocumentsSuccess = '[Search] Document Search Success',
    SearchDocumentsFailure = '[Search] Document Search Failure',
    SearchNewDocuments = '[Search] Search New Documents',
    SearchNewDocumentsSuccess = '[Search] New Documents Search Success',
    SearchNewDocumentsFailure = '[Search] New Documents Search Failure',
    SearchSuggestionsSuccess = '[Search] Search Suggestions Success',
    SearchSuggestionsFailure = '[Search] Search Suggestions Failure',
    SetClassifications = '[Search] Set Images Classifications',
    RemoveSearchTerm = '[Search] Remove Search Term',
    AddSearchTerms = '[Search] Add Search Terms',
    AddAOIQuery = '[Search] Add AOI Query',
    AddDocumentResponseTime = '[Search] Add Document Response Time',
    AddImageResponseTime = '[Search] Add Image Response Time',
    Export = '[Search] Export Search Documents',
    ExportSuccess = '[Search] Export Success',
    ExportFailure = '[Search] Export Failure',
    SetInitialSearch = '[Search] Set Initial Search',
    AddPill = '[Search] Add Pill',
    RemovePill = '[Search] Remove Pill',
    RemovePills = '[Search] Remove Pills',
    SetExportLoading = '[Search] Export Documents Loading',
    ResetAll = '[Search] Reset all',
    SetResetAllState = '[Search] Set resetAllState',
    SetDisplayResetAll = '[Search] Set displayResetAll',
    GetDocImages = '[Search] Get Doc Images',
    GetDocImagesSuccess = '[Search] Get Doc Images Success',
    GetDocImagesFailure = '[Search] Get Doc Images Failure',
    SetWordCloudAppliedTerms = '[Search] Set Word Cloud Applied Terms',
    RemoveCloudAppliedTerms = '[Search] Remove Cloud Applied Terms',
    SetDocumentDetailPanelContent = '[Search] Set Document Detail Panel Content',
    HydrationSearchReset = '[Search] Hydration Search Reset',
    SetLoadingFlags = '[Search] Set Loading Flag',
    GetSurprisingSentencesFromSearch = '[Search] Get Surprising Sentences From a Search',
    SetSurprisingSentencesFromSearch = '[Search] Set Surprising Sentences From a Search',
    SetSurprisingSentencesFailure = '[Search] Set Surprising Sentences From a Search Failure'
}

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

export class SetQueryText implements Action {
    readonly type = SearchActionTypes.SetQueryText;

    constructor(public payload: string) {}
}

export class Search implements Action {
    readonly type = SearchActionTypes.Search;

    constructor(public payload: string) {}
}

export class SearchImages implements Action {
    readonly type = SearchActionTypes.SearchImages;

    constructor(public payload: string) {}
}

export class SearchLeadMaps implements Action {
    readonly type = SearchActionTypes.SearchLeadMaps;

    constructor(public payload: { basin: string, country: string }) {}
}

export class SearchImagesSuccess implements Action {
    readonly type = SearchActionTypes.SearchImagesSuccess;

    constructor(public payload: any) {}
}

export class SearchImagesFailure implements Action {
    readonly type = SearchActionTypes.SearchImagesFailure;

    constructor(public payload: string) {}
}

export class BuildDocumentSearch implements Action {
    readonly type = SearchActionTypes.BuildDocumentSearch;

    constructor(public payload: string) {}
}

export class SearchDocuments implements Action {
    readonly type = SearchActionTypes.SearchDocuments;

    constructor(public payload: Query) {}
}

export class SearchDocumentsSuccess implements Action {
    readonly type = SearchActionTypes.SearchDocumentsSuccess;

    constructor(public payload: SearchResponse) {}
}

export class SearchDocumentsFailure implements Action {
    readonly type = SearchActionTypes.SearchDocumentsFailure;

    constructor(public payload: string) {}
}

export class SearchNewDocuments implements Action {
    readonly type = SearchActionTypes.SearchNewDocuments;

    constructor(public payload: string) {}
}

export class SearchNewDocumentsSuccess implements Action {
    readonly type = SearchActionTypes.SearchNewDocumentsSuccess;

    constructor(public payload: SearchResponse) {}
}

export class SearchNewDocumentsFailure implements Action {
    readonly type = SearchActionTypes.SearchNewDocumentsFailure;

    constructor(public payload: string) {}
}

export class SearchSuggestionsSuccess implements Action {
    readonly type = SearchActionTypes.SearchSuggestionsSuccess;

    constructor(public payload: string) {}
}

export class SearchSuggestionsFailure implements Action {
    readonly type = SearchActionTypes.SearchSuggestionsFailure;

    constructor(public payload: any) {}
}

export class RemoveSearchTerm implements Action {
    readonly type = SearchActionTypes.RemoveSearchTerm;

    constructor(public payload: string) {}
}

export class AddSearchTerms implements Action {
    readonly type = SearchActionTypes.AddSearchTerms;

    constructor(public payload: string[]) {}
}

export class AddAOIQuery implements Action {
    readonly type = SearchActionTypes.AddAOIQuery;

    constructor(public payload: AOIQuery ) {}
}

export class SetClassifications implements Action {
    readonly type = SearchActionTypes.SetClassifications;

    constructor(public payload: ClassificationType[] ) {}
}

export class AddDocumentResponseTime implements Action {
    readonly type = SearchActionTypes.AddDocumentResponseTime;

    constructor(public payload: number) {}
}

export class AddImageResponseTime implements Action {
    readonly type = SearchActionTypes.AddImageResponseTime;

    constructor(public payload: number) {}
}

export class Export implements Action {
    readonly type = SearchActionTypes.Export;

    constructor(public payload: string) {}
}

export class ExportSuccess implements Action {
    readonly type = SearchActionTypes.ExportSuccess;

    constructor(public payload: string) {}
}

export class ExportFailure implements Action {
    readonly type = SearchActionTypes.ExportFailure;

    constructor(public payload: string) {}
}

export class SetInitialSearch implements Action {
    readonly type = SearchActionTypes.SetInitialSearch;

    constructor(public payload: boolean) {}
}

export class AddPill implements Action {
    readonly type = SearchActionTypes.AddPill;

    constructor(public payload: string) {}
}

export class RemovePill implements Action {
    readonly type = SearchActionTypes.RemovePill;

    constructor(public payload: string) {}
}

export class RemovePills implements Action {
    readonly type = SearchActionTypes.RemovePills;

    constructor(public payload: string[]) {}
}

export class SetExportLoading implements Action {
    readonly type = SearchActionTypes.SetExportLoading;

    constructor(public payload: boolean) {}
}

export class ResetAll implements Action {
    readonly type = SearchActionTypes.ResetAll;

    constructor() {}
}

export class SetResetAllState implements Action {
    readonly type = SearchActionTypes.SetResetAllState;

    constructor(public payload: boolean) {}
}

export class SetDisplayResetAll implements Action {
    readonly type = SearchActionTypes.SetDisplayResetAll;

    constructor(public payload: boolean) {}
}

export class GetDocImages implements Action {
    readonly type = SearchActionTypes.GetDocImages;

    constructor(public payload: any[]) {}
}

export class GetDocImagesSuccess implements Action {
    readonly type = SearchActionTypes.GetDocImagesSuccess;

    constructor(public payload: any[]) {}
}

export class GetDocImagesFailure implements Action {
    readonly type = SearchActionTypes.GetDocImagesFailure;

    constructor(public payload: any) {}
}

export class SetWordCloudAppliedTerms implements Action {
    readonly type = SearchActionTypes.SetWordCloudAppliedTerms;

    constructor(public payload: string[]) {}
}

export class RemoveCloudAppliedTerms implements Action {
    readonly type = SearchActionTypes.RemoveCloudAppliedTerms;
}

export class SetDocumentDetailPanelContent implements Action {
    readonly type = SearchActionTypes.SetDocumentDetailPanelContent;

    constructor(public payload: Document | null) {}
}

export class HydrationSearchReset implements Action {
    readonly type = SearchActionTypes.HydrationSearchReset;
}

export class SetLoadingFlags implements Action {
    readonly type = SearchActionTypes.SetLoadingFlags;

    constructor(public payload: boolean) {}
}

export class GetSurprisingSentencesFromSearch implements Action {
    readonly type = SearchActionTypes.GetSurprisingSentencesFromSearch;
    constructor(public payload: Query) {}
}

export class SetSurprisingSentencesFromSearch implements Action {
    readonly type = SearchActionTypes.SetSurprisingSentencesFromSearch;
    constructor(public payload: SurprisingSentence[]) {}
}

export class SetSurprisingSentencesFailure implements Action {
    readonly type = SearchActionTypes.SetSurprisingSentencesFailure;
    constructor(public payload: string) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type SearchActionsUnion =
    SetQueryText
  | BuildDocumentSearch
  | SearchDocuments
  | SearchDocumentsSuccess
  | SearchDocumentsFailure
  | SearchNewDocuments
  | SearchNewDocumentsSuccess
  | SearchNewDocumentsFailure
  | SearchImages
  | SearchImagesSuccess
  | SearchImagesFailure
  | SearchSuggestionsSuccess
  | SearchSuggestionsFailure
  | RemoveSearchTerm
  | AddSearchTerms
  | AddAOIQuery
  | SetClassifications
  | AddDocumentResponseTime
  | AddImageResponseTime
  | Export
  | ExportSuccess
  | ExportFailure
  | SetInitialSearch
  | AddPill
  | RemovePill
  | RemovePills
  | SetExportLoading
  | ResetAll
  | SetResetAllState
  | SetDisplayResetAll
  | GetDocImages
  | GetDocImagesSuccess
  | GetDocImagesFailure
  | SetWordCloudAppliedTerms
  | RemoveCloudAppliedTerms
  | SetDocumentDetailPanelContent
  | HydrationSearchReset
  | SetLoadingFlags
  | GetSurprisingSentencesFromSearch
  | SetSurprisingSentencesFromSearch
  | SetSurprisingSentencesFailure;
