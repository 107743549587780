import { Action } from '@ngrx/store';
import { Layer } from 'leaflet';

export enum MeasureActionTypes {
    SetMetric = '[Measure] Set Metric',
    SetMeasuring = '[Measure] Set Measure status',
    SetMapMeasures = '[Measure] Set Map Measures',
    SetMeasureLayer = '[Measure] Set Measure Layer',
    ResetMeasureLayer = '[Measure] Reset Measure Layer'
}

export class SetMetric implements Action {
    readonly type = MeasureActionTypes.SetMetric;

    constructor(public payload: Boolean) {}
}

export class SetMeasuring implements Action {
    readonly type = MeasureActionTypes.SetMeasuring;

    constructor(public payload: Boolean) {}
}

export class SetMapMeasures implements Action {
    readonly type = MeasureActionTypes.SetMapMeasures;

    constructor(public payload: Array<Array<any>>) {}
}

export class SetMeasureLayer implements Action {
    readonly type = MeasureActionTypes.SetMeasureLayer;

    constructor(public payload: Layer) {}
}

export class ResetMeasureLayer implements Action {
    readonly type = MeasureActionTypes.ResetMeasureLayer;
}

export type MeasureActionsUnion =
| SetMetric
| SetMeasuring
| SetMapMeasures
| SetMeasureLayer
| ResetMeasureLayer;

