import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { HydrationActions } from '../actions';
import { map } from 'rxjs/operators';

@Injectable()
export class HydrationEffects {

    @Effect()
    hydrationStateVerification$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<HydrationActions.SearchCompleted |
                HydrationActions.MapServerHydrationCompleted |
                HydrationActions.HydrationSearchResetCompleted |
                HydrationActions.HydrationStateVerification |
                HydrationActions.SearchDocumentSuccessCompleted |
                HydrationActions.FacetsSearchCompleted
            >(
                HydrationActions.HydrationActionTypes.SearchCompleted,
                HydrationActions.HydrationActionTypes.MapServerHydrationCompleted,
                HydrationActions.HydrationActionTypes.HydrationSearchResetCompleted,
                HydrationActions.HydrationActionTypes.DocumentFacetsRemovalCompleted,
                HydrationActions.HydrationActionTypes.SearchDocumentSuccessCompleted,
                HydrationActions.HydrationActionTypes.FacetsSearchCompleted
            ),
            map(() => new HydrationActions.HydrationStateVerification())
        )

    @Effect()
    resetHydration$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<HydrationActions.ResetHydration>(HydrationActions.HydrationActionTypes.ResetHydration),
            map(action => new HydrationActions.StartHydration(action.payload))
        )

    constructor(
        private actions$: Actions,
    ) { }
}
