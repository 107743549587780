export var HypothesisActionType;
(function (HypothesisActionType) {
    HypothesisActionType["SetAllowedCountries"] = "[Hypothesis] Set the Allowed Contries";
    HypothesisActionType["SetHypothesis"] = "[Hypothesis] Set the Hypothesis Model";
    HypothesisActionType["SetHypothesisCard"] = "[Hypothesis] Set the Hypothesis Card Model";
    HypothesisActionType["SetHypothesisSubscribe"] = "[Hypothesis] Set the Hypothesis Subscribe Model";
    HypothesisActionType["AddHypothesis"] = "[Hypothesis] Add a Hypothesis Model";
    HypothesisActionType["AddHypothesisCard"] = "[Hypothesis] Add a Hypothesis Card Model";
    HypothesisActionType["RemoveHypothesis"] = "[Hypothesis] Remove a Hypothesis Model";
    HypothesisActionType["RemoveHypothesisCard"] = "[Hypothesis] Remove a Hypothesis Card Model";
    HypothesisActionType["UpdateHypothesisEnable"] = "[Hypothesis] Update a Hypothesis Model Enable";
    HypothesisActionType["SetUserSubscribedModel"] = "[Hypothesis] Update a User subscribed Model Enable";
    HypothesisActionType["UpdateHypothesis"] = "[Hypothesis] Update Hypothesis model";
    HypothesisActionType["UpdateHypothesisCard"] = "[Hypothesis] Update Hypothesis Card model";
    HypothesisActionType["SetHypothesisMainLoading"] = "[Hypothesis] Update Hypothesis Main Loading Spinner";
    HypothesisActionType["SetHypothesisManagerLoading"] = "[Hypothesis] Update Hypothesis Manager Loading Spiner";
})(HypothesisActionType || (HypothesisActionType = {}));
var SetHypothesisMainLoading = /** @class */ (function () {
    function SetHypothesisMainLoading(payload) {
        this.payload = payload;
        this.type = HypothesisActionType.SetHypothesisMainLoading;
    }
    return SetHypothesisMainLoading;
}());
export { SetHypothesisMainLoading };
var SetHypothesisManagerLoading = /** @class */ (function () {
    function SetHypothesisManagerLoading(payload) {
        this.payload = payload;
        this.type = HypothesisActionType.SetHypothesisManagerLoading;
    }
    return SetHypothesisManagerLoading;
}());
export { SetHypothesisManagerLoading };
var SetAllowedCountries = /** @class */ (function () {
    function SetAllowedCountries(payload) {
        this.payload = payload;
        this.type = HypothesisActionType.SetAllowedCountries;
    }
    return SetAllowedCountries;
}());
export { SetAllowedCountries };
var SetHypothesis = /** @class */ (function () {
    function SetHypothesis(payload) {
        this.payload = payload;
        this.type = HypothesisActionType.SetHypothesis;
    }
    return SetHypothesis;
}());
export { SetHypothesis };
var SetHypothesisCard = /** @class */ (function () {
    function SetHypothesisCard(payload) {
        this.payload = payload;
        this.type = HypothesisActionType.SetHypothesisCard;
    }
    return SetHypothesisCard;
}());
export { SetHypothesisCard };
var UpdateHypothesisCard = /** @class */ (function () {
    function UpdateHypothesisCard(payload) {
        this.payload = payload;
        this.type = HypothesisActionType.UpdateHypothesisCard;
    }
    return UpdateHypothesisCard;
}());
export { UpdateHypothesisCard };
var UpdateHypothesis = /** @class */ (function () {
    function UpdateHypothesis(payload) {
        this.payload = payload;
        this.type = HypothesisActionType.UpdateHypothesis;
    }
    return UpdateHypothesis;
}());
export { UpdateHypothesis };
var SetUserSubscribedModel = /** @class */ (function () {
    function SetUserSubscribedModel(payload) {
        this.payload = payload;
        this.type = HypothesisActionType.SetUserSubscribedModel;
    }
    return SetUserSubscribedModel;
}());
export { SetUserSubscribedModel };
