import { Injectable } from '@angular/core';
import { AppConfigService, AppConfig } from '@app/app-config.service';


@Injectable()
export class AppGlobals {
    public config: AppConfig;

    constructor(configSvc: AppConfigService) {
        this.config = configSvc.getConfig();
    }

    isEnvironment(env: string): boolean {
        return window.location.href.includes(env);
    }

  /*   public getBaseApiUrl(): string {
        return this.baseApiUrl;
    } */

    public getBaseUrl(): string {
        return ;
    }
}
