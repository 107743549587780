export var SEARCH_SEISBOOK = '[Seisbook] Search Seisbook';
export var SEARCH_SEISBOOK_SUCCESS = '[Seisbook] Search Seisbook Success';
export var SEARCH_SEISBOOK_FAIL = '[Seisbook] Search Seisbook Failure';
export var SEARCH_SEISBOOK_NO_ACCESS = '[Seisbook] Search Seisbook No Access';
export var SEARCH_SEISBOOK_AUTHORIZATION_FAILURE = '[Seisbook] Search Seisbook Authorization Failure';
export var SEARCH_SEISBOOK_EMPTY = '[Seisbook] Search Empty';
var SearchSeisbook = /** @class */ (function () {
    function SearchSeisbook(payload) {
        this.payload = payload;
        this.type = SEARCH_SEISBOOK;
    }
    return SearchSeisbook;
}());
export { SearchSeisbook };
var SearchSeisbookSuccess = /** @class */ (function () {
    function SearchSeisbookSuccess(payload) {
        this.payload = payload;
        this.type = SEARCH_SEISBOOK_SUCCESS;
    }
    return SearchSeisbookSuccess;
}());
export { SearchSeisbookSuccess };
var SearchSeisbookFailure = /** @class */ (function () {
    function SearchSeisbookFailure(payload) {
        this.payload = payload;
        this.type = SEARCH_SEISBOOK_FAIL;
    }
    return SearchSeisbookFailure;
}());
export { SearchSeisbookFailure };
var SearchSeisbookNoAccess = /** @class */ (function () {
    function SearchSeisbookNoAccess(payload) {
        this.payload = payload;
        this.type = SEARCH_SEISBOOK_NO_ACCESS;
    }
    return SearchSeisbookNoAccess;
}());
export { SearchSeisbookNoAccess };
var SearchSeisbookAuthorizationFailure = /** @class */ (function () {
    function SearchSeisbookAuthorizationFailure(payload) {
        this.payload = payload;
        this.type = SEARCH_SEISBOOK_AUTHORIZATION_FAILURE;
    }
    return SearchSeisbookAuthorizationFailure;
}());
export { SearchSeisbookAuthorizationFailure };
var SearchSeisbookEmpty = /** @class */ (function () {
    function SearchSeisbookEmpty() {
        this.type = SEARCH_SEISBOOK_EMPTY;
    }
    return SearchSeisbookEmpty;
}());
export { SearchSeisbookEmpty };
