var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { EMPTY as empty, Observable, of, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, concatMap, } from 'rxjs/operators';
import { WorkspacesActions } from '../actions';
import { WorkspaceService } from '@app/modules/workspaces/services/workspace.service';
import { NotificationService } from '@app/modules/workspaces/services/notification.service';
/**
 * Effects offer a way to isolate and easily test side-effects within your
 * application.
 *
 * If you are unfamiliar with the operators being used in these examples, please
 * check out the sources below:
 *
 * Official Docs: http://reactivex.io/rxjs/manual/overview.html#categories-of-operators
 * RxJS 5 Operators By Example: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35
 */
var WorkspacesEffects = /** @class */ (function () {
    function WorkspacesEffects(actions$, workspacesService, notificationsService, store$) {
        var _this = this;
        this.actions$ = actions$;
        this.workspacesService = workspacesService;
        this.notificationsService = notificationsService;
        this.store$ = store$;
        this.updateWorkspaces$ = function () {
            return _this.actions$.pipe(ofType(WorkspacesActions.WorkspacesActionTypes.UpdateWorkspace, WorkspacesActions.WorkspacesActionTypes.CreateWorkspace, WorkspacesActions.WorkspacesActionTypes.DeleteWorkspace, WorkspacesActions.WorkspacesActionTypes.LoadAllWorkspaces), concatMap(function (workspace) {
                return combineLatest([
                    _this.workspacesService.loadWorkspaces(),
                    _this.workspacesService.loadPersonalWorkspaces(),
                    _this.workspacesService.loadTemplateWorkspaces()
                ]).pipe(map(function (_a) {
                    var all = _a[0], personal = _a[1], template = _a[2];
                    _this.workspacesService.resetNameValidation();
                    _this.workspacesService.getTitlesOfWorkspacesToSetNames(personal);
                    _this.workspacesService.getTitlesOfWorkspacesToSetNames(template);
                    return new WorkspacesActions.LoadAllWorkspacesSuccess(all, personal, template);
                }), catchError(function (err) { return of(new WorkspacesActions.LoadAllWorkspacesFailure(err)); }));
            }));
        };
        this.workspaceLoaded$ = function () {
            return _this.actions$.pipe(ofType(WorkspacesActions.WorkspacesActionTypes.LoadWorkspace), switchMap(function (action) {
                _this.workspacesService.currentWorkspace = action.payload;
                if (action.payload) {
                    _this.workspacesService.openSnackbar('loaded', action.payload.title);
                    var popup = action.payload.appState.find(function (item) { return item.app === 'popup'; });
                    /*
                    if (popup) {
                        const data = JSON.parse(popup.data);
                        if (data.features && data.features.length) {
                            const layer = data.features[0].feature;
                            if (layer && layer.geometry.coordinates) {
                                return of(new PopupActions.OpenPopup(
                                    new LatLng(layer.geometry.coordinates[0], layer.geometry.coordinates[1])
                                ));
                            }
                        }
                    }
                    */
                }
                return empty;
            }));
        };
        this.updateNotifications$ = function () {
            return _this.actions$.pipe(ofType(WorkspacesActions.WorkspacesActionTypes.LoadNotifications), concatMap(function (workspace) {
                return _this.notificationsService.getNotifications().pipe(map(function (notifications) {
                    return new WorkspacesActions.LoadNotificationsSuccess(notifications);
                }), catchError(function (err) { return of(new WorkspacesActions.LoadNotificationsFailure(err)); }));
            }));
        };
        this.createNotification$ = function () {
            return _this.actions$.pipe(ofType(WorkspacesActions.WorkspacesActionTypes.CreateNotification), switchMap(function (action) {
                return _this.notificationsService.createNotification({ workspaceId: action.payload }).pipe(map(function (res) {
                    if (res) {
                        return new WorkspacesActions.LoadNotifications();
                    }
                }));
            }));
        };
        this.deleteNotification$ = function () {
            return _this.actions$.pipe(ofType(WorkspacesActions.WorkspacesActionTypes.DeleteNotification), switchMap(function (action) {
                return _this.notificationsService.deleteNotification(action.payload).pipe(map(function (res) {
                    if (res) {
                        return new WorkspacesActions.LoadNotifications();
                    }
                }));
            }));
        };
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], WorkspacesEffects.prototype, "updateWorkspaces$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], WorkspacesEffects.prototype, "workspaceLoaded$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], WorkspacesEffects.prototype, "updateNotifications$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], WorkspacesEffects.prototype, "createNotification$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], WorkspacesEffects.prototype, "deleteNotification$", void 0);
    return WorkspacesEffects;
}());
export { WorkspacesEffects };
