<div class="modal-layout">
    <div class="modal-content" [ngClass]="{'large': large}">
        <div class="row" [ngClass]="{'header': hasHeader}">
            <div class="col-xs-12 get-help-modal-header">
                <ng-content select="[modal-header]"></ng-content>
                <div class="close">
                    <ng-content select="[modal-close]"></ng-content>
                </div>
            </div>
        </div>
        <div class="row content">
            <div class="col-xs-12">
                <ng-content select="[modal-content]"></ng-content>
            </div>
        </div>
        <div class="row controls">
            <div class="col-xs-12">
                <div class="modal-controls">
                    <ng-content select="[modal-controls]"></ng-content>
                </div>
            </div>
        </div>
    </div>
</div>