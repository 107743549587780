import { WorkspacesActions } from '../actions';
import { Workspace } from '../models/UserAPI.model';

export interface State {
  workspace: Workspace;
  allWorkspaces: Workspace[];
  personalWorkspaces: Workspace[];
  templateWorkspaces: Workspace[];
  notifications: any[];
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export const initialState: State = {
  workspace: null,
  allWorkspaces: [],
  personalWorkspaces: [],
  templateWorkspaces: [],
  notifications: [],
};

export function reducer(
  state = initialState,
  action: WorkspacesActions.WorkspacesActionsUnion
): State {
  switch (action.type) {
    case WorkspacesActions.WorkspacesActionTypes.LoadAllWorkspacesSuccess:
      return {
        ...state,
        allWorkspaces: action.all,
        personalWorkspaces: action.personal,
        templateWorkspaces: action.template
      };
    case WorkspacesActions.WorkspacesActionTypes.LoadWorkspace:
      return {
        ...state,
        workspace: action.payload
      };
    case WorkspacesActions.WorkspacesActionTypes.UpdateWorkspace:
      return {
        ...state,
        workspace: action.payload
      };
    case WorkspacesActions.WorkspacesActionTypes.UpdateLegenedOpen:
    return {
      ...state,
      workspace: {
        ...state.workspace,
        legendOpen: action.payload
      }
    };
    case WorkspacesActions.WorkspacesActionTypes.CreateWorkspace:
      return {
        ...state,
        workspace: action.payload
      };
    case WorkspacesActions.WorkspacesActionTypes.DeleteWorkspace:
      return {
        ...state,
        workspace: action.payload
      };
    case WorkspacesActions.WorkspacesActionTypes.LoadNotificationsSuccess:
      return {
        ...state,
        notifications: action.payload
      };
    default: {
      return state;
    }
  }
}
