import { Action } from '@ngrx/store';
import { Document } from '../models/SearchAPI.model';
import { Mention } from '@app/modules/geosentiment/models/mention';

export enum GeosentimentActionTypes {
    SaveInitialData = '[Geosentiment] Save Initial Data',
    SaveDocumentsData = '[Geosentiment] Save Documents Data',
    SaveGraphProcessedData = '[Geosentiment] Save Graph Processed Data',
    SetIndexesSelected = '[Geosentiment] Set Indexes Selected',
    SetResponseTime = '[Geosentiment] Set Response Time',
    SetStreamGraphOnTheFlyQuery = '[Geosentiment] Set Stream On The Fly Query',
    RunAnalysis = '[Geosentiment] Run Analysis'
}

export class SaveInitialData implements Action {
    readonly type = GeosentimentActionTypes.SaveInitialData;

    constructor(public payload: {graph: {}, mentions: Mention[]}) {}
}

export class SaveDocumentsData implements Action {
    readonly type = GeosentimentActionTypes.SaveDocumentsData;

    constructor(public payload: Document[]) {}
}

export class SaveGraphProcessedData implements Action {
    readonly type = GeosentimentActionTypes.SaveGraphProcessedData;

    constructor(public payload: {}) {}
}

export class SetIndexesSelected implements Action {
    readonly type = GeosentimentActionTypes.SetIndexesSelected;

    constructor(public payload: number[]) {}
}

export class SetResponseTime implements Action {
    readonly type = GeosentimentActionTypes.SetResponseTime;

    constructor(public payload: number) {}
}

export class SetStreamGraphOnTheFlyQuery implements Action {
    readonly type = GeosentimentActionTypes.SetStreamGraphOnTheFlyQuery;
}

export class RunAnalysis implements Action {
    readonly type = GeosentimentActionTypes.RunAnalysis;

    constructor(public payload: {}) {}
}

export type GeosentimentActionsUnion =
    | SaveInitialData
    | SaveDocumentsData
    | SetIndexesSelected
    | SaveGraphProcessedData
    | SetResponseTime
    | SetStreamGraphOnTheFlyQuery
    | RunAnalysis;
