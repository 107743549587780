import { HttpHeaders } from '@angular/common/http';
import { APIErrorHandler } from './api-error-handler.service';
import { AppConfigService } from '@app/app-config.service';
var APIInterceptor = /** @class */ (function () {
    function APIInterceptor(_appConfig, errorHandler) {
        this._appConfig = _appConfig;
        this.errorHandler = errorHandler;
        this._config = this._appConfig.getConfig();
    }
    APIInterceptor.prototype.intercept = function (request, next) {
        var requestUrl = request.url;
        if (requestUrl.includes(this._config.datascience_api_baseurl)) {
            if (!this._config.datascience_api_token) {
                request = request.clone({
                    headers: new HttpHeaders({
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache'
                    }),
                    withCredentials: true
                });
            }
            else {
                request = request.clone({
                    headers: new HttpHeaders({
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache'
                    }),
                    setHeaders: {
                        'Authorization': this._config.datascience_api_token
                    }
                });
            }
        }
        return next.handle(request);
    };
    return APIInterceptor;
}());
export { APIInterceptor };
