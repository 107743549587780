<xom-global-feedback-modal
        *ngIf="((activeModal$ | async) === 'feedback') || ((activeModal$ | async) === 'emoji-feedback')"
        [version]="version"
        (submitFeedback)="submitFeedback($event)"
        (submitFileList)="submitFileList($event)"
        (closeModal)="closeModal()"
></xom-global-feedback-modal>

<xom-show-help-modal
  *ngIf="((activeModal$ | async) === 'show-help')"
  (closeModal)="closeModal()"
></xom-show-help-modal>

<xom-welcome-message *ngIf="((activeModal$ | async) === 'tutorial')" (closeModal)="closeModal()" (next)="openStaticOptions()">
</xom-welcome-message>
<xom-ftue-static *ngIf="showStaticOptions" [count]="count" (closeStaticOptions)="closeStaticOptions()" (backToWelcome)="backToWelcome()" (openDynamicOptions)="openDynamicOptions()">  
</xom-ftue-static>
<xom-ftue-dynamic *ngIf="showDynamicOptions" (closeDynamicOptions)="closeDynamicOptions()" (backToStaticOptions)="backToStaticOptions()">  
</xom-ftue-dynamic>

<xom-ws-loading-screen
    *ngIf="wsLoadingScreen"
></xom-ws-loading-screen>

<xom-promote-modal
    *ngIf="promoteModal"
    [workspaceId]="workspaceId"
    [workspaceName]="workspaceName"
    [workspaceCategory]="workspaceCategory"
    [workspaceDescription]="workspaceDescription"
    (closeModal)="closePromoteModal()"
></xom-promote-modal>
