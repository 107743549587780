import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'formatDate'})
export class FormatDatePipe implements PipeTransform {
  transform(value): any {
    const parsedDate = new Date(Date.parse(value));
    const day = parsedDate.getDate();
    const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December'][parsedDate.getMonth()];
    const year = parsedDate.getFullYear();
    return month + ' ' + day + this.getOrdinalNum(day) + ', ' + year;
  }

  getOrdinalNum(n) {
    return (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
  }
}
