import { InjectionToken } from '@angular/core';
import { ContentService } from '@shared/services/content.service';
var WSContent = /** @class */ (function () {
    function WSContent() {
        this.MANAGER = {
            labels: {
                title: 'Workspaces',
                sortLabel: 'SORT BY',
                tabPersonal: 'My Workspaces',
                tabTemplate: 'Template Workspaces',
                linkLabel1: 'Update your workspace',
                linkLabel2: 'preferences',
                searchTerms: 'Search terms',
                layers: 'AOI GEO UNITS',
                visitWorkspace: 'Visit Workspace',
                newResults: 'New Results',
                noResults: 'No Results',
                saveLabel: 'Save Workspace',
                saveTitle: 'Current workspace',
                saveLabelNew: 'Save New Workspace',
                renameLabel: 'Rename Workspace',
                duplicateLabel: 'Save as New Workspace Copy',
                shareLabel: 'Share Workspace',
                copyLabel: 'Copy',
                copySuffix: ' (Copy)',
                notifyText: 'Notify me by email when new search results are found',
                setHomepageText: 'Set as homepage',
                noWorkspaces: 'There are no saved workspaces in your profile',
                noTemplateWorkspaces: 'No Template Workspaces found',
                noCurrent: 'No workspace created',
                lastSaved: 'Last Saved',
                lastModified: 'Last Modified',
                nameUsed: 'There is already another workspace with this name',
                noTerms: 'No search terms available for this workspace',
                searchPlaceholder: 'Search Workspaces',
                requestLayers: 'Request Access to Regional & Thematic Maps',
                noWorkspacesSelected: ' - No Workspace Selected - ',
                selectOption: 'Select an Option',
                selectWorkspace: 'Select a Workspace',
                NoFavoriteWSSelected: 'No Favorite Workspaces Selected',
                name: 'Name',
                placeholderName: 'Enter workspace name',
                description: 'Description',
                placeholderDescription: 'Add a description of your workspace',
                editButton: 'Edit',
                author: 'Author',
                saveChanges: 'Save Changes',
                descriptionUpdated: 'Workspace Updates Successfully',
                workspaceNameLabel: 'Workspace Name',
                noDescription: 'This workspace has no description',
                showAll: 'Show All',
                favorites: 'Favorites',
                no_results_by_category: [
                    'We haven\'t found any results for your search in this category',
                    'Try again using different search terms or explore more results in another category'
                ]
            },
            urls: {
                share: '/geobrain?workspaceId=',
                requestLayers: 'https://ishareteam8.na.xom.com/sites/Geobrain/Web Map Services WMS/Forms/AllItems.aspx'
            },
            snackbars: {
                cleaned: 'Current workspace empty',
                loaded: 'Workspace loaded',
                deleted: 'Workspace deleted',
                updated: 'Workspace updated successfully',
                created: 'Workspace created successfully',
                duplicated: 'Workspace duplicated successfully',
                defaultLoaded: 'Default workspace loaded',
                defaultUpdated: 'Default workspace updated',
                recentLoaded: 'Most recent workspace loaded',
                urlCopied: 'Share URL copied to clipboard',
                errorLoading: 'There was an error when loading the workspace',
                saveLayer: 'Layers saved to workspace'
            },
            options: {
                listOptions: [
                    { label: 'Personal', icon: 'personal', value: 'personal' },
                ],
                sortOptions: [
                    { label: 'Name (A-Z)', value: 'name_asc' },
                    { label: 'Most Recent', value: 'date_desc' },
                    { label: 'Least Recent', value: 'date_asc' }
                ],
                flipcardOptions: [
                    { label: 'Rename', actionType: 'renameWS' },
                    { label: 'Save As', actionType: 'duplicateWS' },
                    { label: 'Share', actionType: 'shareWS' },
                    { label: 'Set as Homepage', actionType: 'setHomeWS' },
                    { label: 'Delete', actionType: 'deleteWS' },
                    { label: 'Description', actionType: 'viewWSDescription' }
                ],
                flipcardOptionsTemplates: [
                    { label: 'Save as Personal Workspace', actionType: 'duplicateWS' },
                    { label: 'Share', actionType: 'shareWS' },
                    { label: 'Set as Homepage', actionType: 'setHomeWS' },
                    { label: 'Description', actionType: 'viewWSDescription' }
                ],
                standardOptions: [
                    { label: 'Save', actionType: 'saveWS', class: 'hide-wide-screen' },
                    { label: 'Save As', actionType: 'duplicateWS' },
                    { label: 'Rename', actionType: 'renameWS' },
                    { label: 'Share', actionType: 'shareWS', class: 'hide-wide-screen' },
                    { label: 'Set as Homepage', actionType: 'setHomeWS' },
                    { label: 'Delete', actionType: 'deleteWS' },
                    { label: 'Fresh Start', actionType: 'cleanWS' },
                    { label: 'View All Workspaces', actionType: 'viewAllWS' },
                    { label: 'Description', actionType: 'viewWSDescription' }
                ],
                templateOptions: [
                    { label: 'Save As', actionType: 'duplicateWS' },
                    { label: 'Share', actionType: 'shareWS' },
                    { label: 'Set as Homepage', actionType: 'setHomeWS' },
                    { label: 'Fresh Start', actionType: 'cleanWS' },
                    { label: 'View All Workspaces', actionType: 'viewAllWS' },
                    { label: 'Description', actionType: 'viewWSDescription' }
                ],
                favoriteOptions: [
                    { label: 'Favorite', actionType: 'favoriteWS' },
                    { label: 'Unfavorite', actionType: 'unfavoriteWS' }
                ],
                categories: [
                    'My Workspaces',
                    'Regional'
                ],
            },
            personalCategory: 'personal'
        }; // end LAYER_STORE
        this.PROMOTE_DIALOG = {
            title: 'Promote a copy of this workspace',
            button: 'Promote',
            select: 'Select',
            promoteAs: 'PROMOTE AS'
        };
        this.PERSONAL_CATEGORIES = [
            { 'name': 'My Workspaces' }
        ];
    }
    return WSContent;
}());
export { WSContent };
export var WS_CONTENT = new InjectionToken('WS_CONTENT');
var csFactory = function () {
    // https://github.com/mgechev/ngrev/issues/36
    return new ContentService(new WSContent);
};
var ɵ0 = csFactory;
export var WSContentProvider = {
    provide: WS_CONTENT,
    useFactory: csFactory
};
export { ɵ0 };
