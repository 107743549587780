import { GlobalContent } from '@shared/shared-content';
export interface IContentService<G, T> {
  global: G;
  local: T;
}

export class ContentService <T> implements IContentService<GlobalContent, T> {

  public localeCode = 'en-US';
  public global: GlobalContent;

  constructor(
    public local: T
  ) {
    this.global = new GlobalContent;
    // do check to get custom localeCode from API
    // else default to english US.
  }
}
