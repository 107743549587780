var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { asyncScheduler, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, withLatestFrom, } from 'rxjs/operators';
import { SeisbookAPIService } from '../services/seisbook-api.service';
import { SeisbookLayerService } from '../services/seisbook-layer.service';
import { SeisbookSearchActions } from '../actions';
import { dispatchErrorMessage } from '@app/shared/util/search-util';
import { SearchActionTypes } from '@app/modules/search/actions/search.actions';
import { AppConfigService } from '@app/app-config.service';
var SeisbookSearchEffects = /** @class */ (function () {
    function SeisbookSearchEffects(actions$, store$, gisStore$, seisbookAPI, seisbookLayerSvc, sharedStore, configSvc) {
        var _this = this;
        this.actions$ = actions$;
        this.store$ = store$;
        this.gisStore$ = gisStore$;
        this.seisbookAPI = seisbookAPI;
        this.seisbookLayerSvc = seisbookLayerSvc;
        this.sharedStore = sharedStore;
        this.configSvc = configSvc;
        this.triggerSearch = function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.debounce, debounce = _c === void 0 ? 300 : _c, _d = _b.scheduler, scheduler = _d === void 0 ? asyncScheduler : _d;
            return _this.actions$.pipe(ofType(SearchActionTypes.Search), debounceTime(debounce, scheduler), withLatestFrom(_this.store$, _this.gisStore$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1], gisState = _a[2];
                var queryText = action.payload || storeState.search.shared.queryText;
                var seisbookAppInstalled = false;
                _this._config.myApps.forEach(function (app) {
                    if (app.name.includes('seisbook-app')) {
                        seisbookAppInstalled = true;
                    }
                });
                var basins = gisState.gis.aoi.aoiQuery['basin'] || [];
                var countries = gisState.gis.aoi.aoiQuery['country'] || [];
                if (seisbookAppInstalled) {
                    if (countries.length) {
                        return _this.seisbookLayerSvc.getBasinNames(countries).pipe(map(function (countryBasins) {
                            if (countryBasins.length) {
                                basins = basins.concat(countryBasins);
                            }
                            return new SeisbookSearchActions.SearchSeisbook({ queryText: queryText, basins: basins, count: 100, pageIndex: 1 });
                        }), catchError(function (err) { return of(new SeisbookSearchActions.SearchSeisbookFailure(err)); }));
                    }
                    return [new SeisbookSearchActions.SearchSeisbook({ queryText: queryText, basins: basins, count: 100, pageIndex: 1 })];
                }
                else {
                    return [];
                }
            }));
        };
        this.searchSeisbook$ = function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.debounce, debounce = _c === void 0 ? 300 : _c, _d = _b.scheduler, scheduler = _d === void 0 ? asyncScheduler : _d;
            return _this.actions$.pipe(ofType(SeisbookSearchActions.SEARCH_SEISBOOK), debounceTime(debounce, scheduler), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1];
                var queryText = action.payload.queryText || storeState.search.shared.queryText;
                var pageIndex = action.payload.pageIndex || 1;
                var count = action.payload.count;
                var basins = action.payload.basins;
                if (queryText === '' && basins.length === 0) {
                    return of(new SeisbookSearchActions.SearchSeisbookEmpty());
                }
                return _this.seisbookAPI.searchSeisbook(queryText, basins, pageIndex, count).pipe(map(function (res) {
                    if (res.status === 401 || res.status === 403) {
                        return new SeisbookSearchActions.SearchSeisbookAuthorizationFailure(res);
                    }
                    return new SeisbookSearchActions.SearchSeisbookSuccess(res);
                }), catchError(function (err) {
                    if (err.status === 401 || err.status === 403 || String(err.message).includes('AADSTS50105')) {
                        return of(new SeisbookSearchActions.SearchSeisbookNoAccess(err));
                    }
                    else if (String(err.message).includes('Http failure during parsing')) {
                        return of(new SeisbookSearchActions.SearchSeisbookAuthorizationFailure(err));
                    }
                    else {
                        dispatchErrorMessage(_this.sharedStore, err);
                        return of(new SeisbookSearchActions.SearchSeisbookFailure(err));
                    }
                }));
            }));
        };
        this._config = configSvc.getConfig();
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SeisbookSearchEffects.prototype, "triggerSearch", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SeisbookSearchEffects.prototype, "searchSeisbook$", void 0);
    return SeisbookSearchEffects;
}());
export { SeisbookSearchEffects };
