import * as FileSaver from 'file-saver';
import { SearchActions } from '@app/modules/search/actions';
import { Store } from '@ngrx/store';
var ExportFileService = /** @class */ (function () {
    function ExportFileService(store) {
        this.store = store;
    }
    ExportFileService.prototype.exportAsCSVFile = function (data, csvFileName) {
        var blob = new Blob([data], { type: 'text/csv' });
        FileSaver.saveAs(blob, csvFileName + '.csv');
        this.store.dispatch(new SearchActions.SetExportLoading(false));
    };
    ExportFileService.prototype.exportAsShapeFile = function (data, shapeFileName) {
        if (data === void 0) { data = ''; }
        if (shapeFileName === void 0) { shapeFileName = ''; }
        this.store.dispatch(new SearchActions.SetExportLoading(false));
    };
    return ExportFileService;
}());
export { ExportFileService };
