import { Action } from '@ngrx/store';

export enum BasinAwarenessActions {
  LoadBasin = '[Awareness] Load Basin',
  LoadBasinSuccess = '[Awareness] Load Basin Success',
  LoadBasinFailure = '[Awareness] Load Basin Failure',
  GetGeosentimentStatic = '[Awareness] Get Geosentiment Static',
  SetBasinMontages = '[Awareness] Set Basin Montages',
  GetGeosentimentStaticSuccess = '[Awareness] Get Geosentiment Static Success',
  GetGeosentimentStaticFailure = '[Awareness] Get Geosentiment Static Failure',
  SetCreamingCurveAvailable = '[Awareness] Set Creaming Curve Available'
}

export class LoadBasin implements Action {
  readonly type = BasinAwarenessActions.LoadBasin;

  constructor(public payload: string) {}
}

export class LoadBasinSuccess implements Action {
  readonly type = BasinAwarenessActions.LoadBasinSuccess;

  constructor(public payload: any) {}
}

export class LoadBasinFailure implements Action {
  readonly type = BasinAwarenessActions.LoadBasinFailure;

  constructor(public payload: any) {}
}

export class GetGeosentimentStatic implements Action {
  readonly type = BasinAwarenessActions.GetGeosentimentStatic;

  constructor() {}
}

export class GetGeosentimentStaticSuccess implements Action {
  readonly type = BasinAwarenessActions.GetGeosentimentStaticSuccess;

  constructor(public payload: any) {}
}

export class GetGeosentimentStaticFailure implements Action {
  readonly type = BasinAwarenessActions.GetGeosentimentStaticFailure;

  constructor(public payload: any) {}
}

export class SetCreamingCurveAvailable implements Action {
  readonly type = BasinAwarenessActions.SetCreamingCurveAvailable;

  constructor(public payload: boolean) {}
}

export class SetBasinMontages implements Action {
  readonly type = BasinAwarenessActions.SetBasinMontages;

  constructor(public payload: any[]) {}
}

export type BasinAwarenessActionsUnion =
    LoadBasin
  | LoadBasinSuccess
  | LoadBasinFailure
  | GetGeosentimentStatic
  | GetGeosentimentStaticSuccess
  | GetGeosentimentStaticFailure
  | SetBasinMontages
  | SetCreamingCurveAvailable;
