var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CollectionsActions } from '@shared/actions';
export var initialState = {
    userCollection: {
        collectionId: 0,
        collection: [],
        comments: []
    },
    allUserCollections: [],
    collectionResponse: null,
    loading: false,
    loadingAllCollections: false,
    selectedDocuments: 0,
    selectedDocumentNames: [],
    CSVDocs: '',
    allowCardsRefresh: false,
    collectionNotExisting: false,
    toggleNotesPanel: false,
    isNotesFromFlipcard: false,
    shownNotes: [],
    notesLoading: false,
    fileSummary: [],
    filtersTypes: [],
    fileSort: 1
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case CollectionsActions.CollectionsActionTypes.LoadCollection:
            return __assign({}, state, { loading: true, collectionResponse: null });
        case CollectionsActions.CollectionsActionTypes.LoadCollectionSuccess:
            return __assign({}, state, { userCollection: action.payload, allowCardsRefresh: true });
        case CollectionsActions.CollectionsActionTypes.LoadCollectionSummary:
            return __assign({}, state, { fileSummary: action.payload });
        case CollectionsActions.CollectionsActionTypes.SetFilteringTypes:
            return __assign({}, state, { filtersTypes: action.payload });
        case CollectionsActions.CollectionsActionTypes.ResetFilteringTypes:
            return __assign({}, state, { filtersTypes: [] });
        case CollectionsActions.CollectionsActionTypes.GetFilteredCollection:
            return __assign({}, state, { loading: true });
        case CollectionsActions.CollectionsActionTypes.GetFilteredCollectionSuccess:
            return __assign({}, state, { userCollection: action.payload });
        case CollectionsActions.CollectionsActionTypes.GetSortedCollection:
            return __assign({}, state, { loading: true, fileSort: action.payload.fileSort });
        case CollectionsActions.CollectionsActionTypes.GetSortedCollectionSuccess:
            return __assign({}, state, { userCollection: action.payload });
        case CollectionsActions.CollectionsActionTypes.CollectionSearch:
            return __assign({}, state, { loading: true });
        case CollectionsActions.CollectionsActionTypes.CollectionSearchSuccess:
            return __assign({}, state, { userCollection: action.payload });
        case CollectionsActions.CollectionsActionTypes.GetCollectionDocumentsSuccess:
            return __assign({}, state, { collectionResponse: action.payload, loading: false });
        case CollectionsActions.CollectionsActionTypes.LoadAllCollections:
            return __assign({}, state, { loadingAllCollections: true });
        case CollectionsActions.CollectionsActionTypes.LoadAllCollectionsSuccess:
            return __assign({}, state, { allUserCollections: action.payload, loadingAllCollections: false });
        case CollectionsActions.CollectionsActionTypes.DeleteCollectionSuccess:
            var allCollections = state.allUserCollections.slice();
            var collectionIndex_1 = -1;
            allCollections.forEach(function (collection, indx) {
                if (collection.collectionId === action.payload) {
                    collectionIndex_1 = indx;
                }
            });
            allCollections.splice(collectionIndex_1, 1);
            return __assign({}, state, { allUserCollections: allCollections });
        case CollectionsActions.CollectionsActionTypes.RemoveFromCollection:
            var collectionUpdate = __assign({}, state.userCollection);
            collectionUpdate.lastModified = new Date();
            return __assign({}, state, { userCollection: collectionUpdate, allowCardsRefresh: true });
        case CollectionsActions.CollectionsActionTypes.SetSelectedDocument:
            var selectedDocumentsNumber = state.selectedDocuments;
            var selectedDocumentsNames = state.selectedDocumentNames;
            var index_1 = 0;
            if (action.payload.isSelected) {
                selectedDocumentsNumber++;
                selectedDocumentsNames.push(action.payload.documentName);
            }
            else {
                selectedDocumentsNumber--;
                selectedDocumentsNames.forEach(function (name, i) { if (action.payload.documentName === name) {
                    index_1 = i;
                } });
                selectedDocumentsNames.splice(index_1, 1);
            }
            return __assign({}, state, { selectedDocuments: selectedDocumentsNumber, selectedDocumentNames: selectedDocumentsNames });
        case CollectionsActions.CollectionsActionTypes.ResetSelectedDocument:
            return __assign({}, state, { selectedDocuments: 0, selectedDocumentNames: [] });
        case CollectionsActions.CollectionsActionTypes.SetAllSelectedDocument:
            return __assign({}, state, { selectedDocuments: action.payload });
        case CollectionsActions.CollectionsActionTypes.SetNotPresentFilesToRemove:
            return __assign({}, state, { selectedDocumentNames: action.payload });
        case CollectionsActions.CollectionsActionTypes.ExportAllCollectionDocs:
            return __assign({}, state);
        case CollectionsActions.CollectionsActionTypes.ExportAllCollectionDocsSuccess:
            return __assign({}, state, { CSVDocs: action.payload });
        case CollectionsActions.CollectionsActionTypes.SetCardsFlag:
            return __assign({}, state, { allowCardsRefresh: false });
        case CollectionsActions.CollectionsActionTypes.CollectionNotExisting:
            return __assign({}, state, { collectionNotExisting: action.payload });
        case CollectionsActions.CollectionsActionTypes.AddCollectionComment:
            return __assign({}, state, { notesLoading: true });
        case CollectionsActions.CollectionsActionTypes.DeleteCollectionComment:
            return __assign({}, state, { notesLoading: true });
        case CollectionsActions.CollectionsActionTypes.ToggleNotesPanel:
            return __assign({}, state, { toggleNotesPanel: action.payload, notesLoading: action.payload });
        case CollectionsActions.CollectionsActionTypes.SetNotesFromFlipcard:
            return __assign({}, state, { isNotesFromFlipcard: action.payload.state });
        case CollectionsActions.CollectionsActionTypes.SetNotesLoading:
            return __assign({}, state, { notesLoading: action.payload });
        case CollectionsActions.CollectionsActionTypes.SetShownNotes:
            return __assign({}, state, { shownNotes: action.payload, notesLoading: false });
        case CollectionsActions.CollectionsActionTypes.UpdateCollectionSuccess:
            return __assign({}, state, { userCollection: action.payload });
        default: {
            return state;
        }
    }
}
