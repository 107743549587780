export var UserActionTypes;
(function (UserActionTypes) {
    UserActionTypes["LoadAllUsers"] = "[Users] Load All Users";
    UserActionTypes["LoadUser"] = "[Users] Load User";
    UserActionTypes["UpdateUser"] = "[Users] Update User";
    UserActionTypes["LoadPreferences"] = "[Users] Load Preferences";
    UserActionTypes["UpdatePreferences"] = "[Users] Update Preferences";
    UserActionTypes["ScreenSizeWarning"] = "[Users] Screen Size Warning";
    UserActionTypes["SetActiveModal"] = "[Users] Set Active Modal";
    UserActionTypes["GetAvailableMessages"] = "[Users] Get Available Messages";
    UserActionTypes["GetAvailableMessagesSuccess"] = "[Users] Get Available Messages Success";
    UserActionTypes["SetPromoterCategories"] = "[Users] Set Promoder Categories";
})(UserActionTypes || (UserActionTypes = {}));
var LoadAllUsers = /** @class */ (function () {
    function LoadAllUsers(payload) {
        this.payload = payload;
        this.type = UserActionTypes.LoadAllUsers;
    }
    return LoadAllUsers;
}());
export { LoadAllUsers };
var LoadUser = /** @class */ (function () {
    function LoadUser(payload) {
        this.payload = payload;
        this.type = UserActionTypes.LoadUser;
    }
    return LoadUser;
}());
export { LoadUser };
var UpdateUser = /** @class */ (function () {
    function UpdateUser(payload) {
        this.payload = payload;
        this.type = UserActionTypes.UpdateUser;
    }
    return UpdateUser;
}());
export { UpdateUser };
var LoadPreferences = /** @class */ (function () {
    function LoadPreferences(payload) {
        this.payload = payload;
        this.type = UserActionTypes.LoadPreferences;
    }
    return LoadPreferences;
}());
export { LoadPreferences };
var UpdatePreferences = /** @class */ (function () {
    function UpdatePreferences(payload) {
        this.payload = payload;
        this.type = UserActionTypes.UpdatePreferences;
    }
    return UpdatePreferences;
}());
export { UpdatePreferences };
var ScreenSizeWarning = /** @class */ (function () {
    function ScreenSizeWarning() {
        this.type = UserActionTypes.ScreenSizeWarning;
    }
    return ScreenSizeWarning;
}());
export { ScreenSizeWarning };
var SetActiveModal = /** @class */ (function () {
    function SetActiveModal(payload) {
        this.payload = payload;
        this.type = UserActionTypes.SetActiveModal;
    }
    return SetActiveModal;
}());
export { SetActiveModal };
var GetAvailableMessages = /** @class */ (function () {
    function GetAvailableMessages() {
        this.type = UserActionTypes.GetAvailableMessages;
    }
    return GetAvailableMessages;
}());
export { GetAvailableMessages };
var GetAvailableMessagesSuccess = /** @class */ (function () {
    function GetAvailableMessagesSuccess(payload) {
        this.payload = payload;
        this.type = UserActionTypes.GetAvailableMessagesSuccess;
    }
    return GetAvailableMessagesSuccess;
}());
export { GetAvailableMessagesSuccess };
var SetPromoterCategories = /** @class */ (function () {
    function SetPromoterCategories(payload) {
        this.payload = payload;
        this.type = UserActionTypes.SetPromoterCategories;
    }
    return SetPromoterCategories;
}());
export { SetPromoterCategories };
