<xom-modal-layout>
  <div modal-content>
    <div class="row middle-xs promote-container">
      <div class="col-xs-12 close-container no-gutter">
        <span (click)="closeModal.emit()" class="close icon-close increase-icon"></span>
      </div>
      <div class="col-xs-12 promote-content">

        <h1 class="title"> {{content.global.PROMOTE_MODAL.title}} </h1>

        <div class="edit-message-container">
            <strong> {{content.global.PROMOTE_MODAL.editMessage.strong}}  </strong>
            {{content.global.PROMOTE_MODAL.editMessage.text}}
        </div>
        <div class="description-container">
            {{content.global.PROMOTE_MODAL.descriptionMessage}}
        </div>

        <div class="ws-name-container">
            <span> {{content.global.PROMOTE_MODAL.nameLabel}} </span>
            <input #workspaceNameInput xom-input type="text" id="ws-name" name="ws-name" value="{{workspaceName}}">
        </div>

        <div class="ws-name-container-error" *ngIf="workspaceNameError">
            {{workspaceNameError}}
        </div>

        <div class="description-container">
            <textarea [class.grey]="greyTextArea" #textarea (focus)="focusFunction()" (focusout)="focusOutFunction()" class="xom-input" id="ws-description"> {{workspaceDescription}} </textarea>
        </div>
        <div class="options">
          <button (click)="closeModal.emit()" xom-button> {{content.global.PROMOTE_MODAL.cancelButton}} </button>
          <button (click)="promote()" xom-button> {{content.global.PROMOTE_MODAL.publishButton}} </button>
        </div>
      </div>
    </div>
  </div>
</xom-modal-layout>
