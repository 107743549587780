import { JulianDatePipe } from './pipes/julian-date.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { FileNamePipe } from './pipes/file-name.pipe';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { SearchAPIService } from './services/search-api.service';
import { UserAPIService } from './services/user-api.service';
import { FieldDataService } from './services/field-data.service';
import { ExportFileService } from './services/exportFile.service';
import { WSContentProvider } from '@app/modules/workspaces/workspaces-content';
import { SafeURLPipe } from './pipes/safe-url.pipe';
import { GeobrainLoaderService } from './services/geobrain-loader.service';
import { AnalogService } from '@shared/services/analog.service';
import { CreamingCurvesService } from '@app/shared/services/creaming-curves.service';
import { MessageService } from '@shared/services/message.service';
import { PromoteWSAPIService } from '@shared/services/promoteWS.service';
import { InterrogatorService } from '@app/shared/services/interrogator.service';
import { PlayService } from './services/play.service';
import { HttpWrapperService } from './api/http-wrapper.service';
import { AppConfigService } from '@app/app-config.service';
import { BasinEvaluationHistoryService } from './services/basin-evaluation-history.service';
var XomSharedModule = /** @class */ (function () {
    function XomSharedModule() {
    }
    XomSharedModule.forRoot = function () {
        return {
            ngModule: XomSharedModule,
            providers: [
                SearchAPIService,
                UserAPIService,
                JulianDatePipe,
                FormatDatePipe,
                FileNamePipe,
                SafeHTMLPipe,
                SafeURLPipe,
                FieldDataService,
                ExportFileService,
                WSContentProvider,
                GeobrainLoaderService,
                CreamingCurvesService,
                AnalogService,
                MessageService,
                PromoteWSAPIService,
                InterrogatorService,
                BasinEvaluationHistoryService,
                PlayService,
                HttpWrapperService,
                AppConfigService,
            ]
        };
    };
    return XomSharedModule;
}());
export { XomSharedModule };
