var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MapTableActions } from '@gis/actions/index';
/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export var initialState = {
    loading: false,
    expanded: false,
    esriLayer: null,
    cols: [],
    rows: [],
    title: null,
    filterMap: false,
    selectedRowId: null,
    offset: 0,
    totalCount: 0,
    pageSize: 100,
    sortingData: {
        direction: null,
        property: null
    },
    selectedTables: [],
    activeRowsOnMap: [],
    activeRowsFeatures: [],
    allLayerFeatures: []
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case MapTableActions.MapTableActionTypes.LoadMapTableLayerByUrl:
            var tableAlreadyOpen_1 = false;
            var tables = state.selectedTables.slice();
            tables.forEach(function (table) {
                table.active = false;
                if (table.url === action.payload.url && table.layerId === action.payload.layerId) {
                    table.active = true;
                    tableAlreadyOpen_1 = true;
                }
            });
            if (!tableAlreadyOpen_1) {
                tables.push({
                    url: action.payload.url,
                    layerId: action.payload.layerId,
                    title: null,
                    active: true,
                    expressions: [],
                    condition: ''
                });
            }
            return __assign({}, state, { offset: 0, cols: [], rows: [], sortingData: {
                    direction: null,
                    property: null
                }, selectedTables: tables.slice() });
        case MapTableActions.MapTableActionTypes.LoadMapTableLayer:
            var layerData_1 = action.payload ? action.payload : state.esriLayer;
            var tableLayers = void 0;
            tableLayers = state.selectedTables.slice();
            tableLayers.forEach(function (table) {
                if (!table.title && table.url === layerData_1.url && table.layerId === layerData_1.id) {
                    table.title = layerData_1.name;
                }
            });
            return __assign({}, state, { esriLayer: layerData_1, title: layerData_1.name, loading: true, totalCount: null, selectedTables: tableLayers.slice(), activeRowsFeatures: [] });
        case MapTableActions.MapTableActionTypes.LoadLayerFeaturesSuccess:
            return __assign({}, state, { rows: action.payload.features.map(function (feat) { return feat.properties; }), cols: action.fields, totalCount: action.count, loading: false });
        case MapTableActions.MapTableActionTypes.LoadAllLayerFeaturesSuccess:
            return __assign({}, state, { allLayerFeatures: action.payload.features, loading: false });
        case MapTableActions.MapTableActionTypes.LoadLayerFeaturesFailure:
            return __assign({}, state, { rows: [], cols: [{ name: 'Error loading table', type: 'Error loading table', alias: 'Error loading table' }], loading: false });
        case MapTableActions.MapTableActionTypes.ToggleMapTable:
            return __assign({}, state, { expanded: action.payload });
        case MapTableActions.MapTableActionTypes.CloseMapTable:
            return __assign({}, state, { title: action.payload, filterMap: false, totalCount: 0, selectedTables: [] });
        case MapTableActions.MapTableActionTypes.SetSelectedTables:
            return __assign({}, state, { selectedTables: action.payload });
        case MapTableActions.MapTableActionTypes.FilterByMapExtent:
            return __assign({}, state, { filterMap: action.payload, totalCount: null });
        case MapTableActions.MapTableActionTypes.SetRowFeatureId:
            return __assign({}, state, { selectedRowId: action.payload });
        case MapTableActions.MapTableActionTypes.SetTableOffset:
            return __assign({}, state, { offset: action.payload });
        case MapTableActions.MapTableActionTypes.SetSortingData:
            return __assign({}, state, { sortingData: action.payload });
        case MapTableActions.MapTableActionTypes.SetActiveRowsOnMap:
            return __assign({}, state, { activeRowsOnMap: action.payload });
        case MapTableActions.MapTableActionTypes.SetActiveRowsFeatures:
            return __assign({}, state, { activeRowsFeatures: action.payload });
        default: {
            return state;
        }
    }
}
