import { EventEmitter, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ContentService } from '@shared/services/content.service';
import { PromoteWSAPIService } from '@shared/services/promoteWS.service';
import { take } from 'rxjs/operators';
import { MessageService, CHANNELS, OPERATIONS } from '@app/shared/services/message.service';
import { WorkspacesActions } from '@app/shared/actions';
import { Store } from '@ngrx/store';
var XomPromoteModalComponent = /** @class */ (function () {
    function XomPromoteModalComponent(sharedStore, content, promoteService, messages) {
        var _this = this;
        this.sharedStore = sharedStore;
        this.content = content;
        this.promoteService = promoteService;
        this.messages = messages;
        this.closeModal = new EventEmitter(null);
        this.ESC_KEY_CODE = 27;
        this.workspaceNameError = '';
        this.promoteChannelSubscribe$ = messages.getMessage(CHANNELS.Promote).subscribe(function (msg) {
            if (msg && msg.operation === OPERATIONS.Custom) {
                if (msg.data && msg.data.error) {
                    _this.workspaceNameError = msg.data.error;
                }
                if (msg.data && msg.data.cloneId) {
                    _this.promoteService.promote(msg.data.cloneId, _this.workspaceCategory).pipe(take(1)).subscribe(function (res) {
                        _this.sharedStore.dispatch(new WorkspacesActions.LoadAllWorkspaces());
                        _this.closeModal.emit();
                    });
                }
            }
        });
    }
    XomPromoteModalComponent.prototype.ngOnInit = function () {
        if (this.workspaceDescription && this.workspaceDescription !== '') {
            this.greyTextArea = false;
        }
        else {
            this.workspaceDescription = this.content.global.PROMOTE_MODAL.textAreaPlaceHolder;
            this.greyTextArea = true;
        }
    };
    XomPromoteModalComponent.prototype.ngOnDestroy = function () {
        this.promoteChannelSubscribe$.unsubscribe();
    };
    XomPromoteModalComponent.prototype.promote = function () {
        this.messages.sendMessage(CHANNELS.Promote, {
            operation: OPERATIONS.Clone,
            data: {
                workspaceName: this.workspaceNameInput.nativeElement.value,
                workspaceCategory: this.workspaceCategory,
                workspaceDescription: this.textarea.nativeElement.value
                    .includes(this.content.global.PROMOTE_MODAL.textAreaPlaceHolder) ?
                    '' : this.textarea.nativeElement.value
            }
        });
    };
    XomPromoteModalComponent.prototype.focusFunction = function () {
        if (this.textarea.nativeElement.value.includes(this.content.global.PROMOTE_MODAL.textAreaPlaceHolder)) {
            this.textarea.nativeElement.value = '';
            this.greyTextArea = false;
        }
    };
    XomPromoteModalComponent.prototype.focusOutFunction = function () {
        if (this.textarea.nativeElement.value === '') {
            this.textarea.nativeElement.value = this.content.global.PROMOTE_MODAL.textAreaPlaceHolder;
            this.greyTextArea = true;
        }
    };
    XomPromoteModalComponent.prototype.onKeyDown = function (event) {
        if (event.keyCode === this.ESC_KEY_CODE) {
            this.closeModal.emit();
        }
    };
    return XomPromoteModalComponent;
}());
export { XomPromoteModalComponent };
