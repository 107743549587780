import {Component, EventEmitter, Input, Output, ViewChild, ElementRef, OnInit, AfterViewInit} from '@angular/core';
import {ContentService} from '@shared/services/content.service';
import {GlobalContent} from '@shared/shared-content';
import {Feedback, Feeling} from '@shared/models/feedback';
import {Router} from '@angular/router';

@Component({
  selector: 'xom-global-feedback-modal',
  templateUrl: './xom-global-feedback-modal.component.html'
})
export class XomGlobalFeedbackModalComponent implements AfterViewInit {
  @ViewChild('divFocused', { read: ElementRef }) divFocused: ElementRef;
  @Input() version: string;
  @Output() submitFeedback: EventEmitter<Feedback> = new EventEmitter(null);
  @Output() submitFileList: EventEmitter<any> = new EventEmitter(null);
  @Output() closeModal: EventEmitter<null> = new EventEmitter(null);
  activeFeeling: Feeling;
  feedback: Feedback;
  fileList: any[];
  feelings: Feeling[];
  isMood: boolean;

  constructor(public content: ContentService<GlobalContent>, private router: Router) {
    this.feelings = this.content.local.GIVE_FEEDBACK_MODAL.FEELINGS;
    this.isMood = false;
    this.initializeFeedback();
  }

  ngAfterViewInit() {
    this.divFocused.nativeElement.focus();
  }

  initializeFeedback() {
    this.feedback = {subject: '', category: this.feelings[0].category, description: '', URL: window.location.origin + this.router.url};
    this.fileList = [];
    this.activeFeeling = this.feelings[0];
  }

  fileChange(event) {
    if (event.target.files.length) {
      this.fileList.push(event.target.files);
    }
  }

  fileRemoved(event) {
    this.fileList.splice(event, 1);
  }

  close() {
    this.initializeFeedback();
    this.closeModal.emit();
  }

  submit() {
    this.submitFileList.emit(this.fileList);
    this.submitFeedback.emit(this.feedback);
  }

  setFeedbackType(event) {
    this.activeFeeling = event;
    this.feedback.category = this.activeFeeling.category;
    if (event.subject !== this.feelings[0].subject && event.subject !== this.feelings[4].subject) {
      this.feedback.subject = event.subject;
      this.isMood = true;
    } else {
      this.feedback.subject = '';
      this.isMood = false;
    }
  }

  mouseover(event, feeling) {
    event.target.src = feeling.assetActive;
  }

  mouseout(event, feeling) {
    event.target.src = this.activeFeeling.subject === feeling.subject ? feeling.assetActive : feeling.assetInactive;
  }
}

