import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { asyncScheduler, EMPTY as empty, Observable } from 'rxjs';
import {
    debounceTime,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';

import * as fromSearch from '@search/reducers';
import * as fromGis from '@gis/reducers';
import { Query, } from '@shared/models/SearchAPI.model';
import { GeosentimentActions } from '../actions';
import { LocalStorageService } from '../services/local-storage.service';
import { Router } from '@angular/router';


@Injectable()
export class GeosentimentEffects {

    @Effect()
    getStreamGraphOnTheFly$ = ({ debounce = 300, scheduler = asyncScheduler } = {}): Observable< Action > =>
        this.actions$.pipe(
            ofType<GeosentimentActions.SetStreamGraphOnTheFlyQuery>(
                GeosentimentActions.GeosentimentActionTypes.SetStreamGraphOnTheFlyQuery ),
            debounceTime(debounce, scheduler),
            withLatestFrom(this.store$, this.gisStore$),
            switchMap(([action, storeState, gisState]) => {

                const docQuery: Query = {
                    keywords: [storeState.search.shared.queryText],
                    facets: storeState.search.documentQuery.facets,
                    sortField: storeState.search.documentQuery.sortField,
                    sortDirection: storeState.search.documentQuery.sortDirection,
                    country: gisState.gis.aoi.aoiQuery['country'] || [],
                    basin: gisState.gis.aoi.aoiQuery['basin'] || [],
                    block: gisState.gis.aoi.aoiQuery['block'] || [],
                    well: gisState.gis.aoi.aoiQuery['well'] || []
                };

                this.storage.setItem('docQuery',
                    JSON.stringify(docQuery)
                );

                const url = this.router.createUrlTree(['/geosentiment/on-the-fly']);
                this.router.navigate([]).then(result => {  window.open(url.toString(), '_blank'); });

                return empty;
            })
        )


    constructor(
        private actions$: Actions,
        private store$: Store<fromSearch.State>,
        private gisStore$: Store<fromGis.State>,
        private storage: LocalStorageService,
        private router: Router
    ) { }
}
