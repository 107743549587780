<xom-modal-layout>
  <div modal-content>
    <div class="row middle-xs welcome-container">
      <div class="splash-waves top-left">
        <img src="/assets/images/swoop-top-left-turquoise.svg">
      </div>
      <div class="col-xs-12 close-container no-gutter">
        <span (click)="closeModal.emit()" class="close icon-close increase-icon"></span>
      </div>
      <div class="col-xs-12 welcome-content">
        <h1 class="title">{{globalContent.global.WELCOME_CONTENT.TITLE}}</h1>
        <p class="message" [innerHtml]="globalContent.global.WELCOME_CONTENT.MESSAGE"></p>
        <div class="options">
          <div class="second-message">
            {{globalContent.global.WELCOME_CONTENT.OPTIONS.MESSAGE}}
            <div class="help-option" (click)="openHelpOption()">
              {{globalContent.global.WELCOME_CONTENT.OPTIONS.OPTION_1}}
            </div>
          </div>
          <button xom-button (click)="next.emit()">{{globalContent.global.WELCOME_CONTENT.OPTIONS.OPTION_2}}</button>
        </div>
      </div>
      <input type="checkbox" #divFocused>
      <div class="splash-waves bottom">
        <img src="/assets/images/swoop-turquoise.svg">
      </div>
      <div class="splash-waves bottom">
        <img src="/assets/images/swoop-transparent-gray.svg">
      </div>
      <div class="splash-waves bottom-left">
        <img src="/assets/images/swoop-front-bottom-left-blue.svg">
      </div>
      <div class="splash-waves bottom-right">
        <img src="/assets/images/swoop-bottom-right-blue.svg">
      </div>
    </div>
  </div>
</xom-modal-layout>