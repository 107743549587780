import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
    delay,
    map,
    tap
} from 'rxjs/operators';

import { SnackbarActions } from '../actions';
import { MatSnackBar } from '@angular/material';

/**
 * Effects offer a way to isolate and easily test side-effects within your
 * application.
 *
 * If you are unfamiliar with the operators being used in these examples, please
 * check out the sources below:
 *
 * Official Docs: http://reactivex.io/rxjs/manual/overview.html#categories-of-operators
 * RxJS 5 Operators By Example: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35
 */

@Injectable()
export class SnackbarEffects {

    @Effect({
        dispatch: false
    })
        closeSnackbar$ = (): Observable<
            Action
        > =>
        this.actions$.pipe(
            ofType<SnackbarActions.SnackbarActionsUnion>(
                SnackbarActions.SnackbarActionTypes.Close
            ),
            tap(() => this.matSnackBar.dismiss())
        )

   @Effect()
        showSnackbar$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<SnackbarActions.SnackbarActionsUnion>(
                SnackbarActions.SnackbarActionTypes.Open
            ),
            map((action: SnackbarActions.Open) => action.payload),
            tap(payload => this.matSnackBar.open(payload.message, payload.action, payload.config)),
            delay(30000 ),
            tap(payload => {
                if (payload.type !== 'xom-snackbar-fullscreen-error') {
                    this.matSnackBar.dismiss();
                }
            }),
            map(() => new SnackbarActions.OpenSuccess() )
        )

  constructor(
    private actions$: Actions,
    private matSnackBar: MatSnackBar
  ) {}
}
