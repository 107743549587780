/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./xom-promote-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../layouts/modal-layout/modal-layout.component.ngfactory";
import * as i3 from "../../layouts/modal-layout/modal-layout.component";
import * as i4 from "../../directives/xom-input/xom-input.directive";
import * as i5 from "@angular/common";
import * as i6 from "../xom-button/xom-button.component.ngfactory";
import * as i7 from "../xom-button/xom-button.component";
import * as i8 from "./xom-promote-modal.component";
import * as i9 from "@ngrx/store";
import * as i10 from "../../services/content.service";
import * as i11 from "../../services/promoteWS.service";
import * as i12 from "../../services/message.service";
var styles_XomPromoteModalComponent = [i0.styles];
var RenderType_XomPromoteModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_XomPromoteModalComponent, data: {} });
export { RenderType_XomPromoteModalComponent as RenderType_XomPromoteModalComponent };
function View_XomPromoteModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "ws-name-container-error"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.workspaceNameError; _ck(_v, 1, 0, currVal_0); }); }
export function View_XomPromoteModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { textarea: 0 }), i1.ɵqud(402653184, 2, { workspaceNameInput: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 31, "xom-modal-layout", [], null, null, null, i2.View_ModalLayoutComponent_0, i2.RenderType_ModalLayoutComponent)), i1.ɵdid(3, 114688, null, 0, i3.ModalLayoutComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 2, 29, "div", [["modal-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 28, "div", [["class", "row middle-xs promote-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "col-xs-12 close-container no-gutter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "close icon-close increase-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 25, "div", [["class", "col-xs-12 promote-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h1", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "edit-message-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), (_l()(), i1.ɵted(14, null, [" ", " "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "description-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, [" ", " "])), (_l()(), i1.ɵeld(17, 0, null, null, 4, "div", [["class", "ws-name-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(19, null, [" ", " "])), (_l()(), i1.ɵeld(20, 0, [[2, 0], ["workspaceNameInput", 1]], null, 1, "input", [["class", "xom-input"], ["id", "ws-name"], ["name", "ws-name"], ["type", "text"], ["xom-input", ""]], [[8, "value", 0]], null, null, null, null)), i1.ɵdid(21, 16384, null, 0, i4.XomInputDirective, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_XomPromoteModalComponent_1)), i1.ɵdid(23, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 2, "div", [["class", "description-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, [[1, 0], ["textarea", 1]], null, 1, "textarea", [["class", "xom-input"], ["id", "ws-description"]], [[2, "grey", null]], [[null, "focus"], [null, "focusout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (_co.focusFunction() !== false);
        ad = (pd_0 && ad);
    } if (("focusout" === en)) {
        var pd_1 = (_co.focusOutFunction() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(26, null, [" ", " "])), (_l()(), i1.ɵeld(27, 0, null, null, 6, "div", [["class", "options"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 2, "button", [["xom-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_XomButtonComponent_0, i6.RenderType_XomButtonComponent)), i1.ɵdid(29, 49152, null, 0, i7.XomButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(30, 0, [" ", " "])), (_l()(), i1.ɵeld(31, 0, null, null, 2, "button", [["xom-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.promote() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_XomButtonComponent_0, i6.RenderType_XomButtonComponent)), i1.ɵdid(32, 49152, null, 0, i7.XomButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(33, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_6 = _co.workspaceNameError; _ck(_v, 23, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content.global.PROMOTE_MODAL.title; _ck(_v, 10, 0, currVal_0); var currVal_1 = _co.content.global.PROMOTE_MODAL.editMessage.strong; _ck(_v, 13, 0, currVal_1); var currVal_2 = _co.content.global.PROMOTE_MODAL.editMessage.text; _ck(_v, 14, 0, currVal_2); var currVal_3 = _co.content.global.PROMOTE_MODAL.descriptionMessage; _ck(_v, 16, 0, currVal_3); var currVal_4 = _co.content.global.PROMOTE_MODAL.nameLabel; _ck(_v, 19, 0, currVal_4); var currVal_5 = i1.ɵinlineInterpolate(1, "", _co.workspaceName, ""); _ck(_v, 20, 0, currVal_5); var currVal_7 = _co.greyTextArea; _ck(_v, 25, 0, currVal_7); var currVal_8 = _co.workspaceDescription; _ck(_v, 26, 0, currVal_8); var currVal_9 = _co.content.global.PROMOTE_MODAL.cancelButton; _ck(_v, 30, 0, currVal_9); var currVal_10 = _co.content.global.PROMOTE_MODAL.publishButton; _ck(_v, 33, 0, currVal_10); }); }
export function View_XomPromoteModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xom-promote-modal", [], null, [["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_XomPromoteModalComponent_0, RenderType_XomPromoteModalComponent)), i1.ɵdid(1, 245760, null, 0, i8.XomPromoteModalComponent, [i9.Store, i10.ContentService, i11.PromoteWSAPIService, i12.MessageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var XomPromoteModalComponentNgFactory = i1.ɵccf("xom-promote-modal", i8.XomPromoteModalComponent, View_XomPromoteModalComponent_Host_0, { workspaceId: "workspaceId", workspaceName: "workspaceName", workspaceCategory: "workspaceCategory", workspaceDescription: "workspaceDescription" }, { closeModal: "closeModal" }, []);
export { XomPromoteModalComponentNgFactory as XomPromoteModalComponentNgFactory };
