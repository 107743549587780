var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BasinAwarenessActions } from '../actions/index';
export var initialState = {
    basin: '',
    loading: false,
    geoScalesData: null,
    noGeosentimentData: false,
    creamingCurveAvailable: false,
    basinMontages: []
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case BasinAwarenessActions.BasinAwarenessActions.LoadBasin: {
            return __assign({}, state, { basin: action.payload, loading: true, basinMontages: [] });
        }
        case BasinAwarenessActions.BasinAwarenessActions.LoadBasinSuccess: {
            return __assign({}, state, { loading: false, noGeosentimentData: !action.payload });
        }
        case BasinAwarenessActions.BasinAwarenessActions.LoadBasinFailure: {
            return __assign({}, state, { loading: false });
        }
        case BasinAwarenessActions.BasinAwarenessActions.GetGeosentimentStaticSuccess: {
            return __assign({}, state, { geoScalesData: action.payload });
        }
        case BasinAwarenessActions.BasinAwarenessActions.SetCreamingCurveAvailable: {
            return __assign({}, state, { creamingCurveAvailable: action.payload });
        }
        case BasinAwarenessActions.BasinAwarenessActions.SetBasinMontages: {
            return __assign({}, state, { basinMontages: action.payload });
        }
        default: {
            return state;
        }
    }
}
