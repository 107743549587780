import { InterrogatorActions } from '../actions/index';

export interface State {
  showWells: boolean;
  playFilter: boolean;
  selectedFeature: any;
}

export const initialState: State = {
  showWells: false,
  playFilter: false,
  selectedFeature: null
};

export function reducer(
  state = initialState,
  action:
    | InterrogatorActions.InterrogatorActionsUnion
): State {
  switch (action.type) {
    case InterrogatorActions.InterrogatorActions.ToggleWells: {
      return {
        ...state,
        showWells: !state.showWells
      };
    }
    case InterrogatorActions.InterrogatorActions.EnablePlayFilter: {
      return {
        ...state,
        playFilter: !state.playFilter
      };
    }
    case InterrogatorActions.InterrogatorActions.SelectFeature: {
      return {
        ...state,
        selectedFeature: action.payload
      };
    }
    default:
      return state;
  }
}
