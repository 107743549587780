import { Injectable } from '@angular/core';
import { Observable, Subject} from 'rxjs';

// List of Layer Names
export enum CHANNELS {
    Layer = 1, WorkspaceDialog = 2, Promote = 3
}

export enum OPERATIONS {
    Add = 1,
    Remove = 2,
    Save = 3,
    Clean = 4,
    Update = 5,
    Set = 6,
    Custom = 7,
    Show = 8,
    Hide = 9,
    Clone = 10
}

export interface Message {
    operation: OPERATIONS;
    data?: any;
}

@Injectable()
export class MessageService {

    private channels = {};

    channel(channelName: CHANNELS) {
        const ch = this.channels[channelName];
        if (ch) {
            return ch;
        }
        this.channels[channelName] = new Subject<any>();
        return this.channels[channelName];
    }

    sendMessage(channelName: CHANNELS, message: Message) {
        this.channel(channelName).next(message);
    }

    getMessage(channelName: CHANNELS): Observable<any> {
        return this.channel(channelName).asObservable();
    }

    clearMessage(channelName: CHANNELS) {
        this.channel(channelName).next();
    }
}
