/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./modal-layout.component";
var styles_ModalLayoutComponent = [i0.styles];
var RenderType_ModalLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalLayoutComponent, data: {} });
export { RenderType_ModalLayoutComponent as RenderType_ModalLayoutComponent };
export function View_ModalLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "modal-layout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "modal-content"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "large": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "header": 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "col-xs-12 get-help-modal-header"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "close"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "row content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "col-xs-12"]], null, null, null, null, null)), i1.ɵncd(null, 2), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "row controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "modal-controls"]], null, null, null, null, null)), i1.ɵncd(null, 3)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "modal-content"; var currVal_1 = _ck(_v, 3, 0, _co.large); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "row"; var currVal_3 = _ck(_v, 6, 0, _co.hasHeader); _ck(_v, 5, 0, currVal_2, currVal_3); }, null); }
export function View_ModalLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xom-modal-layout", [], null, null, null, View_ModalLayoutComponent_0, RenderType_ModalLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModalLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalLayoutComponentNgFactory = i1.ɵccf("xom-modal-layout", i3.ModalLayoutComponent, View_ModalLayoutComponent_Host_0, { hasHeader: "hasHeader", large: "large" }, {}, ["[modal-header]", "[modal-close]", "[modal-content]", "[modal-controls]"]);
export { ModalLayoutComponentNgFactory as ModalLayoutComponentNgFactory };
