import { Injectable } from '@angular/core';
import { map, delay } from 'rxjs/operators';
import 'isomorphic-fetch';
import { SplashScreen } from './shared/models/SplashScreen.model';
import { AdminMessage } from './shared/models/AdminMessage.model';
import { App } from './shared/models/UserAPI.model';
import { HttpClient } from '@angular/common/http';
import { buildMessageError } from '@app/shared/util/search-util';
import { from } from 'rxjs';
import { console } from '@app/shared/util/console.util';

export interface AppConfig {
  server: string;
  datascience_api_baseurl: string;
  datascience_api_token: string;
  user_api_baseurl: string;
  instrumentation_key: string;
  show_askbob_feedback: boolean;
  splashscreen: SplashScreen;
  admin_message: AdminMessage;
  apps: App[];
  myApps: App[];
  seisbook_api_baseurl: string;
  seisbook_app_baseurl: string;
  seisbook_file_route: string;
  baseUrl: string;
  msalConfig: {
    clientId: string,
    authority: string,
    geobrain_scope: string,
    geobook_scope: string
  };
  wellServiceUrl: string;
  wellMetadataServiceUrl: string;
  wellCoordinatedataServiceUrl: string;
  basinToPlayServiceUrl: string;
  basinToPlayUrl: string;
  playMetadataServiceUrl: string;
  playSummaryLayerUrl: string;
  basinMontageLayerUrl: string;
  sourceRockServiceUrl: string;
  penetrationServiceUrl: string;
  wellSourceServiceUrl: string;
  playLayerUrl: string;
  basinLayerUrl: string;
  sourceRockLayerUrl: string;
  geobookLayerUrl: string;
  playElementUrls: PlayElements;
  closureLayerUrl: string;
  wellLayerUrl: string;
  geoBertAPIUrl: string;
  askBobToken: string;
  basinEvaluationHistory: BasinEvaluationHistoryConfig;
  basinAwarenessChartCategories: Object;
  geoPhysAwarenessMapUrl: string;
}

/** Base configuration for a map server layer. */
export interface LayerConfig {
  url: string;
  basinField: string;
  idField: string;
}

/** Configuration for a map server layer containing blocks. */
export interface BlockLayerConfig extends LayerConfig {
  nameField: string;
  startDateField: string;
  endDateField: string;
}

/** Configuration for a map server layer containing decision gates. */
export interface DecisionGateLayerConfig extends LayerConfig {
  nameField: string;
  dateField: string;
}

/** Configuration for a map server layer containing geophysical surveys. */
export interface GeophysicalSurveyLayerConfig extends LayerConfig {
  nameField: string;
  dateField: string;
}

/** Configuration for a map server layer containing penetrations. */
export interface PenetrationLayerConfig extends LayerConfig {
  wellIdField: string;
  playIdField: string;
  nameField: string;
  reservoirTopField: string;
  reservoirBottomField: string;
  contentField: string;
  generalContentField: string;
}

/** Configuration for a map server layer containing plays. */
export interface PlayLayerConfig extends LayerConfig {
  nameField: string;
  topDateField: string;
  bottomDateField: string;
}

/** Configuration for a map server layer containing studies. */
export interface StudyLayerConfig extends LayerConfig {
  dateField: string;
}

/** Configuration for a map server layer containing wells. */
export interface WellLayerConfig extends LayerConfig {
  nameField: string;
  dateField: string;
  playIdField: string;
  topAgeField: string;
  bottomAgeField: string;
  contentField: string;
  generalContentField: string;
  discoveryFlagField: string;
  highImpactWellFlagField: string;
  regionallyImportantWellFlagField: string;
}

/** Configuration for a grouping of map server layers. */
export interface LayerConfigGroup<T extends LayerConfig> {
  layers: T[];
}

/** Configuration for map server layers that Basin Evaluation History expects. */
export interface BasinEvaluationHistoryConfig {
  awarenessBasinURL: string;
  awarenessBasinAttribute: string;
  blocks: LayerConfigGroup<BlockLayerConfig>;
  decisionGates: LayerConfigGroup<DecisionGateLayerConfig>;
  geophysicalSurveys: LayerConfigGroup<GeophysicalSurveyLayerConfig>;
  plays: LayerConfigGroup<PlayLayerConfig>;
  penetrations: LayerConfigGroup<PenetrationLayerConfig>;
  studies: LayerConfigGroup<StudyLayerConfig>;
  wells: LayerConfigGroup<WellLayerConfig>;
}

export class PlayElements {
  baseUrl: string;
  layerUrls: string[][];
}

export class MockConfig {
  getConfig() {
    return {
      server: 'local',
      datascience_api_baseurl: 'https://hplml0002.hpl.xom.com:5434/geobrain/v2',
      user_api_baseurl: 'https://userprofilesdev.geobrain.xomsvcs.com/api',
      instrumentation_key: '04b30350-2efc-42c7-bdad-77132e9c5ce8',
      splashscreen: {
        title: 'Explore Basin IQ',
        message: 'Lorem ipsum dolor sit amet',
        button_text: 'Learn More',
        button_link: 'http://www.google.com',
        image_url: 'assets/images/splashscreen.png'
      },
      admin_message: {
        title: 'Slower Performance Than Usual',
        message: 'Lorem ipsum dolor sit amet'
      },
      seisbook_app_baseurl: 'https://test.geobook.xomsvcs.com/',
      seisbook_api_baseurl: 'https://testapi.geobook.xomsvcs.com/api/',
    };
  }
}

@Injectable()
export class AppConfigService {
  appConfig: AppConfig;

  constructor(private http: HttpClient) { }

  loadAppConfig() {
    return from(
      fetch('/assets/config/app-config.json')
        .then(res => {
          return res.json() as unknown as AppConfig;
        })
    ).pipe(
      map(config => {
        this.appConfig = config;
        console.log(`${this.appConfig.server} Environment Loaded`);
        console.log(this.appConfig);
      })
    ).toPromise();
  }

  getApps() {
    return this.http.get<any>(`${this.appConfig.user_api_baseurl}/apps`).pipe(
      map(res => {
        if (res.status) {
          throw Error(buildMessageError(res));
        } else {
          return res;
        }
      })
    );
    // return this.http.get<any>(`/assets/data/app_store_get_apps.json`).pipe(delay(3000)).pipe(
    //   map(res => {
    //     if (res.status) {
    //       throw Error(buildMessageError(res));
    //     } else {
    //       return res;
    //     }
    //   })
    // );
  }

  updateMyApps(apps) {
    this.appConfig.myApps = apps;
  }

  updateApps(apps) {
    this.appConfig.apps = apps;
  }

  getConfig(): AppConfig {
    return this.appConfig;
  }

  getAppByName(appName: string) {
    let app: App;
    this.appConfig.apps.map(appElement => {
      if (appElement.name === appName) {
        app = appElement;
      }
    });
    return app;
  }

}
