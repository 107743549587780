var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { asyncScheduler, EMPTY as empty } from 'rxjs';
import { debounceTime, switchMap, withLatestFrom, } from 'rxjs/operators';
import { GeosentimentActions } from '../actions';
import { LocalStorageService } from '../services/local-storage.service';
import { Router } from '@angular/router';
var GeosentimentEffects = /** @class */ (function () {
    function GeosentimentEffects(actions$, store$, gisStore$, storage, router) {
        var _this = this;
        this.actions$ = actions$;
        this.store$ = store$;
        this.gisStore$ = gisStore$;
        this.storage = storage;
        this.router = router;
        this.getStreamGraphOnTheFly$ = function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.debounce, debounce = _c === void 0 ? 300 : _c, _d = _b.scheduler, scheduler = _d === void 0 ? asyncScheduler : _d;
            return _this.actions$.pipe(ofType(GeosentimentActions.GeosentimentActionTypes.SetStreamGraphOnTheFlyQuery), debounceTime(debounce, scheduler), withLatestFrom(_this.store$, _this.gisStore$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1], gisState = _a[2];
                var docQuery = {
                    keywords: [storeState.search.shared.queryText],
                    facets: storeState.search.documentQuery.facets,
                    sortField: storeState.search.documentQuery.sortField,
                    sortDirection: storeState.search.documentQuery.sortDirection,
                    country: gisState.gis.aoi.aoiQuery['country'] || [],
                    basin: gisState.gis.aoi.aoiQuery['basin'] || [],
                    block: gisState.gis.aoi.aoiQuery['block'] || [],
                    well: gisState.gis.aoi.aoiQuery['well'] || []
                };
                _this.storage.setItem('docQuery', JSON.stringify(docQuery));
                var url = _this.router.createUrlTree(['/geosentiment/on-the-fly']);
                _this.router.navigate([]).then(function (result) { window.open(url.toString(), '_blank'); });
                return empty;
            }));
        };
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], GeosentimentEffects.prototype, "getStreamGraphOnTheFly$", void 0);
    return GeosentimentEffects;
}());
export { GeosentimentEffects };
