import {CollectionComment, UserCollection} from '@shared/models/UserAPI.model';
import { CollectionsActions } from '@shared/actions';

export interface State {
    userCollection: UserCollection;
    allUserCollections: UserCollection[];
    collectionResponse: Document[];
    loading: boolean;
    loadingAllCollections: boolean;
    selectedDocuments: number;
    selectedDocumentNames: string[];
    CSVDocs: string;
    allowCardsRefresh: boolean;
    collectionNotExisting: boolean;
    toggleNotesPanel: boolean;
    isNotesFromFlipcard: boolean;
    shownNotes: CollectionComment[];
    notesLoading: boolean;
    fileSummary: [];
    filtersTypes: string[];
    fileSort: number;
}

export const initialState: State = {
  userCollection: {
    collectionId: 0,
    collection: [],
    comments: []
  },
  allUserCollections: [],
  collectionResponse: null,
  loading: false,
  loadingAllCollections: false,
  selectedDocuments: 0,
  selectedDocumentNames: [],
  CSVDocs: '',
  allowCardsRefresh: false,
  collectionNotExisting: false,
  toggleNotesPanel: false,
  isNotesFromFlipcard: false,
  shownNotes: [],
  notesLoading: false,
  fileSummary: [],
  filtersTypes: [],
  fileSort: 1
};

export function reducer(
  state = initialState,
  action: CollectionsActions.CollectionsActionsUnion
): State {
  switch (action.type) {
    case CollectionsActions.CollectionsActionTypes.LoadCollection:
      return {
        ...state,
        loading: true,
        collectionResponse: null
      };
    case CollectionsActions.CollectionsActionTypes.LoadCollectionSuccess:
      return {
        ...state,
        userCollection: action.payload,
        allowCardsRefresh: true
      };
    case CollectionsActions.CollectionsActionTypes.LoadCollectionSummary:
      return {
        ...state,
        fileSummary: action.payload
      };
    case CollectionsActions.CollectionsActionTypes.SetFilteringTypes:
      return {
        ...state,
        filtersTypes: action.payload
      };
    case CollectionsActions.CollectionsActionTypes.ResetFilteringTypes:
      return {
        ...state,
        filtersTypes: []
      };
    case CollectionsActions.CollectionsActionTypes.GetFilteredCollection:
      return {
        ...state,
        loading: true
      };
    case CollectionsActions.CollectionsActionTypes.GetFilteredCollectionSuccess:
      return {
        ...state,
        userCollection: action.payload
      };
    case CollectionsActions.CollectionsActionTypes.GetSortedCollection:
      return {
        ...state,
        loading: true,
        fileSort: action.payload.fileSort
      };
    case CollectionsActions.CollectionsActionTypes.GetSortedCollectionSuccess:
      return {
        ...state,
        userCollection: action.payload
      };
    case CollectionsActions.CollectionsActionTypes.CollectionSearch:
     return {
       ...state,
         loading: true
     };
    case CollectionsActions.CollectionsActionTypes.CollectionSearchSuccess:
      return {
        ...state,
        userCollection: action.payload
      };
    case CollectionsActions.CollectionsActionTypes.GetCollectionDocumentsSuccess:
      return {
        ...state,
        collectionResponse: action.payload,
        loading: false
      };
    case CollectionsActions.CollectionsActionTypes.LoadAllCollections:
      return {
        ...state,
        loadingAllCollections: true
      };
    case CollectionsActions.CollectionsActionTypes.LoadAllCollectionsSuccess:
      return {
        ...state,
        allUserCollections: action.payload,
        loadingAllCollections: false
      };
    case CollectionsActions.CollectionsActionTypes.DeleteCollectionSuccess:
      const allCollections = [...state.allUserCollections];
      let collectionIndex = -1;
      allCollections.forEach((collection, indx) => {
        if (collection.collectionId === action.payload) {
          collectionIndex = indx;
        }
      });
      allCollections.splice(collectionIndex, 1);
      return {
        ...state,
        allUserCollections: allCollections
      };
    case CollectionsActions.CollectionsActionTypes.RemoveFromCollection:
      const collectionUpdate = { ...state.userCollection};
      collectionUpdate.lastModified = new Date();
      return {
        ...state,
        userCollection: collectionUpdate,
        allowCardsRefresh: true
      };
    case CollectionsActions.CollectionsActionTypes.SetSelectedDocument:
      let selectedDocumentsNumber = state.selectedDocuments;
      const selectedDocumentsNames = state.selectedDocumentNames;
      let index = 0;
      if (action.payload.isSelected) {
          selectedDocumentsNumber++;
          selectedDocumentsNames.push(action.payload.documentName);
      } else {
          selectedDocumentsNumber--;
          selectedDocumentsNames.forEach( (name, i) => { if (action.payload.documentName === name) { index = i; } });
          selectedDocumentsNames.splice(index, 1);
      }
      return {
        ...state,
          selectedDocuments: selectedDocumentsNumber,
          selectedDocumentNames: selectedDocumentsNames
      };
    case CollectionsActions.CollectionsActionTypes.ResetSelectedDocument:
      return {
        ...state,
          selectedDocuments: 0,
          selectedDocumentNames: []
      };
    case CollectionsActions.CollectionsActionTypes.SetAllSelectedDocument:
      return {
        ...state,
          selectedDocuments: action.payload
      };
    case CollectionsActions.CollectionsActionTypes.SetNotPresentFilesToRemove:
      return {
        ...state,
        selectedDocumentNames: action.payload
      };
    case CollectionsActions.CollectionsActionTypes.ExportAllCollectionDocs:
      return {
        ...state
      };
    case CollectionsActions.CollectionsActionTypes.ExportAllCollectionDocsSuccess:
      return {
        ...state,
          CSVDocs: action.payload
      };
    case CollectionsActions.CollectionsActionTypes.SetCardsFlag:
      return {
        ...state,
        allowCardsRefresh: false
      };
    case CollectionsActions.CollectionsActionTypes.CollectionNotExisting:
      return {
        ...state,
        collectionNotExisting: action.payload
      };
    case CollectionsActions.CollectionsActionTypes.AddCollectionComment:
      return {
        ...state,
        notesLoading: true
      };
    case CollectionsActions.CollectionsActionTypes.DeleteCollectionComment:
      return {
        ...state,
        notesLoading: true
      };
    case CollectionsActions.CollectionsActionTypes.ToggleNotesPanel:
      return {
        ...state,
        toggleNotesPanel: action.payload,
        notesLoading: action.payload
      };
    case CollectionsActions.CollectionsActionTypes.SetNotesFromFlipcard:
      return {
        ...state,
        isNotesFromFlipcard: action.payload.state
      };
    case CollectionsActions.CollectionsActionTypes.SetNotesLoading:
          return {
              ...state,
              notesLoading: action.payload
          };
    case CollectionsActions.CollectionsActionTypes.SetShownNotes:
      return {
        ...state,
        shownNotes: action.payload,
        notesLoading: false
      };
    case CollectionsActions.CollectionsActionTypes.UpdateCollectionSuccess:
      return {
        ...state,
        userCollection: action.payload
      };
    default: {
      return state;
    }
  }
}
