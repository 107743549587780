<xom-modal-layout>  
  <div modal-content>
    <div class="row middle-xs splash-container">
      <div class="splash-waves top-left">
        <img src="/assets/images/swoop-top-left.svg">
      </div>
      <div class="close-container no-gutter">
        <span (click)="close.emit()" class="close icon-close increase-icon"></span>
      </div>
      <div class="info-container">
        <div class="dynamic-section">
          <h1 class="title"> {{ splashScreen.title }} </h1>
          <p class="message" [innerHtml]="splashScreen.message"></p>
          <button xom-button (click)="buttonClicked()">{{ splashScreen.button_text }}</button>
        </div>
        <div class="no-gutter splash-screen">
          <img src="{{splashScreen.image_url}}">
        </div>
      </div>
      <div class="splash-waves bottom">
        <img src="/assets/images/swoop.svg">
      </div>
      <div class="splash-waves bottom-left">
        <img src="/assets/images/swoop-front-bottom-left.svg">
      </div>
      <div class="splash-waves bottom-right">
        <img src="/assets/images/swoop-bottom-right.svg">
      </div>
    </div>
  </div>    
</xom-modal-layout>
