export var BasinAwarenessActions;
(function (BasinAwarenessActions) {
    BasinAwarenessActions["LoadBasin"] = "[Awareness] Load Basin";
    BasinAwarenessActions["LoadBasinSuccess"] = "[Awareness] Load Basin Success";
    BasinAwarenessActions["LoadBasinFailure"] = "[Awareness] Load Basin Failure";
    BasinAwarenessActions["GetGeosentimentStatic"] = "[Awareness] Get Geosentiment Static";
    BasinAwarenessActions["SetBasinMontages"] = "[Awareness] Set Basin Montages";
    BasinAwarenessActions["GetGeosentimentStaticSuccess"] = "[Awareness] Get Geosentiment Static Success";
    BasinAwarenessActions["GetGeosentimentStaticFailure"] = "[Awareness] Get Geosentiment Static Failure";
    BasinAwarenessActions["SetCreamingCurveAvailable"] = "[Awareness] Set Creaming Curve Available";
})(BasinAwarenessActions || (BasinAwarenessActions = {}));
var LoadBasin = /** @class */ (function () {
    function LoadBasin(payload) {
        this.payload = payload;
        this.type = BasinAwarenessActions.LoadBasin;
    }
    return LoadBasin;
}());
export { LoadBasin };
var LoadBasinSuccess = /** @class */ (function () {
    function LoadBasinSuccess(payload) {
        this.payload = payload;
        this.type = BasinAwarenessActions.LoadBasinSuccess;
    }
    return LoadBasinSuccess;
}());
export { LoadBasinSuccess };
var LoadBasinFailure = /** @class */ (function () {
    function LoadBasinFailure(payload) {
        this.payload = payload;
        this.type = BasinAwarenessActions.LoadBasinFailure;
    }
    return LoadBasinFailure;
}());
export { LoadBasinFailure };
var GetGeosentimentStatic = /** @class */ (function () {
    function GetGeosentimentStatic() {
        this.type = BasinAwarenessActions.GetGeosentimentStatic;
    }
    return GetGeosentimentStatic;
}());
export { GetGeosentimentStatic };
var GetGeosentimentStaticSuccess = /** @class */ (function () {
    function GetGeosentimentStaticSuccess(payload) {
        this.payload = payload;
        this.type = BasinAwarenessActions.GetGeosentimentStaticSuccess;
    }
    return GetGeosentimentStaticSuccess;
}());
export { GetGeosentimentStaticSuccess };
var GetGeosentimentStaticFailure = /** @class */ (function () {
    function GetGeosentimentStaticFailure(payload) {
        this.payload = payload;
        this.type = BasinAwarenessActions.GetGeosentimentStaticFailure;
    }
    return GetGeosentimentStaticFailure;
}());
export { GetGeosentimentStaticFailure };
var SetCreamingCurveAvailable = /** @class */ (function () {
    function SetCreamingCurveAvailable(payload) {
        this.payload = payload;
        this.type = BasinAwarenessActions.SetCreamingCurveAvailable;
    }
    return SetCreamingCurveAvailable;
}());
export { SetCreamingCurveAvailable };
var SetBasinMontages = /** @class */ (function () {
    function SetBasinMontages(payload) {
        this.payload = payload;
        this.type = BasinAwarenessActions.SetBasinMontages;
    }
    return SetBasinMontages;
}());
export { SetBasinMontages };
