export var PopupActionTypes;
(function (PopupActionTypes) {
    PopupActionTypes["OpenPopup"] = "[Popup] Open Popup";
    PopupActionTypes["ValidatePopupFeatures"] = "[Popup] Validate Popup Features";
    PopupActionTypes["AddPopupFeatures"] = "[Popup] Add Popup Features";
    PopupActionTypes["ClearPopupFeatures"] = "[Popup] Clear Popup Features";
    PopupActionTypes["SetPopupIndex"] = "[Popup] Set Popup Index";
    PopupActionTypes["ZoomToFeature"] = "[Popup] Zoom To Feature";
})(PopupActionTypes || (PopupActionTypes = {}));
var OpenPopup = /** @class */ (function () {
    function OpenPopup(payload) {
        this.payload = payload;
        this.type = PopupActionTypes.OpenPopup;
    }
    return OpenPopup;
}());
export { OpenPopup };
var ValidatePopupFeatures = /** @class */ (function () {
    function ValidatePopupFeatures(payload) {
        this.payload = payload;
        this.type = PopupActionTypes.ValidatePopupFeatures;
    }
    return ValidatePopupFeatures;
}());
export { ValidatePopupFeatures };
var AddPopupFeatures = /** @class */ (function () {
    function AddPopupFeatures(payload) {
        this.payload = payload;
        this.type = PopupActionTypes.AddPopupFeatures;
    }
    return AddPopupFeatures;
}());
export { AddPopupFeatures };
var ClearPopupFeatures = /** @class */ (function () {
    function ClearPopupFeatures(payload) {
        this.payload = payload;
        this.type = PopupActionTypes.ClearPopupFeatures;
    }
    return ClearPopupFeatures;
}());
export { ClearPopupFeatures };
var SetPopupIndex = /** @class */ (function () {
    function SetPopupIndex(payload) {
        this.payload = payload;
        this.type = PopupActionTypes.SetPopupIndex;
    }
    return SetPopupIndex;
}());
export { SetPopupIndex };
var ZoomToFeature = /** @class */ (function () {
    function ZoomToFeature() {
        this.type = PopupActionTypes.ZoomToFeature;
    }
    return ZoomToFeature;
}());
export { ZoomToFeature };
