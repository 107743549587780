import { ActionReducer, Action } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ContextMenuActions } from '../actions/index';
import * as fromGis from './index';
import * as L from 'leaflet';

export interface State {
  event: L.LeafletEvent | null;
}

const initialState: State = { event: null };

export function reducer(
  state = initialState,
  action: ContextMenuActions.ContextMenuActionsUnion
): State {
  switch (action.type) {
    case ContextMenuActions.ContextMenuActionTypes.ToggleMenu:
      return {
        ...state,
        event: action.payload
      };
    default: {
      return state;
    }
  }
}
