// Facate to real console implementation
var ConsoleFacade = /** @class */ (function () {
    function ConsoleFacade() {
    }
    ConsoleFacade.prototype.log = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        console.log.apply(console, args);
    };
    ConsoleFacade.prototype.error = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        console.error.apply(console, args);
    };
    ConsoleFacade.prototype.warn = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        console.warn.apply(console, args);
    };
    ConsoleFacade.prototype.groupCollapsed = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        console.groupCollapsed.apply(console, args);
    };
    ConsoleFacade.prototype.groupEnd = function () {
        console.groupEnd();
    };
    return ConsoleFacade;
}());
export { ConsoleFacade };
