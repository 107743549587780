import { EventEmitter } from '@angular/core';
import { ContentService } from '@shared/services/content.service';
import { MessageService, CHANNELS, OPERATIONS } from '@app/shared/services/message.service';
var XomWsLoadingScreenComponent = /** @class */ (function () {
    function XomWsLoadingScreenComponent(content, messages) {
        var _this = this;
        this.content = content;
        this.messages = messages;
        this.closeModal = new EventEmitter(null);
        this.next = new EventEmitter(null);
        this.timeOutToShowCancelButton = 10000; // 10 Sec
        this.showCancelButton = false;
        setTimeout(function () {
            _this.showCancelButton = true;
        }, this.timeOutToShowCancelButton);
    }
    XomWsLoadingScreenComponent.prototype.onCancel = function () {
        this.messages.sendMessage(CHANNELS.WorkspaceDialog, {
            operation: OPERATIONS.Clean,
            data: {
                prop: 'fresh-start'
            }
        });
    };
    return XomWsLoadingScreenComponent;
}());
export { XomWsLoadingScreenComponent };
