export var MapLayerActionTypes;
(function (MapLayerActionTypes) {
    MapLayerActionTypes["ActivateLayer"] = "[ESRILayer] Activate Layer";
    MapLayerActionTypes["ActivateDynamicMapLayer"] = "[ESRILayer] Activate Dynamic Map Layer";
    MapLayerActionTypes["DeactivateDynamicMapLayer"] = "[ESRILayer] Deactivate Dynamic Map Layer";
    MapLayerActionTypes["RemoveActivatedMapServer"] = "[ESRILayer] Remove Activated Map Server";
    MapLayerActionTypes["ActivateLayerByUrl"] = "[ESRILayer] Activate Layer By Id";
    MapLayerActionTypes["ActivateMultipleLayers"] = "[ESRILayer] Activate Multiple Layers";
    MapLayerActionTypes["DeactivateAllLayers"] = "[ESRILayer] Deactivate All Layers";
    MapLayerActionTypes["SetBasemap"] = "[ESRILayer] Set Basemap";
    MapLayerActionTypes["LoadLayers"] = "[ESRILayer] Load Layers";
    MapLayerActionTypes["LoadLayersSuccess"] = "[ESRILayer] Load Layers Success";
    MapLayerActionTypes["LoadLayersFailure"] = "[ESRILayer] Load Layers Failure";
    MapLayerActionTypes["LoadAllMapServers"] = "[ESRILayer] Load All Map Servers";
    MapLayerActionTypes["LoadMapServer"] = "[ESRILayer] Load MapServers";
    MapLayerActionTypes["LoadMapServerFromPortal"] = "[ESRILayer] Load MapServer From Portal";
    MapLayerActionTypes["LoadLegendFromPortal"] = "[ESRILayer] Load Legend From Portal";
    MapLayerActionTypes["RemoveMapLegends"] = "[ESRILayer] Remove Map Legends";
    MapLayerActionTypes["LoadMapServerSuccess"] = "[ESRILayer] Load MapServers Success";
    MapLayerActionTypes["LoadMapServerFailure"] = "[ESRILayer] Load MapServers Failure";
    MapLayerActionTypes["ReplaceMapServer"] = "[ESRILayer] Replace Map Server";
    MapLayerActionTypes["RemoveMapServer"] = "[ESRILayer] Remove MapServers";
    MapLayerActionTypes["RemoveAllMapServers"] = "[ESRILayer] Remove All Map Servers";
    MapLayerActionTypes["ActivateCentroid"] = "[ESRILayer] Activate Centroid";
    MapLayerActionTypes["LoadLegend"] = "[ESRILayer] Load Legend";
    MapLayerActionTypes["LoadLegendSuccess"] = "[ESRILayer] Load Legend Success";
    MapLayerActionTypes["LoadLegendFailure"] = "[ESRILayer] Load Legend Failure";
    MapLayerActionTypes["ActivateFeaturePopup"] = "[ESRILayer] Activate Popup";
    MapLayerActionTypes["ActivateFeaturePopupSuccess"] = "[ESRILayer] Activate Popup Success";
    MapLayerActionTypes["HighlightFeature"] = "[ESRILayer] Highlight Feature Success";
    MapLayerActionTypes["FilterFeatureLayerSuccess"] = "[ESRILayer] Filter Feature Success";
    MapLayerActionTypes["FilterFeatureLayerFailure"] = "[ESRILayer] Filter Feature Failure";
    MapLayerActionTypes["SetGeoTags"] = "[ESRILayer] Set GeoTags";
    MapLayerActionTypes["LoadLayerFromGeoTag"] = "[ESRILayer] Load Layer From Geotag";
    MapLayerActionTypes["SetZoom"] = "[ESRILayer] Set Zoom";
    MapLayerActionTypes["LoadFieldData"] = "[ESRILayer] Load Field Data";
    MapLayerActionTypes["SetMapLoading"] = "[ESRILayer] Set Loading";
    MapLayerActionTypes["SetBounds"] = "[ESRILayer] Set Bounds";
    MapLayerActionTypes["SetCoordinates"] = "[ESRILayer] Set Coordinates";
    MapLayerActionTypes["ResetActiveCentroid"] = "[ESRILayer] Reset Active Centroid";
    MapLayerActionTypes["SetLayerManagerOpen"] = "[Map AOI] Set Layer Manager Open";
    MapLayerActionTypes["AddFacetFeaturesCollection"] = "[ESRILayer] Add Facet Features Collection";
    MapLayerActionTypes["ClearFacetFeaturesCollection"] = "[ESRILayer] Clear Facet Features Collection";
    MapLayerActionTypes["FitGeobodyBounds"] = "[ESRILayer] Fit Geobody Bounds";
    MapLayerActionTypes["SetLayerListSaveButtonEnable"] = "[ESRILayer] Set the save butoon enable in the layer list component";
    MapLayerActionTypes["SetLastURLMapServerFail"] = "[ESRILayer] Set the last URl for the failed MapServer";
})(MapLayerActionTypes || (MapLayerActionTypes = {}));
/**
 * Add Layer to Active Layers Actions
  SetBounds = '[ESRILayer] Set Bounds',
  SetCoordinates = '[ESRILayer] Set Coordinates'
}

/**
 * Add Layer to Active Layers Actions
 */
var ActivateLayer = /** @class */ (function () {
    function ActivateLayer(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.ActivateLayer;
    }
    return ActivateLayer;
}());
export { ActivateLayer };
var ActivateDynamicMapLayer = /** @class */ (function () {
    function ActivateDynamicMapLayer(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.ActivateDynamicMapLayer;
    }
    return ActivateDynamicMapLayer;
}());
export { ActivateDynamicMapLayer };
var DeactivateDynamicMapLayer = /** @class */ (function () {
    function DeactivateDynamicMapLayer(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.DeactivateDynamicMapLayer;
    }
    return DeactivateDynamicMapLayer;
}());
export { DeactivateDynamicMapLayer };
var RemoveActivatedMapServer = /** @class */ (function () {
    function RemoveActivatedMapServer(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.RemoveActivatedMapServer;
    }
    return RemoveActivatedMapServer;
}());
export { RemoveActivatedMapServer };
var ActivateLayerByUrl = /** @class */ (function () {
    function ActivateLayerByUrl(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.ActivateLayerByUrl;
    }
    return ActivateLayerByUrl;
}());
export { ActivateLayerByUrl };
var ActivateMultipleLayers = /** @class */ (function () {
    function ActivateMultipleLayers(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.ActivateMultipleLayers;
    }
    return ActivateMultipleLayers;
}());
export { ActivateMultipleLayers };
var SetMapLoading = /** @class */ (function () {
    function SetMapLoading(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.SetMapLoading;
    }
    return SetMapLoading;
}());
export { SetMapLoading };
/**
 * Deactivate Layer from Collection Actions
 */
var SetBasemap = /** @class */ (function () {
    function SetBasemap(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.SetBasemap;
    }
    return SetBasemap;
}());
export { SetBasemap };
/**
 * Deactivate All Layers Actions
 */
var DeactivateAllLayers = /** @class */ (function () {
    function DeactivateAllLayers(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.DeactivateAllLayers;
    }
    return DeactivateAllLayers;
}());
export { DeactivateAllLayers };
/**
 * Filter features within layer
 */
var FilterFeatureLayerSuccess = /** @class */ (function () {
    function FilterFeatureLayerSuccess(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.FilterFeatureLayerSuccess;
    }
    return FilterFeatureLayerSuccess;
}());
export { FilterFeatureLayerSuccess };
var FilterFeatureLayerFailure = /** @class */ (function () {
    function FilterFeatureLayerFailure(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.FilterFeatureLayerFailure;
    }
    return FilterFeatureLayerFailure;
}());
export { FilterFeatureLayerFailure };
/**
 * Load Layers Actions
 */
var LoadLayers = /** @class */ (function () {
    function LoadLayers(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.LoadLayers;
    }
    return LoadLayers;
}());
export { LoadLayers };
var LoadLayersSuccess = /** @class */ (function () {
    function LoadLayersSuccess(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.LoadLayersSuccess;
    }
    return LoadLayersSuccess;
}());
export { LoadLayersSuccess };
var LoadLayersFailure = /** @class */ (function () {
    function LoadLayersFailure(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.LoadLayersFailure;
    }
    return LoadLayersFailure;
}());
export { LoadLayersFailure };
var LoadAllMapServers = /** @class */ (function () {
    function LoadAllMapServers(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.LoadAllMapServers;
    }
    return LoadAllMapServers;
}());
export { LoadAllMapServers };
var LoadMapServer = /** @class */ (function () {
    function LoadMapServer(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.LoadMapServer;
    }
    return LoadMapServer;
}());
export { LoadMapServer };
var LoadMapServerFromPortal = /** @class */ (function () {
    function LoadMapServerFromPortal(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.LoadMapServerFromPortal;
    }
    return LoadMapServerFromPortal;
}());
export { LoadMapServerFromPortal };
var LoadLegendFromPortal = /** @class */ (function () {
    function LoadLegendFromPortal(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.LoadLegendFromPortal;
    }
    return LoadLegendFromPortal;
}());
export { LoadLegendFromPortal };
var RemoveMapLegends = /** @class */ (function () {
    function RemoveMapLegends() {
        this.type = MapLayerActionTypes.RemoveMapLegends;
    }
    return RemoveMapLegends;
}());
export { RemoveMapLegends };
var LoadMapServerSuccess = /** @class */ (function () {
    function LoadMapServerSuccess(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.LoadMapServerSuccess;
    }
    return LoadMapServerSuccess;
}());
export { LoadMapServerSuccess };
var LoadMapServerFailure = /** @class */ (function () {
    function LoadMapServerFailure(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.LoadMapServerFailure;
    }
    return LoadMapServerFailure;
}());
export { LoadMapServerFailure };
var ReplaceMapServer = /** @class */ (function () {
    function ReplaceMapServer(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.ReplaceMapServer;
    }
    return ReplaceMapServer;
}());
export { ReplaceMapServer };
var RemoveMapServer = /** @class */ (function () {
    function RemoveMapServer(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.RemoveMapServer;
    }
    return RemoveMapServer;
}());
export { RemoveMapServer };
var RemoveAllMapServers = /** @class */ (function () {
    function RemoveAllMapServers() {
        this.type = MapLayerActionTypes.RemoveAllMapServers;
    }
    return RemoveAllMapServers;
}());
export { RemoveAllMapServers };
var ActivateCentroid = /** @class */ (function () {
    function ActivateCentroid(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.ActivateCentroid;
    }
    return ActivateCentroid;
}());
export { ActivateCentroid };
/**
 * Load Legend for Layers that are loaded
 */
var LoadLegend = /** @class */ (function () {
    function LoadLegend(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.LoadLegend;
    }
    return LoadLegend;
}());
export { LoadLegend };
var LoadLegendSuccess = /** @class */ (function () {
    function LoadLegendSuccess(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.LoadLegendSuccess;
    }
    return LoadLegendSuccess;
}());
export { LoadLegendSuccess };
var LoadLegendFailure = /** @class */ (function () {
    function LoadLegendFailure(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.LoadLegendFailure;
    }
    return LoadLegendFailure;
}());
export { LoadLegendFailure };
/**
 * Add Popup to Active Layer
 */
var ActivateFeaturePopup = /** @class */ (function () {
    function ActivateFeaturePopup(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.ActivateFeaturePopup;
    }
    return ActivateFeaturePopup;
}());
export { ActivateFeaturePopup };
var HighlightFeature = /** @class */ (function () {
    function HighlightFeature(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.HighlightFeature;
    }
    return HighlightFeature;
}());
export { HighlightFeature };
var ActivateFeaturePopupSuccess = /** @class */ (function () {
    function ActivateFeaturePopupSuccess(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.ActivateFeaturePopupSuccess;
    }
    return ActivateFeaturePopupSuccess;
}());
export { ActivateFeaturePopupSuccess };
var SetGeoTags = /** @class */ (function () {
    function SetGeoTags(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.SetGeoTags;
    }
    return SetGeoTags;
}());
export { SetGeoTags };
var LoadLayerFromGeoTag = /** @class */ (function () {
    function LoadLayerFromGeoTag(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.LoadLayerFromGeoTag;
    }
    return LoadLayerFromGeoTag;
}());
export { LoadLayerFromGeoTag };
var SetZoom = /** @class */ (function () {
    function SetZoom(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.SetZoom;
    }
    return SetZoom;
}());
export { SetZoom };
var LoadFieldData = /** @class */ (function () {
    function LoadFieldData(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.LoadFieldData;
    }
    return LoadFieldData;
}());
export { LoadFieldData };
var SetCoordinates = /** @class */ (function () {
    function SetCoordinates(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.SetCoordinates;
    }
    return SetCoordinates;
}());
export { SetCoordinates };
var SetBounds = /** @class */ (function () {
    function SetBounds(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.SetBounds;
    }
    return SetBounds;
}());
export { SetBounds };
var ResetActiveCentroid = /** @class */ (function () {
    function ResetActiveCentroid() {
        this.type = MapLayerActionTypes.ResetActiveCentroid;
    }
    return ResetActiveCentroid;
}());
export { ResetActiveCentroid };
var SetLayerManagerOpen = /** @class */ (function () {
    function SetLayerManagerOpen(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.SetLayerManagerOpen;
    }
    return SetLayerManagerOpen;
}());
export { SetLayerManagerOpen };
var AddFacetFeaturesCollection = /** @class */ (function () {
    function AddFacetFeaturesCollection(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.AddFacetFeaturesCollection;
    }
    return AddFacetFeaturesCollection;
}());
export { AddFacetFeaturesCollection };
var ClearFacetFeaturesCollection = /** @class */ (function () {
    function ClearFacetFeaturesCollection() {
        this.type = MapLayerActionTypes.ClearFacetFeaturesCollection;
    }
    return ClearFacetFeaturesCollection;
}());
export { ClearFacetFeaturesCollection };
var FitGeobodyBounds = /** @class */ (function () {
    function FitGeobodyBounds(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.FitGeobodyBounds;
    }
    return FitGeobodyBounds;
}());
export { FitGeobodyBounds };
var SetLastURLMapServerFail = /** @class */ (function () {
    function SetLastURLMapServerFail(payload) {
        this.payload = payload;
        this.type = MapLayerActionTypes.SetLastURLMapServerFail;
    }
    return SetLastURLMapServerFail;
}());
export { SetLastURLMapServerFail };
