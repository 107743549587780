import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { AppConfigService } from '@app/app-config.service';
import { Store } from '@ngrx/store';
import { dispatchErrorMessage } from '@app/shared/util/search-util';
import { buildMessageError } from '@app/shared/util/search-util';
import { SearchActions } from '@search/actions';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app-config.service";
import * as i3 from "@ngrx/store";
var SearchAPIService = /** @class */ (function () {
    function SearchAPIService(http, _appConfig, sharedStore) {
        var _this = this;
        this.http = http;
        this._appConfig = _appConfig;
        this.sharedStore = sharedStore;
        this.solrCounts = 0;
        this.imageCounts = 0;
        this._config = this._appConfig.getConfig();
        this.counts().pipe(take(1)).subscribe(function (res) {
            _this.solrCounts = res.solr ? res.solr : 0;
            _this.imageCounts = res.sql ? res.sql : 0;
        }, function (err) { return dispatchErrorMessage(_this.sharedStore, err); });
    }
    SearchAPIService.prototype.loadClassifications = function () {
        var _this = this;
        this.getClassifications().pipe(take(1)).subscribe(function (res) {
            _this.sharedStore.dispatch(new SearchActions.SetClassifications(res.image_types));
        });
    };
    SearchAPIService.prototype.escapeRegExp = function (string) {
        return string.replace(/["\\]/g, '\$&'); // $& means the whole matched string
    };
    SearchAPIService.prototype.escapeQuery = function (query) {
        var _this = this;
        query.keywords = query.keywords.map(function (keyword) {
            keyword = _this.escapeRegExp(keyword);
            return keyword;
        });
    };
    SearchAPIService.prototype.searchDocs = function (query) {
        this.escapeQuery(query);
        return this.http.post(this._config.datascience_api_baseurl + "/search/docs", query).pipe(map(function (res) {
            if (res.status) {
                throw Error(buildMessageError(res));
            }
            else {
                return res;
            }
        }));
        // return this.http.get<SearchResponse>(`/assets/data/sample-document-response.json`).pipe(delay(2000));
    };
    SearchAPIService.prototype.searchNewDocs = function (query) {
        this.escapeQuery(query);
        //    return this.http.get<any>(`/assets/data/sample-newdocuments-response.json`).pipe(
        return this.http.post(this._config.datascience_api_baseurl + "/search/docs_date", query).pipe(map(function (res) {
            if (res.status) {
                throw Error(buildMessageError(res));
            }
            else {
                return res;
            }
        }));
    };
    SearchAPIService.prototype.searchImages = function (query) {
        return this.http.post(this._config.datascience_api_baseurl + "/search/images", query).pipe(map(function (res) {
            if (res.status) {
                throw Error(buildMessageError(res));
            }
            else {
                return res;
            }
        }));
        /*if (query.keywords.includes('carbonate')) {
          return this.http.get<ImageSearchResponse>(`/assets/data/sample-empty-image-response.json`).pipe(delay(3000));
        } else {
          return this.http.get<ImageSearchResponse>(`/assets/data/sample-image-response.json`).pipe(delay(3000));
        }*/
    };
    SearchAPIService.prototype.similarImages = function (query) {
        return this.http.get(this._config.datascience_api_baseurl + "/images/findsimilar?id=" + query, {}).pipe(map(function (res) {
            if (res.status) {
                throw Error(buildMessageError(res));
            }
            else {
                return res;
            }
        }));
        // return this.http.get<ImageSearchResponse>(`/assets/data/sample-similar-response.json`).pipe(delay(3000));
    };
    SearchAPIService.prototype.searchAll = function (query) {
        this.escapeQuery(query);
        // return this.http.get<any>('/assets/data/sample-document-response.json').pipe(
        return this.http.post(this._config.datascience_api_baseurl + "/search/all", query).pipe(map(function (res) {
            if (res.status) {
                throw Error(buildMessageError(res));
            }
            else {
                return res;
            }
        }));
        // return this.http.get<SearchResponse>('/assets/data/sample-all-response.json');
    };
    SearchAPIService.prototype.counts = function () {
        return this.http.get(this._config.datascience_api_baseurl + "/gistri/counts").pipe(map(function (res) {
            if (res.status) {
                throw Error(buildMessageError(res));
            }
            else {
                return res;
            }
        }));
    };
    SearchAPIService.prototype.suggestions = function (query) {
        // return this.http.get<SearchResponse>(`/assets/data/sample-suggestions-response.json`);
        return this.http.get(this._config.datascience_api_baseurl + "/gistri/suggestions/" + query).pipe(map(function (res) {
            if (res.status) {
                throw Error(buildMessageError(res));
            }
            else {
                return res;
            }
        }));
    };
    SearchAPIService.prototype.getClassifications = function () {
        // return this.http.get<SearchResponse>(`/assets/data/sample-classifications-response.json`).pipe(delay(2000));
        return this.http.get(this._config.datascience_api_baseurl + "/images/classification").pipe(map(function (res) {
            if (res.status) {
                throw Error(buildMessageError(res));
            }
            else {
                return res;
            }
        }));
    };
    SearchAPIService.prototype.getDocImages = function (fileName) {
        return this.http.post(this._config.datascience_api_baseurl + "/images/imagesByIds", { fileNames: fileName }).pipe(map(function (res) {
            if (res.status) {
                throw Error(buildMessageError(res));
            }
            else {
                return res;
            }
        }));
        // return this.http.get<any>('/assets/data/sample-docImages-response.json').pipe(delay(5000));
    };
    SearchAPIService.prototype.getSurprisingSentences = function (query) {
        return this.http.post(this._config.datascience_api_baseurl + "/gistri/retrieve_surprising_sentences", query).pipe(map(function (res) {
            if (res.status) {
                throw Error(buildMessageError(res));
            }
            else {
                return res;
            }
        }));
        // return this.http.get<any>(`/assets/data/sample-ss-response.json`);
    };
    SearchAPIService.prototype.export = function (query) {
        return this.http.post(this._config.datascience_api_baseurl + "/gistri/export", query, { responseType: 'text' });
    };
    SearchAPIService.ngInjectableDef = i0.defineInjectable({ factory: function SearchAPIService_Factory() { return new SearchAPIService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService), i0.inject(i3.Store)); }, token: SearchAPIService, providedIn: "root" });
    return SearchAPIService;
}());
export { SearchAPIService };
