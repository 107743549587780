import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, concat, of } from 'rxjs';
import { catchError, take, concatMap, skipWhile } from 'rxjs/operators';
import { AppConfigService } from '@app/app-config.service';
import { console } from '@app/shared/util/console.util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app-config.service";
var FeedbackService = /** @class */ (function () {
    function FeedbackService(http, _appConfig) {
        var _this = this;
        this.http = http;
        this._appConfig = _appConfig;
        this.sharepointApiUrl = 'https://ishareteam8.na.xom.com/sites/Geobrain/_api/';
        this.sharePointDigestToken = '';
        this._config = this._appConfig.getConfig();
        this.getSharepointDigest().pipe(take(1)).subscribe(function (data) {
            _this.sharePointDigestToken = data.d.GetContextWebInformation.FormDigestValue;
        });
    }
    FeedbackService.prototype.postImageFeedback = function (postData) {
        return this.http.post(this._config.datascience_api_baseurl + "/images/update-image-classification", postData);
    };
    FeedbackService.prototype.postFeedback = function (postData, fileList) {
        var _this = this;
        var sharePointRecord$ = this.createSharepointFeedbackRecord(postData);
        if (fileList.length > 0) {
            // attach files after record is created
            return sharePointRecord$.pipe(skipWhile(function (result) { return result === null || result.d.ID === undefined; }), concatMap(function (result) { return _this.attachAllFilesToCreatedRecord(result.d.ID, fileList); }));
        }
        else {
            return sharePointRecord$;
        }
    };
    // used in constructor to fetch the token
    FeedbackService.prototype.getSharepointDigest = function () {
        var jsonHeader = 'application/json; odata=verbose';
        var headers = new HttpHeaders();
        headers = headers.append('Content-Type', jsonHeader);
        headers = headers.append('Accept', jsonHeader);
        headers = headers.append('Response-Type', 'json');
        var body = '';
        return this.http.post(this.sharepointApiUrl + 'contextinfo', body, { headers: headers, responseType: 'json' });
    };
    // This will create feed back record. once record is created, we will do attachment next
    FeedbackService.prototype.createSharepointFeedbackRecord = function (dataToPost) {
        var headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json;odata=verbose');
        headers = headers.append('Accept', 'application/json;odata=verbose');
        headers = headers.append('X-RequestDigest', this.sharePointDigestToken);
        var options = { withCredentials: true, headers: headers };
        return this.http.post(this.sharepointApiUrl + "web/lists/GetByTitle('Submit Feedback Bugs')/items", dataToPost, options);
    };
    FeedbackService.prototype.attachAllFilesToCreatedRecord = function (sharepointRecordId, attachedFileList) {
        var attachFilesObservable = [];
        for (var i = 0; i < attachedFileList.length; i++) {
            attachFilesObservable.push(this.attachOneFile(sharepointRecordId, attachedFileList[i]));
        }
        return concat.apply(void 0, attachFilesObservable).pipe(catchError(function (err) {
            console.log(err);
            return of(err);
        }));
    };
    // this function will be called by attachAllFilesToCreatedRecord()
    FeedbackService.prototype.attachOneFile = function (sharepointRecordId, attachedFile) {
        var _this = this;
        var fileToUpload = attachedFile;
        return this.getbufferDataObservable(fileToUpload[0]).pipe(concatMap(function (fileResult) {
            var attachementData = fileResult;
            var headers = new HttpHeaders();
            headers = headers.append('accept', 'application/json;odata=verbose');
            headers = headers.append('content-type', 'undefined');
            headers = headers.append('X-RequestDigest', _this.sharePointDigestToken);
            // tslint:disable-next-line:max-line-length
            return _this.http.post(_this.sharepointApiUrl + "web/lists/GetByTitle('Submit Feedback Bugs')/items(" + sharepointRecordId + ")/AttachmentFiles/add(FileName='" + fileToUpload[0].name + "')", attachementData, { headers: headers, withCredentials: true });
        }), catchError(function (err) {
            console.log(err);
            return of(err);
        }));
    };
    // This function will generate uploaded file   binaray data array to feed to sharepoint api
    FeedbackService.prototype.getbufferDataObservable = function (file) {
        var fileData$ = new Observable(function (fObserver) {
            var freader = new FileReader();
            freader.readAsArrayBuffer(file);
            freader.onloadend = function () {
                fObserver.next(freader.result);
                fObserver.complete();
            };
            freader.onerror = function (err) {
                fObserver.error(err);
            };
            freader.onabort = function () {
                fObserver.error('buffer data observer aborted');
            };
        });
        return fileData$;
    };
    FeedbackService.ngInjectableDef = i0.defineInjectable({ factory: function FeedbackService_Factory() { return new FeedbackService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService)); }, token: FeedbackService, providedIn: "root" });
    return FeedbackService;
}());
export { FeedbackService };
