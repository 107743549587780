import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { ContentService } from '@app/shared/services/content.service';
import { GlobalContent } from '@app/shared/shared-content';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'xom-show-help-modal',
  templateUrl: './show-help-modal.component.html'
})
export class ShowHelpModalComponent implements AfterViewInit {
  @ViewChild('divFocused') divFocused;
  @Output() closeModal: EventEmitter<null> = new EventEmitter(null);

  constructor(
    public content: ContentService<GlobalContent>,
    private sanitizer: DomSanitizer
  ) { }

  ngAfterViewInit() {
    this.divFocused.nativeElement.focus();
  }

  closeShowHelp() {
    this.closeModal.emit();
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
