import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ClipboardModule } from 'ngx-clipboard';
import { XomButtonModule } from './components/xom-button/xom-button.module';
import { ModalLayoutComponent } from './layouts/modal-layout/modal-layout.component';
import { LandingLayoutComponent } from './layouts/landing-layout/landing-layout.component';
import { ProfileSettingsLayoutComponent } from './layouts/profile-settings-layout/profile-settings-layout.component';
import { PageLayoutComponent } from './layouts/page-layout/page-layout.component';
import { ListLayoutComponent } from './layouts/list-layout/list-layout.component';
import { ImagePreviewLayoutComponent } from './layouts/image-preview-layout/image-preview-layout.component';
import { XomDropdownComponent } from './components/xom-dropdown/xom-dropdown.component';
import { XomDropdownIconsComponent } from './components/xom-dropdown-icons/xom-dropdown-icons.component';
import { XomDropdownCheckboxComponent } from './components/xom-dropdown-checkbox/xom-dropdown-checkbox.component';
import { XomBadgeComponent } from './components/xom-badge/xom-badge.component';
import { XomTypeaheadComponent } from './components/xom-typeahead/xom-typeahead.component';
import { XomInputDirective } from './directives/xom-input/xom-input.directive';
import { XomWellCardComponent } from './components/xom-well-card/xom-well-card.component';
import { XomDocumentCardComponent } from './components/xom-document-card/xom-document-card.component';
import { XomImageCardComponent } from './components/xom-image-card/xom-image-card.component';
import { XomHeaderComponent } from './components/xom-header/xom-header.component';
import { XomLinkListComponent } from './components/xom-link-list/xom-link-list.component';
import { XomBasinProfileViewerComponent } from './components/xom-basin-profile-viewer/xom-basin-profile-viewer.component';
import {
    XomBasinProfileViewerTableComponent
} from './components/xom-basin-profile-viewer/helper/xom-basin-profile-viewer-table/xom-basin-profile-viewer-table.component';
import {
    MatMenuModule,
    MatTreeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSliderModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule
} from '@angular/material';
import { MatIconModule, MatSelectModule, MatSnackBarModule, MatAutocompleteModule } from '@angular/material';
import { KeysPipe } from './pipes/keys.pipe';
import { ArrayFilterPipe } from './pipes/filter.pipe';
import { JulianDatePipe } from './pipes/julian-date.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { FileNamePipe } from './pipes/file-name.pipe';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { SearchAPIService } from './services/search-api.service';
import { UserAPIService } from './services/user-api.service';
import { XomUserInitialsComponent } from './components/xom-user-initials/xom-user-initials.component';
import { XomOverflowComponent } from './components/xom-overflow/xom-overflow.component';
import { XomPopoverComponent } from './components/xom-popover/xom-popover.component';
import { XomCarouselComponent } from './components/xom-carousel/xom-carousel.component';
import { XomLoaderComponent } from './components/xom-loader/xom-loader.component';
import { XomBreadcrumbsComponent } from './components/xom-breadcrumbs/xom-breadcrumbs.component';
import { XomDropdownMenuComponent } from './components/xom-dropdown-menu/xom-dropdown-menu.component';
import { XomSubheaderComponent } from './components/xom-subheader/xom-subheader.component';
import { XomProgressBarComponent } from './components/xom-progress-bar/xom-progress-bar.component';
import { XomSearchBarComponent } from './components/xom-search-bar/xom-search-bar.component';
import { XomPillComponent } from './components/xom-pill/xom-pill.component';
import { XomSelectionPillComponent } from './components/xom-selection-pill/xom-selection-pill.component';
import { StoreModule } from '@ngrx/store';
import { XomGeologicTimeChartComponent } from './components/xom-geologic-time-chart/xom-geologic-time-chart.component';
import { XomFilterTreeComponent } from './components/xom-filter-tree/xom-filter-tree.component';
import { XomSpinnerComponent } from './components/xom-spinner/xom-spinner.component';
import { XomWorkspaceCardComponent } from './components/xom-workspace-card/xom-workspace-card.component';
import { WorkspaceOptionsComponent } from './components/xom-workspace-options/xom-workspace-options.component';
import { reducers } from './reducers';
import { FieldDataService } from './services/field-data.service';
import { ExportFileService } from './services/exportFile.service';
import { XomResetAllComponent } from './components/xom-reset-all/xom-reset-all.component';
import { WSContentProvider } from '@app/modules/workspaces/workspaces-content';
import { XomSplashscreenComponent } from './components/xom-splashscreen/xom-splashscreen.component';
import { XomAdminMessageComponent } from './components/xom-admin-message/xom-admin-message.component';
import { XomBackButtonComponent } from './components/xom-back-button/xom-back-button.component';
import { XomMessageComponent } from './components/xom-message/xom-message.component';
import { ResultsQuantityComponent } from './components/results-quantity/results-quantity.component';
import { XomImageFeedbackComponent } from './components/xom-image-feedback/xom-image-feedback.component';
import { XomPublishedYearFilterComponent } from './components/xom-published-year-filter/xom-published-year-filter.component';
import { ToolboxComponent } from './components/toolbox/toolbox.component';
import { ToolboxItemComponent } from './components/toolbox-item/toolbox-item.component';
import { ViewSelectionComponent } from './components/view-selection/view-selection.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SafeURLPipe } from './pipes/safe-url.pipe';
import { XomSummaryTeaserComponent } from './components/xom-summary-teaser/xom-summary-teaser.component';
import { XomSummaryTeaserCardComponent } from './components/xom-summary-teaser-card/xom-summary-teaser-card.component';
import { GeobrainLoaderService } from './services/geobrain-loader.service';
import { SharedModalsComponent } from './components/shared-modals/shared-modals.component';
import { EmojiFeedbackModalComponent } from './components/emoji-feedback-modal/emoji-feedback-modal.component';
import { FeedbackModalComponent } from './components/feedback-modal/feedback-modal.component';
import { ShowHelpModalComponent } from './components/show-help-modal/show-help-modal.component';
import { ResultsPaginationComponent } from './components/results-pagination/results-pagination.component';
import { XomNotesPanelComponent } from '@shared/components/xom-notes-panel/xom-notes-panel.component';
import { XomRadioButtonTreeComponent } from './components/xom-radio-button-tree/xom-radio-button-tree.component';
import { CollectionPopupComponent } from './components/collection-popup/collection-popup.component';
import { GeoUnitSelectorComponent } from './components/geo-unit-selector/geo-unit-selector.component';
import { XomSpiderChartComponent } from './components/xom-spider-chart/xom-spider-chart.component';
import { XomCollectionCardComponent } from './components/xom-collection-card/xom-collection-card.component';
import { XomGlobalFeedbackModalComponent } from '@shared/components/xom-global-feedback-modal/xom-global-feedback-modal.component';
import { XomSearchInfoComponent } from '@shared/components/xom-search-info/xom-search-info.component';
import { XomTabControlComponent } from './components/xom-tab-control/xom-tab-control.component';
import { XomTabControlItemComponent } from './components/xom-tab-control-item/xom-tab-control-item.component';
import { XomTabOverlayItemComponent } from '@shared/components/xom-tab-overlay-item/xom-tab-overlay-item.component';
import { XomTabOverlayComponent } from '@shared/components/xom-tab-overlay/xom-tab-overlay.component';
import { FacetFilterComponent } from '@search/components/facet-filter/facet-filter.component';
import { AnalogService } from '@shared/services/analog.service';
import { XomDropdownMaterialComponent } from './components/xom-dropdown-material/xom-dropdown-material.component';
import { XomCreamingCurvesChartComponent } from '@shared/components/xom-creaming-curves-chart/xom-creaming-curves-chart.component';
import { CreamingCurvesService } from '@app/shared/services/creaming-curves.service';
import { XomBarGraphChartComponent } from './components/xom-bar-graph-chart/xom-bar-graph-chart.component';
import { RadioButtonGroupComponent } from './components/radio-button-group/radio-button-group.component';
import { XomWelcomeMessageComponent } from '@shared/components/xom-welcome-message/xom-welcome-message.component';
import { MessageService } from '@shared/services/message.service';
import { XomFTUEStaticComponent } from '@shared/components/xom-ftue-static/xom-ftue-static.component';
import { XomFTUEDynamicComponent } from '@shared/components/xom-ftue-dynamic/xom-ftue-dynamic.component';
import { XomWsLoadingScreenComponent } from '@shared/components/xom-ws-loading-screen/xom-ws-loading-screen.component';
import { VgCoreModule } from 'videogular2/compiled/src/core/core';
import { VgBufferingModule } from 'videogular2/compiled/src/buffering/buffering';
import { VgOverlayPlayModule } from 'videogular2/compiled/src/overlay-play/overlay-play';
import { GeoUnitRadioButtonGroupComponent } from './components/geo-unit-radio-button-group/geo-unit-radio-button-group.component';
import { XomPromoteModalComponent } from './components/xom-promote-modal/xom-promote-modal.component';
import { PromoteWSAPIService } from '@shared/services/promoteWS.service';
import { InterrogatorService } from '@app/shared/services/interrogator.service';
import { PlayService } from './services/play.service';
import { HttpWrapperService } from './api/http-wrapper.service';
import { XomSelectMainBasinComponent } from './components/xom-select-main-basin/xom-select-main-basin.component';
import { AppConfigService } from '@app/app-config.service';
import { XomPathCopiedModalComponent } from './components/xom-path-copied-modal/xom-path-copied-modal.component';
import { XomGeologicTimeChartLegendComponent } from './components/xom-geologic-time-chart-legend/xom-geologic-time-chart-legend.component';
import { XomLegendDrawSvgComponent } from './components/xom-geologic-time-chart-legend/xom-legend-draw-svg/xom-legend-draw-svg.component';
import { ChronostratChartComponent } from './components/chart-components/chronostrat-chart/chronostrat-chart.component';
import { EventsChartComponent } from './components/chart-components/events-chart/events-chart.component';
import { BasinEvaluationHistoryService } from './services/basin-evaluation-history.service';
import { XomMultiTableWithHeaderComponent } from './components/xom-multitable-with-header/xom-multitable-with-header.component';
import { MultiTableComponent } from './components/multi-table/multi-table.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        XomButtonModule,
        ClipboardModule,
        MatMenuModule,
        MatTreeModule,
        MatSnackBarModule,
        StoreModule.forFeature('shared', reducers),
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatSelectModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatRadioModule,
        NgxDatatableModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        VgCoreModule,
        VgOverlayPlayModule,
        VgBufferingModule
    ],
    declarations: [
        XomDropdownComponent,
        XomDropdownIconsComponent,
        XomDropdownCheckboxComponent,
        XomDropdownMenuComponent,
        ModalLayoutComponent,
        LandingLayoutComponent,
        ProfileSettingsLayoutComponent,
        PageLayoutComponent,
        ImagePreviewLayoutComponent,
        XomBadgeComponent,
        XomTypeaheadComponent,
        XomInputDirective,
        XomWellCardComponent,
        XomDocumentCardComponent,
        XomImageCardComponent,
        XomUserInitialsComponent,
        XomOverflowComponent,
        XomPopoverComponent,
        XomHeaderComponent,
        XomCarouselComponent,
        XomLinkListComponent,
        ListLayoutComponent,
        XomLoaderComponent,
        KeysPipe,
        XomBreadcrumbsComponent,
        ArrayFilterPipe,
        JulianDatePipe,
        FormatDatePipe,
        FileNamePipe,
        SafeHTMLPipe,
        SafeURLPipe,
        XomSubheaderComponent,
        XomProgressBarComponent,
        XomSearchBarComponent,
        XomPillComponent,
        XomSelectionPillComponent,
        XomGeologicTimeChartComponent,
        XomGeologicTimeChartLegendComponent,
        XomLegendDrawSvgComponent,
        XomFilterTreeComponent,
        XomSpinnerComponent,
        XomWorkspaceCardComponent,
        WorkspaceOptionsComponent,
        XomResetAllComponent,
        XomSplashscreenComponent,
        XomAdminMessageComponent,
        XomBackButtonComponent,
        XomMessageComponent,
        ResultsQuantityComponent,
        XomImageFeedbackComponent,
        XomPublishedYearFilterComponent,
        PaginationComponent,
        SharedModalsComponent,
        EmojiFeedbackModalComponent,
        FeedbackModalComponent,
        XomGlobalFeedbackModalComponent,
        ShowHelpModalComponent,
        ToolboxComponent,
        ToolboxItemComponent,
        ViewSelectionComponent,
        ResultsPaginationComponent,
        XomSummaryTeaserComponent,
        XomSummaryTeaserCardComponent,
        XomNotesPanelComponent,
        XomRadioButtonTreeComponent,
        XomSpiderChartComponent,
        XomCollectionCardComponent,
        CollectionPopupComponent,
        GeoUnitSelectorComponent,
        XomSearchInfoComponent,
        XomTabControlComponent,
        XomTabControlItemComponent,
        XomTabOverlayItemComponent,
        XomTabOverlayComponent,
        FacetFilterComponent,
        XomDropdownMaterialComponent,
        XomCreamingCurvesChartComponent,
        XomBarGraphChartComponent,
        RadioButtonGroupComponent,
        GeoUnitRadioButtonGroupComponent,
        XomWelcomeMessageComponent,
        XomFTUEStaticComponent,
        XomFTUEDynamicComponent,
        XomWsLoadingScreenComponent,
        XomPromoteModalComponent,
        XomBasinProfileViewerComponent,
        XomBasinProfileViewerTableComponent,
        XomSelectMainBasinComponent,
        XomPathCopiedModalComponent,
        ChronostratChartComponent,
        EventsChartComponent,
        XomMultiTableWithHeaderComponent,
        MultiTableComponent,
    ],
    exports: [
        XomDropdownComponent,
        XomDropdownIconsComponent,
        XomDropdownCheckboxComponent,
        XomHeaderComponent,
        XomDropdownMenuComponent,
        ModalLayoutComponent,
        LandingLayoutComponent,
        ProfileSettingsLayoutComponent,
        PageLayoutComponent,
        ImagePreviewLayoutComponent,
        XomButtonModule,
        XomBadgeComponent,
        XomTypeaheadComponent,
        XomInputDirective,
        FormsModule,
        XomWellCardComponent,
        XomDocumentCardComponent,
        XomImageCardComponent,
        XomUserInitialsComponent,
        XomOverflowComponent,
        XomPopoverComponent,
        XomHeaderComponent,
        XomCarouselComponent,
        XomLinkListComponent,
        ListLayoutComponent,
        XomLoaderComponent,
        KeysPipe,
        XomBreadcrumbsComponent,
        ArrayFilterPipe,
        JulianDatePipe,
        FormatDatePipe,
        FileNamePipe,
        SafeHTMLPipe,
        SafeURLPipe,
        XomSubheaderComponent,
        MatMenuModule,
        MatSliderModule,
        MatTreeModule,
        XomProgressBarComponent,
        XomSearchBarComponent,
        XomPillComponent,
        XomSelectionPillComponent,
        MatSnackBarModule,
        XomGeologicTimeChartComponent,
        XomGeologicTimeChartLegendComponent,
        XomLegendDrawSvgComponent,
        XomFilterTreeComponent,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatSelectModule,
        MatAutocompleteModule,
        XomSpinnerComponent,
        XomWorkspaceCardComponent,
        WorkspaceOptionsComponent,
        XomResetAllComponent,
        XomSplashscreenComponent,
        XomAdminMessageComponent,
        XomBackButtonComponent,
        XomMessageComponent,
        ResultsQuantityComponent,
        XomImageFeedbackComponent,
        XomPublishedYearFilterComponent,
        PaginationComponent,
        SharedModalsComponent,
        EmojiFeedbackModalComponent,
        FeedbackModalComponent,
        ShowHelpModalComponent,
        XomGlobalFeedbackModalComponent,
        ToolboxComponent,
        ToolboxItemComponent,
        ViewSelectionComponent,
        ResultsPaginationComponent,
        XomSummaryTeaserComponent,
        XomSummaryTeaserCardComponent,
        XomNotesPanelComponent,
        XomRadioButtonTreeComponent,
        XomSpiderChartComponent,
        XomCollectionCardComponent,
        MatRadioModule,
        CollectionPopupComponent,
        GeoUnitSelectorComponent,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        XomSearchInfoComponent,
        XomTabControlComponent,
        XomTabControlItemComponent,
        XomTabOverlayItemComponent,
        XomTabOverlayComponent,
        FacetFilterComponent,
        XomDropdownMaterialComponent,
        MatDatepickerModule,
        MatNativeDateModule,
        XomCreamingCurvesChartComponent,
        XomBarGraphChartComponent,
        RadioButtonGroupComponent,
        GeoUnitRadioButtonGroupComponent,
        XomWelcomeMessageComponent,
        XomFTUEStaticComponent,
        XomFTUEDynamicComponent,
        XomWsLoadingScreenComponent,
        XomPromoteModalComponent,
        XomBasinProfileViewerComponent,
        XomBasinProfileViewerTableComponent,
        XomSelectMainBasinComponent,
        XomPathCopiedModalComponent,
        ChronostratChartComponent,
        EventsChartComponent,
        XomMultiTableWithHeaderComponent,
    ]
})
export class XomSharedModule {
    static forRoot() {
        return {
            ngModule: XomSharedModule,
            providers: [
                SearchAPIService,
                UserAPIService,
                JulianDatePipe,
                FormatDatePipe,
                FileNamePipe,
                SafeHTMLPipe,
                SafeURLPipe,
                FieldDataService,
                ExportFileService,
                WSContentProvider,
                GeobrainLoaderService,
                CreamingCurvesService,
                AnalogService,
                MessageService,
                PromoteWSAPIService,
                InterrogatorService,
                BasinEvaluationHistoryService,
                PlayService,
                HttpWrapperService,
                AppConfigService,
            ]
        };
    }
}
