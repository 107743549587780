import { OnDestroy } from '@angular/core';
import { AppConfigService } from '@app/app-config.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ContentService } from '@app/shared/services/content.service';
import { select, Store } from '@ngrx/store';
import * as fromGis from '@gis/reducers/index';
import { map } from 'rxjs/operators';
import * as esri from 'esri-leaflet';
import { MapLayerActions } from '@app/modules/gis/actions';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app-config.service";
import * as i3 from "./content.service";
import * as i4 from "@ngrx/store";
var AnalogService = /** @class */ (function () {
    function AnalogService(http, _appConfig, content, store) {
        var _this = this;
        this.http = http;
        this._appConfig = _appConfig;
        this.content = content;
        this.store = store;
        this.basinConfig = {};
        this.clickedBasin = new BehaviorSubject(0);
        this.unclickedBasin = new BehaviorSubject(0);
        this.hover = new BehaviorSubject(0);
        this.unhover = new BehaviorSubject(0);
        this.colorPool = [];
        this.features = new BehaviorSubject([]);
        this.configs = [];
        this.subscriptions = [];
        var config = this._appConfig.getConfig();
        this.API_PATH = config.datascience_api_baseurl + '/analogs';
        this.spiderChartData = [];
        this.colorIndex = -1;
        this.colorPool = this.content.local.SPIDER_CHART.COLOR_POOL;
        var $getAOILayerConfigSubscribe = this.store.pipe(select(fromGis.getAOILayerConfig), map(function (configs) { return configs.basin; }))
            .subscribe(function (configs) { return _this.configs = configs; });
        this.subscriptions.push($getAOILayerConfigSubscribe);
    }
    AnalogService.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) {
            try {
                subscription.unsubscribe();
            }
            catch (e) {
                console.error('[Error] OnDestroy unsubscribes', e);
            }
        });
    };
    AnalogService.prototype.getColor = function () {
        this.colorIndex = this.colorIndex >= this.colorPool.length ? 0 : this.colorIndex;
        return this.colorPool[this.colorIndex++];
    };
    AnalogService.prototype.colorIndexRestart = function () {
        this.colorIndex = -1;
    };
    AnalogService.prototype.getBasins = function () {
        return this.http.get(this.API_PATH + "/getBasins");
        // return this.http.get<any[]>(`/assets/data/analog_get_basins.json`);
    };
    AnalogService.prototype.getKeywords = function () {
        return this.http.get(this.API_PATH + "/getKeywords");
        // return this.http.get<any[]>(`/assets/data/analog_get_keywords.json`);
    };
    AnalogService.prototype.findSimilar = function (basin) {
        return this.http.get(this.API_PATH + "/findSimilar?basin=" + basin);
        // return this.http.get<any[]>(`/assets/data/analog_find_similar.json`);
    };
    AnalogService.prototype.getAnalogs = function (basin) {
        return this.http.get(this.API_PATH + "/getAnalogs?basin=" + basin);
        // return this.http.get<any[]>(`/assets/data/analog_get_analogs.json`);
    };
    AnalogService.prototype.getEvidenceBreakout = function (basins, lithology) {
        return this.http.get(this.API_PATH + "/getEvidenceBreakout?basins=" + basins.join(',') + "&&keyword=" + lithology);
        // return this.http.get<any[]>(`/assets/data/analog_get_evidence_breakou.json`);
    };
    AnalogService.prototype.SetBasinConfig = function (config) {
        this.store.dispatch(new MapLayerActions.SetMapLoading(true));
        this.SetFeatures([]);
        if (config.basin && config.basinCollection && config.basinCollection.length > 0) {
            if (this.configs) {
                for (var i = 0; i < this.configs.length; i++) {
                    this.basinConfig = config;
                    this.queryFeatures(this.configs[i], config.basinCollection);
                }
            }
        }
    };
    AnalogService.prototype.queryFeatures = function (config, basins) {
        var _this = this;
        var condition = config.attribute + " IN (" + basins.map(function (basin) { return "'" + basin + "'"; }).join(',') + ")";
        var query = esri.query({
            url: config.url,
            useCors: true,
            withCredentials: true
        });
        query.where(condition);
        query.run(function (error, featureCollection) {
            if (error) {
            }
            else {
                var result = {};
                if (featureCollection.features.length > 0) {
                    result = featureCollection.features;
                }
                _this.SetFeatures(result);
            }
        });
    };
    AnalogService.prototype.SetClickedBasin = function (index) {
        this.clickedBasin.next(index);
    };
    AnalogService.prototype.SetUnclickedBasin = function (index) {
        this.unclickedBasin.next(index);
    };
    AnalogService.prototype.SetHover = function (index) {
        this.hover.next(index);
    };
    AnalogService.prototype.SetUnhover = function (index) {
        this.unhover.next(index);
    };
    AnalogService.prototype.SetSpiderChartData = function (spiderChartData) {
        this.spiderChartData = spiderChartData;
    };
    AnalogService.prototype.SetFeatures = function (features) {
        this.features.next(features);
    };
    AnalogService.ngInjectableDef = i0.defineInjectable({ factory: function AnalogService_Factory() { return new AnalogService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService), i0.inject(i3.ContentService), i0.inject(i4.Store)); }, token: AnalogService, providedIn: "root" });
    return AnalogService;
}());
export { AnalogService };
