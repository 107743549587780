import { ActionReducer, Action } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MapMeasureActions } from '../actions/index';
import * as fromGis from './index';
import { Layer } from 'leaflet';
import { SearchActions } from '@app/modules/search/actions';

export interface State {
  measureLayer: Layer | null;
  metric: Boolean;
  measuring: Boolean;
  measures: Array<Array<any>>;
}

const initialState: State = {
  measureLayer: null,
  metric: true,
  measuring: false,
  measures: []
};

export function reducer(
  state = initialState,
  action: MapMeasureActions.MeasureActionsUnion | SearchActions.SearchActionsUnion
): State {
  switch (action.type) {
    case MapMeasureActions.MeasureActionTypes.SetMetric:
      return {
        ...state,
        metric: action.payload
      };
    case MapMeasureActions.MeasureActionTypes.SetMeasuring:
      return {
        ...state,
        measureLayer: (action.payload) ? null : state.measureLayer,
        measuring: action.payload
      };
    case MapMeasureActions.MeasureActionTypes.SetMapMeasures:
      return {
        ...state,
        measures: action.payload
      };
    case MapMeasureActions.MeasureActionTypes.SetMeasureLayer:
      return {
        ...state,
        measureLayer: action.payload
      };
    case SearchActions.SearchActionTypes.ResetAll:
    case MapMeasureActions.MeasureActionTypes.ResetMeasureLayer:
      return {
        ...state,
        measureLayer: new Layer,
        measures: []
      };
    default: {
      return state;
    }
  }
}
