import * as esri from 'esri-leaflet';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
var PlayService = /** @class */ (function () {
    function PlayService() {
        this.wellLayerLoading = false;
    }
    PlayService.prototype.queryPlaysByBasinName = function (url, basinName, attributes, returnGeometry) {
        if (returnGeometry === void 0) { returnGeometry = false; }
        var query = esri.query({
            url: url,
            useCors: true,
            withCredentials: true
        });
        return new Observable(function (observer) {
            var where = "BASIN_NAME = '" + basinName + "'";
            query
                .where(where)
                .returnGeometry(returnGeometry)
                .fields(attributes)
                .run(function (error, featureCollection) {
                if (error) {
                    observer.error(error);
                }
                else {
                    observer.next(featureCollection);
                    observer.complete();
                }
            });
        });
    };
    PlayService.prototype.queryPlayMetadata = function (url, playID) {
        var attrs = ['*'
        ];
        var query = esri.query({
            url: url,
            useCors: true,
            withCredentials: true
        });
        return new Observable(function (observer) {
            var where = "PLAY_ID = '" + playID + "'";
            query
                .where(where)
                .returnGeometry(false)
                .fields(attrs)
                .run(function (error, featureCollection) {
                if (error) {
                    observer.error(error);
                }
                else {
                    observer.next(featureCollection);
                    observer.complete();
                }
            });
        });
    };
    PlayService.prototype.queryWellMetadata = function (url, wellID) {
        var attrs = ['*'
        ];
        var query = esri.query({
            url: url,
            useCors: true,
            withCredentials: true
        });
        return new Observable(function (observer) {
            var where = "WEL_ID = '" + wellID + "'";
            query
                .where(where)
                .returnGeometry(false)
                .fields(attrs)
                .run(function (error, featureCollection) {
                if (error) {
                    observer.error(error);
                }
                else {
                    observer.next(featureCollection);
                    observer.complete();
                }
            });
        });
    };
    PlayService.prototype.removeLayer = function (layer) {
        if (this.leafletSvc.map.hasLayer(layer)) {
            this.leafletSvc.map.removeLayer(layer);
        }
    };
    PlayService.prototype.queryPlayMetadataForBasin = function (url, playIDs) {
        var attrs = ['*'
        ];
        var query = esri.query({
            url: url,
            useCors: true,
            withCredentials: true
        });
        return new Observable(function (observer) {
            var where = "" + playIDs.map(function (p) { return "PLAY_ID = '" + p + "'"; }).join(' OR ');
            query
                .where(where)
                .returnGeometry(false)
                .fields(attrs)
                .run(function (error, featureCollection, data) {
                if (error) {
                    observer.error(error);
                }
                else {
                    observer.next(data);
                    observer.complete();
                }
            });
        });
    };
    PlayService.prototype.querySourceRocksForPlays = function (url, playIDs) {
        var attrs = [
            'PLAY_ID',
            'SRC_NAME',
            'AGE_START',
            'AGE_END'
        ];
        var query = esri.query({
            url: url,
            useCors: true,
            withCredentials: true
        });
        return new Observable(function (observer) {
            var where = "" + playIDs.map(function (p) { return "PLAY_ID = '" + p + "'"; }).join(' OR ');
            query
                .where(where)
                .returnGeometry(false)
                .fields(attrs)
                .run(function (error, featureCollection) {
                if (error) {
                    observer.error(error);
                }
                else {
                    observer.next(featureCollection);
                    observer.complete();
                }
            });
        });
    };
    PlayService.prototype.parsePlayData = function (data) {
        var features = data.features;
        var playData = [];
        for (var _i = 0, features_1 = features; _i < features_1.length; _i++) {
            var p = features_1[_i];
            var westMostX = p.properties.MBG_APodX1 < p.properties.MBG_APodX2 ?
                p.properties.MBG_APodX1 : p.properties.MBG_APodX2;
            var play = {
                name: p.properties.PLAY_ID,
                startX: westMostX,
                endX: westMostX + p.properties.geobrain_width,
                minAge: Math.abs(p.properties.RFP_MIN_AGE_VAL),
                maxAge: Math.abs(p.properties.RFP_MAX_AGE_VAL),
                centroid: [p.properties.INSIDE_X, p.properties.INSIDE_Y],
                axis: p.properties.MBG_Orientation
            };
            playData.push(play);
        }
        playData = playData.sort(function (a, b) { return a.startX - b.startX; });
        return { plays: playData };
    };
    PlayService.ngInjectableDef = i0.defineInjectable({ factory: function PlayService_Factory() { return new PlayService(); }, token: PlayService, providedIn: "root" });
    return PlayService;
}());
export { PlayService };
