import { OnInit, EventEmitter } from '@angular/core';
import { AppConfigService } from '@app/app-config.service';
var XomSplashscreenComponent = /** @class */ (function () {
    function XomSplashscreenComponent(_appConfig) {
        this._appConfig = _appConfig;
        this.close = new EventEmitter(null);
        this.splashScreen = this._appConfig.getConfig().splashscreen;
    }
    XomSplashscreenComponent.prototype.ngOnInit = function () {
    };
    XomSplashscreenComponent.prototype.buttonClicked = function () {
        if (this.splashScreen.button_link) {
            window.open(this.splashScreen.button_link, '_blank');
        }
        this.close.emit();
    };
    return XomSplashscreenComponent;
}());
export { XomSplashscreenComponent };
