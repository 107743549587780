import { defer } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SearchActions } from '../actions';
import { GeosentimentActions } from '../../../shared/actions';
import { Store } from '@ngrx/store';
var ApiSearchTimeInterceptor = /** @class */ (function () {
    function ApiSearchTimeInterceptor(store) {
        this.store = store;
    }
    ApiSearchTimeInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        return defer(function () {
            var key = request.urlWithParams;
            var start = window.performance.now();
            return next.handle(request).pipe(finalize(function () {
                var end = window.performance.now();
                if (key.includes('/geobrain/v2/search/docs')) {
                    _this.store.dispatch(new SearchActions.AddDocumentResponseTime(Math.round((end - start) / 1000)));
                }
                else if (key.includes('/geobrain/v2/search/images')) {
                    _this.store.dispatch(new SearchActions.AddImageResponseTime(Math.round((end - start) / 1000)));
                }
                else if (key.includes('/gistri/geosentiment')) {
                    _this.store.dispatch(new GeosentimentActions.SetResponseTime(Math.round((end - start) / 1000)));
                }
            }));
        });
    };
    return ApiSearchTimeInterceptor;
}());
export { ApiSearchTimeInterceptor };
