import {MapLayerActionTypes} from '@app/modules/gis/actions/map-layers.actions';
import { STORAGE_ACTION_TYPE } from '@app/actions';
import {SnackbarActionTypes} from '@app/shared/actions/snackbar.actions';


export const appInsightsDoNotTrackArray: Array<string> = [
    MapLayerActionTypes.LoadMapServer,
    STORAGE_ACTION_TYPE,
    SnackbarActionTypes.Open,
    SnackbarActionTypes.Close
  ];

