import { EventEmitter, AfterViewInit } from '@angular/core';
import { ContentService } from '@shared/services/content.service';
import { Router } from '@angular/router';
var XomWelcomeMessageComponent = /** @class */ (function () {
    function XomWelcomeMessageComponent(globalContent, router) {
        this.globalContent = globalContent;
        this.router = router;
        this.closeModal = new EventEmitter(null);
        this.next = new EventEmitter(null);
    }
    XomWelcomeMessageComponent.prototype.ngAfterViewInit = function () {
        this.divFocused.nativeElement.focus();
    };
    XomWelcomeMessageComponent.prototype.openHelpOption = function () {
        var _this = this;
        this.router.navigate([]).then(function (result) { window.open(_this.globalContent.global.HELP_ITEMS[4].hyperlink, '_blank'); });
    };
    return XomWelcomeMessageComponent;
}());
export { XomWelcomeMessageComponent };
