import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Notification } from '@shared/models/UserAPI.model';
import { AppConfigService } from '@app/app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../app-config.service";
import * as i3 from "@ngrx/store";
var NotificationService = /** @class */ (function () {
    function NotificationService(http, _appConfig, sharedStore) {
        this.http = http;
        this._appConfig = _appConfig;
        this.sharedStore = sharedStore;
        this._config = this._appConfig.getConfig();
    }
    NotificationService.prototype.getNotification = function (id) {
        return this.http.get(this._config.user_api_baseurl + "/notification/" + id);
    };
    NotificationService.prototype.getNotifications = function () {
        return this.http.get(this._config.user_api_baseurl + "/notifications");
        // return this.http.get<any>('/assets/data/sample-notifications-response.json');
    };
    NotificationService.prototype.createNotification = function (notification) {
        return this.http.post(this._config.user_api_baseurl + "/notification", notification);
    };
    NotificationService.prototype.updateNotification = function (notification) {
        return this.http.put(this._config.user_api_baseurl + "/notification/" + notification.notificationId, notification);
    };
    NotificationService.prototype.deleteNotification = function (id) {
        return this.http.delete(this._config.user_api_baseurl + "/notification/deleteByWorkspaceId/" + id);
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService), i0.inject(i3.Store)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
