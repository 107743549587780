export var ContextMenuActionTypes;
(function (ContextMenuActionTypes) {
    ContextMenuActionTypes["ToggleMenu"] = "[Context Menu] Toggle Menu";
})(ContextMenuActionTypes || (ContextMenuActionTypes = {}));
var ToggleMenu = /** @class */ (function () {
    function ToggleMenu(payload) {
        this.payload = payload;
        this.type = ContextMenuActionTypes.ToggleMenu;
    }
    return ToggleMenu;
}());
export { ToggleMenu };
