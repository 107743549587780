import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { State } from './reducers';
import { RoutingService } from './shared/services/routing.service';
import { ContentService } from '@shared/services/content.service';
import { GlobalContent } from '@shared/shared-content';
import { UserActions } from '@app/shared/actions';
import * as fromShared from '@shared/reducers';
import { Observable } from 'rxjs';
import { Preference } from '@app/shared/models/UserAPI.model';
import { skipWhile, map, take } from 'rxjs/operators';
import { AdminMessage } from '@app/shared/models/AdminMessage.model';
import { UserAPIService } from '@app/shared/services/user-api.service';
import { AppConfigService } from '@app/app-config.service';
import { Router } from '@angular/router';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { Logger, CryptoUtils } from 'msal';


@Component({
  selector: 'xom-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  browserTypeWarning = false;
  screenSizeValidationFlag = false;
  screenSizeWarning = false;
  minRecommendedWidth = 1680;
  minRecommendedHeight = 750;

  preferences$: Observable<Preference>;
  preferences: Preference = null;

  showAdminMessage = false;
  adminMessage: AdminMessage;
  $sharedStoreSuscribe: any;

  ESC_KEY_CODE = this.content.global.ESC_KEY_CODE;

  loggedIn = false;
  subscription = null;
  isIframe = false;

  constructor(
    private readonly store: Store<State>,
    private routing: RoutingService,
    private router: Router,
    public content: ContentService<GlobalContent>,
    private sharedStore: Store<fromShared.State>,
    private _userSvc: UserAPIService,
    private _appConfig: AppConfigService,
    private broadcastService: BroadcastService,
    private authService: MsalService
  ) {
    this.routing.startRouteSubscribe();

    this.preferences$ = this.sharedStore.pipe(
      select(fromShared.getPreferences),
      skipWhile(preferences => preferences === null),
      map(pref => {
        this.showAdminMessage = pref.showAdminMessage;
        this.preferences = pref;
        if (this.preferences.showFTUE) {
          this.sharedStore.dispatch(new UserActions.SetActiveModal('tutorial'));
          this.sharedStore.dispatch(new UserActions.UpdatePreferences({showFTUE: false}));
        }
        return pref;
      })
    );

    this.$sharedStoreSuscribe = this.sharedStore.pipe(select(fromShared.getScreenSizeWarning))
      .subscribe(res => this.screenSizeValidationFlag = res);
    this.setScreenSizeWarning(window.innerWidth, window.innerHeight);
    this.adminMessage = this._appConfig.getConfig().admin_message;
  }

  ngOnInit() {
    if (
      (!(navigator.userAgent.toLowerCase().indexOf('chrome') > -1 || navigator.userAgent.toLowerCase().indexOf('edge') > -1))
     ) {
      this.browserTypeWarning = true;
    }

    this.isIframe = window !== window.parent && !window.opener;

    this.subscription = this.broadcastService.subscribe('msal:acquireTokenFailure', (payload) => {
      console.log(payload);
    });

    this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
      console.log('MSAL Logging: ', message);
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: false
    }));
  }

  ngOnDestroy() {
      this.$sharedStoreSuscribe.unsubscribe();
      this.broadcastService.getMSALSubject().next(1);
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setScreenSizeWarning(event.target.innerWidth, event.target.innerHeight);
  }

  closeBrowserTypeWarning() {
    this.browserTypeWarning = false;
  }

  closeScreenSizeWarning() {
    this.store.dispatch(new UserActions.ScreenSizeWarning());
    this.screenSizeWarning = false;
  }

  setScreenSizeWarning(width, height) {
    if (!this.screenSizeValidationFlag) {
      if (height < this.minRecommendedHeight || width < this.minRecommendedWidth) {
        this.screenSizeWarning = true;
      } else {
        this.screenSizeWarning = false;
        this.closeScreenSizeWarning();
      }
    }
  }

  closeSplashScreen() {
    // update API
    this.preferences.showSplashScreen = false;
    this._userSvc.updatePreferences(this.preferences).pipe(
      take(1)
    ).subscribe();
  }

  onFtueFinished() {
    this.preferences.showFTUE = false;
    this._userSvc.updatePreferences(this.preferences).pipe(
      take(1)
    ).subscribe();
  }

  closeAdminMessage() {
    // update API
    this.showAdminMessage = false;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === this.ESC_KEY_CODE) {
      if (this.browserTypeWarning) {
        this.closeBrowserTypeWarning();
      }
      if (!this.browserTypeWarning && this.screenSizeWarning) {
        this.closeScreenSizeWarning();
      }
      if (this.preferences && this.preferences.showSplashScreen && !this.showAdminMessage) {
        this.closeSplashScreen();
      }
      if (this.showAdminMessage && this.router.url.includes('/geobrain')) {
        this.closeAdminMessage();
      }
    }
  }
}
