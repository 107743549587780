import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {asyncScheduler, Observable} from 'rxjs';
import {
    map,
    switchMap, withLatestFrom,
} from 'rxjs/operators';

import {FeedbackService} from '@shared/services/feedback-service';
import {FeedbackActions, SnackbarActions} from '@shared/actions';
import * as fromShared from '@shared/reducers';
import { console } from '@app/shared/util/console.util';

@Injectable()
export class FeedbackEffects {
    constructor(
        private actions$: Actions,
        private store$: Store<fromShared.State>,
        private feedbackApi: FeedbackService
    ) {}
    @Effect()
    postFeedback$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<FeedbackActions.PostFeedback>( FeedbackActions.FeedbackActionTypes.PostFeedback),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                return this.feedbackApi.postFeedback(action.payload, storeState.shared.feedback.fileList).pipe(
                    map((res: any) => {
                        console.log('feedsuccess', res);
                        return new SnackbarActions.Open({
                            message: `Feedback successfully submited`,
                            type: 'xom-snackbar-success',
                            action: 'x'
                        });
                    })
                );
            })
        )
    @Effect()
    postImageFeedback$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<FeedbackActions.PostImageFeedback>( FeedbackActions.FeedbackActionTypes.PostImageFeedback),
            switchMap((action) => {
                return this.feedbackApi.postImageFeedback(action.payload).pipe(
                    map((res: any) => {
                        return new SnackbarActions.Open({
                            message: `Image feedback successfully submited`,
                            type: 'xom-snackbar-success',
                            action: 'x'
                        });
                    })
                );
            })
        )
}
