import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'julianDate'})
export class JulianDatePipe implements PipeTransform {
  transform(value): any {
    const parsedDate = new Date(Date.parse(value));
    const julianDate = Math.floor(((parsedDate.valueOf() - (parsedDate.getTimezoneOffset() * 60000)) / 86400000) + 2440588);
    return julianDate;
  }
}
