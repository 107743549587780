/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./xom-ws-loading-screen.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../layouts/modal-layout/modal-layout.component.ngfactory";
import * as i3 from "../../layouts/modal-layout/modal-layout.component";
import * as i4 from "../xom-button/xom-button.component.ngfactory";
import * as i5 from "../xom-button/xom-button.component";
import * as i6 from "./xom-ws-loading-screen.component";
import * as i7 from "../../services/content.service";
import * as i8 from "../../services/message.service";
var styles_XomWsLoadingScreenComponent = [i0.styles];
var RenderType_XomWsLoadingScreenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_XomWsLoadingScreenComponent, data: {} });
export { RenderType_XomWsLoadingScreenComponent as RenderType_XomWsLoadingScreenComponent };
export function View_XomWsLoadingScreenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "xom-modal-layout", [], null, null, null, i2.View_ModalLayoutComponent_0, i2.RenderType_ModalLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModalLayoutComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 2, 14, "div", [["modal-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 13, "div", [["class", "row middle-xs ws-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "lds-roller"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["xom-button", ""]], [[2, "invisible", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_XomButtonComponent_0, i4.RenderType_XomButtonComponent)), i1.ɵdid(15, 49152, null, 0, i5.XomButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(16, 0, [" ", " "]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content.global.WORK_SPACE_LOADING_SCREEN.title; _ck(_v, 11, 0, currVal_0); var currVal_1 = _co.content.global.WORK_SPACE_LOADING_SCREEN.subTitle; _ck(_v, 13, 0, currVal_1); var currVal_2 = !_co.showCancelButton; _ck(_v, 14, 0, currVal_2); var currVal_3 = _co.content.global.WORK_SPACE_LOADING_SCREEN.cancelLabel; _ck(_v, 16, 0, currVal_3); }); }
export function View_XomWsLoadingScreenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xom-ws-loading-screen", [], null, null, null, View_XomWsLoadingScreenComponent_0, RenderType_XomWsLoadingScreenComponent)), i1.ɵdid(1, 49152, null, 0, i6.XomWsLoadingScreenComponent, [i7.ContentService, i8.MessageService], null, null)], null, null); }
var XomWsLoadingScreenComponentNgFactory = i1.ɵccf("xom-ws-loading-screen", i6.XomWsLoadingScreenComponent, View_XomWsLoadingScreenComponent_Host_0, { title: "title", message: "message" }, { closeModal: "closeModal", next: "next" }, []);
export { XomWsLoadingScreenComponentNgFactory as XomWsLoadingScreenComponentNgFactory };
