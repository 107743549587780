import { Injectable } from '@angular/core';
import * as esri from 'esri-leaflet';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { ChartData, ChronostratChartData, EventsChartData } from '../components/chart-components/chart-items/chart-data.class';
import {
  BasinEvaluationHistoryConfig,
  BlockLayerConfig,
  DecisionGateLayerConfig,
  GeophysicalSurveyLayerConfig,
  LayerConfig,
  PenetrationLayerConfig,
  PlayLayerConfig,
  StudyLayerConfig,
  WellLayerConfig
} from '@app/app-config.service';

export interface MapFields {
  chartData: ChartData[];
  basinName: string;
}

/** A map server layer containing both feature data and layer metadata. */
export interface Layer<TConfig extends LayerConfig> {
  data: LayerData;
  metadata: TypedLayerMetadata<TConfig>;
}

/** Feature data of a map server layer with optional error. */
export interface LayerData {
  features: any[];
  error: string | null;
}

/** Metadata of a map server layer. */
export interface TypedLayerMetadata<TConfig extends LayerConfig> {
  name: string;
  config: TConfig;
  fields: LayerField[];
  geometryType: string;
  error: string | null;
}

/** Metadata for a generic map server layer. */
export type LayerMetadata = TypedLayerMetadata<LayerConfig>;

/** Metadata for a map server layer containing blocks. */
export type BlockLayerMetadata = TypedLayerMetadata<BlockLayerConfig>;

/** Metadata for a map server layer containing decision gates. */
export type DecisionGateLayerMetadata = TypedLayerMetadata<DecisionGateLayerConfig>;

/** Metadata for a map server layer containing geophysical surveys. */
export type GeophysicalSurveyLayerMetadata = TypedLayerMetadata<GeophysicalSurveyLayerConfig>;

/** Metadata for a map server layer containing penetrations. */
export type PenetrationLayerMetadata = TypedLayerMetadata<PenetrationLayerConfig>;

/** Metadata for a map server layer containing plays. */
export type PlayLayerMetadata = TypedLayerMetadata<PlayLayerConfig>;

/** Metadata for a map server layer containing studies. */
export type StudyLayerMetadata = TypedLayerMetadata<StudyLayerConfig>;

/** Metadata for a map server layer containing wells. */
export type WellLayerMetadata = TypedLayerMetadata<WellLayerConfig>;

/** A map server layer field. */
export interface LayerField {
  name: string;
  esriType: string;
}

/** All layers for Basin Evaluation History. */
export interface BasinEvaluationHistoryLayers {
  blocks: Layer<BlockLayerConfig>[];
  decisionGates: Layer<DecisionGateLayerConfig>[];
  geophysicalSurveys: Layer<GeophysicalSurveyLayerConfig>[];
  penetrations: Layer<PenetrationLayerConfig>[];
  plays: Layer<PlayLayerConfig>[];
  studies: Layer<StudyLayerConfig>[];
  wells: Layer<WellLayerConfig>[];
}

/**
 * Handles the data requests from other components within basin eval
 * Allows for a generic query to be exposed and usable
 */
@Injectable({
  providedIn: 'root'
})
export class BasinEvaluationHistoryService {

  /**
   * This Observable is used to pass the ESID from the
   * basin-evaluation-history.component to the leaflet
   * component so that it can render geometry data on the map
   */
  public mapFieldsBasinEval = new BehaviorSubject<MapFields>({ chartData: null, basinName: '' });

  /**
   * This method is a receiver for the fields esID and mapServer from
   * basin-eval-history.component and is called after an object in the
   * awareness charts is clicked
   * @param uniqueID The unique ID field for a chartItem inside the basin-eval
   * charts that is used to filter against within the mapserver
   * @param mapServerUrl Url of mapserver that the chartItem data was taken from
   */
  showOnMap(chartData: ChronostratChartData[] | EventsChartData[], basinName: string) {
    this.mapFieldsBasinEval.next({
      chartData: chartData,
      basinName: basinName
    });
  }

  /**
   * Queries the layer data needed for Basin Evaluation History.
   * @param config The configuration for Basin Evaluation History.
   * @param basinName The name of the basin.
   * @returns An Observable containing all layer data needed for Basin Evaluation History.
   */
  queryBasinEvaluationHistoryLayers$(config: BasinEvaluationHistoryConfig, basinName: string): Observable<BasinEvaluationHistoryLayers> {
    return forkJoin({
      blocks: forkJoin(config.blocks.layers.map(s => this.queryLayer(s, basinName))),
      decisionGates: forkJoin(config.decisionGates.layers.map(s => this.queryLayer(s, basinName))),
      geophysicalSurveys: forkJoin(config.geophysicalSurveys.layers.map(s => this.queryLayer(s, basinName))),
      penetrations: forkJoin(config.penetrations.layers.map(s => this.queryLayer(s, basinName))),
      plays: forkJoin(config.plays.layers.map(s => this.queryLayer(s, basinName))),
      studies: forkJoin(config.studies.layers.map(s => this.queryLayer(s, basinName))),
      wells: forkJoin(config.wells.layers.map(s => this.queryLayer(s, basinName)))
    });
  }

  /**
   * Queries the data and metadata of a map service layer.
   * @param layerConfig The configuration of the layer.
   * @param basinName The name of the basin.
   * @returns An Observable of the layer's data and metadata.
   */
  queryLayer<T extends LayerConfig>(layerConfig: T, basinName: string): Observable<Layer<T>> {
    return forkJoin({
      data: this.queryLayerData$(layerConfig, basinName),
      metadata: this.queryLayerMetadata$(layerConfig)
    });
  }

  /**
   * Queries the feature data of a map service layer.
   * @param config The configuration of the layer.
   * @param basinName The name of the basin.
   * @returns An Observable of the layer's data.
   */
  queryLayerData$<T extends LayerConfig>(config: T, basinName: string): Observable<LayerData> {
    return new Observable<any>(subscriber => {
      const result = { features: [], error: null };

      esri
        .query({ url: config.url, useCors: true, withCredentials: true })
        .where(`${config.basinField} = '${basinName}'`)
        .returnGeometry(false)
        .run((error, featureCollection) => {
          try {
            if (error) {
              result.error = `Failed to query layer data for ${config.url}`;
              console.error(result.error, error);
            } else {
              result.features = featureCollection.features.map(f => f.properties);
            }
          } catch (exception) {
            result.error = `Exception occurred querying layer data for ${config.url}`;
            console.error(result.error, exception);
          } finally {
            subscriber.next(result);
            subscriber.complete();
          }
        });
    });
  }

  /**
   * Queries the metadata of a map service layer.
   * @param config The configuration of the layer.
   * @returns An Observable of the layer's metadata.
   */
  queryLayerMetadata$<T extends LayerConfig>(config: T): Observable<TypedLayerMetadata<T>> {
    return new Observable<TypedLayerMetadata<T>>(subscriber => {
      const result = { name: '', config: config, fields: [], geometryType: '', error: null };
      esri
        .featureLayer({ 'url': config.url, 'useCors': false })
        .metadata((error, metadata) => {
          try {
            if (error) {
              result.error = `Failed to query layer metadata for ${config.url}`;
              console.error(result.error, error);
            } else {
              result.name = metadata.name;
              result.fields = metadata.fields.map(f => ({ name: f.name, esriType: f.type }));
              result.geometryType = metadata.geometryType;
            }
          } catch (exception) {
            result.error = `Exception occurred retrieving metadata for ${config.url}`;
            console.error(result.error, exception);
          } finally {
            subscriber.next(result);
            subscriber.complete();
          }
        });
    });
  }
}
