import { Injectable } from '@angular/core';
import { AppConfig, AppConfigService } from '@app/app-config.service';
import { Observable, EMPTY as empty } from 'rxjs';
import * as esri from 'esri-leaflet';

@Injectable({
  providedIn: 'root',
})
export class SeisbookLayerService {
  private _config: AppConfig;

  constructor(
    private _appConfig: AppConfigService,
  ) {
    this._config = this._appConfig.getConfig();
  }

  getBasinNames(countries: string[]): Observable<any> {
    const featureQuery = esri.query({
      url: this._config.geobookLayerUrl,
      useCors: true,
      withCredentials: true
    });

    if (!countries.length) { return empty; }

    const condition = countries.reduce(
      (prev, country) => prev.length ? `${prev} OR COUNTRY_NAMES = '${country}'` : `COUNTRY_NAMES = '${country}'`, '');

    featureQuery.where(condition);
    featureQuery.fields('BASIN_NAME');

    return new Observable(observer => {
      featureQuery.run((error, featureCollection: any) => {
        if (error) {
          observer.next({});
        } else {
          observer.next(featureCollection.features.map((feature => feature.properties.BASIN_NAME)));
        }
      });
    });
  }
}
