import { Component, Output, EventEmitter, Input, AfterViewInit, ViewChild } from '@angular/core';
import { ContentService } from '@shared/services/content.service';
import { GlobalContent } from '@shared/shared-content';
import { Router } from '@angular/router';

@Component({
  selector: 'xom-welcome-message',
  templateUrl: './xom-welcome-message.component.html',
  styleUrls: ['./xom-welcome-message.component.scss']
})
export class XomWelcomeMessageComponent implements AfterViewInit {
  @ViewChild('divFocused') divFocused;
  @Input() title: string;
  @Input() message: string;
  @Output() closeModal: EventEmitter<any> = new EventEmitter(null);
  @Output() next: EventEmitter<any> = new EventEmitter(null);

  constructor(public globalContent: ContentService<GlobalContent>, private router: Router) { }

  ngAfterViewInit() {
    this.divFocused.nativeElement.focus();
  }

  openHelpOption() {
    this.router.navigate([]).then(result => {  window.open( this.globalContent.global.HELP_ITEMS[4].hyperlink, '_blank'); });
  }
}
