import { Observable } from 'rxjs';
import { publish, refCount } from 'rxjs/operators';
import * as esri from 'esri-leaflet';
import { HttpClient } from '@angular/common/http';
var FieldDataService = /** @class */ (function () {
    function FieldDataService(http) {
        this.http = http;
    }
    FieldDataService.prototype.queryFieldData = function (url, geometry, attributes, returnGeometry) {
        if (returnGeometry === void 0) { returnGeometry = false; }
        var query = esri.query({
            url: url,
            useCors: true,
            withCredentials: true
        });
        return new Observable(function (observer) {
            query
                .intersects(geometry)
                .returnGeometry(returnGeometry)
                .fields(attributes)
                .run(function (error, featureCollection) {
                if (error) {
                    observer.error(error);
                }
                else {
                    observer.next(featureCollection);
                }
            });
        }).pipe(publish(), refCount());
    };
    return FieldDataService;
}());
export { FieldDataService };
