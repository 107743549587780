import { AwarenessActions } from '../actions/index';

export interface State {
  basin: string;
  tab: string;
}

export const initialState: State = {
  basin: '',
  tab: 'Basin'
};

export function reducer(
  state = initialState,
  action:
    | AwarenessActions.AwarenessActionsUnion
): State {
  switch (action.type) {
    case AwarenessActions.AwarenessActions.SetBasin: {
      return {
        ...state,
        basin: action.payload
      };
    }
    case AwarenessActions.AwarenessActions.SetTab: {
      return {
        ...state,
        tab: action.payload
      };
    }
    default:
      return state;
  }
}
