import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { SearchActions } from '@app/modules/search/actions';
import * as fromSearch from '@search/reducers';
import { Store } from '@ngrx/store';

@Injectable()
export class ExportFileService {

  constructor(public store: Store<fromSearch.State>) {
  }

  public exportAsCSVFile(data: string, csvFileName: string): void {
    const blob = new Blob([data], { type: 'text/csv' });
    FileSaver.saveAs(blob, csvFileName + '.csv');
    this.store.dispatch(new SearchActions.SetExportLoading(false));
  }
  public exportAsShapeFile(data: string = '', shapeFileName: string = '') {

    this.store.dispatch(new SearchActions.SetExportLoading(false));
  }
}
