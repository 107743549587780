import { Action } from '@ngrx/store';
import { PopupFeature } from '../models/PopupFeature.model';
import { LatLng } from 'leaflet';

export enum PopupActionTypes {
    OpenPopup = '[Popup] Open Popup',
    ValidatePopupFeatures = '[Popup] Validate Popup Features',
    AddPopupFeatures = '[Popup] Add Popup Features',
    ClearPopupFeatures = '[Popup] Clear Popup Features',
    SetPopupIndex = '[Popup] Set Popup Index',
    ZoomToFeature = '[Popup] Zoom To Feature',
}

export class OpenPopup implements Action {
    readonly type = PopupActionTypes.OpenPopup;

    constructor(public payload: L.LatLng) {}
}

export class ValidatePopupFeatures implements Action {
    readonly type = PopupActionTypes.ValidatePopupFeatures;

    constructor(public payload: { features: PopupFeature[], layer: string }) {}
}

export class AddPopupFeatures implements Action {
    readonly type = PopupActionTypes.AddPopupFeatures;

    constructor(public payload: PopupFeature[]) {}
}

export class ClearPopupFeatures implements Action {
    readonly type = PopupActionTypes.ClearPopupFeatures;

    constructor(public payload?: any) {}
}

export class SetPopupIndex implements Action {
    readonly type = PopupActionTypes.SetPopupIndex;

    constructor(public payload: number) {}
}

export class ZoomToFeature implements Action {
    readonly type = PopupActionTypes.ZoomToFeature;
}


export type PopupActionsUnion =
    | OpenPopup
    | AddPopupFeatures
    | ClearPopupFeatures
    | SetPopupIndex
    | ValidatePopupFeatures
    | ZoomToFeature;

