import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
var ɵ0 = { key: 'geobrain-key' };
var routes = [
    { path: '', redirectTo: 'geobrain', pathMatch: 'full' },
    {
        path: 'map',
        loadChildren: '@gis/gis.module#GisModule'
    },
    {
        path: 'geobrain', canActivate: [MsalGuard],
        loadChildren: '@geobrain-loader/geobrain-loader.module#GeobrainLoaderModule',
        data: ɵ0
    },
    {
        path: 'landing', canActivate: [MsalGuard],
        loadChildren: '@landing/landing.module#LandingModule'
    },
    {
        path: 'showcase', canActivate: [MsalGuard],
        loadChildren: '@showcase/style-showcase.module#StyleShowcaseModule'
    },
    {
        path: 'workspaces', canActivate: [MsalGuard],
        loadChildren: '@workspaces/workspaces.module#WorkspacesModule'
    },
    {
        path: 'profile', canActivate: [MsalGuard],
        loadChildren: '@profile/profile.module#ProfileModule'
    },
    {
        path: 'geosentiment',
        loadChildren: '@geosentiment/geosentiment.module#GeosentimentModule'
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
