export var CollectionsActionTypes;
(function (CollectionsActionTypes) {
    CollectionsActionTypes["LoadCollection"] = "[Collections] Load Collection";
    CollectionsActionTypes["LoadCollectionSuccess"] = "[Collections] Load Collection Success";
    CollectionsActionTypes["GetCollectionDocuments"] = "[Collections] Get Collection Documents";
    CollectionsActionTypes["GetCollectionDocumentsSuccess"] = "[Collections] Get Collection Documents Success";
    CollectionsActionTypes["GetCollectionDocumentsFailure"] = "[Collections] Get Collection Documents Failure";
    CollectionsActionTypes["LoadCollectionSummary"] = "[Collections] Load Collection Summary";
    CollectionsActionTypes["SetFilteringTypes"] = "[Collections] Set Filtering Types";
    CollectionsActionTypes["ResetFilteringTypes"] = "[Collections] Reset Filtering Types";
    CollectionsActionTypes["GetFilteredCollection"] = "[Collections] Get Filtered Collection";
    CollectionsActionTypes["GetFilteredCollectionSuccess"] = "[Collections] Get Filtered Collection Success";
    CollectionsActionTypes["GetSortedCollection"] = "[Collections] Get Sorted Collection";
    CollectionsActionTypes["GetSortedCollectionSuccess"] = "[Collections] Get Sorted Collection Success";
    CollectionsActionTypes["CollectionSearch"] = "[Collections] Collection Search";
    CollectionsActionTypes["CollectionSearchSuccess"] = "[Collections] Collection Search Success";
    CollectionsActionTypes["LoadAllCollections"] = "[Collections] Load All Collections";
    CollectionsActionTypes["LoadAllCollectionsSuccess"] = "[Collections] Load All Collections Success";
    CollectionsActionTypes["LoadAllCollectionsFailure"] = "[Collections] Load All Collections Failure";
    CollectionsActionTypes["CreateCollection"] = "[Collections] Create Collection";
    CollectionsActionTypes["CreateCollectionSuccess"] = "[Collections] Create Collections Success";
    CollectionsActionTypes["CreateCollectionFailure"] = "[Collections] Create Collections Failure";
    CollectionsActionTypes["DeleteCollection"] = "[Collections] Delete Collection";
    CollectionsActionTypes["DeleteCollectionSuccess"] = "[Collections] Delete Collections Success";
    CollectionsActionTypes["DeleteCollectionFailure"] = "[Collections] Delete Collections Failure";
    CollectionsActionTypes["RemoveFromCollection"] = "[Collections] Remove From Collection";
    CollectionsActionTypes["RemoveFromCollectionSuccess"] = "[Collections] Remove From Collection Success";
    CollectionsActionTypes["UpdateCollection"] = "[Collections] Update Collection";
    CollectionsActionTypes["UpdateCollectionSuccess"] = "[Collections] Update Collection Success";
    CollectionsActionTypes["UpdateCollectionFailure"] = "[Collections] Update Collection Failure";
    CollectionsActionTypes["SetSelectedDocument"] = "[Collections] Set Selected Document";
    CollectionsActionTypes["SetAllSelectedDocument"] = "[Collections] Set All Selected Document";
    CollectionsActionTypes["SetNotPresentFilesToRemove"] = "[Collections] Set Not Present Files To Remove";
    CollectionsActionTypes["ResetSelectedDocument"] = "[Collections] Reset Selected Document";
    CollectionsActionTypes["ExportAllCollectionDocs"] = "[Collections] Export Documents to CSV";
    CollectionsActionTypes["ExportAllCollectionDocsSuccess"] = "[Collections] Export Documents to CSV Success";
    CollectionsActionTypes["SetCardsFlag"] = "[Collections] Set Cards Flag";
    CollectionsActionTypes["CollectionNotExisting"] = "[Collections] Collection Not Existing";
    CollectionsActionTypes["ToggleNotesPanel"] = "[Collections] Toggle Notes Panel";
    CollectionsActionTypes["SetNotesFromFlipcard"] = "[Collections] Set Notes From Flipcard";
    CollectionsActionTypes["SetNotesLoading"] = "[Collections] Set Notes Loading";
    CollectionsActionTypes["SetShownNotes"] = "[Collections] Set Shown Notes";
    CollectionsActionTypes["AddCollectionComment"] = "[Collections] Add Collection Comment";
    CollectionsActionTypes["AddCollectionCommentSuccess"] = "[Collections] Add Collection Comment Success";
    CollectionsActionTypes["DeleteCollectionComment"] = "[Collections] Delete Collection Comment";
    CollectionsActionTypes["DeleteCollectionCommentSuccess"] = "[Collections] Delete Collection Comment Success";
    CollectionsActionTypes["PinCollection"] = "[Collections] Pin Collection";
    CollectionsActionTypes["UnpinCollection"] = "[Collections] Unpin Collection";
})(CollectionsActionTypes || (CollectionsActionTypes = {}));
var LoadCollection = /** @class */ (function () {
    function LoadCollection(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.LoadCollection;
    }
    return LoadCollection;
}());
export { LoadCollection };
var LoadCollectionSuccess = /** @class */ (function () {
    function LoadCollectionSuccess(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.LoadCollectionSuccess;
    }
    return LoadCollectionSuccess;
}());
export { LoadCollectionSuccess };
var GetCollectionDocuments = /** @class */ (function () {
    function GetCollectionDocuments(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.GetCollectionDocuments;
    }
    return GetCollectionDocuments;
}());
export { GetCollectionDocuments };
var GetCollectionDocumentsSuccess = /** @class */ (function () {
    function GetCollectionDocumentsSuccess(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.GetCollectionDocumentsSuccess;
    }
    return GetCollectionDocumentsSuccess;
}());
export { GetCollectionDocumentsSuccess };
var GetCollectionDocumentsFailure = /** @class */ (function () {
    function GetCollectionDocumentsFailure(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.GetCollectionDocumentsFailure;
    }
    return GetCollectionDocumentsFailure;
}());
export { GetCollectionDocumentsFailure };
var LoadCollectionSummary = /** @class */ (function () {
    function LoadCollectionSummary(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.LoadCollectionSummary;
    }
    return LoadCollectionSummary;
}());
export { LoadCollectionSummary };
var SetFilteringTypes = /** @class */ (function () {
    function SetFilteringTypes(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.SetFilteringTypes;
    }
    return SetFilteringTypes;
}());
export { SetFilteringTypes };
var ResetFilteringTypes = /** @class */ (function () {
    function ResetFilteringTypes() {
        this.type = CollectionsActionTypes.ResetFilteringTypes;
    }
    return ResetFilteringTypes;
}());
export { ResetFilteringTypes };
var GetFilteredCollection = /** @class */ (function () {
    function GetFilteredCollection(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.GetFilteredCollection;
    }
    return GetFilteredCollection;
}());
export { GetFilteredCollection };
var GetFilteredCollectionSuccess = /** @class */ (function () {
    function GetFilteredCollectionSuccess(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.GetFilteredCollectionSuccess;
    }
    return GetFilteredCollectionSuccess;
}());
export { GetFilteredCollectionSuccess };
var GetSortedCollection = /** @class */ (function () {
    function GetSortedCollection(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.GetSortedCollection;
    }
    return GetSortedCollection;
}());
export { GetSortedCollection };
var GetSortedCollectionSuccess = /** @class */ (function () {
    function GetSortedCollectionSuccess(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.GetSortedCollectionSuccess;
    }
    return GetSortedCollectionSuccess;
}());
export { GetSortedCollectionSuccess };
var CollectionSearch = /** @class */ (function () {
    function CollectionSearch(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.CollectionSearch;
    }
    return CollectionSearch;
}());
export { CollectionSearch };
var CollectionSearchSuccess = /** @class */ (function () {
    function CollectionSearchSuccess(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.CollectionSearchSuccess;
    }
    return CollectionSearchSuccess;
}());
export { CollectionSearchSuccess };
var LoadAllCollections = /** @class */ (function () {
    function LoadAllCollections() {
        this.type = CollectionsActionTypes.LoadAllCollections;
    }
    return LoadAllCollections;
}());
export { LoadAllCollections };
var LoadAllCollectionsSuccess = /** @class */ (function () {
    function LoadAllCollectionsSuccess(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.LoadAllCollectionsSuccess;
    }
    return LoadAllCollectionsSuccess;
}());
export { LoadAllCollectionsSuccess };
var LoadAllCollectionsFailure = /** @class */ (function () {
    function LoadAllCollectionsFailure(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.LoadAllCollectionsFailure;
    }
    return LoadAllCollectionsFailure;
}());
export { LoadAllCollectionsFailure };
var CreateCollection = /** @class */ (function () {
    function CreateCollection(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.CreateCollection;
    }
    return CreateCollection;
}());
export { CreateCollection };
var CreateCollectionSuccess = /** @class */ (function () {
    function CreateCollectionSuccess(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.CreateCollectionSuccess;
    }
    return CreateCollectionSuccess;
}());
export { CreateCollectionSuccess };
var CreateCollectionFailure = /** @class */ (function () {
    function CreateCollectionFailure(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.CreateCollectionFailure;
    }
    return CreateCollectionFailure;
}());
export { CreateCollectionFailure };
var DeleteCollection = /** @class */ (function () {
    function DeleteCollection(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.DeleteCollection;
    }
    return DeleteCollection;
}());
export { DeleteCollection };
var DeleteCollectionSuccess = /** @class */ (function () {
    function DeleteCollectionSuccess(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.DeleteCollectionSuccess;
    }
    return DeleteCollectionSuccess;
}());
export { DeleteCollectionSuccess };
var DeleteCollectionFailure = /** @class */ (function () {
    function DeleteCollectionFailure(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.DeleteCollectionFailure;
    }
    return DeleteCollectionFailure;
}());
export { DeleteCollectionFailure };
var RemoveFromCollection = /** @class */ (function () {
    function RemoveFromCollection() {
        this.type = CollectionsActionTypes.RemoveFromCollection;
    }
    return RemoveFromCollection;
}());
export { RemoveFromCollection };
var RemoveFromCollectionSuccess = /** @class */ (function () {
    function RemoveFromCollectionSuccess(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.RemoveFromCollectionSuccess;
    }
    return RemoveFromCollectionSuccess;
}());
export { RemoveFromCollectionSuccess };
var UpdateCollection = /** @class */ (function () {
    function UpdateCollection(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.UpdateCollection;
    }
    return UpdateCollection;
}());
export { UpdateCollection };
var UpdateCollectionSuccess = /** @class */ (function () {
    function UpdateCollectionSuccess(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.UpdateCollectionSuccess;
    }
    return UpdateCollectionSuccess;
}());
export { UpdateCollectionSuccess };
var UpdateCollectionFailure = /** @class */ (function () {
    function UpdateCollectionFailure(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.UpdateCollectionFailure;
    }
    return UpdateCollectionFailure;
}());
export { UpdateCollectionFailure };
var SetCardsFlag = /** @class */ (function () {
    function SetCardsFlag(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.SetCardsFlag;
    }
    return SetCardsFlag;
}());
export { SetCardsFlag };
var SetSelectedDocument = /** @class */ (function () {
    function SetSelectedDocument(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.SetSelectedDocument;
    }
    return SetSelectedDocument;
}());
export { SetSelectedDocument };
var SetAllSelectedDocument = /** @class */ (function () {
    function SetAllSelectedDocument(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.SetAllSelectedDocument;
    }
    return SetAllSelectedDocument;
}());
export { SetAllSelectedDocument };
var SetNotPresentFilesToRemove = /** @class */ (function () {
    function SetNotPresentFilesToRemove(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.SetNotPresentFilesToRemove;
    }
    return SetNotPresentFilesToRemove;
}());
export { SetNotPresentFilesToRemove };
var ResetSelectedDocument = /** @class */ (function () {
    function ResetSelectedDocument() {
        this.type = CollectionsActionTypes.ResetSelectedDocument;
    }
    return ResetSelectedDocument;
}());
export { ResetSelectedDocument };
var ExportAllCollectionDocs = /** @class */ (function () {
    function ExportAllCollectionDocs(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.ExportAllCollectionDocs;
    }
    return ExportAllCollectionDocs;
}());
export { ExportAllCollectionDocs };
var ExportAllCollectionDocsSuccess = /** @class */ (function () {
    function ExportAllCollectionDocsSuccess(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.ExportAllCollectionDocsSuccess;
    }
    return ExportAllCollectionDocsSuccess;
}());
export { ExportAllCollectionDocsSuccess };
var CollectionNotExisting = /** @class */ (function () {
    function CollectionNotExisting(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.CollectionNotExisting;
    }
    return CollectionNotExisting;
}());
export { CollectionNotExisting };
var ToggleNotesPanel = /** @class */ (function () {
    function ToggleNotesPanel(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.ToggleNotesPanel;
    }
    return ToggleNotesPanel;
}());
export { ToggleNotesPanel };
var SetNotesFromFlipcard = /** @class */ (function () {
    function SetNotesFromFlipcard(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.SetNotesFromFlipcard;
    }
    return SetNotesFromFlipcard;
}());
export { SetNotesFromFlipcard };
var SetNotesLoading = /** @class */ (function () {
    function SetNotesLoading(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.SetNotesLoading;
    }
    return SetNotesLoading;
}());
export { SetNotesLoading };
var SetShownNotes = /** @class */ (function () {
    function SetShownNotes(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.SetShownNotes;
    }
    return SetShownNotes;
}());
export { SetShownNotes };
var AddCollectionComment = /** @class */ (function () {
    function AddCollectionComment(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.AddCollectionComment;
    }
    return AddCollectionComment;
}());
export { AddCollectionComment };
var AddCollectionCommentSuccess = /** @class */ (function () {
    function AddCollectionCommentSuccess(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.AddCollectionCommentSuccess;
    }
    return AddCollectionCommentSuccess;
}());
export { AddCollectionCommentSuccess };
var DeleteCollectionComment = /** @class */ (function () {
    function DeleteCollectionComment(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.DeleteCollectionComment;
    }
    return DeleteCollectionComment;
}());
export { DeleteCollectionComment };
var DeleteCollectionCommentSuccess = /** @class */ (function () {
    function DeleteCollectionCommentSuccess(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.DeleteCollectionCommentSuccess;
    }
    return DeleteCollectionCommentSuccess;
}());
export { DeleteCollectionCommentSuccess };
var PinCollection = /** @class */ (function () {
    function PinCollection(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.PinCollection;
    }
    return PinCollection;
}());
export { PinCollection };
var UnpinCollection = /** @class */ (function () {
    function UnpinCollection(payload) {
        this.payload = payload;
        this.type = CollectionsActionTypes.UnpinCollection;
    }
    return UnpinCollection;
}());
export { UnpinCollection };
