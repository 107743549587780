var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GeosentimentActions } from '@shared/actions';
export var initialState = {
    originalGraphData: undefined,
    processedGraphData: undefined,
    originalMentionsData: undefined,
    documentsData: [],
    indexesToFilterAssociations: [-1],
    responseTime: 0
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case GeosentimentActions.GeosentimentActionTypes.SaveInitialData:
            return __assign({}, state, { originalGraphData: action.payload.graph, originalMentionsData: action.payload.mentions, processedGraphData: action.payload.graph });
        case GeosentimentActions.GeosentimentActionTypes.SaveDocumentsData:
            return __assign({}, state, { documentsData: action.payload });
        case GeosentimentActions.GeosentimentActionTypes.SaveGraphProcessedData:
            return __assign({}, state, { processedGraphData: action.payload });
        case GeosentimentActions.GeosentimentActionTypes.SetIndexesSelected:
            return __assign({}, state, { indexesToFilterAssociations: action.payload });
        case GeosentimentActions.GeosentimentActionTypes.SetResponseTime:
            return __assign({}, state, { responseTime: action.payload });
        default: {
            return state;
        }
    }
}
