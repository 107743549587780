/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./xom-welcome-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../layouts/modal-layout/modal-layout.component.ngfactory";
import * as i3 from "../../layouts/modal-layout/modal-layout.component";
import * as i4 from "../xom-button/xom-button.component.ngfactory";
import * as i5 from "../xom-button/xom-button.component";
import * as i6 from "./xom-welcome-message.component";
import * as i7 from "../../services/content.service";
import * as i8 from "@angular/router";
var styles_XomWelcomeMessageComponent = [i0.styles];
var RenderType_XomWelcomeMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_XomWelcomeMessageComponent, data: {} });
export { RenderType_XomWelcomeMessageComponent as RenderType_XomWelcomeMessageComponent };
export function View_XomWelcomeMessageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { divFocused: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 28, "xom-modal-layout", [], null, null, null, i2.View_ModalLayoutComponent_0, i2.RenderType_ModalLayoutComponent)), i1.ɵdid(2, 114688, null, 0, i3.ModalLayoutComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 2, 26, "div", [["modal-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 25, "div", [["class", "row middle-xs welcome-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "splash-waves top-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["src", "/assets/images/swoop-top-left-turquoise.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "col-xs-12 close-container no-gutter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "close icon-close increase-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 11, "div", [["class", "col-xs-12 welcome-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h1", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "p", [["class", "message"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 7, "div", [["class", "options"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "second-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, [" ", " "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "help-option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openHelpOption() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, [" ", " "])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "button", [["xom-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.next.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_XomButtonComponent_0, i4.RenderType_XomButtonComponent)), i1.ɵdid(19, 49152, null, 0, i5.XomButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(20, 0, ["", ""])), (_l()(), i1.ɵeld(21, 0, [[1, 0], ["divFocused", 1]], null, 0, "input", [["type", "checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "div", [["class", "splash-waves bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 0, "img", [["src", "/assets/images/swoop-turquoise.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "div", [["class", "splash-waves bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "img", [["src", "/assets/images/swoop-transparent-gray.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 1, "div", [["class", "splash-waves bottom-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 0, "img", [["src", "/assets/images/swoop-front-bottom-left-blue.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 1, "div", [["class", "splash-waves bottom-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 0, "img", [["src", "/assets/images/swoop-bottom-right-blue.svg"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.globalContent.global.WELCOME_CONTENT.TITLE; _ck(_v, 11, 0, currVal_0); var currVal_1 = _co.globalContent.global.WELCOME_CONTENT.MESSAGE; _ck(_v, 12, 0, currVal_1); var currVal_2 = _co.globalContent.global.WELCOME_CONTENT.OPTIONS.MESSAGE; _ck(_v, 15, 0, currVal_2); var currVal_3 = _co.globalContent.global.WELCOME_CONTENT.OPTIONS.OPTION_1; _ck(_v, 17, 0, currVal_3); var currVal_4 = _co.globalContent.global.WELCOME_CONTENT.OPTIONS.OPTION_2; _ck(_v, 20, 0, currVal_4); }); }
export function View_XomWelcomeMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xom-welcome-message", [], null, null, null, View_XomWelcomeMessageComponent_0, RenderType_XomWelcomeMessageComponent)), i1.ɵdid(1, 4243456, null, 0, i6.XomWelcomeMessageComponent, [i7.ContentService, i8.Router], null, null)], null, null); }
var XomWelcomeMessageComponentNgFactory = i1.ɵccf("xom-welcome-message", i6.XomWelcomeMessageComponent, View_XomWelcomeMessageComponent_Host_0, { title: "title", message: "message" }, { closeModal: "closeModal", next: "next" }, []);
export { XomWelcomeMessageComponentNgFactory as XomWelcomeMessageComponentNgFactory };
