import { Injectable, OnDestroy } from '@angular/core';
import {Location} from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutingService implements OnDestroy {

  public previousUrl = new BehaviorSubject<string>(null);
  public currentUrl = new BehaviorSubject<string>(null);
  public hasNavigatedBack = new BehaviorSubject<Boolean>(false);
  public activateWorkspaceSaveButton = new BehaviorSubject<string>(null);
  subscriptions: Subscription[] = [];

  constructor(private router: Router, public location: Location) { }

  ngOnDestroy() {
      this.subscriptions.forEach( subscription => {
          try {
              subscription.unsubscribe();
          } catch (e) {
              console.error('[Error] OnDestroy unsubscribes', e);
          }
      });
  }

  startRouteSubscribe() {
    const $routeSubscribe = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.previousUrl.next(this.currentUrl.value);
        this.currentUrl.next(this.router.url);
        this.navigationBackExecution();
        this.activateWorkspaceSaveButton.next(this.router.url);
    });
    this.subscriptions.push($routeSubscribe);
  }

  navigationBackExecution() {
    if (this.previousUrl) {
      this.hasNavigatedBack.next(true);
    }
  }

  goToPreviousUrl() {
    if (this.previousUrl) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/geobrain');
    }
  }
}
