/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./xom-button.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./xom-button.component";
var styles_XomButtonComponent = [i0.styles];
var RenderType_XomButtonComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_XomButtonComponent, data: {} });
export { RenderType_XomButtonComponent as RenderType_XomButtonComponent };
export function View_XomButtonComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "xom-button-wrapper uicontrol"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_XomButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["xom-button", ""]], null, null, null, View_XomButtonComponent_0, RenderType_XomButtonComponent)), i1.ɵdid(1, 49152, null, 0, i2.XomButtonComponent, [i1.ElementRef], null, null)], null, null); }
var XomButtonComponentNgFactory = i1.ɵccf("button[xom-button], button[xom-secondary-button], button[xom-tertiary-button], button[xom-round-button],\n             button[xom-round-outline-button], button[xom-small-button], button[xom-small-error-button], button[xom-circle-button]", i2.XomButtonComponent, View_XomButtonComponent_Host_0, { color: "color" }, {}, ["*"]);
export { XomButtonComponentNgFactory as XomButtonComponentNgFactory };
