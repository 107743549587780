import { Injectable } from '@angular/core';
import * as esri from 'esri-leaflet';
import { Observable } from 'rxjs';
import { FeatureCollection, Point } from 'geojson';
import { AwarenessService } from '@app/modules/basin-awareness/services/awareness-service';
import { MapLayerActions, PopupActions } from '@app/modules/gis/actions';
import { LeafletMapService } from '@app/modules/gis/services/leaflet-map.service';
import * as fromGis from '@gis/reducers/index';
import { Store } from '@ngrx/store';
import { SetMapLoading } from '@app/modules/gis/actions/map-layers.actions';
import L from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class PlayService {
  wellLayer: L.GeoJSON<Point>;
  private awareSvc: AwarenessService;
  private leafletSvc: LeafletMapService;
  wellLayerLoading    = false;
  public store: Store<fromGis.State>;


  public queryPlaysByBasinName(url: string, basinName: string,
    attributes: string[], returnGeometry = false): Observable<FeatureCollection> {
    const query = esri.query({
      url: url,
      useCors: true,
      withCredentials: true
    });
    return new Observable(observer => {
      const where = `BASIN_NAME = '${basinName}'`;
      query
        .where(where)
        .returnGeometry(returnGeometry)
        .fields(attributes)
        .run((error, featureCollection: FeatureCollection) => {
          if (error) {
            observer.error(error);
          } else {
            observer.next(featureCollection);
            observer.complete();
          }
        });
    });
  }

  public queryPlayMetadata(url: string, playID: string): Observable<any> {
    const attrs = ['*'
    ];
    const query = esri.query({
      url: url,
      useCors: true,
      withCredentials: true
    });
    return new Observable(observer => {
      const where = `PLAY_ID = '${playID}'`;
      query
        .where(where)
        .returnGeometry(false)
        .fields(attrs)
        .run((error, featureCollection: FeatureCollection) => {
          if (error) {
            observer.error(error);
          } else {
            observer.next(featureCollection);
            observer.complete();
          }
        });
    });
  }

  public queryWellMetadata(url: string, wellID: string): Observable<any> {
    const attrs = ['*'
    ];
    const query = esri.query({
      url: url,
      useCors: true,
      withCredentials: true
    });
    return new Observable(observer => {
      const where = `WEL_ID = '${wellID}'`;
      query
        .where(where)
        .returnGeometry(false)
        .fields(attrs)
        .run((error, featureCollection: FeatureCollection) => {
          if (error) {
            observer.error(error);
          } else {
            observer.next(featureCollection);
            observer.complete();
          }
        });
    });
  }

  removeLayer(layer) {
    if (this.leafletSvc.map.hasLayer(layer)) {
      this.leafletSvc.map.removeLayer(layer);
    }
  }

  public queryPlayMetadataForBasin(url: string, playIDs: string[]): Observable<any> {
    const attrs = ['*'
    ];
    const query = esri.query({
      url: url,
      useCors: true,
      withCredentials: true
    });
    return new Observable(observer => {
      const where = `${playIDs.map(p => `PLAY_ID = '${p}'`).join(' OR ')}`;
      query
        .where(where)
        .returnGeometry(false)
        .fields(attrs)
        .run((error, featureCollection: FeatureCollection, data) => {
          if (error) {
            observer.error(error);
          } else {
            observer.next(data);
            observer.complete();
          }
        });
    });
  }

  public querySourceRocksForPlays(url: string, playIDs: string[]): Observable<any> {
    const attrs = [
      'PLAY_ID',
      'SRC_NAME',
      'AGE_START',
      'AGE_END'
    ];
    const query = esri.query({
      url: url,
      useCors: true,
      withCredentials: true
    });
    return new Observable(observer => {
      const where = `${playIDs.map(p => `PLAY_ID = '${p}'`).join(' OR ')}`;
      query
        .where(where)
        .returnGeometry(false)
        .fields(attrs)
        .run((error, featureCollection: FeatureCollection) => {
          if (error) {
            observer.error(error);
          } else {
            observer.next(featureCollection);
            observer.complete();
          }
        });
    });
  }

  public parsePlayData(data: any): any {
    const features = data.features;
    let playData = [];
    for (const p of features) {
      const westMostX = p.properties.MBG_APodX1 < p.properties.MBG_APodX2 ?
        p.properties.MBG_APodX1 : p.properties.MBG_APodX2;
      const play: PlayData = {
        name: p.properties.PLAY_ID,
        startX: westMostX,
        endX: westMostX + p.properties.geobrain_width,
        minAge: Math.abs(p.properties.RFP_MIN_AGE_VAL),
        maxAge: Math.abs(p.properties.RFP_MAX_AGE_VAL),
        centroid: [p.properties.INSIDE_X, p.properties.INSIDE_Y],
        axis: p.properties.MBG_Orientation
      };
      playData.push(play);
    }
    playData = playData.sort((a, b) => a.startX - b.startX);
    return { plays: playData };
  }
}

interface PlayData {
  name: string;
  startX: number;
  endX: number;
  minAge: number;
  maxAge: number;
  centroid: number[];
  axis: number;
}
