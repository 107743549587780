import { ActionReducer, Action } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PopupActions } from '../actions/index';
import { PopupFeature } from '../models/PopupFeature.model';
import { LatLng } from 'leaflet';

export interface State {
  location: LatLng;
  features: PopupFeature[];
  loading: boolean;
  popupIndex: number;
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export const initialState: State = {
  features: [],
  location: null,
  loading: false,
  popupIndex: null
};

export function reducer(
  state = initialState,
  action: PopupActions.PopupActionsUnion
): State {
  switch (action.type) {
    case PopupActions.PopupActionTypes.OpenPopup:
      return {
        ...state,
        features: [],
        loading: true
      };
    case PopupActions.PopupActionTypes.AddPopupFeatures:
      return {
        ...state,
        features: [...state.features, ...action.payload],
        loading: false
      };
    case PopupActions.PopupActionTypes.ClearPopupFeatures:
      return {
        ...state,
        features: []
      };
    case PopupActions.PopupActionTypes.SetPopupIndex:
      return {
        ...state,
        popupIndex: action.payload
      };
    default: {
      return state;
    }
  }
}
