import { GlobalContent } from '@shared/shared-content';
var ContentService = /** @class */ (function () {
    function ContentService(local) {
        this.local = local;
        this.localeCode = 'en-US';
        this.global = new GlobalContent;
        // do check to get custom localeCode from API
        // else default to english US.
    }
    return ContentService;
}());
export { ContentService };
