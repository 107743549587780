import { PipeTransform } from '@angular/core';
var FormatDatePipe = /** @class */ (function () {
    function FormatDatePipe() {
    }
    FormatDatePipe.prototype.transform = function (value) {
        var parsedDate = new Date(Date.parse(value));
        var day = parsedDate.getDate();
        var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
            'August', 'September', 'October', 'November', 'December'][parsedDate.getMonth()];
        var year = parsedDate.getFullYear();
        return month + ' ' + day + this.getOrdinalNum(day) + ', ' + year;
    };
    FormatDatePipe.prototype.getOrdinalNum = function (n) {
        return (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
    };
    return FormatDatePipe;
}());
export { FormatDatePipe };
