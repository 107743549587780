import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EsriLayer, EsriLayerResponse } from '@shared/interfaces/esri.interface';
import * as esri from 'esri-leaflet';
import { Layer, map } from 'leaflet';
import { EsriBasemapLayers, BasemapConfig} from '@gis/models/EsriBasemapLayers';
import {
  MapLayerActions
} from '@gis/actions/index';
import { EsriLayerLegend, EsriServerLegend } from '@gis/models/EsriLegend';
import { MapServerResponse, DefaultLayer } from '@app/shared/interfaces/map-server.interface';
import { GeoTag } from '@app/shared/models/SearchAPI.model';
import { FeatureCollection } from 'geojson';
import { FacetFeatureCollection } from '../models/FacetFeature';
import { DEFAULT_ACTIVE_BASE_MAP } from '../gis-content';

export interface ActiveMapServer {
  url: string;
  opacity: number;
  layers: number[];
  plotIndex: number;
  visible: boolean;
  portalId: string;
  minZoom: number;
}
export interface State {
  availableLayers: EsriLayer[];
  availableMapServers: MapServerResponse[];
  activeMapServers: ActiveMapServer[];
  availableLegends: EsriServerLegend[];
  availableBasemaps: Array<BasemapConfig>;
  activeBasemap: BasemapConfig;
  mapServerUrls: string[];
  activePopups: Layer[];
  selectedFeatures: string[];
  activeGeoTags: GeoTag[];
  currentZoom: number;
  currentFieldData: FeatureCollection;
  activeCentroid: boolean;
  loading: boolean;
  coordinates: [number, number];
  bounds: [number, number][];
  layerManagerOpen: Boolean;
  facetFeaturesCollection: FacetFeatureCollection[];
  lastURLMapServerFail: string;
}

export const initialState: State = {
    availableLayers: [],
    availableMapServers: [],
    activeMapServers: [],
    availableLegends: [],
    availableBasemaps: EsriBasemapLayers,
    activeBasemap: EsriBasemapLayers.find(config => config.name === DEFAULT_ACTIVE_BASE_MAP),
    mapServerUrls: [],
    activePopups: [],
    selectedFeatures: [],
    activeGeoTags: [],
    currentZoom: null,
    currentFieldData: null,
    activeCentroid: null,
    loading: false,
    coordinates: null,
    bounds: [ [ 0, 0 ], [ 0, 0 ] ],
    layerManagerOpen: false,
    facetFeaturesCollection: [],
    lastURLMapServerFail: ''
};

export function reducer(
  state = initialState,
  action:
    | MapLayerActions.MapLayerActionsUnion
): State {
  switch (action.type) {
    case MapLayerActions.MapLayerActionTypes.ActivateDynamicMapLayer: {
      const index = state.activeMapServers.map(server => server.url).indexOf(action.payload.url);
      let mapServers: ActiveMapServer[] = [];
      if (index > -1) {
        // do update
        mapServers = state.activeMapServers.map(server => {
          if (server.url === action.payload.url) {
            if (!action.payload.layerId.length) {
              // just toggle visibility and opacity
              return {
                ...server,
                plotIndex: action.payload.plotIndex,
                opacity: action.payload.opacity,
                visible: true
              };
            } else {
              let layers;
              if (server.layers && server.layers.length) {
                if (action.payload.layerId && action.payload.layerId.length) {
                  layers = Array.from(new Set([...server.layers, ...action.payload.layerId]));
                } else {
                  layers = server.layers;
                }
              } else {
                layers = action.payload.layerId;
              }
              return {
                ...server,
                layers: layers,
                opacity: action.payload.opacity,
              };
            }
          } else {
            return server;
          }
        });
      } else {
        // add
        mapServers = [
          ...state.activeMapServers,
          {
            url: action.payload.url,
            layers: action.payload.layerId,
            opacity: action.payload.opacity,
            plotIndex: action.payload.plotIndex,
            visible: action.payload.layerId.length ? false : true,
            portalId: action.payload.portalId,
            minZoom: action.payload.minZoom
          }
        ];
      }

      return {
        ...state,
        activeMapServers: mapServers,
        loading: action.payload.layerId === null ? true : false
      };
    }
    case MapLayerActions.MapLayerActionTypes.SetMapLoading: {
      return {
        ...state,
        loading: action.payload
      };
    }
    case MapLayerActions.MapLayerActionTypes.DeactivateDynamicMapLayer: {
      let mapServers: ActiveMapServer[] = [];

      mapServers = state.activeMapServers.filter(server => {
        if (server.url === action.payload.url) {

          if (action.payload.layerId !== null) {
            // remove layer id provided
            server.layers = server.layers.filter(layerId => layerId !== action.payload.layerId);
          } else {
            // no layer id provided, toggle visibility
            server.visible = false;
          }
        }

        return server;
      });

      return {
        ...state,
        activeMapServers: [
          ...mapServers
        ]
      };
    }
    case MapLayerActions.MapLayerActionTypes.RemoveActivatedMapServer: {
      const index = state.activeMapServers.map(server => server.url).indexOf(action.payload);
      const activeServers = [...state.activeMapServers];
      activeServers.splice(index, 1);

      return {
        ...state,
        activeMapServers: activeServers
      };
    }
    case MapLayerActions.MapLayerActionTypes.SetBasemap: {
      return {
        ...state,
        activeBasemap: action.payload
      };
    }
    case MapLayerActions.MapLayerActionTypes.LoadAllMapServers: {

      const availableServers = action.payload;
      const activeServers = [...state.activeMapServers];

      availableServers.forEach((server) => {
        activeServers.forEach(active => {
          if (server.url === active.url) {
            active.plotIndex = server.plotIndex;
          }
        });
      });

      return {
        ...state,
        availableMapServers: availableServers,
        activeMapServers: activeServers
      };
    }
    case MapLayerActions.MapLayerActionTypes.LoadMapServerSuccess: {

      const availableServers = [...state.availableMapServers];
      const activeServers = [...state.activeMapServers];

      if (!action.payload.plotIndex) {
        action.payload.plotIndex = 1;

        availableServers.forEach((server, index) => {
          server.plotIndex = index + 2;
          activeServers.forEach(active => {
            if (server.url === active.url) {
              active.plotIndex = server.plotIndex;
            }
          });
        });
      } else {
        // For old workspaces to align available and active map servers plotIndex
        availableServers.forEach((server) => {
          activeServers.forEach(active => {
            if (server.url === active.url) {
              active.plotIndex = server.plotIndex;
            }
          });
        });
      }

      const sortedLayers = [action.payload, ...state.availableMapServers];
      sortedLayers.sort((a, b) => a.plotIndex - b.plotIndex);
      return {
        ...state,
        availableMapServers: sortedLayers,
        activeMapServers: activeServers
      };
    }
    case MapLayerActions.MapLayerActionTypes.LoadLayers: {
      return {
        ...state,
        mapServerUrls: [...state.mapServerUrls, action.payload]
      };
    }
    case MapLayerActions.MapLayerActionTypes.LoadLayersSuccess: {
      return {
        ...state,
        availableLayers: [...state.availableLayers, ...action.payload]
      };
    }
    case MapLayerActions.MapLayerActionTypes.RemoveMapServer: {
      const availableMapServers = [...state.availableMapServers.filter(layer => layer !== action.payload)];
      const activeMapServers = [...state.activeMapServers];

      const idx = state.activeMapServers.map(server => server.url).indexOf(action.payload.url);
      if (idx !== -1) {
        activeMapServers.splice(idx, 1);
      }

      availableMapServers.forEach((mapServer, index) => {
        mapServer.plotIndex = index + 1;
        activeMapServers.forEach(activeMapServer => {
          if (activeMapServer.url === mapServer.url) {
            activeMapServer.plotIndex = mapServer.plotIndex;
          }
        });
      });

      const legends = [...state.availableLegends];
      for (let i = legends.length - 1; i >= 0 ; i--) {
        if (legends[i].url === action.payload.url) {
          legends.splice(i, 1);
        }
      }
      return {
        ...state,
        availableMapServers: availableMapServers,
        activeMapServers: activeMapServers,
        availableLegends: legends
      };
    }
    case MapLayerActions.MapLayerActionTypes.RemoveAllMapServers: {
      return {
        ...state,
        availableMapServers: []
      };
    }
    case MapLayerActions.MapLayerActionTypes.ReplaceMapServer: {
      const mapServers = [...state.availableMapServers];
      mapServers[action.payload.index] = action.payload.mapServer;
      return {
        ...state,
        availableMapServers: [...mapServers]
      };
    }
    case MapLayerActions.MapLayerActionTypes.ActivateCentroid: {
      return {
        ...state,
        activeCentroid: action.payload
      };
    }
    case MapLayerActions.MapLayerActionTypes.LoadLegendSuccess: {
      return {
        ...state,
        availableLegends: [...state.availableLegends, action.payload]
      };
    }
    case MapLayerActions.MapLayerActionTypes.RemoveMapLegends: {
      return {
        ...state,
        availableLegends: []
      };
    }
    case MapLayerActions.MapLayerActionTypes.HighlightFeature: {
      return {
        ...state,
        selectedFeatures: [...state.selectedFeatures, action.payload]
      };
    }
    case MapLayerActions.MapLayerActionTypes.ActivateFeaturePopupSuccess: {
      return {
        ...state,
        activePopups: [...state.activePopups, action.payload]
      };
    }
    case MapLayerActions.MapLayerActionTypes.DeactivateAllLayers: {
      return {
        ...state,
        activeMapServers: []
      };
    }
    case MapLayerActions.MapLayerActionTypes.SetGeoTags: {
      return {
        ...state,
        activeGeoTags: action.payload
      };
    }
    case MapLayerActions.MapLayerActionTypes.SetZoom: {
      return {
        ...state,
        currentZoom: action.payload
      };
    }
    case MapLayerActions.MapLayerActionTypes.LoadFieldData: {
      return {
        ...state,
        currentFieldData: action.payload
      };
    }
    case MapLayerActions.MapLayerActionTypes.SetCoordinates: {
      return {
        ...state,
        coordinates: action.payload
      };
    }
    case MapLayerActions.MapLayerActionTypes.SetBounds: {
      return {
        ...state,
        bounds: action.payload
      };
    }
    case MapLayerActions.MapLayerActionTypes.ResetActiveCentroid: {
      return {
        ...state,
        activeCentroid: null
      };
    }
    case MapLayerActions.MapLayerActionTypes.SetLayerManagerOpen: {
      return {
          ...state,
          layerManagerOpen: action.payload
      };
    }
    case MapLayerActions.MapLayerActionTypes.AddFacetFeaturesCollection: {
      return {
        ...state,
        facetFeaturesCollection: [...state.facetFeaturesCollection, action.payload]
      };
    }
    case MapLayerActions.MapLayerActionTypes.ClearFacetFeaturesCollection: {
      return {
        ...state,
        facetFeaturesCollection: []
      };
    }
    case MapLayerActions.MapLayerActionTypes.SetLastURLMapServerFail: {
      return {
          ...state,
          lastURLMapServerFail: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
