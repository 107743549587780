import { UserActions } from '../actions';
import { User, Preference } from '../models/UserAPI.model';
import {GetAvailableMessagesSuccess} from '@shared/actions/user.actions';

export interface State {
    allUsers: User[];
    userInfo: User;
    preferences: Preference;
    screenSizeFlag: boolean;
    activeModal: string;
    availableMessages: any[];
    promoterCategories: any[]; // If the user is promoter this is not empty
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export const initialState: State = {
  allUsers: [],
  userInfo: null,
  preferences: null,
  screenSizeFlag: false,
  activeModal: '',
  availableMessages: [],
  promoterCategories: []
};

export function reducer(
  state = initialState,
  action: UserActions.UserActionsUnion
): State {
  switch (action.type) {
    case UserActions.UserActionTypes.LoadAllUsers:
      return {
        ...state,
        allUsers: action.payload
      };
    case UserActions.UserActionTypes.LoadUser:
      return {
        ...state,
        userInfo: action.payload
      };
    case UserActions.UserActionTypes.UpdateUser:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.payload
        }
      };
    case UserActions.UserActionTypes.LoadPreferences:
      return {
        ...state,
        preferences: action.payload
      };
    case UserActions.UserActionTypes.UpdatePreferences:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.payload
        }
      };
    case UserActions.UserActionTypes.ScreenSizeWarning:
      return {
        ...state,
        screenSizeFlag: true
      };
    case UserActions.UserActionTypes.SetActiveModal:
      return {
        ...state,
        activeModal: action.payload
      };
    case UserActions.UserActionTypes.GetAvailableMessagesSuccess:
      return {
        ...state,
        availableMessages: action.payload
      };
    case UserActions.UserActionTypes.SetPromoterCategories:
      return {
        ...state,
        promoterCategories: action.payload
      };
    default: {
      return state;
    }
  }
}
