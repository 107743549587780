import { Injectable, Inject } from '@angular/core';
import { AppConfig, AppConfigService } from '@app/app-config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface BasinConfig {
  basinCollection: any;
  analogType: boolean;
  basin: string;
}

@Injectable({
  providedIn: 'root'
})
export class CreamingCurvesService {

  private API_PATH: string;
  public features = new BehaviorSubject<any>([]);

  constructor(private http: HttpClient, private _appConfig: AppConfigService) {
      const config = this._appConfig.getConfig();
      this.API_PATH = config.datascience_api_baseurl + '/sqldata/creamingcurves';
  }

  getBasins() {
      return this.http.get<any>(`${this.API_PATH}/getBasins`);
      // return this.http.get<any[]>(`/assets/data/creaming_curves_getBasins.json`);
  }

  getPlays() {
      return this.http.get<any>(`${this.API_PATH}/getPlays`);
      // return this.http.get<any[]>(`/assets/data/creaming_curves_getPlays.json`);
  }

  productionByBasins(listOfBasins: string[]) {
      return this.http.post<any>(`${this.API_PATH}/productionByBasins`, {basins: listOfBasins});
      // return this.http.get<any[]>(`/assets/data/creaming_curves_productionByBasins.json`);
  }

  productionByPlays(listOfBasins: string[]) {
      return this.http.post<any>(`${this.API_PATH}/productionByPlays`, {basins: listOfBasins});
      // return this.http.get<any[]>(`/assets/data/creaming_curves_productionByBasinsPlays.json`);
  }

  productionByBasinsPlays(listOfBasins: string[], listOfPlays: string[]) {
      return this.http.post<any>(`${this.API_PATH}/productionByBasinsPlays`, {basins: listOfBasins, plays: listOfPlays});
      // return this.http.get<any[]>(`/assets/data/creaming_curves_productionByPlays.json`);
      // return this.http.get<any[]>(`/assets/data/creaming_curves_productionByBasinsPlaysFilterJustBasinCuumLum.json`);
  }

  getBasinsWithCreamingCurve(country: string): Observable<any> {
    return this.http.get<any>(`${this.API_PATH}/getBasins?country=${country}`);
  }
}
