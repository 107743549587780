import { Action } from '@ngrx/store';

export enum AwarenessActions {
  SetBasin = '[Awareness] Set Basin',
  GetTab = '[Awareness] Get Tab',
  SetTab = '[Awareness] Set Tab'
}

export class SetBasin implements Action {
  readonly type = AwarenessActions.SetBasin;

  constructor(public payload: string) {}
}

export class GetTab implements Action {
  readonly type = AwarenessActions.GetTab;

  constructor(public payload: string) {}
}

export class SetTab implements Action {
  readonly type = AwarenessActions.SetTab;

  constructor(public payload: string) {}
}

export type AwarenessActionsUnion =
  | SetBasin
  | GetTab
  | SetTab;
