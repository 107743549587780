export var MapAOIActionTypes;
(function (MapAOIActionTypes) {
    MapAOIActionTypes["SetAOIActive"] = "[Map AOI] Set drawing mode";
    MapAOIActionTypes["SetAOIApplied"] = "[Map AOI] Set AOI Applied";
    MapAOIActionTypes["SetAddingStatus"] = "[Map AOI] Set add feature mode";
    MapAOIActionTypes["SetAOILayer"] = "[Map AOI] Set AOI Layer";
    MapAOIActionTypes["ClearAOI"] = "[Map AOI] Clear AOI";
    MapAOIActionTypes["SetAOIArea"] = "[Map AOI] Set AOI Area";
    MapAOIActionTypes["AddAOIFeature"] = "[Map AOI] Add AOI Feature";
    MapAOIActionTypes["LoadAOI"] = "[Map AOI] Load AOI";
    MapAOIActionTypes["SetAOIQuery"] = "[Map AOI] Set AOI Query";
    MapAOIActionTypes["RemoveAOIFeature"] = "[Map AOI] Remove AOI Feature";
    MapAOIActionTypes["LayerIntersectFailure"] = "[MAP AOI] Layer Intersect Failure";
    MapAOIActionTypes["FindNearFeatures"] = "[Map AOI] Find Near Features to Add";
    MapAOIActionTypes["SetAOIGeoUnit"] = "[Map AOI] Set AOI Geo Unit";
    MapAOIActionTypes["SetAOILayerConfigs"] = "[Map AOI] Set Layer Configs";
    MapAOIActionTypes["SetAOIManagerOpen"] = "[Map AOI] Set AOI Manager Open";
    MapAOIActionTypes["SetAOIMeasurementToolOpen"] = "[Map AOI] Set AOI Measurement Tool Open";
    MapAOIActionTypes["SetAOIManagerFromProfile"] = "[Map AOI] Set AOI Manager From Profile";
    MapAOIActionTypes["SetAOIVisibility"] = "[Map AOI] Set AOI Layer Visibility";
    MapAOIActionTypes["UpdateAOIQuery"] = "[Map AOI] Update AOI Query";
    MapAOIActionTypes["AddAvailableAOI"] = "[Map AOI] Add Availabe AOIs";
    MapAOIActionTypes["SetWellCountryAssociation"] = "[Map AOI] Set Well Country Association";
    MapAOIActionTypes["SetSelectedAOIBasin"] = "[Map AOI] Set Selected AOI Basin";
})(MapAOIActionTypes || (MapAOIActionTypes = {}));
var SetAOIActive = /** @class */ (function () {
    function SetAOIActive(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.SetAOIActive;
    }
    return SetAOIActive;
}());
export { SetAOIActive };
var SetAOIApplied = /** @class */ (function () {
    function SetAOIApplied(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.SetAOIApplied;
    }
    return SetAOIApplied;
}());
export { SetAOIApplied };
var SetAddingStatus = /** @class */ (function () {
    function SetAddingStatus(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.SetAddingStatus;
    }
    return SetAddingStatus;
}());
export { SetAddingStatus };
var SetAOILayer = /** @class */ (function () {
    function SetAOILayer(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.SetAOILayer;
    }
    return SetAOILayer;
}());
export { SetAOILayer };
var SetAOIArea = /** @class */ (function () {
    function SetAOIArea(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.SetAOIArea;
    }
    return SetAOIArea;
}());
export { SetAOIArea };
var AddAOIFeature = /** @class */ (function () {
    function AddAOIFeature(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.AddAOIFeature;
    }
    return AddAOIFeature;
}());
export { AddAOIFeature };
var LoadAOI = /** @class */ (function () {
    function LoadAOI(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.LoadAOI;
    }
    return LoadAOI;
}());
export { LoadAOI };
var SetAOIQuery = /** @class */ (function () {
    function SetAOIQuery(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.SetAOIQuery;
    }
    return SetAOIQuery;
}());
export { SetAOIQuery };
var RemoveAOIFeature = /** @class */ (function () {
    function RemoveAOIFeature(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.RemoveAOIFeature;
    }
    return RemoveAOIFeature;
}());
export { RemoveAOIFeature };
var ClearAOI = /** @class */ (function () {
    function ClearAOI(payload) {
        if (payload === void 0) { payload = null; }
        this.payload = payload;
        this.type = MapAOIActionTypes.ClearAOI;
    }
    return ClearAOI;
}());
export { ClearAOI };
var LayerIntersectFailure = /** @class */ (function () {
    function LayerIntersectFailure(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.LayerIntersectFailure;
    }
    return LayerIntersectFailure;
}());
export { LayerIntersectFailure };
var FindNearFeatures = /** @class */ (function () {
    function FindNearFeatures(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.FindNearFeatures;
    }
    return FindNearFeatures;
}());
export { FindNearFeatures };
var SetAOIGeoUnit = /** @class */ (function () {
    function SetAOIGeoUnit(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.SetAOIGeoUnit;
    }
    return SetAOIGeoUnit;
}());
export { SetAOIGeoUnit };
var SetAOILayerConfigs = /** @class */ (function () {
    function SetAOILayerConfigs(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.SetAOILayerConfigs;
    }
    return SetAOILayerConfigs;
}());
export { SetAOILayerConfigs };
var SetAOIManagerOpen = /** @class */ (function () {
    function SetAOIManagerOpen(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.SetAOIManagerOpen;
    }
    return SetAOIManagerOpen;
}());
export { SetAOIManagerOpen };
var SetAOIMeasurementToolOpen = /** @class */ (function () {
    function SetAOIMeasurementToolOpen(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.SetAOIMeasurementToolOpen;
    }
    return SetAOIMeasurementToolOpen;
}());
export { SetAOIMeasurementToolOpen };
var SetAOIManagerFromProfile = /** @class */ (function () {
    function SetAOIManagerFromProfile(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.SetAOIManagerFromProfile;
    }
    return SetAOIManagerFromProfile;
}());
export { SetAOIManagerFromProfile };
var SetAOIVisibility = /** @class */ (function () {
    function SetAOIVisibility(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.SetAOIVisibility;
    }
    return SetAOIVisibility;
}());
export { SetAOIVisibility };
var UpdateAOIQuery = /** @class */ (function () {
    function UpdateAOIQuery(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.UpdateAOIQuery;
    }
    return UpdateAOIQuery;
}());
export { UpdateAOIQuery };
var AddAvailableAOI = /** @class */ (function () {
    function AddAvailableAOI(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.AddAvailableAOI;
    }
    return AddAvailableAOI;
}());
export { AddAvailableAOI };
var SetWellCountryAssociation = /** @class */ (function () {
    function SetWellCountryAssociation(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.SetWellCountryAssociation;
    }
    return SetWellCountryAssociation;
}());
export { SetWellCountryAssociation };
var SetSelectedAOIBasin = /** @class */ (function () {
    function SetSelectedAOIBasin(payload) {
        this.payload = payload;
        this.type = MapAOIActionTypes.SetSelectedAOIBasin;
    }
    return SetSelectedAOIBasin;
}());
export { SetSelectedAOIBasin };
