import { Subject } from 'rxjs';
// List of Layer Names
export var CHANNELS;
(function (CHANNELS) {
    CHANNELS[CHANNELS["Layer"] = 1] = "Layer";
    CHANNELS[CHANNELS["WorkspaceDialog"] = 2] = "WorkspaceDialog";
    CHANNELS[CHANNELS["Promote"] = 3] = "Promote";
})(CHANNELS || (CHANNELS = {}));
export var OPERATIONS;
(function (OPERATIONS) {
    OPERATIONS[OPERATIONS["Add"] = 1] = "Add";
    OPERATIONS[OPERATIONS["Remove"] = 2] = "Remove";
    OPERATIONS[OPERATIONS["Save"] = 3] = "Save";
    OPERATIONS[OPERATIONS["Clean"] = 4] = "Clean";
    OPERATIONS[OPERATIONS["Update"] = 5] = "Update";
    OPERATIONS[OPERATIONS["Set"] = 6] = "Set";
    OPERATIONS[OPERATIONS["Custom"] = 7] = "Custom";
    OPERATIONS[OPERATIONS["Show"] = 8] = "Show";
    OPERATIONS[OPERATIONS["Hide"] = 9] = "Hide";
    OPERATIONS[OPERATIONS["Clone"] = 10] = "Clone";
})(OPERATIONS || (OPERATIONS = {}));
var MessageService = /** @class */ (function () {
    function MessageService() {
        this.channels = {};
    }
    MessageService.prototype.channel = function (channelName) {
        var ch = this.channels[channelName];
        if (ch) {
            return ch;
        }
        this.channels[channelName] = new Subject();
        return this.channels[channelName];
    };
    MessageService.prototype.sendMessage = function (channelName, message) {
        this.channel(channelName).next(message);
    };
    MessageService.prototype.getMessage = function (channelName) {
        return this.channel(channelName).asObservable();
    };
    MessageService.prototype.clearMessage = function (channelName) {
        this.channel(channelName).next();
    };
    return MessageService;
}());
export { MessageService };
