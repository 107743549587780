import {
    ActionReducerMap,
    ActionReducer,
    MetaReducer
} from '@ngrx/store';
import { localStorageSync, rehydrateApplicationState } from 'ngrx-store-localstorage';
import { environment } from '@environments/environment';
import * as fromRouter from '@ngrx/router-store';

import { STORAGE_ACTION_TYPE, WORKSPACE_ACTION_TYPE } from '@app/actions';
import { appInsights } from '@app/service/insights-service/insights.service';
import * as L from 'leaflet';
import {appInsightsDoNotTrackArray} from '@app/reducers/app-insights-track-event';
import { SearchActionTypes } from '@app/modules/search/actions/search.actions';
import { console } from '@app/shared/util/console.util';
import { DEFAULT_ACTIVE_BASE_MAP } from '@app/modules/gis/gis-content';
import EsriBasemapLayers, { BasemapConfig } from '@app/modules/gis/models/EsriBasemapLayers';
import { DataSanitizer } from '@microsoft/applicationinsights-common';
import { AppState } from '@app/shared/models/UserAPI.model';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

// import * as fromLayout from '@example-app/core/reducers/layout.reducer';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  router: fromRouter.RouterReducerState;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: any): any => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: State, action: any) => {
    const keys = ['search'];

    if  (action.type === STORAGE_ACTION_TYPE && keys.includes(action.payload)) {
      const rehydratedState = rehydrateApplicationState([action.payload], localStorage, k => k, false);
      return { ...state, ...rehydratedState };
    }

    return localStorageSync(
      {
        keys: [
          'search'
        ],
        rehydrate: false
      }
    )(reducer)(state, action);
  };
}

export function workspaceHydrateReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any) => {

    if  (action.type === WORKSPACE_ACTION_TYPE) {
      let finalAoiLayer = null;
      const brightRed = '#fe030c';
      if (action.payload.aoi.aoiLayer) {
        const geoJson = L.geoJSON(action.payload.aoi.aoiLayer);
        const savedLayer = geoJson.getLayers()[0] as any;
        savedLayer.options.color = brightRed;
        finalAoiLayer = savedLayer;
      }

      action.payload.activeMapServer.forEach(activeServer => {
        action.payload.customMapServers.forEach(customServer => {
          if (customServer.url === activeServer.url) {
            if (customServer.portalId) {
              activeServer.portalId = customServer.portalId;
            }
          }
        });
      });

      let activeBasemap: BasemapConfig = null;
      if (action.payload.activeBasemap && action.payload.activeBasemap.length) {
        activeBasemap = EsriBasemapLayers.find(config => config.name === action.payload.activeBasemap);
      }
       if (activeBasemap === null || activeBasemap === undefined) {
        activeBasemap = EsriBasemapLayers.find(config => config.name === DEFAULT_ACTIVE_BASE_MAP);
      }

      const appState: AppState[] = action.payload.appState;

      /*
      const popup = appState.find(item => item.app === 'popup');
      if (popup) {
        state.gis.popup = JSON.parse(popup.data);
      }
      */

      appState.filter(item => item.app !== 'popup').map(item => state[item.app] = JSON.parse(item.data));

      return {
        ...state,
        search: {
          ...state.search,
          documentQuery: {
            start: action.payload.query.start,
            pageSize: action.payload.query.pageSize,
            countries: action.payload.query.countries,
            keywords: action.payload.query.keywords,
            sortField: action.payload.query.sortField,
            sortDirection: action.payload.query.sortDirection,
            facets: {}
          },
          shared: {
            ...state.search.shared,
            queryText: action.payload.queryText,
          }
        },
        gis: {
          ...state.gis,
          aoi: {
            ...state.gis.aoi,
            aoiQuery: action.payload.aoi.aoiQuery,
            availableAois: action.payload.aoi.availableAois,
            aoiLayer: finalAoiLayer,
            wellsCountryAssociation: action.payload.wellCountryAssociation,
            selectedAOIBasin: action.payload.dropdownBasin
          },
          layers: {
            ...state.gis.layers,
            activeMapServers: action.payload.activeMapServer,
            activeBasemap:  activeBasemap
          }
        }
      };
    } else {
      return reducer(state, action);
    }
  };
}

export function appInsightsTrackReducer(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: any, action: any): any => {
    const result = reducer(state, action);
    let telemetry: any = null,
        customEvents: any = null;
    if (action && action.type) {
        telemetry = {
            name: action.type, // Easy to see in the Azure's interface
            properties: {
                type: action.type
            }
        };
    }
    if (action && action.payload) {

      if (action.type === SearchActionTypes.AddDocumentResponseTime || action.type === SearchActionTypes.AddImageResponseTime) {
        if (state.search.shared !== 'undefined') {
          customEvents = {'Query_Time' : action.payload,
          'Query_Text' : state.search.shared.queryText,
          'Query_Query': state.search.shared.initialFacets,
          'Query_AOI' : state.gis.aoi.aoiApplied };
        } else {
          customEvents = [{ payload: action.payload }];
        }

      } else if (action.type === 'ROUTER_NAVIGATION') {
        customEvents = [{ payload: { url: action.payload.event.url }}];
      } else {
        customEvents = [{ payload: action.payload }];
      }
    }

    try {
      const eventStr = JSON.stringify(customEvents);
      if (eventStr.length > DataSanitizer.MAX_PROPERTY_LENGTH) {
        customEvents = eventStr.substring(0, DataSanitizer.MAX_PROPERTY_LENGTH);
        console.groupCollapsed('[App Insights] Event string truncated.');
        console.log(`from ${eventStr.length} to ${DataSanitizer.MAX_PROPERTY_LENGTH} characters.`);
        console.log('action', action);
        console.groupEnd();
      }
    } catch (error) {
      console.groupCollapsed('[App Insights] Error on insights payload');
      console.log('Might be due to a circular payload object. See error for more details.');
      console.log('action', action);
      console.log('error', error);
      console.groupEnd();
    }

    // tslint:disable-next-line:max-line-length
    if (appInsights.appInsights.core !== undefined) {
      if (telemetry && customEvents && !appInsightsDoNotTrackArray.includes(action.type)
          && appInsights.config.instrumentationKey !== undefined) {
        appInsights.trackEvent(telemetry, customEvents);
      }
    }
    return result;
  };
}


/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger, workspaceHydrateReducer, appInsightsTrackReducer]
  : [workspaceHydrateReducer, appInsightsTrackReducer];

  // ? [logger, localStorageSyncReducer]
  // : [localStorageSyncReducer];

/**
 * Layout Reducers
 */
// export const getLayoutState = createFeatureSelector<State, fromLayout.State>(
//   'layout'
// );

// export const getShowSidenav = createSelector(
//   getLayoutState,
//   fromLayout.getShowSidenav
// );
