import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import { asyncScheduler, EMPTY as empty, Observable, of } from 'rxjs';
import {
    catchError,
    debounceTime,
    map,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';

import {UserAPIService} from '@shared/services/user-api.service';
import {UserActions} from '@shared/actions';
import {SnackbarActions} from '@shared/actions/index';
import * as fromShared from '@shared/reducers';
import {AOI, Preference, User} from '@shared/models/UserAPI.model';
import {sharedStylesheetJitUrl} from '@angular/compiler';

@Injectable()
export class UserEffects {
    @Effect()
    updateUser$ = ({ debounce = 300, scheduler = asyncScheduler } = {}): Observable<Action> =>
        this.actions$.pipe(
            ofType<UserActions.UpdateUser>( UserActions.UserActionTypes.UpdateUser ),
            debounceTime(debounce, scheduler),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                return this.userApi.updateUser(storeState.shared.user.userInfo).pipe(
                    map((res: User) => {
                        return new SnackbarActions.Open({
                            message: `User updated succesfully`,
                            type: 'xom-snackbar-success',
                            action: 'x'
                          });
                    })
                );
            })
        )
    @Effect()
    updatePreferences$ = ({ debounce = 300, scheduler = asyncScheduler } = {}): Observable<Action> =>
        this.actions$.pipe(
            ofType<UserActions.UpdatePreferences>( UserActions.UserActionTypes.UpdatePreferences ),
            debounceTime(debounce, scheduler),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                return this.userApi.updatePreferences(storeState.shared.user.preferences).pipe(
                    map((res: any) => {
                        // res is a message with the operation result, i.e.: "1 User preferences modified successfully: "
                        return new SnackbarActions.Open({
                            message: `Preferences saved succesfully`,
                            type: 'xom-snackbar-success',
                            action: 'x'
                          });
                    })
                );
            })
        )
    @Effect()
    GetAvailableMessages$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<UserActions.GetAvailableMessages>( UserActions.UserActionTypes.GetAvailableMessages ),
            switchMap((action) => {
                return this.userApi.getAvailableMessages().pipe(
                    map((res: any) => {
                        return new UserActions.GetAvailableMessagesSuccess(res);
                    })
                );
            })
        )
    constructor(
        private actions$: Actions,
        private store$: Store<fromShared.State>,
        private userApi: UserAPIService
    ) {}
}
