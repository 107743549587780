/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./xom-admin-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../layouts/modal-layout/modal-layout.component.ngfactory";
import * as i3 from "../../layouts/modal-layout/modal-layout.component";
import * as i4 from "../xom-button/xom-button.component.ngfactory";
import * as i5 from "../xom-button/xom-button.component";
import * as i6 from "./xom-admin-message.component";
var styles_XomAdminMessageComponent = [i0.styles];
var RenderType_XomAdminMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_XomAdminMessageComponent, data: {} });
export { RenderType_XomAdminMessageComponent as RenderType_XomAdminMessageComponent };
export function View_XomAdminMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "xom-modal-layout", [], null, null, null, i2.View_ModalLayoutComponent_0, i2.RenderType_ModalLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModalLayoutComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 2, 20, "div", [["modal-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 19, "div", [["class", "row middle-xs admin-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "col-xs-12 close-container no-gutter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "close icon-close increase-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "col-xs-12 admin-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "h1", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "warning"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["!"])), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "p", [["class", "message"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["xom-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_XomButtonComponent_0, i4.RenderType_XomButtonComponent)), i1.ɵdid(13, 49152, null, 0, i5.XomButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Dismiss"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "splash-waves bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "img", [["src", "/assets/images/swoop-orange-bottom.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "div", [["class", "splash-waves bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "img", [["src", "/assets/images/swoop-transparent.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "div", [["class", "splash-waves bottom-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "img", [["src", "/assets/images/swoop-orange-bottom-left.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "div", [["class", "splash-waves bottom-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "img", [["src", "/assets/images/swoop-orange-bottom-right.svg"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 10, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 11, 0, currVal_1); }); }
export function View_XomAdminMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xom-admin-message", [], null, null, null, View_XomAdminMessageComponent_0, RenderType_XomAdminMessageComponent)), i1.ɵdid(1, 49152, null, 0, i6.XomAdminMessageComponent, [], null, null)], null, null); }
var XomAdminMessageComponentNgFactory = i1.ɵccf("xom-admin-message", i6.XomAdminMessageComponent, View_XomAdminMessageComponent_Host_0, { title: "title", message: "message" }, { close: "close" }, []);
export { XomAdminMessageComponentNgFactory as XomAdminMessageComponentNgFactory };
