import { PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
var SafeHTMLPipe = /** @class */ (function () {
    function SafeHTMLPipe(sanitizer) {
        this.sanitizer = sanitizer;
    }
    SafeHTMLPipe.prototype.transform = function (style) {
        return this.sanitizer.bypassSecurityTrustHtml(style);
    };
    return SafeHTMLPipe;
}());
export { SafeHTMLPipe };
