import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppConfigService } from '@app/app-config.service';
import { console } from '@app/shared/util/console.util';
export var appInsights = new ApplicationInsights({ config: {
        instrumentationKey: '',
        samplingPercentage: 0
    } });
var InsightsService = /** @class */ (function () {
    function InsightsService(appConfigSvc) {
        this.appConfigSvc = appConfigSvc;
        this._appConfig = this.appConfigSvc.getConfig();
        appInsights.config.instrumentationKey = this._appConfig.instrumentation_key;
        appInsights.loadAppInsights();
        console.log('App Insights Loaded', appInsights);
        this.appInsights = appInsights;
        this.trackEvent = appInsights.trackEvent;
        window.appInsights = this.appInsights; // TODO: For testing/debugging purposes only. Remove before Prod release
    }
    return InsightsService;
}());
export { InsightsService };
