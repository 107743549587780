import { AppConfigService } from '@app/app-config.service';
var AppGlobals = /** @class */ (function () {
    function AppGlobals(configSvc) {
        this.config = configSvc.getConfig();
    }
    AppGlobals.prototype.isEnvironment = function (env) {
        return window.location.href.includes(env);
    };
    /*   public getBaseApiUrl(): string {
          return this.baseApiUrl;
      } */
    AppGlobals.prototype.getBaseUrl = function () {
        return;
    };
    return AppGlobals;
}());
export { AppGlobals };
