export var FeedbackActionTypes;
(function (FeedbackActionTypes) {
    FeedbackActionTypes["PostFeedback"] = "[Feedback] Post Feedback";
    FeedbackActionTypes["PostFeedbackSuccess"] = "[Feedback] Post Feedback Success";
    FeedbackActionTypes["PostFeedbackAttachments"] = "[Feedback] Post Feedback Attachments";
    FeedbackActionTypes["PostImageFeedback"] = "[Feedback] Post Image Feedback";
    FeedbackActionTypes["PostImageFeedbackSuccess"] = "[Feedback] Post Image Feedback Success";
    FeedbackActionTypes["PostImageFeedbackAttachments"] = "[Feedback] Post Image Feedback Attachments";
})(FeedbackActionTypes || (FeedbackActionTypes = {}));
var PostFeedback = /** @class */ (function () {
    function PostFeedback(payload) {
        this.payload = payload;
        this.type = FeedbackActionTypes.PostFeedback;
    }
    return PostFeedback;
}());
export { PostFeedback };
var PostFeedbackSuccess = /** @class */ (function () {
    function PostFeedbackSuccess(payload) {
        this.payload = payload;
        this.type = FeedbackActionTypes.PostFeedbackSuccess;
    }
    return PostFeedbackSuccess;
}());
export { PostFeedbackSuccess };
var PostFeedbackAttachments = /** @class */ (function () {
    function PostFeedbackAttachments(payload) {
        this.payload = payload;
        this.type = FeedbackActionTypes.PostFeedbackAttachments;
    }
    return PostFeedbackAttachments;
}());
export { PostFeedbackAttachments };
var PostImageFeedback = /** @class */ (function () {
    function PostImageFeedback(payload) {
        this.payload = payload;
        this.type = FeedbackActionTypes.PostImageFeedback;
    }
    return PostImageFeedback;
}());
export { PostImageFeedback };
var PostImageFeedbackSuccess = /** @class */ (function () {
    function PostImageFeedbackSuccess(payload) {
        this.payload = payload;
        this.type = FeedbackActionTypes.PostImageFeedbackSuccess;
    }
    return PostImageFeedbackSuccess;
}());
export { PostImageFeedbackSuccess };
var PostImageFeedbackAttachments = /** @class */ (function () {
    function PostImageFeedbackAttachments(payload) {
        this.payload = payload;
        this.type = FeedbackActionTypes.PostImageFeedbackAttachments;
    }
    return PostImageFeedbackAttachments;
}());
export { PostImageFeedbackAttachments };
