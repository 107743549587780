import { NgModule } from '@angular/core';
import { RouterModule, Routes, RouteReuseStrategy } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { CustomReuseStrategy } from './reuse-strategy';
import { MsalGuard } from '@azure/msal-angular';


const routes: Routes = [
  { path: '', redirectTo: 'geobrain', pathMatch: 'full' },
  {
    path: 'map',
    loadChildren: '@gis/gis.module#GisModule'
  },
  {
    path: 'geobrain',  canActivate: [MsalGuard],
    loadChildren: '@geobrain-loader/geobrain-loader.module#GeobrainLoaderModule',
    data: { key: 'geobrain-key' }
  },
  {
    path: 'landing', canActivate: [MsalGuard],
    loadChildren: '@landing/landing.module#LandingModule'
  },
  {
    path: 'showcase', canActivate: [MsalGuard],
    loadChildren: '@showcase/style-showcase.module#StyleShowcaseModule'
  },
  {
    path: 'workspaces', canActivate: [MsalGuard],
    loadChildren: '@workspaces/workspaces.module#WorkspacesModule'
  },
  {
    path: 'profile', canActivate: [MsalGuard],
    loadChildren: '@profile/profile.module#ProfileModule'
  },
  {
    path: 'geosentiment',
    loadChildren: '@geosentiment/geosentiment.module#GeosentimentModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'},
    {provide: RouteReuseStrategy, useClass: CustomReuseStrategy}
  ]
})
export class AppRoutingModule {}
