import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ContentService } from '@shared/services/content.service';
import { GlobalContent } from '@shared/shared-content';
import { MessageService, CHANNELS, OPERATIONS } from '@app/shared/services/message.service';

@Component({
  selector: 'xom-ws-loading-screen',
  templateUrl: './xom-ws-loading-screen.component.html',
  styleUrls: ['./xom-ws-loading-screen.component.scss']
})
export class XomWsLoadingScreenComponent {
  @Input() title: string;
  @Input() message: string;
  @Output() closeModal: EventEmitter<any> = new EventEmitter(null);
  @Output() next: EventEmitter<any> = new EventEmitter(null);
  showCancelButton: boolean;
  timeOutToShowCancelButton = 10000; // 10 Sec

  constructor(public content: ContentService<GlobalContent>,
              public messages: MessageService,
  ) {
      this.showCancelButton = false;
      setTimeout(() => {
          this.showCancelButton = true;
      }, this.timeOutToShowCancelButton);
  }

  onCancel() {
      this.messages.sendMessage(CHANNELS.WorkspaceDialog, {
          operation: OPERATIONS.Clean,
              data: {
                  prop: 'fresh-start'
              }
          }
      );
  }

}
