import { MatSnackBarConfig } from '@angular/material';
import { Action } from '@ngrx/store';

export enum SnackbarActionTypes {
    Open = '[Snackbar] Open',
    Close = '[Snackbar] Close',
    OpenSuccess = '[Snackbar] Open Success',
}

export type SnackbarClassType = 'xom-snackbar-success' | 'xom-snackbar-warning' |
                                'xom-snackbar-error' | 'xom-snackbar-fullscreen-error' | 'xom-snackbar-info';

export class Open implements Action {
    readonly type = SnackbarActionTypes.Open;

    constructor(public payload: {
        message: string,
        type?: SnackbarClassType,
        action?: string,
        config?: MatSnackBarConfig
    }) {
        if (!payload.config) {
            this.payload.config = {
                horizontalPosition: 'right',
                verticalPosition: 'bottom'
            };
        }

        if (payload.type) {
            this.payload.config.panelClass = payload.type;
        }
    }
}

export class Close implements Action {
    readonly type = SnackbarActionTypes.Close;
}

export class OpenSuccess implements Action {
    readonly type = SnackbarActionTypes.OpenSuccess;
}

export type SnackbarActionsUnion =
    | Open
    | Close
    | OpenSuccess;

