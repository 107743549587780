import { EventEmitter, AfterViewInit } from '@angular/core';
import { ContentService } from '@app/shared/services/content.service';
import { DomSanitizer } from '@angular/platform-browser';
var ShowHelpModalComponent = /** @class */ (function () {
    function ShowHelpModalComponent(content, sanitizer) {
        this.content = content;
        this.sanitizer = sanitizer;
        this.closeModal = new EventEmitter(null);
    }
    ShowHelpModalComponent.prototype.ngAfterViewInit = function () {
        this.divFocused.nativeElement.focus();
    };
    ShowHelpModalComponent.prototype.closeShowHelp = function () {
        this.closeModal.emit();
    };
    ShowHelpModalComponent.prototype.sanitize = function (url) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    };
    return ShowHelpModalComponent;
}());
export { ShowHelpModalComponent };
