// this architecture is copied from the angular material library
// https://github.com/angular/material2/blob/master/src/lib/button/button.ts
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef } from '@angular/core';
var XomButtonBase = /** @class */ (function () {
    function XomButtonBase(_elementRef) {
        this._elementRef = _elementRef;
    }
    return XomButtonBase;
}());
export { XomButtonBase };
var BUTTON_HOST_ATTRIBUTES = [
    'xom-button',
    'xom-secondary-button',
    'xom-tertiary-button',
    'xom-round-button',
    'xom-round-outline-button',
    'xom-small-button',
    'xom-small-error-button',
    'xom-circle-button'
];
var XomButtonComponent = /** @class */ (function (_super) {
    __extends(XomButtonComponent, _super);
    function XomButtonComponent(elementRef) {
        var _this = _super.call(this, elementRef) || this;
        _this.color = 'primary';
        for (var _i = 0, BUTTON_HOST_ATTRIBUTES_1 = BUTTON_HOST_ATTRIBUTES; _i < BUTTON_HOST_ATTRIBUTES_1.length; _i++) {
            var attr = BUTTON_HOST_ATTRIBUTES_1[_i];
            if (_this._hasHostAttributes(attr)) {
                elementRef.nativeElement.classList.add(attr);
            }
        }
        return _this;
    }
    XomButtonComponent.prototype._getHostElement = function () {
        return this._elementRef.nativeElement;
    };
    /** Gets whether the button has one of the given attributes. */
    XomButtonComponent.prototype._hasHostAttributes = function () {
        var _this = this;
        var attributes = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            attributes[_i] = arguments[_i];
        }
        return attributes.some(function (attribute) { return _this._getHostElement().hasAttribute(attribute); });
    };
    return XomButtonComponent;
}(XomButtonBase));
export { XomButtonComponent };
