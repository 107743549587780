import { PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
var SafeURLPipe = /** @class */ (function () {
    function SafeURLPipe(sanitizer) {
        this.sanitizer = sanitizer;
    }
    SafeURLPipe.prototype.transform = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    return SafeURLPipe;
}());
export { SafeURLPipe };
