import { Component, Output, EventEmitter, Input, ElementRef, ViewChild, OnDestroy, HostListener, OnInit } from '@angular/core';
import { ContentService } from '@shared/services/content.service';
import { GlobalContent } from '@shared/shared-content';
import { PromoteWSAPIService } from '@shared/services/promoteWS.service';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MessageService, CHANNELS, OPERATIONS, Message } from '@app/shared/services/message.service';
import { WorkspacesActions } from '@app/shared/actions';
import * as fromShared from '@shared/reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'xom-promote-modal',
  templateUrl: './xom-promote-modal.component.html',
  styleUrls: ['./xom-promote-modal.component.scss']
})
export class XomPromoteModalComponent implements OnInit, OnDestroy {
  @Output() closeModal: EventEmitter<any> = new EventEmitter(null);
  @Input() workspaceId: string;
  @Input() workspaceName: string;
  @Input() workspaceCategory: string;
  @Input() workspaceDescription: string;
  ESC_KEY_CODE = 27;
  workspaceNameError: string;
  greyTextArea: boolean;
  // Workspace
  promoteChannelSubscribe$: Subscription;
  // View
  @ViewChild('textarea') textarea: ElementRef;
  @ViewChild('workspaceNameInput') workspaceNameInput: ElementRef;

  constructor(private sharedStore: Store<fromShared.State>,
              public content: ContentService<GlobalContent>,
              public promoteService: PromoteWSAPIService,
              public messages: MessageService) {

      this.workspaceNameError = '';
      this.promoteChannelSubscribe$ = messages.getMessage(CHANNELS.Promote).subscribe((msg: Message) => {
          if (msg && msg.operation === OPERATIONS.Custom) {
              if (msg.data && msg.data.error) {
                  this.workspaceNameError = msg.data.error;
              }
              if (msg.data && msg.data.cloneId) {
                  this.promoteService.promote(msg.data.cloneId, this.workspaceCategory).pipe(take(1)).subscribe(res => {
                      this.sharedStore.dispatch(new WorkspacesActions.LoadAllWorkspaces());
                      this.closeModal.emit();
                  });
              }
          }
      });
  }

  ngOnInit() {
      if (this.workspaceDescription && this.workspaceDescription !== '') {
          this.greyTextArea = false;
      } else {
          this.workspaceDescription = this.content.global.PROMOTE_MODAL.textAreaPlaceHolder;
          this.greyTextArea = true;
      }
  }

  ngOnDestroy() {
      this.promoteChannelSubscribe$.unsubscribe();
  }

  promote() {
      this.messages.sendMessage(CHANNELS.Promote, {
          operation: OPERATIONS.Clone,
          data: {
              workspaceName: this.workspaceNameInput.nativeElement.value,
              workspaceCategory: this.workspaceCategory,
              workspaceDescription: this.textarea.nativeElement.value
                                    .includes(this.content.global.PROMOTE_MODAL.textAreaPlaceHolder) ?
                                    '' : this.textarea.nativeElement.value
          }
      });
  }

  focusFunction() {
      if (this.textarea.nativeElement.value.includes(this.content.global.PROMOTE_MODAL.textAreaPlaceHolder)) {
          this.textarea.nativeElement.value = '';
          this.greyTextArea = false;
      }
  }

  focusOutFunction() {
      if (this.textarea.nativeElement.value === '') {
          this.textarea.nativeElement.value = this.content.global.PROMOTE_MODAL.textAreaPlaceHolder;
          this.greyTextArea = true;
      }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === this.ESC_KEY_CODE) {
      this.closeModal.emit();
    }
  }

}
