// services
import { AppConfigService } from './app-config.service';
import { ContentService } from '@shared/services/content.service';
import { GlobalContent } from '@shared/shared-content';
import { MsalAngularConfiguration } from '@azure/msal-angular';
// See: https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/msal-angular-v1/lib/msal-angular#internet-explorer-support
var isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
export function init_app(appConfigSvc) {
    return function () { return appConfigSvc.loadAppConfig(); };
}
var ɵ0 = function (appConfigSvc) {
    var appConfig = appConfigSvc.getConfig();
    return {
        auth: {
            clientId: appConfig.msalConfig.clientId,
            authority: appConfig.msalConfig.authority,
            validateAuthority: true,
            redirectUri: window.location.origin,
            postLogoutRedirectUri: window.location.origin,
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: isIE,
        },
        system: {
            // See: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1592#issuecomment-725777269
            loadFrameTimeout: 30000
        }
    };
}, ɵ1 = function (appConfigSvc) {
    var appConfig = appConfigSvc.getConfig();
    return {
        popUp: false,
        consentScopes: [appConfig.msalConfig.geobrain_scope, appConfig.msalConfig.geobook_scope],
        protectedResourceMap: [
            [appConfig.user_api_baseurl, [appConfig.msalConfig.geobrain_scope]],
            [appConfig.seisbook_api_baseurl, [appConfig.msalConfig.geobook_scope]],
            [appConfig.datascience_api_baseurl, null], [appConfig.geoBertAPIUrl, null]
        ],
        extraQueryParameters: {
            domain_hint: 'exxonmobil.com'
        }
    };
}, ɵ2 = function () { return new ContentService(new GlobalContent); };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2 };
