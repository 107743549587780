import { Action } from '@ngrx/store';
import { LeafletEvent } from 'leaflet';

export enum ContextMenuActionTypes {
    ToggleMenu = '[Context Menu] Toggle Menu'
}

export class ToggleMenu implements Action {
    readonly type = ContextMenuActionTypes.ToggleMenu;

    constructor(public payload: LeafletEvent) {}
}


export type ContextMenuActionsUnion =
| ToggleMenu;

