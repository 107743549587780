import { HypothesisAction } from '@shared/actions';
import { HypothesisModel } from '@app/modules/hypothesis/models/hypothesis.model';
import { HypothesisCardModel } from '@app/modules/hypothesis/models/hypothesis-card.model';
import { UserSubscribedModel } from '@app/modules/hypothesis/models/user-subscribed.model';

export interface State {
    hypothesisModel: HypothesisModel[];
    allowedCountries: string[];
    hypothesisCardModel: HypothesisCardModel[];
    userSubscribedModel: UserSubscribedModel[];
    hypothesisMainLoading: boolean;
    hypothesisManagerLoading: boolean;
}

export const initialState: State = {
    hypothesisModel: [],
    allowedCountries: [],
    hypothesisCardModel: [],
    userSubscribedModel: [],
    hypothesisMainLoading: true,
    hypothesisManagerLoading: true
};

export function reducer(
  state = initialState,
  action: HypothesisAction.HypothesisActionUnion
): State {
  switch (action.type) {
    case HypothesisAction.HypothesisActionType.SetAllowedCountries: {
        return {
            ...state,
            allowedCountries: action.payload
        };
    }
    case HypothesisAction.HypothesisActionType.SetHypothesis: {
        return {
            ...state,
            hypothesisModel: action.payload
        };
    }
    case HypothesisAction.HypothesisActionType.SetHypothesisCard: {
        return {
            ...state,
            hypothesisCardModel: action.payload
        };
    }
    case HypothesisAction.HypothesisActionType.SetUserSubscribedModel: {
        return {
            ...state,
            userSubscribedModel: action.payload
        };
    }
    case HypothesisAction.HypothesisActionType.UpdateHypothesis: {
        for (let x = 0 ; x < state.hypothesisModel.length ; x++) {
            const model = state.hypothesisModel[x];
            if (model.title === action.payload.title &&
                model.category === action.payload.category
            ) {
                state.hypothesisModel[x] = action.payload;
            }
        }
        return state;
    }
    case HypothesisAction.HypothesisActionType.UpdateHypothesisCard: {
        for (let x = 0 ; x < state.hypothesisCardModel.length ; x++) {
            const model = state.hypothesisCardModel[x];
            if (model.triggerId === action.payload.triggerId) {
                state.hypothesisCardModel[x] = action.payload;
            }
        }
        return state;
    }
    case HypothesisAction.HypothesisActionType.SetHypothesisMainLoading: {
        return {
            ...state,
            hypothesisMainLoading: action.payload
        };
    }
    case HypothesisAction.HypothesisActionType.SetHypothesisManagerLoading: {
        return {
            ...state,
            hypothesisManagerLoading: action.payload
        };
    }
    default: {
      return state;
    }
  }
}
