import { Action } from '@ngrx/store';

export enum AskBobActions {
  SetBasin = '[Ask Bob] Set Basin',
  SetActiveTab = '[Ask Bob] Set Active Tab',
  SetQueryParameters = '[Ask Bob] Set Query Parameters',
  LoadSummary = '[Ask Bob] Load Summary',
  LoadSummarySuccess = '[Ask Bob] Load Summary Success',
  LoadSummaryFailure = '[Ask Bob] Load Summary Failure',
  LoadAnswers = '[Ask Bob] Load Answers',
  LoadAnswersSuccess = '[Ask Bob] Load Answers Success',
  LoadAnswersFailure = '[Ask Bob] Load Answers Failure',
  SetFeedbackAnswer = '[Ask Bob] Set Feedback Answer',
}

export class SetBasin implements Action {
  readonly type = AskBobActions.SetBasin;

  constructor(public payload: string) { }
}

export class SetActiveTab implements Action {
  readonly type = AskBobActions.SetActiveTab;

  constructor(public payload: string) { }
}

export class SetQueryParameters implements Action {
  readonly type = AskBobActions.SetQueryParameters;

  constructor(public payload: any) { }
}

export class LoadSummary implements Action {
  readonly type = AskBobActions.LoadSummary;

  constructor(public parameters: any, public basin: string) { }
}

export class LoadSummarySuccess implements Action {
  readonly type = AskBobActions.LoadSummarySuccess;

  constructor(public response: any) { }
}

export class LoadSummaryFailure implements Action {
  readonly type = AskBobActions.LoadSummaryFailure;

  constructor(public payload: any) { }
}

export class LoadAnswers implements Action {
  readonly type = AskBobActions.LoadAnswers;

  constructor(public parameters: any, public basin: string) { }
}

export class LoadAnswersSuccess implements Action {
  readonly type = AskBobActions.LoadAnswersSuccess;

  constructor(public response: any) { }
}

export class LoadAnswersFailure implements Action {
  readonly type = AskBobActions.LoadAnswersFailure;

  constructor(public payload: any) { }
}

export class SetFeedbackAnswer implements Action {
  readonly type = AskBobActions.SetFeedbackAnswer;

  constructor(public payload: any) { }
}

export type AskBobActionsUnion =
  | SetBasin
  | SetActiveTab
  | SetQueryParameters
  | LoadAnswers
  | LoadAnswersSuccess
  | LoadAnswersFailure
  | LoadSummary
  | LoadSummarySuccess
  | LoadSummaryFailure
  | SetFeedbackAnswer;
