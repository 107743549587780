import { Basemaps } from 'esri-leaflet';
const pointerEvents = document.documentElement.style.pointerEvents === '';
const tileProtocol = (window.location.protocol !== 'https:') ? 'http:' : 'https:';

export interface BasemapConfig {
    name: Basemaps;
    urlTemplate: String;
    thumbnailUrl: String;
    options: {
        minZoom: Number;
        maxZoom: Number;
        subdomains?: Array<String>;
        pane?: String;
        attribution?: String;
        attributionUrl?: String;
    };
}

export const EsriBasemapLayers: Array<BasemapConfig> = [
  {
    name: 'Streets',
    urlTemplate: tileProtocol + '//{s}.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: tileProtocol + '//js.arcgis.com/3.15/esri/images/basemap/streets.jpg',
    options: {
      minZoom: 1,
      maxZoom: 19,
      subdomains: ['server', 'services'],
      attribution: 'USGS, NOAA',
      attributionUrl: 'https://static.arcgis.com/attribution/World_Street_Map'
    }
  },
  {
    name: 'Topographic',
    urlTemplate: tileProtocol + '//{s}.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: tileProtocol + '//js.arcgis.com/3.15/esri/images/basemap/topo.jpg',
    options: {
      minZoom: 1,
      maxZoom: 19,
      subdomains: ['server', 'services'],
      attribution: 'USGS, NOAA',
      attributionUrl: 'https://static.arcgis.com/attribution/World_Topo_Map'
    }
  },
  {
    name: 'Oceans',
    urlTemplate: tileProtocol + '//{s}.arcgisonline.com/arcgis/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: tileProtocol + '//js.arcgis.com/3.15/esri/images/basemap/oceans.jpg',
    options: {
      minZoom: 1,
      maxZoom: 16,
      subdomains: ['server', 'services'],
      attribution: 'USGS, NOAA',
      attributionUrl: 'https://static.arcgis.com/attribution/Ocean_Basemap'
    }
  },
  {
    name: 'OceansLabels',
    urlTemplate: tileProtocol + '//{s}.arcgisonline.com/arcgis/rest/services/Ocean/World_Ocean_Reference/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: '',
    options: {
      minZoom: 1,
      maxZoom: 16,
      subdomains: ['server', 'services'],
      pane: (pointerEvents) ? 'esri-labels' : 'tilePane',
      attribution: ''
    }
  },
  {
    name: 'NationalGeographic',
    urlTemplate: tileProtocol + '//{s}.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: tileProtocol + '//js.arcgis.com/3.15/esri/images/basemap/national-geographic.jpg',
    options: {
      minZoom: 1,
      maxZoom: 16,
      subdomains: ['server', 'services'],
      attribution: 'National Geographic, DeLorme, HERE, UNEP-WCMC, USGS, NASA, ESA, METI, NRCAN, GEBCO, NOAA, increment P Corp.'
    }
  },
  {
    name: 'DarkGray',
    urlTemplate: tileProtocol + '//{s}.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: tileProtocol + '//js.arcgis.com/3.15/esri/images/basemap/dark-gray.jpg',
    options: {
      minZoom: 1,
      maxZoom: 16,
      subdomains: ['server', 'services'],
      attribution: 'HERE, DeLorme, MapmyIndia, &copy; OpenStreetMap contributors'
    }
  },
  {
    name: 'DarkGrayLabels',
    urlTemplate: tileProtocol + '//{s}.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Reference/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: '',
    options: {
      minZoom: 1,
      maxZoom: 16,
      subdomains: ['server', 'services'],
      pane: (pointerEvents) ? 'esri-labels' : 'tilePane',
      attribution: ''

    }
  },
  {
    name: 'Gray',
    urlTemplate: tileProtocol + '//{s}.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: tileProtocol + '//js.arcgis.com/3.15/esri/images/basemap/gray.jpg',
    options: {
      minZoom: 1,
      maxZoom: 16,
      subdomains: ['server', 'services'],
      attribution: 'HERE, DeLorme, MapmyIndia, &copy; OpenStreetMap contributors'
    }
  },
  {
    name: 'GrayLabels',
    urlTemplate: tileProtocol + '//{s}.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Reference/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: '',
    options: {
      minZoom: 1,
      maxZoom: 16,
      subdomains: ['server', 'services'],
      pane: (pointerEvents) ? 'esri-labels' : 'tilePane',
      attribution: ''
    }
  },
  {
    name: 'Imagery',
    urlTemplate: tileProtocol + '//{s}.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: tileProtocol + '//js.arcgis.com/3.15/esri/images/basemap/satellite.jpg',
    options: {
      minZoom: 1,
      maxZoom: 19,
      subdomains: ['server', 'services'],
      attribution: 'DigitalGlobe, GeoEye, i-cubed, USDA, USGS, AEX, Getmapping, Aerogrid, IGN, IGP, swisstopo, and the GIS User Community',
      attributionUrl: 'https://static.arcgis.com/attribution/World_Imagery'
    }
  },
  {
    name: 'ImageryLabels',
    urlTemplate: tileProtocol
        + '//{s}.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: '',
    options: {
      minZoom: 1,
      maxZoom: 19,
      subdomains: ['server', 'services'],
      pane: (pointerEvents) ? 'esri-labels' : 'tilePane',
      attribution: ''
    }
  },
  {
    name: 'ImageryTransportation',
    urlTemplate: tileProtocol + '//{s}.arcgisonline.com/ArcGIS/rest/services/Reference/World_Transportation/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: '',
    options: {
      minZoom: 1,
      maxZoom: 19,
      subdomains: ['server', 'services'],
      pane: (pointerEvents) ? 'esri-labels' : 'tilePane',
      attribution: ''
    }
  },
  {
    name: 'ShadedRelief',
    urlTemplate: tileProtocol + '//{s}.arcgisonline.com/ArcGIS/rest/services/World_Shaded_Relief/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: tileProtocol + '//js.arcgis.com/3.15/esri/images/basemap/terrain.jpg',
    options: {
      minZoom: 1,
      maxZoom: 13,
      subdomains: ['server', 'services'],
      attribution: 'USGS'
    }
  },
  {
    name: 'ShadedReliefLabels',
    urlTemplate: tileProtocol
        + '//{s}.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places_Alternate/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: '',
    options: {
      minZoom: 1,
      maxZoom: 12,
      subdomains: ['server', 'services'],
      pane: (pointerEvents) ? 'esri-labels' : 'tilePane',
      attribution: ''
    }
  },
  {
    name: 'Terrain',
    urlTemplate: tileProtocol + '//{s}.arcgisonline.com/ArcGIS/rest/services/World_Terrain_Base/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: tileProtocol + '//js.arcgis.com/3.15/esri/images/basemap/terrain.jpg',
    options: {
      minZoom: 1,
      maxZoom: 13,
      subdomains: ['server', 'services'],
      attribution: 'USGS, NOAA'
    }
  },
  {
    name: 'TerrainLabels',
    urlTemplate: tileProtocol + '//{s}.arcgisonline.com/ArcGIS/rest/services/Reference/World_Reference_Overlay/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: '',
    options: {
      minZoom: 1,
      maxZoom: 13,
      subdomains: ['server', 'services'],
      pane: (pointerEvents) ? 'esri-labels' : 'tilePane',
      attribution: ''
    }
  },
  {
    name: 'USATopo',
    urlTemplate: tileProtocol + '//{s}.arcgisonline.com/ArcGIS/rest/services/USA_Topo_Maps/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: '',
    options: {
      minZoom: 1,
      maxZoom: 15,
      subdomains: ['server', 'services'],
      attribution: 'USGS, National Geographic Society, i-cubed'
    }
  },
  {
    name: 'ImageryClarity',
    urlTemplate: tileProtocol + '//clarity.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    thumbnailUrl: '',
    options: {
      minZoom: 1,
      maxZoom: 19,
      attribution:
        'Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'
    }
  }
];

export default EsriBasemapLayers;
