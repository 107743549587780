export var DocumentQueryActionTypes;
(function (DocumentQueryActionTypes) {
    DocumentQueryActionTypes["DocumentResultsSort"] = "[Search Document] Search Results Sorting";
    DocumentQueryActionTypes["DocumentResultsPageSize"] = "[Search Document] Search Results Page Size";
    DocumentQueryActionTypes["DocumentResultsStartIndex"] = "[Search Document] Search Results Start Index";
    DocumentQueryActionTypes["DocumentAddFacets"] = "[Search Document] Add Facets";
    DocumentQueryActionTypes["DocumentAddIndividualFacet"] = "[Search Document] Add Individual Facets";
    DocumentQueryActionTypes["DocumentRemoveIndividualFacet"] = "[Search Document] Remove Individual Facets";
    DocumentQueryActionTypes["DocumentRemoveFacets"] = "[Search Document] Remove Facets";
})(DocumentQueryActionTypes || (DocumentQueryActionTypes = {}));
var DocumentResultsSort = /** @class */ (function () {
    function DocumentResultsSort(payload) {
        this.payload = payload;
        this.type = DocumentQueryActionTypes.DocumentResultsSort;
    }
    return DocumentResultsSort;
}());
export { DocumentResultsSort };
var DocumentResultsPageSize = /** @class */ (function () {
    function DocumentResultsPageSize(payload) {
        this.payload = payload;
        this.type = DocumentQueryActionTypes.DocumentResultsPageSize;
    }
    return DocumentResultsPageSize;
}());
export { DocumentResultsPageSize };
var DocumentResultsStartIndex = /** @class */ (function () {
    function DocumentResultsStartIndex(payload) {
        this.payload = payload;
        this.type = DocumentQueryActionTypes.DocumentResultsStartIndex;
    }
    return DocumentResultsStartIndex;
}());
export { DocumentResultsStartIndex };
var DocumentAddFacets = /** @class */ (function () {
    function DocumentAddFacets(payload) {
        this.payload = payload;
        this.type = DocumentQueryActionTypes.DocumentAddFacets;
    }
    return DocumentAddFacets;
}());
export { DocumentAddFacets };
var DocumentAddIndividualFacet = /** @class */ (function () {
    function DocumentAddIndividualFacet(payload) {
        this.payload = payload;
        this.type = DocumentQueryActionTypes.DocumentAddIndividualFacet;
    }
    return DocumentAddIndividualFacet;
}());
export { DocumentAddIndividualFacet };
var DocumentRemoveIndividualFacet = /** @class */ (function () {
    function DocumentRemoveIndividualFacet(payload) {
        this.payload = payload;
        this.type = DocumentQueryActionTypes.DocumentRemoveIndividualFacet;
    }
    return DocumentRemoveIndividualFacet;
}());
export { DocumentRemoveIndividualFacet };
var DocumentRemoveFacets = /** @class */ (function () {
    function DocumentRemoveFacets(payload) {
        this.payload = payload;
        this.type = DocumentQueryActionTypes.DocumentRemoveFacets;
    }
    return DocumentRemoveFacets;
}());
export { DocumentRemoveFacets };
