import { Action } from '@ngrx/store';

export enum PlayAwarenessActions {
  SetPlay = '[Awareness] Set Play',
  SetPlayElement = '[Awareness] Set Play Element',
  LoadPlaySummary = '[Awareness] Load Play Summary',
  LoadPlaySummarySuccess = '[Awareness] Load Play Summary Success',
  LoadPlaySummaryFailure = '[Awareness] Load Play Summary Failure',
  LoadDiscoveredVolume = '[Awareness] Load Discovered Volume',
  LoadDiscoveredVolumeSuccess = '[Awareness] Load Discovered Volume Success',
  LoadDiscoveredVolumeFailure = '[Awareness] Load Discovered Volume Failure',
  LoadRiskMean = '[Awareness] Load Risk Mean',
  LoadStats = '[Awareness] Load Stats',
  SetSummaryCharts = '[Awareness] Set Summary Charts',
  ClearPlaySummary = '[Awareness] Clear Play Summary'
}

export class SetPlay implements Action {
  readonly type = PlayAwarenessActions.SetPlay;

  constructor(public payload: string) {}
}

export class SetPlayElement implements Action {
  readonly type = PlayAwarenessActions.SetPlayElement;

  constructor(public payload: string) {}
}

export class LoadPlaySummary implements Action {
  readonly type = PlayAwarenessActions.LoadPlaySummary;

  constructor(public playID: string) {}
}


export class LoadPlaySummarySuccess implements Action {
  readonly type = PlayAwarenessActions.LoadPlaySummarySuccess;

  constructor(public playSummary: {
    playID: string,
    riskMeanOEBXOM: string,
    closureCountXOM: string,
    closureCountIHS: string,
    fieldCountIHS: string,
    wellPenetrationCountIHS: string,
    minAge: number,
    maxAge: number
  }) {}
}

export class LoadPlaySummaryFailure implements Action {
  readonly type = PlayAwarenessActions.LoadPlaySummaryFailure;

  constructor(public payload: string) {}
}

export class SetSummaryCharts implements Action {
  readonly type = PlayAwarenessActions.SetSummaryCharts;

  constructor(public payload: any[]) {}
}

export class LoadDiscoveredVolume implements Action {
  readonly type = PlayAwarenessActions.LoadDiscoveredVolume;

  constructor(public payload: {
    playName: string,
    wellIDs: number[],
    minAge: number,
    maxAge: number
  }) {}
}

export class LoadDiscoveredVolumeSuccess implements Action {
  readonly type = PlayAwarenessActions.LoadDiscoveredVolumeSuccess;

  constructor(public payload: string) {}
}

export class LoadDiscoveredVolumeFailure implements Action {
  readonly type = PlayAwarenessActions.LoadDiscoveredVolumeFailure;

  constructor(public payload: string) {}
}

export class LoadRiskMean implements Action {
  readonly type = PlayAwarenessActions.LoadRiskMean;

  constructor(public payload: string) {}
}

export class ClearPlaySummary implements Action {
  readonly type = PlayAwarenessActions.ClearPlaySummary;

  constructor() {}
}

export type PlayAwarenessActionsUnion =
    SetPlay
  | SetPlayElement
  | LoadPlaySummary
  | LoadPlaySummarySuccess
  | LoadPlaySummaryFailure
  | LoadDiscoveredVolume
  | LoadDiscoveredVolumeSuccess
  | LoadDiscoveredVolumeFailure
  | LoadRiskMean
  | SetSummaryCharts
  | ClearPlaySummary;
