import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '@app/app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app-config.service";
var PromoteWSAPIService = /** @class */ (function () {
    function PromoteWSAPIService(http, _appConfig) {
        this.http = http;
        this._appConfig = _appConfig;
        this._config = this._appConfig.getConfig();
    }
    PromoteWSAPIService.prototype.promote = function (workspaceId, category) {
        return this.http.put(this._config.user_api_baseurl + "/workspace/Promote/" + workspaceId, {
            category: category
        });
    };
    PromoteWSAPIService.prototype.demote = function (workspaceId, category) {
        return this.http.put(this._config.user_api_baseurl + "/workspace/Demote/" + workspaceId, {});
    };
    PromoteWSAPIService.prototype.getWorkSpaceCategories = function () {
        return this.http.get(this._config.user_api_baseurl + "/workspaceCategories");
        // return this.http.get<any>('/assets/data/workspace_categories.json');
    };
    PromoteWSAPIService.ngInjectableDef = i0.defineInjectable({ factory: function PromoteWSAPIService_Factory() { return new PromoteWSAPIService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService)); }, token: PromoteWSAPIService, providedIn: "root" });
    return PromoteWSAPIService;
}());
export { PromoteWSAPIService };
