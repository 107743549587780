var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, withLatestFrom, } from 'rxjs/operators';
import { FeedbackService } from '@shared/services/feedback-service';
import { FeedbackActions, SnackbarActions } from '@shared/actions';
import { console } from '@app/shared/util/console.util';
var FeedbackEffects = /** @class */ (function () {
    function FeedbackEffects(actions$, store$, feedbackApi) {
        var _this = this;
        this.actions$ = actions$;
        this.store$ = store$;
        this.feedbackApi = feedbackApi;
        this.postFeedback$ = function () {
            return _this.actions$.pipe(ofType(FeedbackActions.FeedbackActionTypes.PostFeedback), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1];
                return _this.feedbackApi.postFeedback(action.payload, storeState.shared.feedback.fileList).pipe(map(function (res) {
                    console.log('feedsuccess', res);
                    return new SnackbarActions.Open({
                        message: "Feedback successfully submited",
                        type: 'xom-snackbar-success',
                        action: 'x'
                    });
                }));
            }));
        };
        this.postImageFeedback$ = function () {
            return _this.actions$.pipe(ofType(FeedbackActions.FeedbackActionTypes.PostImageFeedback), switchMap(function (action) {
                return _this.feedbackApi.postImageFeedback(action.payload).pipe(map(function (res) {
                    return new SnackbarActions.Open({
                        message: "Image feedback successfully submited",
                        type: 'xom-snackbar-success',
                        action: 'x'
                    });
                }));
            }));
        };
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], FeedbackEffects.prototype, "postFeedback$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], FeedbackEffects.prototype, "postImageFeedback$", void 0);
    return FeedbackEffects;
}());
export { FeedbackEffects };
