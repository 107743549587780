import { Injectable, OnDestroy } from '@angular/core';
import { AppConfigService } from '@app/app-config.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GlobalContent } from '@app/shared/shared-content';
import { ContentService } from '@app/shared/services/content.service';
import { select, Store } from '@ngrx/store';
import * as fromGis from '@gis/reducers/index';
import { map } from 'rxjs/operators';
import * as esri from 'esri-leaflet';
import { MapLayerActions } from '@app/modules/gis/actions';

export interface BasinConfig {
  basinCollection: any;
  analogType: boolean;
  basin: string;
}

@Injectable({
  providedIn: 'root'
})
export class AnalogService implements OnDestroy {

  private API_PATH: string;
  public basinConfig = {};
  public clickedBasin = new BehaviorSubject<number>(0);
  public unclickedBasin = new BehaviorSubject<number>(0);
  public hover = new BehaviorSubject<number>(0);
  public unhover = new BehaviorSubject<number>(0);
  public spiderChartData: any;
  private colorPool = [];
  private colorIndex;
  public features = new BehaviorSubject<any>([]);
  private configs = [];
  subscriptions: Subscription[] = [];

  constructor(private http: HttpClient, private _appConfig: AppConfigService,
    public content: ContentService<GlobalContent>,
    public store: Store<fromGis.State>) {
      const config = this._appConfig.getConfig();
      this.API_PATH = config.datascience_api_baseurl + '/analogs';
      this.spiderChartData = [];
      this.colorIndex = -1;
      this.colorPool = this.content.local.SPIDER_CHART.COLOR_POOL;
      const $getAOILayerConfigSubscribe = this.store.pipe(select(fromGis.getAOILayerConfig), map(configs => configs.basin))
                                          .subscribe(configs => this.configs = configs);
      this.subscriptions.push($getAOILayerConfigSubscribe);
  }

  ngOnDestroy() {
      this.subscriptions.forEach( subscription => {
          try {
              subscription.unsubscribe();
          } catch (e) {
              console.error('[Error] OnDestroy unsubscribes', e);
          }
      });
  }

  getColor() {
      this.colorIndex = this.colorIndex >= this.colorPool.length ? 0 : this.colorIndex;
      return this.colorPool[this.colorIndex++];
  }

  colorIndexRestart() {
      this.colorIndex = -1;
  }

  getBasins() {
      return this.http.get<any>(`${this.API_PATH}/getBasins`);
      // return this.http.get<any[]>(`/assets/data/analog_get_basins.json`);
  }

  getKeywords() {
      return this.http.get<any>(`${this.API_PATH}/getKeywords`);
      // return this.http.get<any[]>(`/assets/data/analog_get_keywords.json`);
  }

  findSimilar(basin: string) {
      return this.http.get<any>(`${this.API_PATH}/findSimilar?basin=${basin}`);
      // return this.http.get<any[]>(`/assets/data/analog_find_similar.json`);
  }

  getAnalogs(basin: string) {
      return this.http.get<any>(`${this.API_PATH}/getAnalogs?basin=${basin}`);
      // return this.http.get<any[]>(`/assets/data/analog_get_analogs.json`);
  }

  getEvidenceBreakout(basins: string[], lithology: string) {
      return this.http.get<any>(`${this.API_PATH}/getEvidenceBreakout?basins=${basins.join(',')}&&keyword=${lithology}`);
      // return this.http.get<any[]>(`/assets/data/analog_get_evidence_breakou.json`);
  }

  SetBasinConfig(config) {
    this.store.dispatch(new MapLayerActions.SetMapLoading(true));
    this.SetFeatures([]);
    if (config.basin && config.basinCollection && config.basinCollection.length > 0) {
      if (this.configs) {
          for (let i = 0; i < this.configs.length; i++) {
            this.basinConfig = config;
            this.queryFeatures(this.configs[i], config.basinCollection);
          }
      }
    }
  }

  queryFeatures(config, basins) {
    const condition = `${config.attribute} IN (${basins.map(basin => `'` + basin + `'`).join(',')})`;
    const query = esri.query({
      url: config.url,
      useCors: true,
      withCredentials: true
    });
    query.where(condition);
    query.run((error, featureCollection: any) => {
      if (error) {
      } else {
        let result = {};
        if (featureCollection.features.length > 0) {
          result = featureCollection.features;
        }
        this.SetFeatures(result);
      }
    });
  }

  SetClickedBasin(index) {
    this.clickedBasin.next(index);
  }

  SetUnclickedBasin(index) {
    this.unclickedBasin.next(index);
  }

  SetHover(index) {
    this.hover.next(index);
  }

  SetUnhover(index) {
    this.unhover.next(index);
  }

  SetSpiderChartData(spiderChartData) {
    this.spiderChartData = spiderChartData;
  }

  SetFeatures(features) {
    this.features.next(features);
  }
}
