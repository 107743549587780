import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, concat, of } from 'rxjs';
import { catchError, take, concatMap, skipWhile  } from 'rxjs/operators';
import { SearchResponse } from '@shared/models/SearchAPI.model';
import { AppConfig, AppConfigService } from '@app/app-config.service';

import { console } from '@app/shared/util/console.util';

@Injectable({
    providedIn: 'root',
})
export class FeedbackService  {
    private sharepointApiUrl = 'https://ishareteam8.na.xom.com/sites/Geobrain/_api/';
    private sharePointDigestToken = '';
    private _config: AppConfig;

    constructor( private http: HttpClient, private _appConfig: AppConfigService ) {
        this._config = this._appConfig.getConfig();
        this.getSharepointDigest().pipe(
            take(1)
        ).subscribe( (data: any) => {
            this.sharePointDigestToken =  data.d.GetContextWebInformation.FormDigestValue;
        });
    }

    postImageFeedback(postData: any): Observable<any> {
        return this.http.post<SearchResponse>(`${this._config.datascience_api_baseurl}/images/update-image-classification`, postData);
    }

    postFeedback(postData: any, fileList: any[]): Observable<any> {
        const sharePointRecord$ = this.createSharepointFeedbackRecord(postData);

        if (fileList.length > 0) {
            // attach files after record is created
            return sharePointRecord$.pipe(
                skipWhile(result => result === null || result.d.ID === undefined),
                concatMap(result => this.attachAllFilesToCreatedRecord(result.d.ID, fileList))
            );
        } else {
            return sharePointRecord$;
        }
    }

    // used in constructor to fetch the token
    getSharepointDigest() {
        const jsonHeader = 'application/json; odata=verbose';
        let  headers = new HttpHeaders();
        headers = headers.append('Content-Type', jsonHeader);
        headers = headers.append('Accept', jsonHeader);
        headers = headers.append('Response-Type', 'json');

        const body = ''  ;
        return this.http.post(this.sharepointApiUrl + 'contextinfo', body, { headers: headers, responseType: 'json' });
    }

    // This will create feed back record. once record is created, we will do attachment next
    createSharepointFeedbackRecord(dataToPost: any) {
        let  headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json;odata=verbose');
        headers = headers.append('Accept', 'application/json;odata=verbose');
        headers = headers.append('X-RequestDigest',  this.sharePointDigestToken);
        const options = { withCredentials: true, headers: headers};
        return this.http.post<any>(`${this.sharepointApiUrl}web/lists/GetByTitle('Submit Feedback Bugs')/items`, dataToPost, options);
    }



    attachAllFilesToCreatedRecord(sharepointRecordId, attachedFileList: any[]) {
        const attachFilesObservable: Observable<Response>[] = [];

        for (let i = 0; i < attachedFileList.length; i++) {
            attachFilesObservable.push(this.attachOneFile(sharepointRecordId,  attachedFileList[i]));
        }

        return concat(...attachFilesObservable).pipe(
            catchError(err => {
                console.log(err);
                return of(err);
            })
        );
    }


     // this function will be called by attachAllFilesToCreatedRecord()
    attachOneFile(sharepointRecordId, attachedFile): Observable<any> {
        const fileToUpload =  attachedFile;
        return this.getbufferDataObservable(fileToUpload[0]).pipe(
            concatMap(fileResult => {
                const attachementData = fileResult;
                let  headers = new HttpHeaders();
                headers = headers.append('accept', 'application/json;odata=verbose');
                headers = headers.append( 'content-type', 'undefined');
                headers = headers.append('X-RequestDigest',  this.sharePointDigestToken);
                // tslint:disable-next-line:max-line-length
                return this.http.post<any>(`${this.sharepointApiUrl}web/lists/GetByTitle('Submit Feedback Bugs')/items(${sharepointRecordId})/AttachmentFiles/add(FileName='${fileToUpload[0].name}')`, attachementData, { headers: headers, withCredentials: true});
            }),
            catchError(err => {
                console.log(err);
                return of(err);
            })
        );
    }

    // This function will generate uploaded file   binaray data array to feed to sharepoint api
    getbufferDataObservable (file: any): Observable<any> {
        const fileData$ = new Observable((fObserver) => {
            const freader = new FileReader();
            freader.readAsArrayBuffer(file);
            freader.onloadend = () => {
                fObserver.next(freader.result);
                fObserver.complete();
            };
            freader.onerror = (err) => {
                fObserver.error(err);
            };
            freader.onabort = () => {
                fObserver.error('buffer data observer aborted');
            };
        });
        return fileData$;
    }

}
