import { Action } from '@ngrx/store';
import { User, Preference } from '@app/shared/models/UserAPI.model';

export enum FeedbackActionTypes {
    PostFeedback = '[Feedback] Post Feedback',
    PostFeedbackSuccess = '[Feedback] Post Feedback Success',
    PostFeedbackAttachments = '[Feedback] Post Feedback Attachments',
    PostImageFeedback = '[Feedback] Post Image Feedback',
    PostImageFeedbackSuccess = '[Feedback] Post Image Feedback Success',
    PostImageFeedbackAttachments = '[Feedback] Post Image Feedback Attachments'
}


export class PostFeedback implements Action {
    readonly type = FeedbackActionTypes.PostFeedback;

    constructor(public payload: any) { }
}

export class PostFeedbackSuccess implements Action {
    readonly type = FeedbackActionTypes.PostFeedbackSuccess;

    constructor(public payload: any) { }
}

export class PostFeedbackAttachments implements Action {
    readonly type = FeedbackActionTypes.PostFeedbackAttachments;

    constructor(public payload: any) { }
}

export class PostImageFeedback implements Action {
    readonly type = FeedbackActionTypes.PostImageFeedback;

    constructor(public payload: any) { }
}

export class PostImageFeedbackSuccess implements Action {
    readonly type = FeedbackActionTypes.PostImageFeedbackSuccess;

    constructor(public payload: any) { }
}

export class PostImageFeedbackAttachments implements Action {
    readonly type = FeedbackActionTypes.PostImageFeedbackAttachments;

    constructor(public payload: any) { }
}

export type FeedbackActionsUnion =
    | PostFeedback
    | PostFeedbackSuccess
    | PostFeedbackAttachments
    | PostImageFeedback
    | PostImageFeedbackSuccess
    | PostImageFeedbackAttachments;
