<xom-modal-layout>  
  <div modal-content>
    <div class="row middle-xs admin-container">
      <div class="col-xs-12 close-container no-gutter">
        <span (click)="close.emit()" class="close icon-close increase-icon"></span>
      </div>
      <div class="col-xs-12 admin-content">
          <h1 class="title"> 
            <span class="warning">!</span>
            {{ title }} 
          </h1>
          <p class="message" [innerHtml]="message"></p>
          <button xom-button (click)="close.emit()">Dismiss</button>
      </div>
      <div class="splash-waves bottom">
        <img src="/assets/images/swoop-orange-bottom.svg">
      </div>
      <div class="splash-waves bottom">
        <img src="/assets/images/swoop-transparent.svg">
      </div>
      <div class="splash-waves bottom-left">
        <img src="/assets/images/swoop-orange-bottom-left.svg">
      </div>
      <div class="splash-waves bottom-right">
        <img src="/assets/images/swoop-orange-bottom-right.svg">
      </div>
    </div>
  </div>    
</xom-modal-layout>
