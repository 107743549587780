import { ConsoleFacade } from './console.facade';
import { environment } from '@environments/environment';

// Singleton to overwrite the normal console process
class ConsoleUtil {

    private static instance: ConsoleUtil;
    private consoleFacade = new ConsoleFacade();

    private constructor() { }

    public static getInstance(): ConsoleUtil {
        if (!ConsoleUtil.instance) {
            ConsoleUtil.instance = new ConsoleUtil();
        }
        return ConsoleUtil.instance;
    }

    log(...args: any[]) {
        if (!environment.production) {
            this.consoleFacade.log(...args);
        }
    }

    error(...args: any[]) {
        if (!environment.production) {
            this.consoleFacade.error(...args);
        }
    }

    warn(...args: any[]) {
        if (!environment.production) {
            this.consoleFacade.warn(...args);
        }
    }

    groupCollapsed(...args: any[]) {
        if (!environment.production) {
            this.consoleFacade.groupCollapsed(...args);
        }
    }

    groupEnd() {
        if (!environment.production) {
            this.consoleFacade.groupEnd();
        }
    }
}

export const console = ConsoleUtil.getInstance();
