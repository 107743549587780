import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Preference, User } from '@shared/models/UserAPI.model';
import { UserActions } from '@shared/actions';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AppConfigService } from '@app/app-config.service';
import { PromoteWSAPIService } from '@shared/services/promoteWS.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app-config.service";
import * as i3 from "@ngrx/store";
import * as i4 from "./promoteWS.service";
var UserAPIService = /** @class */ (function () {
    function UserAPIService(http, _appConfig, sharedStore, promoteService) {
        this.http = http;
        this._appConfig = _appConfig;
        this.sharedStore = sharedStore;
        this.promoteService = promoteService;
        this._config = this._appConfig.getConfig();
        this.loadUser();
    }
    UserAPIService.prototype.loadUser = function () {
        var _this = this;
        this.getUser().pipe(take(1)).subscribe(function (res) {
            // If the User is Promoter then load the categories to promote
            if (res.isPromoter) {
                _this.promoteService.getWorkSpaceCategories().pipe(take(1)).subscribe(function (categories) {
                    _this.sharedStore.dispatch(new UserActions.SetPromoterCategories(categories));
                });
            }
            _this.sharedStore.dispatch(new UserActions.LoadUser(res));
            _this.getPreferences().pipe(take(1)).subscribe(function (prefs) {
                _this.sharedStore.dispatch(new UserActions.LoadPreferences(prefs.preferences));
            });
        });
    };
    UserAPIService.prototype.getUser = function () {
        return this.http.get(this._config.user_api_baseurl + "/user");
        // return this.http.get<User>('/assets/data/user-sample-response.json');
    };
    UserAPIService.prototype.updateUser = function (user) {
        return this.http.put(this._config.user_api_baseurl + "/user", user);
    };
    UserAPIService.prototype.getPreferences = function () {
        return this.http.get(this._config.user_api_baseurl + "/preferences");
        // return this.http.get<any>('/assets/data/preferences-sample-response.json');
    };
    UserAPIService.prototype.updatePreferences = function (preferences) {
        return this.http.put(this._config.user_api_baseurl + "/preferences", { preferences: preferences });
    };
    // available messages
    UserAPIService.prototype.getAvailableMessages = function () {
        return this.http.get(this._config.user_api_baseurl + "/messages");
        // return this.http.get<any>('/assets/data/landing-messages-sample-response.json');
    };
    UserAPIService.prototype.setFavoriteWorkspace = function (workspaceId) {
        return this.http.put(this._config.user_api_baseurl + "/preferences/workspace/" + workspaceId + "/favorite", {});
        // return this.http.get<any>('/assets/data/preferences-sample-response.json');
    };
    UserAPIService.prototype.setUnfavoriteWorkspace = function (workspaceId) {
        return this.http.put(this._config.user_api_baseurl + "/preferences/workspace/" + workspaceId + "/unFavorite", {});
        // return this.http.get<any>('/assets/data/preferences-sample-response.json');
    };
    UserAPIService.ngInjectableDef = i0.defineInjectable({ factory: function UserAPIService_Factory() { return new UserAPIService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService), i0.inject(i3.Store), i0.inject(i4.PromoteWSAPIService)); }, token: UserAPIService, providedIn: "root" });
    return UserAPIService;
}());
export { UserAPIService };
