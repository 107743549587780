var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY as empty, asyncScheduler, of, from } from 'rxjs';
import { debounceTime, map, switchMap, withLatestFrom, catchError, } from 'rxjs/operators';
import { CollectionsApiService } from '@shared/services/collections-api.service';
import { CollectionsActions, SnackbarActions, UserActions } from '@shared/actions';
import { ContentService } from '@shared/services/content.service';
var CollectionsEffects = /** @class */ (function () {
    function CollectionsEffects(actions$, store$, collectionsApi, content) {
        var _this = this;
        this.actions$ = actions$;
        this.store$ = store$;
        this.collectionsApi = collectionsApi;
        this.content = content;
        this.loadCollection$ = function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.debounce, debounce = _c === void 0 ? 300 : _c, _d = _b.scheduler, scheduler = _d === void 0 ? asyncScheduler : _d;
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.LoadCollection), debounceTime(debounce, scheduler), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1];
                return _this.collectionsApi.getCollection(action.payload, [], storeState.shared.collections.fileSort).pipe(map(function (res) {
                    return new CollectionsActions.LoadCollectionSuccess(res);
                }), catchError(function () { return of(new CollectionsActions.CollectionNotExisting(true)); }));
            }));
        };
        this.loadCollectionSuccess$ = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.LoadCollectionSuccess), switchMap(function (action) {
                return _this.collectionsApi.getFileSummary(action.payload.collectionId).pipe(map(function (res) {
                    return new CollectionsActions.LoadCollectionSummary(res);
                }));
            }));
        };
        this.GetFilteredCollection$ = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.GetFilteredCollection), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1];
                return _this.collectionsApi.getCollection(action.payload.collectionId, action.payload.filterExtensions, storeState.shared.collections.fileSort).pipe(map(function (res) {
                    return new CollectionsActions.GetFilteredCollectionSuccess(res);
                }));
            }));
        };
        this.GetSortedCollection$ = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.GetSortedCollection), switchMap(function (action) {
                return _this.collectionsApi.getCollection(action.payload.collectionId, [], action.payload.fileSort).pipe(map(function (res) {
                    return new CollectionsActions.GetSortedCollectionSuccess(res);
                }));
            }));
        };
        this.CollectionSearch$ = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.CollectionSearch), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1];
                return _this.collectionsApi.getCollection(action.payload.collectionId, [], storeState.shared.collections.fileSort, action.payload.filterFileContains).pipe(map(function (res) {
                    return new CollectionsActions.CollectionSearchSuccess(res);
                }));
            }));
        };
        this.GetFilteredSortedCollectionSuccess$ = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.GetFilteredCollectionSuccess, CollectionsActions.CollectionsActionTypes.GetSortedCollectionSuccess, CollectionsActions.CollectionsActionTypes.CollectionSearchSuccess), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1];
                return of(new CollectionsActions.LoadCollectionSuccess(storeState.shared.collections.userCollection));
            }));
        };
        this.loadAllCollections$ = function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.debounce, debounce = _c === void 0 ? 300 : _c, _d = _b.scheduler, scheduler = _d === void 0 ? asyncScheduler : _d;
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.LoadAllCollections), debounceTime(debounce, scheduler), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1];
                return _this.collectionsApi.getCollections().pipe(map(function (res) {
                    return new CollectionsActions.LoadAllCollectionsSuccess(res);
                }));
            }));
        };
        // Create Collection Effects
        this.createCollection$ = function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.debounce, debounce = _c === void 0 ? 300 : _c, _d = _b.scheduler, scheduler = _d === void 0 ? asyncScheduler : _d;
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.CreateCollection), debounceTime(debounce, scheduler), switchMap(function (action) {
                return _this.collectionsApi.createCollection(action.payload).pipe(map(function (res) {
                    return new CollectionsActions.CreateCollectionSuccess(res);
                }), catchError(function (err) { return of(new CollectionsActions.CreateCollectionFailure(err)); }));
            }));
        };
        this.createCollectionSuccess$ = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.CreateCollectionSuccess), switchMap(function (action) {
                return of(new CollectionsActions.LoadAllCollections(), new SnackbarActions.Open({
                    message: _this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.CREATED,
                    type: 'xom-snackbar-success',
                    action: 'x'
                }));
            }));
        };
        this.createCollectionFailed$ = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.CreateCollectionFailure), switchMap(function (action) {
                return of(new SnackbarActions.Open({
                    message: _this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.NOT_CREATED,
                    type: 'xom-snackbar-error',
                    action: 'x'
                }));
            }));
        };
        // Remove From Collection Effects
        this.removeFromCollection$ = function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.debounce, debounce = _c === void 0 ? 300 : _c, _d = _b.scheduler, scheduler = _d === void 0 ? asyncScheduler : _d;
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.RemoveFromCollection), debounceTime(debounce, scheduler), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1];
                var updatedCollection = __assign({}, storeState.shared.collections.userCollection);
                updatedCollection.collection =
                    updatedCollection.collection.filter(function (file) { return !storeState.shared.collections.selectedDocumentNames.includes(file); });
                return _this.collectionsApi.updateCollection(updatedCollection).pipe(map(function (res) {
                    return new CollectionsActions.RemoveFromCollectionSuccess(res);
                }), catchError(function (err) { return of(new CollectionsActions.UpdateCollectionFailure(err)); }));
            }));
        };
        this.removeFromCollectionSuccess$ = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.RemoveFromCollectionSuccess), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1];
                return of(new CollectionsActions.LoadCollection(storeState.shared.collections.userCollection.collectionId), new CollectionsActions.ResetSelectedDocument(), new SnackbarActions.Open({
                    message: _this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.UPDATED,
                    type: 'xom-snackbar-success',
                    action: 'x'
                }));
            }));
        };
        // Update Collection Effects
        this.updateCollection$ = function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.debounce, debounce = _c === void 0 ? 300 : _c, _d = _b.scheduler, scheduler = _d === void 0 ? asyncScheduler : _d;
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.UpdateCollection), debounceTime(debounce, scheduler), switchMap(function (action) {
                return _this.collectionsApi.updateCollection(action.payload).pipe(map(function (res) {
                    return new CollectionsActions.UpdateCollectionSuccess(action.payload);
                }), catchError(function (err) { return of(new CollectionsActions.UpdateCollectionFailure(err)); }));
            }));
        };
        this.updateCollectionSuccess$ = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.UpdateCollectionSuccess), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1];
                return of(new CollectionsActions.LoadCollection(storeState.shared.collections.userCollection.collectionId), new SnackbarActions.Open({
                    message: _this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.UPDATED,
                    type: 'xom-snackbar-success',
                    action: 'x'
                }));
            }));
        };
        this.updateCollectionFailed$ = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.UpdateCollectionFailure), switchMap(function (action) {
                return of(new SnackbarActions.Open({
                    message: _this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.NOT_UPDATED,
                    type: 'xom-snackbar-error',
                    action: 'x'
                }));
            }));
        };
        // Delete Collection Effects
        this.deleteCollection$ = function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.debounce, debounce = _c === void 0 ? 300 : _c, _d = _b.scheduler, scheduler = _d === void 0 ? asyncScheduler : _d;
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.DeleteCollection), debounceTime(debounce, scheduler), switchMap(function (action) {
                return _this.collectionsApi.deleteCollection(action.payload.collectionId).pipe(map(function (res) {
                    return new CollectionsActions.DeleteCollectionSuccess(action.payload.collectionId);
                }), catchError(function (err) { return of(new CollectionsActions.DeleteCollectionFailure(err)); }));
            }));
        };
        this.deleteCollectionSuccess$ = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.DeleteCollectionSuccess), map(function (action) {
                return new SnackbarActions.Open({
                    message: _this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.DELETED,
                    type: 'xom-snackbar-success',
                    action: 'x'
                });
            }));
        };
        this.deleteCollectionFailed$ = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.DeleteCollectionFailure), map(function (action) {
                return new SnackbarActions.Open({
                    message: _this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.NOT_DELETED,
                    type: 'xom-snackbar-error',
                    action: 'x'
                });
            }));
        };
        // Get Documents by Id
        this.getCollectionDocuments$ = function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.debounce, debounce = _c === void 0 ? 300 : _c, _d = _b.scheduler, scheduler = _d === void 0 ? asyncScheduler : _d;
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.LoadCollectionSuccess), debounceTime(debounce, scheduler), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0];
                return _this.collectionsApi.docsById(action.payload.collection).pipe(map(function (res) {
                    return new CollectionsActions.GetCollectionDocumentsSuccess(res);
                }), catchError(function (err) { return of(new CollectionsActions.GetCollectionDocumentsFailure(err)); }));
            }));
        };
        // Export Collections
        this.exportAllCollectionDocs$ = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.ExportAllCollectionDocs), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0];
                return _this.collectionsApi.exportDocsById(action.payload).pipe(map(function (res) {
                    return new CollectionsActions.ExportAllCollectionDocsSuccess(res);
                }));
            }));
        };
        this.exportAllCollectionDocsSuccess$ = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.ExportAllCollectionDocsSuccess), map(function (action) {
                return new SnackbarActions.Open({
                    message: _this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.DOWNLOAD,
                    type: 'xom-snackbar-success',
                    action: 'x'
                });
            }));
        };
        // Comments
        this.addCollectionComment$ = function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.debounce, debounce = _c === void 0 ? 300 : _c, _d = _b.scheduler, scheduler = _d === void 0 ? asyncScheduler : _d;
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.AddCollectionComment), debounceTime(debounce, scheduler), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1];
                var messageObj = {
                    'body': action.payload.message
                };
                if (storeState && storeState.shared.collections.userCollection.collectionId) {
                    return _this.collectionsApi.addCollectionComment(storeState.shared.collections.userCollection.collectionId, messageObj).pipe(map(function (res) {
                        return new CollectionsActions.AddCollectionCommentSuccess(res);
                    }));
                }
                else {
                    return empty;
                }
            }));
        };
        this.deleteCollectionComment$ = function (_a) {
            var _b = _a === void 0 ? {} : _a, _c = _b.debounce, debounce = _c === void 0 ? 300 : _c, _d = _b.scheduler, scheduler = _d === void 0 ? asyncScheduler : _d;
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.DeleteCollectionComment), debounceTime(debounce, scheduler), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1];
                if (storeState && storeState.shared.collections.userCollection.collectionId) {
                    return _this.collectionsApi.deleteCollectionComment(storeState.shared.collections.userCollection.collectionId, action.payload.messageID).pipe(switchMap(function (res) {
                        if (storeState.shared.collections.isNotesFromFlipcard) {
                            return from([
                                new CollectionsActions.DeleteCollectionCommentSuccess(res),
                                new CollectionsActions.LoadCollection(storeState.shared.collections.userCollection.collectionId)
                            ]);
                        }
                        else {
                            return of(new CollectionsActions.DeleteCollectionCommentSuccess(res));
                        }
                    }));
                }
                else {
                    return empty;
                }
            }));
        };
        this.setNotesFromFlipcard$ = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.SetNotesFromFlipcard), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0];
                if (!action.payload.state) {
                    return empty;
                }
                return of(new CollectionsActions.LoadCollection(action.payload.id));
            }));
        };
        this.SetShownNotes$ = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.LoadCollectionSuccess, CollectionsActions.CollectionsActionTypes.AddCollectionCommentSuccess, CollectionsActions.CollectionsActionTypes.DeleteCollectionCommentSuccess), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1];
                return _this.collectionsApi.getCollection(storeState.shared.collections.userCollection.collectionId, [], storeState.shared.collections.fileSort).pipe(map(function (res) {
                    return new CollectionsActions.SetShownNotes(res.comments.sort(function (a, b) { return new Date(b.createDate) - new Date(a.createDate); }));
                }));
            }));
        };
        // Pin
        this.pinCollection$ = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.PinCollection), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1];
                var updatedPreferences = {
                    'pinnedCollection': storeState.shared.user.preferences.pinnedCollection.concat([action.payload])
                };
                _this.store$.dispatch(new UserActions.UpdatePreferences(updatedPreferences));
                return _this.collectionsApi.addPinnedCollection(action.payload).pipe(map(function (res) {
                    return new SnackbarActions.Open({
                        message: _this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.PINNED,
                        type: 'xom-snackbar-success',
                        action: 'x'
                    });
                }), catchError(function (err) {
                    return of(new SnackbarActions.Open({
                        message: _this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.NOT_PINNED,
                        type: 'xom-snackbar-error',
                        action: 'x'
                    }));
                }));
            }));
        };
        this.unpinCollection = function () {
            return _this.actions$.pipe(ofType(CollectionsActions.CollectionsActionTypes.UnpinCollection), withLatestFrom(_this.store$), switchMap(function (_a) {
                var action = _a[0], storeState = _a[1];
                var currentpins = storeState.shared.user.preferences.pinnedCollection;
                var index = null;
                currentpins.forEach(function (pin, i) { if (pin === action.payload) {
                    index = i;
                } });
                currentpins.splice(index, 1);
                var updatedPreferences = {
                    'pinnedCollection': currentpins
                };
                _this.store$.dispatch(new UserActions.UpdatePreferences(updatedPreferences));
                return _this.collectionsApi.removePinnedCollection(action.payload).pipe(map(function (res) {
                    return new SnackbarActions.Open({
                        message: _this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.UNPINNED,
                        type: 'xom-snackbar-success',
                        action: 'x'
                    });
                }), catchError(function (err) {
                    return of(new SnackbarActions.Open({
                        message: _this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.UNPINNED,
                        type: 'xom-snackbar-error',
                        action: 'x'
                    }));
                }));
            }));
        };
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "loadCollection$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "loadCollectionSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "GetFilteredCollection$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "GetSortedCollection$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "CollectionSearch$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "GetFilteredSortedCollectionSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "loadAllCollections$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "createCollection$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "createCollectionSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "createCollectionFailed$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "removeFromCollection$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "removeFromCollectionSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "updateCollection$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "updateCollectionSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "updateCollectionFailed$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "deleteCollection$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "deleteCollectionSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "deleteCollectionFailed$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "getCollectionDocuments$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "exportAllCollectionDocs$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "exportAllCollectionDocsSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "addCollectionComment$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "deleteCollectionComment$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "setNotesFromFlipcard$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "SetShownNotes$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "pinCollection$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], CollectionsEffects.prototype, "unpinCollection", void 0);
    return CollectionsEffects;
}());
export { CollectionsEffects };
