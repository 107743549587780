import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '@app/app-config.service';
import { EMPTY as empty } from 'rxjs';
import { buildMessageError } from '@shared/util/search-util';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../app-config.service";
var SeisbookAPIService = /** @class */ (function () {
    function SeisbookAPIService(http, _appConfig) {
        this.http = http;
        this._appConfig = _appConfig;
        this._config = this._appConfig.getConfig();
    }
    SeisbookAPIService.prototype.getImage = function (url) {
        if (!url || url.substr(url.length - 4) === 'null') {
            return empty;
        }
        return this.http.get(url, { withCredentials: true, responseType: 'blob' });
    };
    SeisbookAPIService.prototype.searchSeisbook = function (queryText, basins, pageIndex, pageSize) {
        if (pageIndex === void 0) { pageIndex = 1; }
        if (pageSize === void 0) { pageSize = 100; }
        var query = [{
                searchBy: 'all',
                searchKeyword: queryText,
            }];
        if (basins.length) {
            query.push({
                searchBy: 'basin',
                searchKeyword: basins
            });
        }
        return this.http.post(this._config.seisbook_api_baseurl + "api/posts/search/multi?PageIndex=" + pageIndex + "&PageSize=" + pageSize, query, {
            withCredentials: true
        }).pipe(map(function (res) {
            if (res.status) {
                if (res.status === 401 || res.status === 403) {
                    return res;
                }
                throw Error(buildMessageError(res));
            }
            else {
                return res;
            }
        }));
    };
    SeisbookAPIService.ngInjectableDef = i0.defineInjectable({ factory: function SeisbookAPIService_Factory() { return new SeisbookAPIService(i0.inject(i1.HttpClient), i0.inject(i2.AppConfigService)); }, token: SeisbookAPIService, providedIn: "root" });
    return SeisbookAPIService;
}());
export { SeisbookAPIService };
