import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {EMPTY as empty, asyncScheduler, Observable, of, from } from 'rxjs';
import {
    debounceTime,
    map,
    switchMap,
    withLatestFrom,
    catchError,
    concatMap,
} from 'rxjs/operators';
import { CollectionsApiService } from '@shared/services/collections-api.service';
import {CollectionsActions, SnackbarActions, UserActions} from '@shared/actions';
import { UserCollection } from '@shared/models/UserAPI.model';
import * as fromShared from '@shared/reducers';
import {ContentService} from '@shared/services/content.service';
import {GlobalContent} from '@shared/shared-content';

@Injectable()
export class CollectionsEffects {

    @Effect()
    loadCollection$ = ({ debounce = 300, scheduler = asyncScheduler } = {}): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.LoadCollection>( CollectionsActions.CollectionsActionTypes.LoadCollection ),
            debounceTime(debounce, scheduler),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                return this.collectionsApi.getCollection(action.payload, [], storeState.shared.collections.fileSort).pipe(
                    map((res) => {
                        return new CollectionsActions.LoadCollectionSuccess(res);
                    }),
                    catchError(() => of(new CollectionsActions.CollectionNotExisting(true))
                ));
            })
        )

    @Effect()
    loadCollectionSuccess$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.LoadCollectionSuccess>( CollectionsActions.CollectionsActionTypes.LoadCollectionSuccess ),
            switchMap((action) => {
                return this.collectionsApi.getFileSummary(action.payload.collectionId).pipe(
                    map((res) => {
                        return new CollectionsActions.LoadCollectionSummary(res);
                    })
                );
            })
        )

    @Effect()
    GetFilteredCollection$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.GetFilteredCollection>( CollectionsActions.CollectionsActionTypes.GetFilteredCollection ),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                return this.collectionsApi.getCollection(
                    action.payload.collectionId,
                    action.payload.filterExtensions,
                    storeState.shared.collections.fileSort
                ).pipe(
                    map((res: UserCollection) => {
                        return new CollectionsActions.GetFilteredCollectionSuccess(res);
                    })
                );
            })
        )

    @Effect()
    GetSortedCollection$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.GetSortedCollection>( CollectionsActions.CollectionsActionTypes.GetSortedCollection ),
            switchMap((action) => {
                return this.collectionsApi.getCollection(action.payload.collectionId, [], action.payload.fileSort).pipe(
                    map((res: UserCollection) => {
                        return new CollectionsActions.GetSortedCollectionSuccess(res);
                    })
                );
            })
        )

    @Effect()
    CollectionSearch$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.CollectionSearch>( CollectionsActions.CollectionsActionTypes.CollectionSearch ),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                return this.collectionsApi.getCollection(
                    action.payload.collectionId,
                    [],
                    storeState.shared.collections.fileSort,
                    action.payload.filterFileContains
                ).pipe(
                    map((res: UserCollection) => {
                        return new CollectionsActions.CollectionSearchSuccess(res);
                    })
                );
            })
        )

    @Effect()
    GetFilteredSortedCollectionSuccess$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.GetFilteredCollectionSuccess | CollectionsActions.GetSortedCollectionSuccess>(
                CollectionsActions.CollectionsActionTypes.GetFilteredCollectionSuccess,
                CollectionsActions.CollectionsActionTypes.GetSortedCollectionSuccess,
                CollectionsActions.CollectionsActionTypes.CollectionSearchSuccess,
            ),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                return of(
                    new CollectionsActions.LoadCollectionSuccess(storeState.shared.collections.userCollection)
                );
            })
        )

    @Effect()
    loadAllCollections$ = ({ debounce = 300, scheduler = asyncScheduler } = {}): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.LoadAllCollections>( CollectionsActions.CollectionsActionTypes.LoadAllCollections ),
            debounceTime(debounce, scheduler),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                return this.collectionsApi.getCollections().pipe(
                    map((res: UserCollection) => {
                        return new CollectionsActions.LoadAllCollectionsSuccess(res);
                    })
                );
            })
        )

    // Create Collection Effects
    @Effect()
    createCollection$ = ({ debounce = 300, scheduler = asyncScheduler } = {}): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.CreateCollection>( CollectionsActions.CollectionsActionTypes.CreateCollection ),
            debounceTime(debounce, scheduler),
            switchMap((action) => {
                return this.collectionsApi.createCollection(
                    action.payload
                ).pipe(
                    map((res: string) => {
                        return new CollectionsActions.CreateCollectionSuccess(res);
                    }),
                    catchError(err => of(new CollectionsActions.CreateCollectionFailure(err)))
                );
            })
        )

    @Effect()
    createCollectionSuccess$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.CreateCollectionSuccess>(
                CollectionsActions.CollectionsActionTypes.CreateCollectionSuccess
            ),
            switchMap(action => {
                return of(
                    new CollectionsActions.LoadAllCollections(),
                    new SnackbarActions.Open({
                    message: this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.CREATED,
                    type: 'xom-snackbar-success',
                    action: 'x'
                }));
            }),
        )

    @Effect()
    createCollectionFailed$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.CreateCollectionFailure>(
                CollectionsActions.CollectionsActionTypes.CreateCollectionFailure
            ),
            switchMap(action => {
                return of(
                    new SnackbarActions.Open({
                    message: this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.NOT_CREATED,
                    type: 'xom-snackbar-error',
                    action: 'x'
                }));
            }),
        )

    // Remove From Collection Effects

    @Effect()
    removeFromCollection$ = ({ debounce = 300, scheduler = asyncScheduler } = {}): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.RemoveFromCollection>( CollectionsActions.CollectionsActionTypes.RemoveFromCollection ),
            debounceTime(debounce, scheduler),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                const updatedCollection = { ...storeState.shared.collections.userCollection };
                updatedCollection.collection =
                    updatedCollection.collection.filter(file => !storeState.shared.collections.selectedDocumentNames.includes(file));
                return this.collectionsApi.updateCollection(
                    updatedCollection
                ).pipe(
                    map((res: UserCollection) => {
                        return new CollectionsActions.RemoveFromCollectionSuccess(res);
                    }),
                    catchError(err => of(new CollectionsActions.UpdateCollectionFailure(err)))
                );
            })
        )

    @Effect()
    removeFromCollectionSuccess$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.RemoveFromCollectionSuccess>(
                CollectionsActions.CollectionsActionTypes.RemoveFromCollectionSuccess
            ),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                return of(
                    new CollectionsActions.LoadCollection(storeState.shared.collections.userCollection.collectionId),
                    new CollectionsActions.ResetSelectedDocument(),
                    new SnackbarActions.Open({
                        message: this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.UPDATED,
                        type: 'xom-snackbar-success',
                        action: 'x'
                    })
                );
            })
        )

    // Update Collection Effects

    @Effect()
    updateCollection$ = ({ debounce = 300, scheduler = asyncScheduler } = {}): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.UpdateCollection>( CollectionsActions.CollectionsActionTypes.UpdateCollection ),
            debounceTime(debounce, scheduler),
            switchMap((action) => {
                return this.collectionsApi.updateCollection(
                    action.payload
                ).pipe(
                    map((res: UserCollection) => {
                        return new CollectionsActions.UpdateCollectionSuccess(action.payload);
                    }),
                    catchError(err => of(new CollectionsActions.UpdateCollectionFailure(err)))
                );
            })
        )

    @Effect()
    updateCollectionSuccess$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.UpdateCollectionSuccess>(
                CollectionsActions.CollectionsActionTypes.UpdateCollectionSuccess
            ),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                return of(
                    new CollectionsActions.LoadCollection(storeState.shared.collections.userCollection.collectionId),
                    new SnackbarActions.Open({
                    message: this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.UPDATED,
                    type: 'xom-snackbar-success',
                    action: 'x'
                }));
            })
        )

    @Effect()
    updateCollectionFailed$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.UpdateCollectionFailure>(
                CollectionsActions.CollectionsActionTypes.UpdateCollectionFailure
            ),
            switchMap(action => {
                return of(
                    new SnackbarActions.Open({
                    message: this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.NOT_UPDATED,
                    type: 'xom-snackbar-error',
                    action: 'x'
                }));
            }),
        )

    // Delete Collection Effects

    @Effect()
    deleteCollection$ = ({ debounce = 300, scheduler = asyncScheduler } = {}): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.DeleteCollection>( CollectionsActions.CollectionsActionTypes.DeleteCollection ),
            debounceTime(debounce, scheduler),
            switchMap(action => {
                return this.collectionsApi.deleteCollection(
                    action.payload.collectionId
                ).pipe(
                    map((res: string) => {
                        return new CollectionsActions.DeleteCollectionSuccess(action.payload.collectionId);
                    }),
                    catchError(err => of(new CollectionsActions.DeleteCollectionFailure(err)))
                );
            })
        )

    @Effect()
    deleteCollectionSuccess$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.DeleteCollectionSuccess>(
                CollectionsActions.CollectionsActionTypes.DeleteCollectionSuccess
            ),
            map(action => {
                return new SnackbarActions.Open({
                    message: this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.DELETED,
                    type: 'xom-snackbar-success',
                    action: 'x'
                });
            }),
        )

    @Effect()
    deleteCollectionFailed$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.DeleteCollectionFailure>(
                CollectionsActions.CollectionsActionTypes.DeleteCollectionFailure
            ),
            map(action => {
                return new SnackbarActions.Open({
                    message: this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.NOT_DELETED,
                    type: 'xom-snackbar-error',
                    action: 'x'
                });
            }),
        )

    // Get Documents by Id

    @Effect()
    getCollectionDocuments$ = ({ debounce = 300, scheduler = asyncScheduler } = {}): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.LoadCollectionSuccess>(CollectionsActions.CollectionsActionTypes.LoadCollectionSuccess),
            debounceTime(debounce, scheduler),
            withLatestFrom(this.store$),
            switchMap(([action]) => {
                return this.collectionsApi.docsById(
                    action.payload.collection
                ).pipe(
                    map((res: any) => {
                        return new CollectionsActions.GetCollectionDocumentsSuccess(res);
                    }),
                    catchError(err => of(new CollectionsActions.GetCollectionDocumentsFailure(err)))
                );
            })
        )

    // Export Collections

    @Effect()
    exportAllCollectionDocs$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.ExportAllCollectionDocs>(CollectionsActions.CollectionsActionTypes.ExportAllCollectionDocs),
            withLatestFrom(this.store$),
            switchMap(([action]) => {
                return this.collectionsApi.exportDocsById(
                    action.payload
                ).pipe(
                    map((res: any) => {
                        return new CollectionsActions.ExportAllCollectionDocsSuccess(res);
                    })
                );
            })
        )

    @Effect()
    exportAllCollectionDocsSuccess$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.ExportAllCollectionDocsSuccess>(
                CollectionsActions.CollectionsActionTypes.ExportAllCollectionDocsSuccess
            ),
            map(action => {
                return new SnackbarActions.Open({
                    message: this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.DOWNLOAD,
                    type: 'xom-snackbar-success',
                    action: 'x'
                });
            }),
        )

    // Comments

    @Effect()
    addCollectionComment$ = ({ debounce = 300, scheduler = asyncScheduler } = {}): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.AddCollectionComment>( CollectionsActions.CollectionsActionTypes.AddCollectionComment ),
            debounceTime(debounce, scheduler),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                const messageObj = {
                    'body': action.payload.message
                };
                if (storeState && storeState.shared.collections.userCollection.collectionId) {
                    return this.collectionsApi.addCollectionComment( storeState.shared.collections.userCollection.collectionId,
                        messageObj ).pipe(
                        map((res: any) => {
                            return new CollectionsActions.AddCollectionCommentSuccess(res);
                        })
                    );
                } else {
                    return empty;
                }
            })
        )

    @Effect()
    deleteCollectionComment$ = ({ debounce = 300, scheduler = asyncScheduler } = {}): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.DeleteCollectionComment>( CollectionsActions.CollectionsActionTypes.DeleteCollectionComment ),
            debounceTime(debounce, scheduler),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                if (storeState && storeState.shared.collections.userCollection.collectionId) {
                    return this.collectionsApi.deleteCollectionComment( storeState.shared.collections.userCollection.collectionId,
                        action.payload.messageID ).pipe(
                        switchMap((res: any) => {
                            if (storeState.shared.collections.isNotesFromFlipcard) {
                                return from([
                                    new CollectionsActions.DeleteCollectionCommentSuccess(res),
                                    new CollectionsActions.LoadCollection(storeState.shared.collections.userCollection.collectionId)
                                ]);
                            } else {
                                return of(new CollectionsActions.DeleteCollectionCommentSuccess(res));
                            }
                        })
                    );
                } else {
                    return empty;
                }
            })
        )

    @Effect()
    setNotesFromFlipcard$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.SetNotesFromFlipcard>( CollectionsActions.CollectionsActionTypes.SetNotesFromFlipcard ),
            withLatestFrom(this.store$),
            switchMap(([action]) => {
                if (!action.payload.state) {
                    return empty;
                }
                return of(new CollectionsActions.LoadCollection(action.payload.id));
            })
        )

    @Effect()
    SetShownNotes$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<
                CollectionsActions.LoadCollectionSuccess |
                CollectionsActions.AddCollectionCommentSuccess |
                CollectionsActions.DeleteCollectionCommentSuccess
                >(
                CollectionsActions.CollectionsActionTypes.LoadCollectionSuccess,
                CollectionsActions.CollectionsActionTypes.AddCollectionCommentSuccess,
                CollectionsActions.CollectionsActionTypes.DeleteCollectionCommentSuccess,
            ),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                return this.collectionsApi.getCollection(
                    storeState.shared.collections.userCollection.collectionId,
                    [],
                    storeState.shared.collections.fileSort
                ).pipe(
                    map((res) => {
                        return new CollectionsActions.SetShownNotes(
                            res.comments.sort(
                                (a, b) => <any>new Date(b.createDate) - <any>new Date(a.createDate)
                            )
                        );
                    })
                );
            })
        )

    // Pin
    @Effect()
    pinCollection$ = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.PinCollection>( CollectionsActions.CollectionsActionTypes.PinCollection ),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                const updatedPreferences = {
                    'pinnedCollection': storeState.shared.user.preferences.pinnedCollection.concat([action.payload])
                };
                this.store$.dispatch(new UserActions.UpdatePreferences(updatedPreferences));
                return this.collectionsApi.addPinnedCollection(action.payload).pipe(
                    map((res: any) => {
                        return new SnackbarActions.Open({
                            message: this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.PINNED,
                            type: 'xom-snackbar-success',
                            action: 'x'
                        });
                    }),
                    catchError(err =>
                        of(
                            new SnackbarActions.Open({
                                message: this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.NOT_PINNED,
                                type: 'xom-snackbar-error',
                                action: 'x'
                            })
                        )
                    )
                );
            })
        )

    @Effect()
    unpinCollection = (): Observable<Action> =>
        this.actions$.pipe(
            ofType<CollectionsActions.UnpinCollection>( CollectionsActions.CollectionsActionTypes.UnpinCollection ),
            withLatestFrom(this.store$),
            switchMap(([action, storeState]) => {
                const currentpins = storeState.shared.user.preferences.pinnedCollection;
                let index: number = null;
                currentpins.forEach((pin, i) => { if (pin === action.payload) { index = i; } });
                currentpins.splice(index, 1);
                const updatedPreferences = {
                    'pinnedCollection': currentpins
                };
                this.store$.dispatch(new UserActions.UpdatePreferences(updatedPreferences));
                return this.collectionsApi.removePinnedCollection(action.payload).pipe(
                    map((res: any) => {
                        return new SnackbarActions.Open({
                            message: this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.UNPINNED,
                            type: 'xom-snackbar-success',
                            action: 'x'
                        });
                    }),
                    catchError(err =>
                        of(
                            new SnackbarActions.Open({
                                message: this.content.global.SNACKBAR_NOTIFICATION.COLLECTIONS.UNPINNED,
                                type: 'xom-snackbar-error',
                                action: 'x'
                            })
                        )
                    )
                );
            })
        )

    constructor(
        private actions$: Actions,
        private store$: Store<fromShared.State>,
        private collectionsApi: CollectionsApiService,
        public content: ContentService<GlobalContent>
    ) {}
}
