import { BasinAwarenessActions } from '../actions/index';

export interface State {
  basin: string;
  loading: boolean;
  geoScalesData: any;
  noGeosentimentData: boolean;
  creamingCurveAvailable: boolean;
  basinMontages: any[];
}

export const initialState: State = {
  basin: '',
  loading: false,
  geoScalesData: null,
  noGeosentimentData: false,
  creamingCurveAvailable: false,
  basinMontages: []
};

export function reducer(
  state = initialState,
  action:
    | BasinAwarenessActions.BasinAwarenessActionsUnion
): State {
  switch (action.type) {
    case BasinAwarenessActions.BasinAwarenessActions.LoadBasin: {
      return {
        ...state,
        basin: action.payload,
        loading: true,
        basinMontages: []
      };
    }
    case BasinAwarenessActions.BasinAwarenessActions.LoadBasinSuccess: {
      return {
        ...state,
        loading: false,
        noGeosentimentData: !action.payload
      };
    }
    case BasinAwarenessActions.BasinAwarenessActions.LoadBasinFailure: {
      return {
        ...state,
        loading: false
      };
    }
    case BasinAwarenessActions.BasinAwarenessActions.GetGeosentimentStaticSuccess: {
      return {
        ...state,
        geoScalesData: action.payload
      };
    }
    case BasinAwarenessActions.BasinAwarenessActions.SetCreamingCurveAvailable: {
      return {
        ...state,
        creamingCurveAvailable: action.payload
      };
    }
    case BasinAwarenessActions.BasinAwarenessActions.SetBasinMontages: {
      return {
        ...state,
        basinMontages: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
