import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class HttpWrapperService {

  constructor(private _http: HttpClient) {}

  get(url: string): Observable<any> {
    return this._http.get(url, { withCredentials: true }).pipe(
      catchError(() => {
        return this._http.get(url).pipe(
          catchError(error => error),
          map(res => res)
        );
      }),
      map(res => res)
    );
  }
}
