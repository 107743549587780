export var AskBobActions;
(function (AskBobActions) {
    AskBobActions["SetBasin"] = "[Ask Bob] Set Basin";
    AskBobActions["SetActiveTab"] = "[Ask Bob] Set Active Tab";
    AskBobActions["SetQueryParameters"] = "[Ask Bob] Set Query Parameters";
    AskBobActions["LoadSummary"] = "[Ask Bob] Load Summary";
    AskBobActions["LoadSummarySuccess"] = "[Ask Bob] Load Summary Success";
    AskBobActions["LoadSummaryFailure"] = "[Ask Bob] Load Summary Failure";
    AskBobActions["LoadAnswers"] = "[Ask Bob] Load Answers";
    AskBobActions["LoadAnswersSuccess"] = "[Ask Bob] Load Answers Success";
    AskBobActions["LoadAnswersFailure"] = "[Ask Bob] Load Answers Failure";
    AskBobActions["SetFeedbackAnswer"] = "[Ask Bob] Set Feedback Answer";
})(AskBobActions || (AskBobActions = {}));
var SetBasin = /** @class */ (function () {
    function SetBasin(payload) {
        this.payload = payload;
        this.type = AskBobActions.SetBasin;
    }
    return SetBasin;
}());
export { SetBasin };
var SetActiveTab = /** @class */ (function () {
    function SetActiveTab(payload) {
        this.payload = payload;
        this.type = AskBobActions.SetActiveTab;
    }
    return SetActiveTab;
}());
export { SetActiveTab };
var SetQueryParameters = /** @class */ (function () {
    function SetQueryParameters(payload) {
        this.payload = payload;
        this.type = AskBobActions.SetQueryParameters;
    }
    return SetQueryParameters;
}());
export { SetQueryParameters };
var LoadSummary = /** @class */ (function () {
    function LoadSummary(parameters, basin) {
        this.parameters = parameters;
        this.basin = basin;
        this.type = AskBobActions.LoadSummary;
    }
    return LoadSummary;
}());
export { LoadSummary };
var LoadSummarySuccess = /** @class */ (function () {
    function LoadSummarySuccess(response) {
        this.response = response;
        this.type = AskBobActions.LoadSummarySuccess;
    }
    return LoadSummarySuccess;
}());
export { LoadSummarySuccess };
var LoadSummaryFailure = /** @class */ (function () {
    function LoadSummaryFailure(payload) {
        this.payload = payload;
        this.type = AskBobActions.LoadSummaryFailure;
    }
    return LoadSummaryFailure;
}());
export { LoadSummaryFailure };
var LoadAnswers = /** @class */ (function () {
    function LoadAnswers(parameters, basin) {
        this.parameters = parameters;
        this.basin = basin;
        this.type = AskBobActions.LoadAnswers;
    }
    return LoadAnswers;
}());
export { LoadAnswers };
var LoadAnswersSuccess = /** @class */ (function () {
    function LoadAnswersSuccess(response) {
        this.response = response;
        this.type = AskBobActions.LoadAnswersSuccess;
    }
    return LoadAnswersSuccess;
}());
export { LoadAnswersSuccess };
var LoadAnswersFailure = /** @class */ (function () {
    function LoadAnswersFailure(payload) {
        this.payload = payload;
        this.type = AskBobActions.LoadAnswersFailure;
    }
    return LoadAnswersFailure;
}());
export { LoadAnswersFailure };
var SetFeedbackAnswer = /** @class */ (function () {
    function SetFeedbackAnswer(payload) {
        this.payload = payload;
        this.type = AskBobActions.SetFeedbackAnswer;
    }
    return SetFeedbackAnswer;
}());
export { SetFeedbackAnswer };
