import { PipeTransform } from '@angular/core';
var JulianDatePipe = /** @class */ (function () {
    function JulianDatePipe() {
    }
    JulianDatePipe.prototype.transform = function (value) {
        var parsedDate = new Date(Date.parse(value));
        var julianDate = Math.floor(((parsedDate.valueOf() - (parsedDate.getTimezoneOffset() * 60000)) / 86400000) + 2440588);
        return julianDate;
    };
    return JulianDatePipe;
}());
export { JulianDatePipe };
