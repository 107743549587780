import { OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { RoutingService } from './shared/services/routing.service';
import { ContentService } from '@shared/services/content.service';
import { UserActions } from '@app/shared/actions';
import * as fromShared from '@shared/reducers';
import { skipWhile, map, take } from 'rxjs/operators';
import { UserAPIService } from '@app/shared/services/user-api.service';
import { AppConfigService } from '@app/app-config.service';
import { Router } from '@angular/router';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { Logger, CryptoUtils } from 'msal';
var AppComponent = /** @class */ (function () {
    function AppComponent(store, routing, router, content, sharedStore, _userSvc, _appConfig, broadcastService, authService) {
        var _this = this;
        this.store = store;
        this.routing = routing;
        this.router = router;
        this.content = content;
        this.sharedStore = sharedStore;
        this._userSvc = _userSvc;
        this._appConfig = _appConfig;
        this.broadcastService = broadcastService;
        this.authService = authService;
        this.browserTypeWarning = false;
        this.screenSizeValidationFlag = false;
        this.screenSizeWarning = false;
        this.minRecommendedWidth = 1680;
        this.minRecommendedHeight = 750;
        this.preferences = null;
        this.showAdminMessage = false;
        this.ESC_KEY_CODE = this.content.global.ESC_KEY_CODE;
        this.loggedIn = false;
        this.subscription = null;
        this.isIframe = false;
        this.routing.startRouteSubscribe();
        this.preferences$ = this.sharedStore.pipe(select(fromShared.getPreferences), skipWhile(function (preferences) { return preferences === null; }), map(function (pref) {
            _this.showAdminMessage = pref.showAdminMessage;
            _this.preferences = pref;
            if (_this.preferences.showFTUE) {
                _this.sharedStore.dispatch(new UserActions.SetActiveModal('tutorial'));
                _this.sharedStore.dispatch(new UserActions.UpdatePreferences({ showFTUE: false }));
            }
            return pref;
        }));
        this.$sharedStoreSuscribe = this.sharedStore.pipe(select(fromShared.getScreenSizeWarning))
            .subscribe(function (res) { return _this.screenSizeValidationFlag = res; });
        this.setScreenSizeWarning(window.innerWidth, window.innerHeight);
        this.adminMessage = this._appConfig.getConfig().admin_message;
    }
    AppComponent.prototype.ngOnInit = function () {
        if ((!(navigator.userAgent.toLowerCase().indexOf('chrome') > -1 || navigator.userAgent.toLowerCase().indexOf('edge') > -1))) {
            this.browserTypeWarning = true;
        }
        this.isIframe = window !== window.parent && !window.opener;
        this.subscription = this.broadcastService.subscribe('msal:acquireTokenFailure', function (payload) {
            console.log(payload);
        });
        this.authService.setLogger(new Logger(function (logLevel, message, piiEnabled) {
            console.log('MSAL Logging: ', message);
        }, {
            correlationId: CryptoUtils.createNewGuid(),
            piiLoggingEnabled: false
        }));
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.$sharedStoreSuscribe.unsubscribe();
        this.broadcastService.getMSALSubject().next(1);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    AppComponent.prototype.onResize = function (event) {
        this.setScreenSizeWarning(event.target.innerWidth, event.target.innerHeight);
    };
    AppComponent.prototype.closeBrowserTypeWarning = function () {
        this.browserTypeWarning = false;
    };
    AppComponent.prototype.closeScreenSizeWarning = function () {
        this.store.dispatch(new UserActions.ScreenSizeWarning());
        this.screenSizeWarning = false;
    };
    AppComponent.prototype.setScreenSizeWarning = function (width, height) {
        if (!this.screenSizeValidationFlag) {
            if (height < this.minRecommendedHeight || width < this.minRecommendedWidth) {
                this.screenSizeWarning = true;
            }
            else {
                this.screenSizeWarning = false;
                this.closeScreenSizeWarning();
            }
        }
    };
    AppComponent.prototype.closeSplashScreen = function () {
        // update API
        this.preferences.showSplashScreen = false;
        this._userSvc.updatePreferences(this.preferences).pipe(take(1)).subscribe();
    };
    AppComponent.prototype.onFtueFinished = function () {
        this.preferences.showFTUE = false;
        this._userSvc.updatePreferences(this.preferences).pipe(take(1)).subscribe();
    };
    AppComponent.prototype.closeAdminMessage = function () {
        // update API
        this.showAdminMessage = false;
    };
    AppComponent.prototype.onKeyDown = function (event) {
        if (event.keyCode === this.ESC_KEY_CODE) {
            if (this.browserTypeWarning) {
                this.closeBrowserTypeWarning();
            }
            if (!this.browserTypeWarning && this.screenSizeWarning) {
                this.closeScreenSizeWarning();
            }
            if (this.preferences && this.preferences.showSplashScreen && !this.showAdminMessage) {
                this.closeSplashScreen();
            }
            if (this.showAdminMessage && this.router.url.includes('/geobrain')) {
                this.closeAdminMessage();
            }
        }
    };
    return AppComponent;
}());
export { AppComponent };
