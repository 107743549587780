/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./xom-splashscreen.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../layouts/modal-layout/modal-layout.component.ngfactory";
import * as i3 from "../../layouts/modal-layout/modal-layout.component";
import * as i4 from "../xom-button/xom-button.component.ngfactory";
import * as i5 from "../xom-button/xom-button.component";
import * as i6 from "./xom-splashscreen.component";
import * as i7 from "../../../app-config.service";
var styles_XomSplashscreenComponent = [i0.styles];
var RenderType_XomSplashscreenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_XomSplashscreenComponent, data: {} });
export { RenderType_XomSplashscreenComponent as RenderType_XomSplashscreenComponent };
export function View_XomSplashscreenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "xom-modal-layout", [], null, null, null, i2.View_ModalLayoutComponent_0, i2.RenderType_ModalLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModalLayoutComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 2, 21, "div", [["modal-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 20, "div", [["class", "row middle-xs splash-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "splash-waves top-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["src", "/assets/images/swoop-top-left.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "close-container no-gutter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "close icon-close increase-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 9, "div", [["class", "info-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "dynamic-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h1", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "p", [["class", "message"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["xom-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buttonClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_XomButtonComponent_0, i4.RenderType_XomButtonComponent)), i1.ɵdid(14, 49152, null, 0, i5.XomButtonComponent, [i1.ElementRef], null, null), (_l()(), i1.ɵted(15, 0, ["", ""])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "no-gutter splash-screen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "div", [["class", "splash-waves bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "img", [["src", "/assets/images/swoop.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "div", [["class", "splash-waves bottom-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "img", [["src", "/assets/images/swoop-front-bottom-left.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "div", [["class", "splash-waves bottom-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 0, "img", [["src", "/assets/images/swoop-bottom-right.svg"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.splashScreen.title; _ck(_v, 11, 0, currVal_0); var currVal_1 = _co.splashScreen.message; _ck(_v, 12, 0, currVal_1); var currVal_2 = _co.splashScreen.button_text; _ck(_v, 15, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.splashScreen.image_url, ""); _ck(_v, 17, 0, currVal_3); }); }
export function View_XomSplashscreenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xom-splashscreen", [], null, null, null, View_XomSplashscreenComponent_0, RenderType_XomSplashscreenComponent)), i1.ɵdid(1, 114688, null, 0, i6.XomSplashscreenComponent, [i7.AppConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var XomSplashscreenComponentNgFactory = i1.ɵccf("xom-splashscreen", i6.XomSplashscreenComponent, View_XomSplashscreenComponent_Host_0, {}, { close: "close" }, []);
export { XomSplashscreenComponentNgFactory as XomSplashscreenComponentNgFactory };
