import { OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
var RoutingService = /** @class */ (function () {
    function RoutingService(router, location) {
        this.router = router;
        this.location = location;
        this.previousUrl = new BehaviorSubject(null);
        this.currentUrl = new BehaviorSubject(null);
        this.hasNavigatedBack = new BehaviorSubject(false);
        this.activateWorkspaceSaveButton = new BehaviorSubject(null);
        this.subscriptions = [];
    }
    RoutingService.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) {
            try {
                subscription.unsubscribe();
            }
            catch (e) {
                console.error('[Error] OnDestroy unsubscribes', e);
            }
        });
    };
    RoutingService.prototype.startRouteSubscribe = function () {
        var _this = this;
        var $routeSubscribe = this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function () {
            _this.previousUrl.next(_this.currentUrl.value);
            _this.currentUrl.next(_this.router.url);
            _this.navigationBackExecution();
            _this.activateWorkspaceSaveButton.next(_this.router.url);
        });
        this.subscriptions.push($routeSubscribe);
    };
    RoutingService.prototype.navigationBackExecution = function () {
        if (this.previousUrl) {
            this.hasNavigatedBack.next(true);
        }
    };
    RoutingService.prototype.goToPreviousUrl = function () {
        if (this.previousUrl) {
            this.location.back();
        }
        else {
            this.router.navigateByUrl('/geobrain');
        }
    };
    RoutingService.ngInjectableDef = i0.defineInjectable({ factory: function RoutingService_Factory() { return new RoutingService(i0.inject(i1.Router), i0.inject(i2.Location)); }, token: RoutingService, providedIn: "root" });
    return RoutingService;
}());
export { RoutingService };
