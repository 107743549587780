var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { UserActions } from '../actions';
/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export var initialState = {
    allUsers: [],
    userInfo: null,
    preferences: null,
    screenSizeFlag: false,
    activeModal: '',
    availableMessages: [],
    promoterCategories: []
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case UserActions.UserActionTypes.LoadAllUsers:
            return __assign({}, state, { allUsers: action.payload });
        case UserActions.UserActionTypes.LoadUser:
            return __assign({}, state, { userInfo: action.payload });
        case UserActions.UserActionTypes.UpdateUser:
            return __assign({}, state, { userInfo: __assign({}, state.userInfo, action.payload) });
        case UserActions.UserActionTypes.LoadPreferences:
            return __assign({}, state, { preferences: action.payload });
        case UserActions.UserActionTypes.UpdatePreferences:
            return __assign({}, state, { preferences: __assign({}, state.preferences, action.payload) });
        case UserActions.UserActionTypes.ScreenSizeWarning:
            return __assign({}, state, { screenSizeFlag: true });
        case UserActions.UserActionTypes.SetActiveModal:
            return __assign({}, state, { activeModal: action.payload });
        case UserActions.UserActionTypes.GetAvailableMessagesSuccess:
            return __assign({}, state, { availableMessages: action.payload });
        case UserActions.UserActionTypes.SetPromoterCategories:
            return __assign({}, state, { promoterCategories: action.payload });
        default: {
            return state;
        }
    }
}
