import { Action } from '@ngrx/store';

export enum LeadMapActionTypes {
    Basin = '[Lead Map] Basin',
    Country = '[Lead Map] Country'
}

export class Basin implements Action {
    readonly type = LeadMapActionTypes.Basin;

    constructor(public payload: string) {}
}

export class Country implements Action {
    readonly type = LeadMapActionTypes.Country;

    constructor(public payload: string) {}
}

export type LeadMapsActionsUnion =
| Basin
| Country;
