import { AwarenessActions, PlayAwarenessActions } from '../actions/index';

export interface State {
  play: any;
  playID: string;
  basin: string;
  element: string;
  riskMeanOEBXOM: string;
  discoveredVolumeIHS: string;
  closureCountXOM: string;
  closureCountIHS: string;
  fieldCountIHS: string;
  wellPenetrationCountIHS: string;
  summaryCharts: any[];
  minAge: number;
  maxAge: number;
}

export const initialState: State = {
  play: null,
  playID: '',
  basin: '',
  element: '',
  riskMeanOEBXOM: '',
  discoveredVolumeIHS: '',
  closureCountXOM: '',
  closureCountIHS: '',
  fieldCountIHS: '',
  wellPenetrationCountIHS: '',
  summaryCharts: [],
  minAge: null,
  maxAge: null
};

export function reducer(
  state = initialState,
  action:
    | AwarenessActions.AwarenessActionsUnion
    | PlayAwarenessActions.PlayAwarenessActionsUnion
): State {
  switch (action.type) {
    case PlayAwarenessActions.PlayAwarenessActions.SetPlay: {
      return {
        ...state,
        play: action.payload
      };
    }
    case PlayAwarenessActions.PlayAwarenessActions.SetPlayElement: {
      return {
        ...state,
        element: action.payload
      };
    }
    case PlayAwarenessActions.PlayAwarenessActions.LoadPlaySummarySuccess: {
      return {
        ...state,
        ...action.playSummary
      };
    }
    case PlayAwarenessActions.PlayAwarenessActions.LoadDiscoveredVolumeSuccess: {
      return {
        ...state,
        discoveredVolumeIHS: action.payload,
      };
    }
    case PlayAwarenessActions.PlayAwarenessActions.LoadDiscoveredVolumeFailure: {
      return {
        ...state,
        discoveredVolumeIHS: '-1'
      };
    }
    case PlayAwarenessActions.PlayAwarenessActions.SetSummaryCharts: {
      return {
        ...state,
        summaryCharts: action.payload
      };
    }
    case PlayAwarenessActions.PlayAwarenessActions.ClearPlaySummary: {
      return {
        ...state,
        playID: '',
        element: '',
        riskMeanOEBXOM: '',
        discoveredVolumeIHS: '',
        closureCountXOM: '',
        closureCountIHS: '',
        fieldCountIHS: '',
        wellPenetrationCountIHS: '',
        summaryCharts: [],
        minAge: null,
        maxAge: null,
      };
    }
    case AwarenessActions.AwarenessActions.SetBasin: {
      if (action.payload === state.basin) {
        return state;
      }
      return {
        ...state,
        play: null,
        playID: '',
        element: '',
        basin: action.payload,
        riskMeanOEBXOM: '',
        discoveredVolumeIHS: '',
        closureCountXOM: '',
        closureCountIHS: '',
        fieldCountIHS: '',
        wellPenetrationCountIHS: '',
        summaryCharts: [],
        minAge: null,
        maxAge: null,
      };
    }
    default: {
      return state;
    }
  }
}
