import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SplashScreen } from '@app/shared/models/SplashScreen.model';
import { AppConfigService } from '@app/app-config.service';

@Component({
  selector: 'xom-splashscreen',
  templateUrl: './xom-splashscreen.component.html',
  styleUrls: ['./xom-splashscreen.component.scss']
})
export class XomSplashscreenComponent implements OnInit {
  splashScreen: SplashScreen;

  @Output() close: EventEmitter<any> = new EventEmitter(null);

  constructor(
    private _appConfig: AppConfigService
  ) {
    this.splashScreen = this._appConfig.getConfig().splashscreen;
  }

  ngOnInit() {
  }

  buttonClicked() {
    if (this.splashScreen.button_link) {
      window.open(this.splashScreen.button_link, '_blank');
    }
    this.close.emit();
  }

}
