import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { AppConfigService } from '@app/app-config.service';
import { ContentService } from '@shared/services/content.service';
import { GlobalContent } from '@shared/shared-content';

export interface TextTrackCueList {
  length: number;
  getCueById(id: string): TextTrackCue;
  item(index: number): TextTrackCue;
  [index: number]: TextTrackCue;
}
@Component({
  selector: 'xom-ftue-static',
  templateUrl: './xom-ftue-static.component.html',
  styleUrls: ['./xom-ftue-static.component.scss']
})

export class XomFTUEStaticComponent implements OnInit {
  @Input() count: number;
  @Output() closeStaticOptions: EventEmitter<any> = new EventEmitter(null);
  @Output() backToWelcome: EventEmitter<any> = new EventEmitter(null);
  @Output() openDynamicOptions: EventEmitter<any> = new EventEmitter(null);

  selectedOption = {
    icon: '',
    title: '',
    description: '',
    image: ''
  };
  apps: any;

  staticOptions = this.globalContent.global.FTUE.STATIC_OPTIONS;
  options = this.globalContent.global.FTUE.OPTIONS;

  constructor(private appConfig: AppConfigService, public globalContent: ContentService<GlobalContent>) {
    this.apps = this.appConfig.getConfig().apps;
  }

  ngOnInit() {
    this.updateSelectedOption(this.staticOptions[this.count]);
  }

  next() {
    if (this.count < this.staticOptions.length - 1) {
      this.count++;
      this.updateSelectedOption(this.staticOptions[this.count]);
    } else {
      this.openDynamicOptions.emit();
    }
  }

  back() {
      if (this.count - 1 < 0) {
        this.backToWelcome.emit();
      }
      if (this.count > 0) {
        this.count--;
        this.updateSelectedOption(this.staticOptions[this.count]);
      }
  }

  updateSelectedOption(option) {
    this.selectedOption.icon = option.icon;
    this.selectedOption.title = option.title;
    this.selectedOption.description = option.description;
    this.selectedOption.image = option.image;
  }
}
