export var PlayAwarenessActions;
(function (PlayAwarenessActions) {
    PlayAwarenessActions["SetPlay"] = "[Awareness] Set Play";
    PlayAwarenessActions["SetPlayElement"] = "[Awareness] Set Play Element";
    PlayAwarenessActions["LoadPlaySummary"] = "[Awareness] Load Play Summary";
    PlayAwarenessActions["LoadPlaySummarySuccess"] = "[Awareness] Load Play Summary Success";
    PlayAwarenessActions["LoadPlaySummaryFailure"] = "[Awareness] Load Play Summary Failure";
    PlayAwarenessActions["LoadDiscoveredVolume"] = "[Awareness] Load Discovered Volume";
    PlayAwarenessActions["LoadDiscoveredVolumeSuccess"] = "[Awareness] Load Discovered Volume Success";
    PlayAwarenessActions["LoadDiscoveredVolumeFailure"] = "[Awareness] Load Discovered Volume Failure";
    PlayAwarenessActions["LoadRiskMean"] = "[Awareness] Load Risk Mean";
    PlayAwarenessActions["LoadStats"] = "[Awareness] Load Stats";
    PlayAwarenessActions["SetSummaryCharts"] = "[Awareness] Set Summary Charts";
    PlayAwarenessActions["ClearPlaySummary"] = "[Awareness] Clear Play Summary";
})(PlayAwarenessActions || (PlayAwarenessActions = {}));
var SetPlay = /** @class */ (function () {
    function SetPlay(payload) {
        this.payload = payload;
        this.type = PlayAwarenessActions.SetPlay;
    }
    return SetPlay;
}());
export { SetPlay };
var SetPlayElement = /** @class */ (function () {
    function SetPlayElement(payload) {
        this.payload = payload;
        this.type = PlayAwarenessActions.SetPlayElement;
    }
    return SetPlayElement;
}());
export { SetPlayElement };
var LoadPlaySummary = /** @class */ (function () {
    function LoadPlaySummary(playID) {
        this.playID = playID;
        this.type = PlayAwarenessActions.LoadPlaySummary;
    }
    return LoadPlaySummary;
}());
export { LoadPlaySummary };
var LoadPlaySummarySuccess = /** @class */ (function () {
    function LoadPlaySummarySuccess(playSummary) {
        this.playSummary = playSummary;
        this.type = PlayAwarenessActions.LoadPlaySummarySuccess;
    }
    return LoadPlaySummarySuccess;
}());
export { LoadPlaySummarySuccess };
var LoadPlaySummaryFailure = /** @class */ (function () {
    function LoadPlaySummaryFailure(payload) {
        this.payload = payload;
        this.type = PlayAwarenessActions.LoadPlaySummaryFailure;
    }
    return LoadPlaySummaryFailure;
}());
export { LoadPlaySummaryFailure };
var SetSummaryCharts = /** @class */ (function () {
    function SetSummaryCharts(payload) {
        this.payload = payload;
        this.type = PlayAwarenessActions.SetSummaryCharts;
    }
    return SetSummaryCharts;
}());
export { SetSummaryCharts };
var LoadDiscoveredVolume = /** @class */ (function () {
    function LoadDiscoveredVolume(payload) {
        this.payload = payload;
        this.type = PlayAwarenessActions.LoadDiscoveredVolume;
    }
    return LoadDiscoveredVolume;
}());
export { LoadDiscoveredVolume };
var LoadDiscoveredVolumeSuccess = /** @class */ (function () {
    function LoadDiscoveredVolumeSuccess(payload) {
        this.payload = payload;
        this.type = PlayAwarenessActions.LoadDiscoveredVolumeSuccess;
    }
    return LoadDiscoveredVolumeSuccess;
}());
export { LoadDiscoveredVolumeSuccess };
var LoadDiscoveredVolumeFailure = /** @class */ (function () {
    function LoadDiscoveredVolumeFailure(payload) {
        this.payload = payload;
        this.type = PlayAwarenessActions.LoadDiscoveredVolumeFailure;
    }
    return LoadDiscoveredVolumeFailure;
}());
export { LoadDiscoveredVolumeFailure };
var LoadRiskMean = /** @class */ (function () {
    function LoadRiskMean(payload) {
        this.payload = payload;
        this.type = PlayAwarenessActions.LoadRiskMean;
    }
    return LoadRiskMean;
}());
export { LoadRiskMean };
var ClearPlaySummary = /** @class */ (function () {
    function ClearPlaySummary() {
        this.type = PlayAwarenessActions.ClearPlaySummary;
    }
    return ClearPlaySummary;
}());
export { ClearPlaySummary };
