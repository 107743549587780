import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take} from 'rxjs/operators';

import { Query, SearchResponse } from '../models/SearchAPI.model';
import { ImageQuery } from '../models/ImageAPI.model';
import { AppConfig, AppConfigService } from '@app/app-config.service';
import * as fromShared from '@shared/reducers/index';
import { Store } from '@ngrx/store';
import { dispatchErrorMessage } from '@app/shared/util/search-util';
import { buildMessageError } from '@app/shared/util/search-util';
import {SearchActions} from '@search/actions';

@Injectable({
    providedIn: 'root',
})
export class SearchAPIService {
  private _config: AppConfig;
  public solrCounts = 0;
  public imageCounts = 0;

  constructor(
    private http: HttpClient,
    private _appConfig: AppConfigService,
    private sharedStore: Store<fromShared.State>
  ) {
    this._config = this._appConfig.getConfig();
    this.counts().pipe(take(1)).subscribe(
      res => {
        this.solrCounts = res.solr ? res.solr : 0;
        this.imageCounts = res.sql ? res.sql : 0;
      },
      err => dispatchErrorMessage(this.sharedStore, err)
    );
  }

  loadClassifications () {
      this.getClassifications().pipe(take(1)).subscribe(res => {
          this.sharedStore.dispatch(new SearchActions.SetClassifications(res.image_types));
      });
  }

  escapeRegExp(string) {
    return string.replace(/["\\]/g, '\$&'); // $& means the whole matched string
  }

  escapeQuery(query) {
    query.keywords = query.keywords.map(keyword => {
      keyword = this.escapeRegExp(keyword);
      return keyword;
    });
  }

  searchDocs(query: Query): Observable<SearchResponse> {
    this.escapeQuery(query);
    return this.http.post<any>(`${this._config.datascience_api_baseurl}/search/docs`, query).pipe(
      map(res => {
        if (res.status) {
          throw Error(buildMessageError(res));
        } else {
          return res;
        }
      })
    );
    // return this.http.get<SearchResponse>(`/assets/data/sample-document-response.json`).pipe(delay(2000));
  }

  searchNewDocs(query: any): Observable<SearchResponse> {
    this.escapeQuery(query);
//    return this.http.get<any>(`/assets/data/sample-newdocuments-response.json`).pipe(
    return this.http.post<any>(`${this._config.datascience_api_baseurl}/search/docs_date`, query).pipe(
      map(res => {
        if (res.status) {
          throw Error(buildMessageError(res));
        } else {
          return res;
        }
      })
    );
  }

  searchImages(query: ImageQuery): Observable<any> {
    return this.http.post<any>(`${this._config.datascience_api_baseurl}/search/images`, query).pipe(
      map(res => {
        if (res.status) {
          throw Error(buildMessageError(res));
        } else {
          return res;
        }
      })
    );
    /*if (query.keywords.includes('carbonate')) {
      return this.http.get<ImageSearchResponse>(`/assets/data/sample-empty-image-response.json`).pipe(delay(3000));
    } else {
      return this.http.get<ImageSearchResponse>(`/assets/data/sample-image-response.json`).pipe(delay(3000));
    }*/
  }

  similarImages(query: number): Observable<any> {
    return this.http.get<any>(`${this._config.datascience_api_baseurl}/images/findsimilar?id=${query}`, {}).pipe(
      map(res => {
        if (res.status) {
          throw Error(buildMessageError(res));
        } else {
          return res;
        }
      })
    );
    // return this.http.get<ImageSearchResponse>(`/assets/data/sample-similar-response.json`).pipe(delay(3000));
  }

  searchAll(query: Query): Observable<SearchResponse> {
    this.escapeQuery(query);
    // return this.http.get<any>('/assets/data/sample-document-response.json').pipe(
    return this.http.post<any>(`${this._config.datascience_api_baseurl}/search/all`, query).pipe(
      map(res => {
        if (res.status) {
          throw Error(buildMessageError(res));
        } else {
          return res;
        }
      })
    );
    // return this.http.get<SearchResponse>('/assets/data/sample-all-response.json');
  }

  counts(): Observable<any> {
    return this.http.get<any>(`${this._config.datascience_api_baseurl}/gistri/counts`).pipe(
      map(res => {
        if (res.status) {
          throw Error(buildMessageError(res));
        } else {
          return res;
        }
      })
    );
  }

  suggestions(query: String): Observable<any> {
    // return this.http.get<SearchResponse>(`/assets/data/sample-suggestions-response.json`);
    return this.http.get<any>(`${this._config.datascience_api_baseurl}/gistri/suggestions/${query}`).pipe(
      map(res => {
        if (res.status) {
          throw Error(buildMessageError(res));
        } else {
          return res;
        }
      })
    );
  }

  getClassifications(): Observable<any> {
    // return this.http.get<SearchResponse>(`/assets/data/sample-classifications-response.json`).pipe(delay(2000));
    return this.http.get<any>(`${this._config.datascience_api_baseurl}/images/classification`).pipe(
      map(res => {
        if (res.status) {
          throw Error(buildMessageError(res));
        } else {
          return res;
        }
      })
    );
  }

  getDocImages(fileName: string[]): Observable<any> {
    return this.http.post<any>(`${this._config.datascience_api_baseurl}/images/imagesByIds`, { fileNames: fileName }).pipe(
      map(res => {
        if (res.status) {
          throw Error(buildMessageError(res));
        } else {
          return res;
        }
      })
    );
    // return this.http.get<any>('/assets/data/sample-docImages-response.json').pipe(delay(5000));
  }

  getSurprisingSentences(query: Query): Observable<any> {
    return this.http.post<any>(`${this._config.datascience_api_baseurl}/gistri/retrieve_surprising_sentences`, query).pipe(
      map(res => {
        if (res.status) {
          throw Error(buildMessageError(res));
        } else {
          return res;
        }
      })
    );
    // return this.http.get<any>(`/assets/data/sample-ss-response.json`);
  }

  export(query: Query): Observable<any> {
    return this.http.post(`${this._config.datascience_api_baseurl}/gistri/export`, query, { responseType: 'text' });
  }
}
