import { HydrationActions } from '../actions';

export interface State {
    hydrationStarted: boolean;
    hydrationCompleted: boolean;
    step_hydrationSearchResetCompleted: boolean;
    step_documentFacetsRemovalCompleted: boolean;
    step_searchCompleted: boolean;
    step_searchDocumentSuccessCompleted: boolean;
    step_facetsSearchCompleted: boolean;
    step_mapServersCompleted: boolean;
    mapServersTotal: number;
    mapServersCounter: number;
}

export const initialState: State = {
    hydrationStarted: false,
    hydrationCompleted: false,
    step_hydrationSearchResetCompleted: false,
    step_documentFacetsRemovalCompleted: false,
    step_searchCompleted: false,
    step_searchDocumentSuccessCompleted: false,
    step_facetsSearchCompleted: false,
    step_mapServersCompleted: false,
    mapServersTotal: 0,
    mapServersCounter: 0
};

export function reducer(
    state = initialState,
    action: HydrationActions.HydrationActionsUnion
): State {
    switch (action.type) {
        case HydrationActions.HydrationActionTypes.ResetHydration:

            return {
                ...state,
                hydrationStarted: false,
                hydrationCompleted: false,
                step_hydrationSearchResetCompleted: false,
                step_documentFacetsRemovalCompleted: false,
                step_searchCompleted: false,
                step_mapServersCompleted: false,
                step_searchDocumentSuccessCompleted: false,
                step_facetsSearchCompleted: false,
                mapServersCounter: 0
            };
        case HydrationActions.HydrationActionTypes.StartHydration:
            let mapServesHydrationCompleted = false;
            if (!action.payload.mapServersTotal) {
                mapServesHydrationCompleted = true;
            }
            return {
                ...state,
                hydrationStarted: true,
                step_mapServersCompleted: mapServesHydrationCompleted,
                mapServersTotal: action.payload.mapServersTotal,
            };
        case HydrationActions.HydrationActionTypes.HydrationSearchResetCompleted:
            return {
                ...state,
                step_hydrationSearchResetCompleted: true
            };
        case HydrationActions.HydrationActionTypes.DocumentFacetsRemovalCompleted:
            return {
                ...state,
                step_documentFacetsRemovalCompleted: true
            };
        case HydrationActions.HydrationActionTypes.SearchCompleted:
            return {
                ...state,
                step_searchCompleted: true
            };
        case HydrationActions.HydrationActionTypes.SearchDocumentSuccessCompleted:
            return {
                ...state,
                step_searchDocumentSuccessCompleted: true
            };
        case HydrationActions.HydrationActionTypes.FacetsSearchCompleted:
            return {
                ...state,
                step_facetsSearchCompleted: true
            };
        case HydrationActions.HydrationActionTypes.MapServerHydrationCompleted:

            let mapServersCounter = state.mapServersCounter;
            let mapServersCompleted = false;

            mapServersCounter += 1;

            if (state.step_mapServersCompleted) {
                mapServersCompleted = true;
            } else {
                if (mapServersCounter === state.mapServersTotal) {
                    mapServersCompleted = true;
                }
            }

            return {
                ...state,
                mapServersCounter: mapServersCounter,
                step_mapServersCompleted: mapServersCompleted
            };
        case HydrationActions.HydrationActionTypes.HydrationStateVerification:

            let propertyCounter = 0;
            let completedStepCounter = 0;

            for (const propt in state) {
                if (propt.startsWith('step_')) {
                    propertyCounter += 1;
                    if (state[propt]) {
                        completedStepCounter += 1;
                    }
                }
            }

            if (completedStepCounter === propertyCounter && state.hydrationStarted) {
                return {
                    ...state,
                    hydrationStarted: false,
                    hydrationCompleted: true
                };
            } else {
                return {
                    ...state
                };
            }
        case HydrationActions.HydrationActionTypes.SetHydrationToInitialState:
            return {
                ...state,
                hydrationStarted: false,
                hydrationCompleted: false,
                step_hydrationSearchResetCompleted: false,
                step_documentFacetsRemovalCompleted: false,
                step_searchCompleted: false,
                step_searchDocumentSuccessCompleted: false,
                step_facetsSearchCompleted: false,
                step_mapServersCompleted: false,
            };
        default: {
            return state;
        }
    }
}
