import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
var HttpWrapperService = /** @class */ (function () {
    function HttpWrapperService(_http) {
        this._http = _http;
    }
    HttpWrapperService.prototype.get = function (url) {
        var _this = this;
        return this._http.get(url, { withCredentials: true }).pipe(catchError(function () {
            return _this._http.get(url).pipe(catchError(function (error) { return error; }), map(function (res) { return res; }));
        }), map(function (res) { return res; }));
    };
    return HttpWrapperService;
}());
export { HttpWrapperService };
