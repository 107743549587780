import { Action } from '@ngrx/store';
import {
  EsriLayer,
} from '@shared/interfaces/esri.interface';
import { Layer } from 'leaflet';
import { Feature, FeatureCollection } from 'geojson';
import { BasemapConfig} from '../models/EsriBasemapLayers';
import { EsriLegendResponse, EsriLayerLegend, EsriServerLegend } from '../models/EsriLegend';
import { MapServerResponse, DefaultLayer } from '@app/shared/interfaces/map-server.interface';
import { GeoTag } from '@app/shared/models/SearchAPI.model';
import { FacetFeatureCollection } from '../models/FacetFeature';


export enum MapLayerActionTypes {
  ActivateLayer = '[ESRILayer] Activate Layer',
  ActivateDynamicMapLayer = '[ESRILayer] Activate Dynamic Map Layer',
  DeactivateDynamicMapLayer = '[ESRILayer] Deactivate Dynamic Map Layer',
  RemoveActivatedMapServer = '[ESRILayer] Remove Activated Map Server',
  ActivateLayerByUrl = '[ESRILayer] Activate Layer By Id',
  ActivateMultipleLayers = '[ESRILayer] Activate Multiple Layers',
  DeactivateAllLayers = '[ESRILayer] Deactivate All Layers',
  SetBasemap = '[ESRILayer] Set Basemap',
  LoadLayers = '[ESRILayer] Load Layers',
  LoadLayersSuccess = '[ESRILayer] Load Layers Success',
  LoadLayersFailure = '[ESRILayer] Load Layers Failure',
  LoadAllMapServers = '[ESRILayer] Load All Map Servers',
  LoadMapServer = '[ESRILayer] Load MapServers',
  LoadMapServerFromPortal = '[ESRILayer] Load MapServer From Portal',
  LoadLegendFromPortal = '[ESRILayer] Load Legend From Portal',
  RemoveMapLegends = '[ESRILayer] Remove Map Legends',
  LoadMapServerSuccess = '[ESRILayer] Load MapServers Success',
  LoadMapServerFailure = '[ESRILayer] Load MapServers Failure',
  ReplaceMapServer = '[ESRILayer] Replace Map Server',
  RemoveMapServer = '[ESRILayer] Remove MapServers',
  RemoveAllMapServers = '[ESRILayer] Remove All Map Servers',
  ActivateCentroid = '[ESRILayer] Activate Centroid',
  LoadLegend = '[ESRILayer] Load Legend',
  LoadLegendSuccess = '[ESRILayer] Load Legend Success',
  LoadLegendFailure = '[ESRILayer] Load Legend Failure',
  ActivateFeaturePopup = '[ESRILayer] Activate Popup',
  ActivateFeaturePopupSuccess = '[ESRILayer] Activate Popup Success',
  HighlightFeature = '[ESRILayer] Highlight Feature Success',
  FilterFeatureLayerSuccess = '[ESRILayer] Filter Feature Success',
  FilterFeatureLayerFailure = '[ESRILayer] Filter Feature Failure',
  SetGeoTags = '[ESRILayer] Set GeoTags',
  LoadLayerFromGeoTag = '[ESRILayer] Load Layer From Geotag',
  SetZoom = '[ESRILayer] Set Zoom',
  LoadFieldData = '[ESRILayer] Load Field Data',
  SetMapLoading = '[ESRILayer] Set Loading',
  SetBounds = '[ESRILayer] Set Bounds',
  SetCoordinates = '[ESRILayer] Set Coordinates',
  ResetActiveCentroid = '[ESRILayer] Reset Active Centroid',
  SetLayerManagerOpen = '[Map AOI] Set Layer Manager Open',
  AddFacetFeaturesCollection = '[ESRILayer] Add Facet Features Collection',
  ClearFacetFeaturesCollection = '[ESRILayer] Clear Facet Features Collection',
  FitGeobodyBounds = '[ESRILayer] Fit Geobody Bounds',
  SetLayerListSaveButtonEnable = '[ESRILayer] Set the save butoon enable in the layer list component',
  SetLastURLMapServerFail = '[ESRILayer] Set the last URl for the failed MapServer'
}

/**
 * Add Layer to Active Layers Actions
  SetBounds = '[ESRILayer] Set Bounds',
  SetCoordinates = '[ESRILayer] Set Coordinates'
}

/**
 * Add Layer to Active Layers Actions
 */
export class ActivateLayer implements Action {
  readonly type = MapLayerActionTypes.ActivateLayer;

  constructor(public payload: Layer) {}
}

export class ActivateDynamicMapLayer implements Action {
  readonly type = MapLayerActionTypes.ActivateDynamicMapLayer;

  constructor(
    public payload: {
      url: string, layerId: number[], opacity: number, plotIndex: number, portalId: string, minZoom: number
    } | null
  ) {}
}

export class DeactivateDynamicMapLayer implements Action {
  readonly type = MapLayerActionTypes.DeactivateDynamicMapLayer;

  constructor(public payload: { url: string, layerId: number }) {}
}

export class RemoveActivatedMapServer implements Action {
  readonly type = MapLayerActionTypes.RemoveActivatedMapServer;

  constructor(public payload: string) {}
}

export class ActivateLayerByUrl implements Action {
  readonly type = MapLayerActionTypes.ActivateLayerByUrl;

  constructor(public payload: string) {}
}

export class ActivateMultipleLayers implements Action {
  readonly type = MapLayerActionTypes.ActivateMultipleLayers;

  constructor(public payload: Layer[]) {}
}

export class SetMapLoading implements Action {
  readonly type = MapLayerActionTypes.SetMapLoading;

  constructor(public payload: boolean) {}
}

/**
 * Deactivate Layer from Collection Actions
 */
export class SetBasemap implements Action {
  readonly type = MapLayerActionTypes.SetBasemap;

  constructor(public payload: BasemapConfig) {}
}

/**
 * Deactivate All Layers Actions
 */
export class DeactivateAllLayers implements Action {
  readonly type = MapLayerActionTypes.DeactivateAllLayers;

  constructor(public payload: boolean) {}
}

/**
 * Filter features within layer
 */
export class FilterFeatureLayerSuccess implements Action {
  readonly type = MapLayerActionTypes.FilterFeatureLayerSuccess;

  constructor(public payload: Layer) {}
}

export class FilterFeatureLayerFailure implements Action {
  readonly type = MapLayerActionTypes.FilterFeatureLayerFailure;

  constructor(public payload: string) {}
}

/**
 * Load Layers Actions
 */
export class LoadLayers implements Action {
  readonly type = MapLayerActionTypes.LoadLayers;

  constructor(public payload: string) {}
}
export class LoadLayersSuccess implements Action {
  readonly type = MapLayerActionTypes.LoadLayersSuccess;

  constructor(public payload: EsriLayer[]) {}
}

export class LoadLayersFailure implements Action {
  readonly type = MapLayerActionTypes.LoadLayersFailure;

  constructor(public payload: any) {}
}
export class LoadAllMapServers implements Action {
  readonly type = MapLayerActionTypes.LoadAllMapServers;

  constructor(public payload: MapServerResponse[]) {}
}
export class LoadMapServer implements Action {
  readonly type = MapLayerActionTypes.LoadMapServer;

  constructor(public payload: {url: string, title: string, opacity: number, plotIndex: number, isCustom: boolean, portalId: string}) {}
}
export class LoadMapServerFromPortal implements Action {
  readonly type = MapLayerActionTypes.LoadMapServerFromPortal;

  constructor(public payload: any) {}
}
export class LoadLegendFromPortal implements Action {
  readonly type = MapLayerActionTypes.LoadLegendFromPortal;

  constructor(public payload: {mapServer: any, portalId: string, url: string}) {}
}
export class RemoveMapLegends implements Action {
  readonly type = MapLayerActionTypes.RemoveMapLegends;
}
export class LoadMapServerSuccess implements Action {
  readonly type = MapLayerActionTypes.LoadMapServerSuccess;

  constructor(public payload: MapServerResponse) {}
}
export class LoadMapServerFailure implements Action {
  readonly type = MapLayerActionTypes.LoadMapServerFailure;

  constructor(public payload: any) {}
}
export class ReplaceMapServer implements Action {
  readonly type = MapLayerActionTypes.ReplaceMapServer;

  constructor(public payload: {mapServer: MapServerResponse, index: number}) {}
}
export class RemoveMapServer implements Action {
  readonly type = MapLayerActionTypes.RemoveMapServer;

  constructor(public payload: MapServerResponse) {}
}
export class RemoveAllMapServers implements Action {
  readonly type = MapLayerActionTypes.RemoveAllMapServers;
}

export class ActivateCentroid implements Action {
  readonly type = MapLayerActionTypes.ActivateCentroid;

  constructor(public payload: boolean) {}
}

/**
 * Load Legend for Layers that are loaded
 */
export class LoadLegend implements Action {
  readonly type = MapLayerActionTypes.LoadLegend;

  constructor(public payload: { url: string}) {}
}

export class LoadLegendSuccess implements Action {
  readonly type = MapLayerActionTypes.LoadLegendSuccess;

  constructor(public payload: EsriServerLegend) {}
}

export class LoadLegendFailure implements Action {
  readonly type = MapLayerActionTypes.LoadLegendFailure;

  constructor(public payload: any) {}
}

/**
 * Add Popup to Active Layer
 */
export class ActivateFeaturePopup implements Action {
  readonly type = MapLayerActionTypes.ActivateFeaturePopup;

  constructor(public payload: Feature) {}
}

export class HighlightFeature implements Action {
  readonly type = MapLayerActionTypes.HighlightFeature;

  constructor(public payload: string) {}
}

export class ActivateFeaturePopupSuccess implements Action {
  readonly type = MapLayerActionTypes.ActivateFeaturePopupSuccess;

  constructor(public payload: Layer) {}
}

export class SetGeoTags implements Action {
  readonly type = MapLayerActionTypes.SetGeoTags;

  constructor(public payload: GeoTag[]) {}
}

export class LoadLayerFromGeoTag implements Action {
  readonly type = MapLayerActionTypes.LoadLayerFromGeoTag;

  constructor(public payload: GeoTag) {}
}

export class SetZoom implements Action {
  readonly type = MapLayerActionTypes.SetZoom;

  constructor(public payload: number) {}
}
export class LoadFieldData implements Action {
  readonly type = MapLayerActionTypes.LoadFieldData;

  constructor(public payload: FeatureCollection) {}
}
export class SetCoordinates implements Action {
  readonly type = MapLayerActionTypes.SetCoordinates;

  constructor(public payload: [number, number]) {}
}
export class SetBounds implements Action {
  readonly type = MapLayerActionTypes.SetBounds;

  constructor(public payload: [number, number][]) {}
}
export class ResetActiveCentroid implements Action {
  readonly type = MapLayerActionTypes.ResetActiveCentroid;

  constructor() {}
}
export class SetLayerManagerOpen implements Action {
  readonly type = MapLayerActionTypes.SetLayerManagerOpen;

  constructor(public payload: Boolean) {}
}
export class AddFacetFeaturesCollection implements Action {
  readonly type = MapLayerActionTypes.AddFacetFeaturesCollection;

  constructor(public payload: FacetFeatureCollection) {}
}

export class ClearFacetFeaturesCollection implements Action {
  readonly type = MapLayerActionTypes.ClearFacetFeaturesCollection;

  constructor() {}
}

export class FitGeobodyBounds implements Action {
  readonly type = MapLayerActionTypes.FitGeobodyBounds;

  constructor(public payload: {bounds: L.LatLngBounds, point: L.LatLng}) {}
}

export class SetLastURLMapServerFail implements Action {
  readonly type = MapLayerActionTypes.SetLastURLMapServerFail;

  constructor(public payload: string) {}
}

export type MapLayerActionsUnion =
  | ActivateLayer
  | ActivateDynamicMapLayer
  | DeactivateDynamicMapLayer
  | RemoveActivatedMapServer
  | ActivateLayerByUrl
  | ActivateMultipleLayers
  | DeactivateAllLayers
  | SetBasemap
  | LoadLayers
  | LoadLayersSuccess
  | LoadLayersFailure
  | LoadAllMapServers
  | LoadMapServer
  | LoadMapServerFromPortal
  | LoadLegendFromPortal
  | RemoveMapLegends
  | LoadMapServerSuccess
  | LoadMapServerFailure
  | ReplaceMapServer
  | RemoveMapServer
  | RemoveAllMapServers
  | ActivateCentroid
  | LoadLegend
  | LoadLegendSuccess
  | LoadLegendFailure
  | ActivateFeaturePopup
  | ActivateFeaturePopupSuccess
  | HighlightFeature
  | FilterFeatureLayerSuccess
  | FilterFeatureLayerFailure
  | SetGeoTags
  | LoadLayerFromGeoTag
  | SetZoom
  | LoadFieldData
  | SetMapLoading
  | SetBounds
  | SetCoordinates
  | ResetActiveCentroid
  | SetLayerManagerOpen
  | AddFacetFeaturesCollection
  | ClearFacetFeaturesCollection
  | FitGeobodyBounds
  | SetLastURLMapServerFail;
