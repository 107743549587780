<xom-modal-layout>
  <div modal-content>
    <div class="row middle-xs ftue-container">
      <div class="col-xs-12 close-container no-gutter">
        <span (click)="closeDynamicOptions.emit()" class="close icon-close increase-icon"></span>
      </div>
      <div class="col-xs-12 ftue-content">
        <div class="title-section">
          <img class="title-icon" *ngIf="selectedOption.icon" src="/assets/icons/{{selectedOption.icon}}.svg" />
          <h1 class="title">{{selectedOption.title}}</h1>
        </div>
        <p class="message" [innerHtml]="selectedOption.description"> </p>
        <p class="message sub-message" *ngIf="!isToolSelected" [innerHtml]="selectedOption.subDescription"> </p>

        <div class="dinamic-section" *ngIf="!isToolSelected">
          <div class="dinamic-section-info">
            {{dinamicSectionInfo[0]}}
          </div>
          <div class="investigation-tools">
            <div class="tool" *ngFor="let app of apps" (click)="openApp(app)">
              <div class="icon">
                <img src="/assets/icons/{{app.icon}}.svg">
              </div>
              <div class="tool-title">{{app.title}}</div>
            </div>
          </div>
          <div class="dinamic-section-info">
            {{dinamicSectionInfo[1]}}
          </div>
          <div class="map-tools">
            <div class="tool map-tool" *ngFor="let tool of mapTools" (click)="openTool(tool)">
              <div class="icon">
                <img src="/assets/icons/{{tool.icon}}.svg">
              </div>
              <div class="tool-title">{{tool.title}}</div>
            </div>
          </div>
        </div>

        <div class="video-section" *ngIf="isToolSelected && selectedOption.videoUrl !== ''">
          <vg-player>
            <video [vgMedia]="media" width="650" height="250" #media id="singleVideo" preload="auto" controls>
              <source src="{{selectedOption.videoUrl}}" type="video/mp4">
            </video>
          </vg-player>
        </div>
        <div class="options">
          <button xom-button class="back-button" (click)="back()">{{options[0]}}</button>
          <button xom-button (click)="closeDynamicOptions.emit()">{{options[2]}}</button>
        </div>
      </div>
    </div>
  </div>
</xom-modal-layout>