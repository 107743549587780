import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig, AppConfigService } from '@app/app-config.service';

import { EMPTY as empty, Observable } from 'rxjs';
import { buildMessageError } from '@shared/util/search-util';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SeisbookAPIService {
  private _config: AppConfig;

  constructor(
    private http: HttpClient,
    private _appConfig: AppConfigService
  ) {
    this._config = this._appConfig.getConfig();
  }

  getImage(url): Observable<Blob> {
    if (!url || url.substr(url.length - 4) === 'null') { return empty; }
    return this.http.get(url, { withCredentials: true, responseType: 'blob' });
  }

  searchSeisbook(queryText: string, basins: string[], pageIndex: number = 1, pageSize = 100): Observable<any> {
    const query: any = [{
      searchBy: 'all',
      searchKeyword: queryText,
    }];

    if (basins.length) {
      query.push({
        searchBy: 'basin',
        searchKeyword: basins
      });
    }

    return this.http.post<any>(`${this._config.seisbook_api_baseurl}api/posts/search/multi?PageIndex=${pageIndex}&PageSize=${pageSize}`,
      query,
      {
        withCredentials: true
      }).pipe(
        map(res => {
          if (res.status) {
            if (res.status === 401 || res.status === 403) {
              return res;
            }
            throw Error(buildMessageError(res));
          } else {
            return res;
          }
        })
      );
  }
}
